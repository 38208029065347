import React from "react";
//import { getValidationMessage } from "../../../../utility/Validation.js";

// Textinput component

export const Input = ({
    ...props
    }) => {
  return (
      <div className="lds_login_form_input">
        <input
           {...props}
            />
      </div>
  );
};