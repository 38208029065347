import React from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({ setLastPage, setNextPage, page, count }) => {
  return (
    <>
      <Pagination>
        {page > 1 && <Pagination.Prev onClick={() => setLastPage()} />}
        <Pagination.Item>{page}</Pagination.Item>
        {page < count && <Pagination.Next onClick={() => setNextPage()} />}
      </Pagination>
    </>
  );
};

export default PaginationComponent;
