import React from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { HEADER_COMPONENT_COLORS } from "utility/constants/constants";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {components} from "react-select";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiMenu-paper": {
    marginTop: "10px", // Adjust this value as per your requirement
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: HEADER_COMPONENT_COLORS.BACKGROUND,
    svg: {
      color: HEADER_COMPONENT_COLORS.MAIN,
    },
    p: {
      color: HEADER_COMPONENT_COLORS.MAIN,
    },
  },
}));

export const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowForwardIosIcon></ArrowForwardIosIcon>
    </components.DropdownIndicator>
  );
};

export const dropDownStyles = {
  menu: provided => ({ ...provided, zIndex: 9999, width: "max-content", minWidth: "100%" }),
  control: (provided, state) => ({
    ...provided,
    fontFamily: 'Lato', // Specify the desired font family
    fontSize: '10px', // Specify the desired font size
    width: "max-content",
    minWidth: "100%",
    height: "min-content"
  }),
  placeholder: (provided, state) => ({
      ...provided,
      fontSize: '10px', // Specify the desired font size
    }),
  // Add more styles for other components if needed
  indicatorSeparator: (base, state) => ({
      ...base,
      // Custom styles for the indicator separator
      display: 'none', // Hide the indicator separator
    }),
  dropdownIndicator: (base, state) => ({
      ...base,
      // Custom styles for the dropdown indicator
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      transform: state.selectProps.menuIsOpen ? 'rotate(0deg)' : null, // Prevent rotation
      transition: 'transform 0.3s ease', // Add a transition effect if needed
    })
};