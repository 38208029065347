import React, { useEffect, useState } from "react";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import TextField from '@mui/material/TextField';
import Select from 'react-select'
import { useDispatch } from "react-redux";
import * as datasetActions from "../../../redux/actions/DataSetActions";

const UploadTaskConfigOptions = ({
  selectedNode,
  formData,
  setFormData,
  handleSave,
  handleDelete,
  datasetPathOptions,
}) => {
  const [save, setSave] = useState(false)
  const [loadFormdata, setLoadFormData] = useState(false);
  const dispatch = useDispatch();
  const [selectedDataset, setSelectedDataset] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedNode.data.taskData) {
      setFormData(selectedNode.data.taskData);
    } else {
      setFormData({
        fileName: "",
        datasetID: "",
        datasetName: "",
        datasetDestination: "",
        datasetPath: ""
      });
    }
    setLoadFormData(true)
  }, [setFormData, selectedNode]);

  useEffect(() => {
    if (formData) {
      const initialDataset = datasetPathOptions.find(option => option.name === formData.datasetName);
      setSelectedDataset(initialDataset);
    }
  }, [formData, datasetPathOptions]);

  useEffect(() => {
    if (save) {
      handleSave();
      setSave(false)
    }
  }, [save, handleSave])

  const getDatasetUrl = (selectedOption) => {
    let dPath = selectedOption.path;
    let dName = selectedOption.name;
    let dID = selectedOption.id;
    dispatch(datasetActions.getLudisUrl({ filePath: dPath }, dID)).then((res) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        datasetDestination: res.value.ludisUrl,
        datasetID: dID,
        datasetName: dName,
        datasetPath: dPath}));
      setSave(true);
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const regex = /\.[0-9a-z]+$/i;
    if (!regex.test(value)) {
      setError('Please enter a valid file name with an extension');
    } else {
      setError('');
    }
    setFormData({ ...formData, fileName: value });
    setSave(true)
  }

  return (
    <div>
      <div className="header">
        <div>Upload</div>
        <div className="delete" onClick={handleDelete}>
          <DeleteOutline></DeleteOutline> Delete
        </div>
      </div>
      <div className="content">
        {formData && loadFormdata && <div className="options">
          <div>
            Upload File Name <span className="required-field"></span>
            <TextField
              value={formData?.fileName}
              onChange={handleChange}
              error={!!error}
              helperText={error}
            />
          </div>
          <div>
            Dataset Destination <span className="required-field"></span>
            <Select
              options={datasetPathOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={selectedDataset}
              onChange={getDatasetUrl}
            />
          </div>
        </div>}
      </div>
    </div>
  );
};

export default UploadTaskConfigOptions;