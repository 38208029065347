import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../ClusteringTemplate/ClusteringTemplate.css";
import "./SliceAndDiceTemplate.css"
import { useSelector } from "react-redux";
import ValueBox from "../ClusteringTemplate/ValueBox";
import DropdownCustomContainer from "../ClusteringTemplate/Dropdown";
import MUIDataTable from "mui-datatables";
import LineGraphChart from "../TemplateComponents/SliceAndDiceCharts";
import bargraphPNG from "./images/multiBar.svg"
import linegraphPNG from "./images/line.svg"
import bargraphSelectedPNG from "./images/multiBar-selected.svg"
import linegraphSelectedPNG from "./images/line-selected.svg"
import stackedbargraphPNG from "./images/stackedBar.svg"
import stackedbargraphSelectedPNG from "./images/stackedBar-selected.svg"
import radarPNG from "./images/radar.svg"
import radarSelectedPNG from "./images/radar-selected.svg"
import * as appActions from "../../../../../redux/actions/AppActions";
import * as DataSetActions from "../../../../../redux/actions/DataSetActions";
import { groupAndSummarize, groupAndSummarize2Vars } from "../template.util";
import AppHeader from "../TemplateComponents/AppHeader";

export function SliceAndDiceTemplate(
    {application}
) {
    const dispatch = useDispatch();
    const [groupedColNames, setGroupedColNames] = useState([])
    const [xval, setXval] = useState('');
    const [yval, setYval] = useState('');
    const [groupVal, setgroupVal] = useState(null);
    const [xAxis, setXAxis] = useState('');
    const [yAxis, setYAxis] = useState('');
    const [group, setgroup] = useState('');
    const [selectedOption, setSelectedOption] = useState('No aggregation');
    const [groupedData, setGroupedData] = useState(null)
    const [linegraphURL, setLineGraphURL] = useState(linegraphPNG)
    const [bargraphURL, setBarGraphURL] = useState(bargraphPNG)
    const [stackedbargraphURL, setStackedBarGraphURL] = useState(stackedbargraphPNG)
    const [radarURL, setRadarURL] = useState(radarPNG)
    const [graphType, setGraphType] = useState(null)
    const [isStacked, setIsStacked] = useState(false)
    useEffect(() => {
        dispatch(appActions.getColumnNames({id: application.id}));
        dispatch(appActions.getGraphData({id: application.id}));
        dispatch(DataSetActions.getDataSet({ id:application.datasets[0] })).then((res) => {
            let path = res.value.entity.path
            let dataSetId = res.value.entity.id
            //get files associated with dataset
            dispatch(
                DataSetActions.getDataSetFiles({
                body: { path: path },
                id : dataSetId
              })
            )
        });
      }, [application, dispatch]);

    const { directoryFiles } = useSelector(
        (state) => state.dataSetReducer
    );
    const { templateData, appColumnNames } = useSelector(
        (state) => state.appReducer
    );

    useEffect(() => {

        const calculateGroupedData = (kmeansData) => {
            var clean_data = null
            if (!groupVal) {
                clean_data = groupAndSummarize(kmeansData, xval, yval)
            } else {
                clean_data = groupAndSummarize2Vars(kmeansData, xval, groupVal, yval)
            }
            setGroupedData(clean_data)
            return clean_data
        }

        if (templateData) {
            calculateGroupedData(templateData[0])
        }
    }, [xval, yval, groupVal, templateData, selectedOption])

    useEffect(() => {
        if(groupedData?.length > 0) {
            getGroupedColumnNames(groupedData)
        }
    }, [xval, yval, groupVal, groupedData, selectedOption])

    // Modal handling
    const [isOpen, setIsOpen] = useState(false);

    const handleDivClick = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const agg_options = ["sum", "mean", "median", "variance", "count"];

    // X Axis dropdown
    const handleXAxisChange = (e) => {
        setXAxis(e.name);
        setXval(e.value);
    };

    // Y Axis dropdown
    const handleYAxisChange = (e) => {
        setYAxis(e.name);
        setYval(e.value);
    };

    // Z Axis dropdown
    const handlegroupChange = (e) => {
        setgroup(e.name);
        setgroupVal(e.value);
    };

    const getGroupedColumnNames = (kmeansData) => {
        const cols = Object.keys(kmeansData[0])
        setGroupedColNames(cols)
        return cols
    }

    const handleGraphSelection = (graphType, stacked) => {
        setLineGraphURL(linegraphPNG)
        setGraphType(graphType)
        setIsStacked(stacked)
        setBarGraphURL(bargraphPNG)
        setStackedBarGraphURL(stackedbargraphPNG)
        setRadarURL(radarPNG)

        if (graphType === 'line') {
            setLineGraphURL(linegraphSelectedPNG)
        }

        if (graphType === 'bar' && !stacked) {
            setBarGraphURL(bargraphSelectedPNG)
        }

        if (graphType === 'bar' && stacked) {
            setStackedBarGraphURL(stackedbargraphSelectedPNG)
        }

        if (graphType === 'radar') {
            setRadarURL(radarSelectedPNG)
        }
    }
    return(

        <div className="templateContainer">
            {application && <AppHeader name={application.name}
                                        description={application.description}
                                        directoryFiles={directoryFiles}
                                        >
                            </AppHeader>}
            <div>
                <div className="axisDisplayText">
                    Axis Display
                </div>
                <div className="axisDisplaySad">
                    <div className="box" style={{left:'0%'}}>
                            {appColumnNames && <div className="dropdownCustomSad">
                                <DropdownCustomContainer
                                    align="flex-end"
                                    axisOptions={appColumnNames}
                                    handleAxisChange={handleXAxisChange}
                                    value={xAxis}
                                    label='X-Axis'
                                    name='xAxis'>
                                </DropdownCustomContainer>
                            </div>}
                        {
                            <div className="axisDisplayXSad">
                                <ValueBox plotData={templateData} axis={xval} label={xAxis} rows={templateData}></ValueBox>
                            </div>
                        }
                    </div>
                    <div className="box" style={{left:'calc(100% / 3)'}}>
                            {appColumnNames &&
                            <div className="dropdownCustomSad">
                                <DropdownCustomContainer
                                    align="flex-start"
                                    axisOptions={appColumnNames}
                                    handleAxisChange={handleYAxisChange}
                                    value={yAxis}
                                    label='Y-Axis'
                                    name='yAxis'
                                    handleDivClick={handleDivClick}
                                    selectedOption={selectedOption}
                                    isOpen={isOpen}
                                    handleCloseModal={handleCloseModal}
                                    agg_options={agg_options}
                                    handleRadioChange={handleRadioChange}>
                                </DropdownCustomContainer>
                            </div>}
                        {
                            <div className="axisDisplayXSad">
                                <ValueBox order="1" align="flex-start" plotData={templateData} axis={yval} label={yAxis} rows={templateData} summaryStat={selectedOption}></ValueBox>
                            </div>
                        }
                    </div>
                    <div className="box" style={{left:'calc(100% / 3 * 2)'}}>
                        {appColumnNames && <div className="dropdownCustomSad">
                            <DropdownCustomContainer
                                align="flex-start"
                                axisOptions={appColumnNames}
                                handleAxisChange={handlegroupChange}
                                value={group}
                                label='Group'
                                name='group'>
                            </DropdownCustomContainer>
                        </div>}
                        {
                            <div className="axisDisplayXSad">
                                <ValueBox order="2" align="flex-start" plotData={templateData} axis={groupVal} label={group} rows={templateData}></ValueBox>
                            </div>
                        }
                    </div>
                </div>
                <div className="clusterPlot">
                    <div className="clusterPlotStyle">
                        <div className="customGraph">
                            {groupedData && xval && yval && graphType &&
                                <LineGraphChart data={groupedData} xval={xval} yval={yval} groupVal={groupVal} type={graphType} isStacked={isStacked} summaryStat={selectedOption}></LineGraphChart>
                            }
                        </div>
                    </div>
                    <div className="graphInfo">
                        <div className="graphInfoHeader">
                            <p>
                                Graph Type
                            </p>
                        </div>
                        <div className="graphTypes">
                            <div className="graphPanel" onClick={() => handleGraphSelection('line',false)} style={{order:0,cursor: 'pointer', backgroundSize: 'contain', backgroundImage: `url("${linegraphURL}")`}}></div>
                            <div className="graphPanel" onClick={() => handleGraphSelection('bar', false)} style={{order:1,cursor: 'pointer', backgroundSize: 'contain', backgroundImage: `url('${bargraphURL}')`}}></div>
                            <div className="graphPanel" onClick={() => handleGraphSelection('bar', true)} style={{order:2,cursor: 'pointer', backgroundSize: 'contain', backgroundImage: `url('${stackedbargraphURL}')`}}></div>
                            <div className="graphPanel" onClick={() => handleGraphSelection('radar', false)} style={{order:3,cursor: 'pointer', backgroundSize: 'contain', backgroundImage: `url('${radarURL}')`}}></div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="datatableStyle">
                {groupedData && groupedColNames && <MUIDataTable
                    title={"Data"}
                    data={groupedData}
                    columns={groupedColNames}
                    options={{
                        selectableRows: false // <===== will turn off checkboxes in rows
                    }}
                />}
            </div>
        </div>
    );

}

export default SliceAndDiceTemplate;