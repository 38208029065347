import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteWorkflow, removeWorkflow} from "../../../redux/actions/WorkflowActions";
import MenuItem from "@mui/material/MenuItem";
import swal from "sweetalert"
import * as commonService from "utility/CommonService.js";

export const DeleteWorkflowModal = ({entity, handleMenuClose, entityType, children}) => {
  const dispatch = useDispatch();
  const history = useHistory()

  const dispatchDeleteWorkflow = async (e) => {
    swal({
      text: `Are you sure you want to remove this from the workflows page?`,
      className: "logout-alert-modal",
      buttons: {
        cancel: true,
        confirm: {
          text: "Delete",
          value: "delete",
        },
      },
    }).then((result) => {
      if (result === "delete") {
        dispatch(deleteWorkflow(entity.id)).then((res) => {
          if (res.value && res.value.success) {
            commonService.forSuccess(entityType + " deleted successfully!", "Success");
            dispatch(removeWorkflow(entity.id));
            if (history.state !== "/workflows") {
              history.push("/workflows");
            }
          } else {
            commonService.forError(entityType + " delete failed", "Error");
          }
        });
      }
    });
    handleMenuClose();
  };

  return (
    <>
      <MenuItem
          dense={true}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            dispatchDeleteWorkflow()
            e.stopPropagation();
          }}
        >{children}
      </MenuItem>
    </>
  );
};
