import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { FLIGHTING_CONSTANTS } from "utility/constants/constants";
import {
  IntegrationInstructionsOutlined,
  HomeOutlined,
  AccountTreeOutlined,
  TimelineOutlined,
  AssignmentOutlined,
  HelpOutlineOutlined,
  MenuBookOutlined,
  PowerSettingsNew,
  StorageOutlined,
  Menu,
  AdminPanelSettingsOutlined,
} from "@mui/icons-material";

function HamburgerMenu({ logout, support, hasFlightingPermission }) {
  const { user } = useSelector((state) => state.authReducer);
  return (
    <Dropdown>
      <Dropdown.Toggle className="menu-dropdown" id="dropdown-basic">
        <Menu id="dropdown_menu_icn" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href={"/home"}>
          <HomeOutlined />
          Home
        </Dropdown.Item>
        {hasFlightingPermission(FLIGHTING_CONSTANTS.INSIGHTS) && (
          <Dropdown.Item href={"/insights"}>
            <TimelineOutlined />
            Insights
          </Dropdown.Item>
        )}
        {hasFlightingPermission(FLIGHTING_CONSTANTS.PROJECTS) && (
          <Dropdown.Item href={"/projectsv2"}>
            <AssignmentOutlined />
            Projects
          </Dropdown.Item>
        )}
        {hasFlightingPermission(FLIGHTING_CONSTANTS.WORKFLOWS) && (
          <Dropdown.Item href={"/workflows"}>
            <AccountTreeOutlined />
            Workflows
          </Dropdown.Item>
        )}
        {hasFlightingPermission(FLIGHTING_CONSTANTS.DATASETS) && (
          <Dropdown.Item href={"/datasets"}>
            <StorageOutlined />
            Datasets
          </Dropdown.Item>
        )}
        {hasFlightingPermission(FLIGHTING_CONSTANTS.NOTEBOOKS) && (
          <Dropdown.Item href={"/projects"}>
            <IntegrationInstructionsOutlined />
            Notebooks
          </Dropdown.Item>
        )}
        <Dropdown.Item href={"#"} onClick={support}>
          <HelpOutlineOutlined />
          Support
        </Dropdown.Item>
        <Dropdown.Item href={"https://ludisanalytics.github.io/documentation/"}>
          <MenuBookOutlined />
          Documentation
        </Dropdown.Item>
        {["administrator", "superadmin"].includes(user.role) && (
          <Dropdown.Item href={"/admin-portal"}>
            <AdminPanelSettingsOutlined />
            Admin
          </Dropdown.Item>
        )}
        <Dropdown.Item href={"/profile-setting"}>
          <img
            src={
              user && user.photo_path
                ? user.photo_path.small
                : require("assets/custom-styles/images/thumbnails/user1.png")
            }
            alt="side_img"
            className="border-round"
          />
          Profile
        </Dropdown.Item>
        <Dropdown.Item href="#" onClick={logout}>
          <PowerSettingsNew />
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default HamburgerMenu;
