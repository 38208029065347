import { HighlightOff } from "@mui/icons-material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/WorkflowActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CreateVisualWorkflowModalV2 = ({
  show,
  onHide,
  setShowCreateManualWorkflow,
}) => {
  const [workflowName, setWorkflowName] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { project } = useSelector((state) => state.projectsReducer);

  const handleInputChange = (e) => {
    setWorkflowName(e.target.value);
  };

  const handleVisualWorkflowCreate = async (e) => {
    e.preventDefault();
    dispatch(
      actions.createWorkflow({
        body: {
          name: workflowName,
          project_id: project.id,
          visual_workflow: true
        },
      })
    ).then((res) => {
      let newWorkflowId = res.value.workflowResp.id;
      history.push(`/visual-workflows/${newWorkflowId}`);
    });
  };

  return (
    <Modal
      className="delete_modal publish_modal lds_workflow_modal"
      centered
      show={show}
      onHide={onHide}
    >
      <div className="delete_modal_wrapper">
        <div className="delete_modal_header">
          <h4>Create a New Workflow</h4>
          <HighlightOff onClick={onHide} />
        </div>
        <form onSubmit={handleVisualWorkflowCreate}>
          <h6>Workflow Name</h6>
          <input
            type="text"
            placeholder={"Input the name of the workflow"}
            value={workflowName}
            name="name"
            onChange={handleInputChange}
            required
          />
          <div>
            <p>Want to manually define workflow steps?</p>
            <div>
              <span
                onClick={() => {
                  onHide();
                  setShowCreateManualWorkflow(true);
                }}
                className="manual-workflow-link"
              >
                Initialize Workflow
              </span>
            </div>
          </div>
          <button className="btn_small" variant="primary" type="submit">
            Open in Designer
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default CreateVisualWorkflowModalV2;
