import { Web3ActionTypes } from "./ActionType";
import * as API from "../../../api/Web3API";

export const getStarMap = (params) => (dispatch) =>
    dispatch({
        type: Web3ActionTypes.GET_STARMAP,
        payload: API.getStarMap(params)
    });

export const getCollections = () => (dispatch) =>
    dispatch({
        type: Web3ActionTypes.GET_COLLECTIONS,
        payload: API.getCollections()
    })

export const getBubbleWrap = (params) => (dispatch) =>
    dispatch({
        type: Web3ActionTypes.GET_BUBBLEWRAP,
        payload: API.getBubbleWrap(params)
    });

export const addCollection = ({ body, contractAddress }) => (dispatch) =>
  dispatch({
    type: Web3ActionTypes.ADD_COLLECTION,
    payload: API.addCollection(body, contractAddress)
  })

export const validateContract = ({ body, contractAddress }) => (dispatch) =>
  dispatch({
    type: Web3ActionTypes.VALIDATE_ALLIUM_CONTRACT,
    payload: API.validateContract(body, contractAddress)
  })
