import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "redux/actions/ProjectActions";

import ProjectDetailsPage from "components/Projects/ProjectDetails";

const ProjectDetails = ({ history }) => {
  const dispatch = useDispatch();
  const project = useSelector(state => state.projectReducer.project);
  let id = history.location.pathname.split("/")[2];

  //effect for get project
  useEffect(() => {
    if (!project || project.id !== parseInt(id)) {
      dispatch(actions.getProject({ id }));
    }
  }, [project, dispatch, history, id]);

  return (
    <ProjectDetailsPage project={project} projectId={parseInt(id)} history={history} />
  )
};

export default ProjectDetails;