import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  clearAppMetrics,
  clearDatasetMetrics,
  clearProjectMetrics,
  clearWorkflowMetrics,
} from "../../redux/actions/MetricsActions/Action";
import {
  getOrganization
} from "../../redux/actions/OrganizationActions/Action";
import BreadCrumb from "../shared/Navigation/BreadCrumb";
import InsightsUsageGraph from "./Graphs/InsightsUsageGraph";
import ProjectsUsageGraph from "./Graphs/ProjectsUsageGraph";
import { ENTITY_TYPES } from "../../utility/constants/constants";
import { isFinite } from "lodash";
import DatasetsUsageGraph from "./Graphs/DatasetsUsageGraph";
import WorkflowsUsageGraph from "./Graphs/WorkflowsUsageGraph";

const EntityUsageDetails = ({
                              isSelected,
                              organizationId,
                              setCurrentOrganizationId,
                              orgAppDetailsClicked,
                              setOrgAppDetailsClicked
                            }) => {
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.organizationReducer);
  const [expand, setExpand] = useState({
    insights: false,
    projects: false,
    datasets: false,
    workflows: false
  });
  const detailsOptions = {
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true
      }]
    },
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
          if (label) {
            label += `: ${value}`;
          }
          return label
        },
        footer: (tooltipItems, data) => {
          let sum = 0;
          for (let i = 0; i < data.datasets.length; i++) {
            if (isFinite(data.datasets[i].data[tooltipItems[i].index])) {
              sum += data.datasets[i].data[tooltipItems[i].index];
            }
          }
          return `Total: ${sum.toString()}`
        }
      }
    }
  }

  const handleBack = () => {
    setOrgAppDetailsClicked(false);
    setCurrentOrganizationId("");
  }

  const handleExpand = (entity) => {
    if (entity === ENTITY_TYPES.INSIGHT) {
      setExpand({
        insights: true,
        projects: false,
        datasets: false,
        workflows: false,
      })
    } else if (entity === ENTITY_TYPES.PROJECT_V2) {
      setExpand({
        insights: false,
        projects: true,
        datasets: false,
        workflows: false,
      })
    } else if (entity === ENTITY_TYPES.DATASET) {
      setExpand({
        insights: false,
        projects: false,
        datasets: true,
        workflows: false,
      })
    } else if (entity === ENTITY_TYPES.WORKFLOW) {
      setExpand({
        insights: false,
        projects: false,
        datasets: false,
        workflows: true,
      })
    }
  }

  const handleCollapse = () => {
    setExpand({
      insights: false,
      projects: false,
      datasets: false,
      workflows: false
    })
  }

  useEffect(() => {
    if (isSelected) {
      dispatch(getOrganization({ id: organizationId }));
    }
  }, [dispatch, isSelected, organizationId])


  useEffect(() => {
    if (isSelected) {
      dispatch(clearAppMetrics());
      dispatch(clearProjectMetrics());
      dispatch(clearDatasetMetrics());
      dispatch(clearWorkflowMetrics());
    }
  }, [dispatch, isSelected])

  return (
    <div className={orgAppDetailsClicked ? "admin_portal_details_panel" : ""}>
      {orgAppDetailsClicked &&
        <BreadCrumb currentLoc={organization?.name} previousLoc={"Admin"}
                    goBack={() => handleBack()} />}
      <div className="admin_tab_panel_container">
        <div className="panel_header">Entity Usage</div>
        {
          Object.values(expand).every((v) => v === false) ?
            <>
              <div className="graph_row">
                <InsightsUsageGraph organizationId={organizationId}
                                    graphTitle={'Number of insights actions'}
                                    graphOptions={detailsOptions}
                                    expand={expand.insights}
                                    handleExpand={handleExpand}></InsightsUsageGraph>
                <ProjectsUsageGraph organizationId={organizationId}
                                    graphTitle={'Number of projects actions'}
                                    graphOptions={detailsOptions}
                                    expand={expand.projects}
                                    handleExpand={handleExpand}></ProjectsUsageGraph>
              </div>
              <div className="graph_row">
                <DatasetsUsageGraph organizationId={organizationId}
                                    graphTitle={'Number of datasets actions'}
                                    graphOptions={detailsOptions}
                                    expand={expand.datasets}
                                    handleExpand={handleExpand}></DatasetsUsageGraph>
                <WorkflowsUsageGraph organizationId={organizationId}
                                    graphTitle={'Number of workflows actions'}
                                    graphOptions={detailsOptions}
                                    expand={expand.workflows}
                                    handleExpand={handleExpand}></WorkflowsUsageGraph>
              </div>
            </>
             :
            <div className="insights_usage_details_graph_container">
              {expand.insights && <InsightsUsageGraph organizationId={organizationId}
                                                      graphTitle={'Number of insights actions'}
                                                      graphOptions={detailsOptions}
                                                      expand={expand.insights}
                                                      handleExpand={handleExpand}
                                                      handleCollapse={handleCollapse}></InsightsUsageGraph>}
              {expand.projects && <ProjectsUsageGraph organizationId={organizationId}
                                                      graphTitle={'Number of projects actions'}
                                                      graphOptions={detailsOptions}
                                                      expand={expand.projects}
                                                      handleExpand={handleExpand}
                                                      handleCollapse={handleCollapse}></ProjectsUsageGraph>}
              {expand.datasets && <DatasetsUsageGraph organizationId={organizationId}
                                                      graphTitle={'Number of datasets actions'}
                                                      graphOptions={detailsOptions}
                                                      expand={expand.datasets}
                                                      handleExpand={handleExpand}
                                                      handleCollapse={handleCollapse}></DatasetsUsageGraph>}
              {expand.workflows && <WorkflowsUsageGraph organizationId={organizationId}
                                                      graphTitle={'Number of workflows actions'}
                                                      graphOptions={detailsOptions}
                                                      expand={expand.workflows}
                                                      handleExpand={handleExpand}
                                                      handleCollapse={handleCollapse}></WorkflowsUsageGraph>}
            </div>
        }
      </div>
    </div>
  )
}

export default EntityUsageDetails