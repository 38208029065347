import React from "react";
import EnableTwoFactorAuth from "../../../components/Auth/TwoFactorAuth/EnableTwoFactorAuth";
import { enable2FA } from "../../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";

const EnableTwoFactorAuthScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  const handleClose = async () => {
    await handle2FAChange(false);
    history.push("/login");
  }

  const handleSubmitCleanup = () => {
    history.push("/");
  }

  const handle2FAChange = async (checked) => {
    return await dispatch(
      enable2FA({
        body: {
          enabled_2fa: checked
        },
        id: user.uid
      })
    )
  }

  return (<EnableTwoFactorAuth history={history} showModal={true} handleSubmitCleanup={handleSubmitCleanup} handleClose={handleClose}></EnableTwoFactorAuth>);
}

export default EnableTwoFactorAuthScreen;