import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  SlidingPanelContainer,
  TabContainer,
  TabLine,
  TabLine1,
} from "./SlidingWindow.styled";
import ContainerLogs from "components/ProjectsV2/ProjectDetails/ContainerLogs";
import CloseIcon from "@mui/icons-material/Close";
import DockerTerminal from "components/ProjectsV2/ProjectDetails/DockerTerminal";
import { ENTITY_TYPES } from "../../../../utility/constants/constants";
import { AppSummaryClickedContext } from "../AppDetails";
const SlidingWindow = (props) => {
  const { isPanelOpen, onBackdropClicked } = props;
  const { app } = useSelector((state) => state.appReducer);
  const terminalWrapperRef = useRef();
  const CONSOLE_TABS = {
    TERMINAL: "Terminal",
    LOGS: "Logs",
  };
  const [currentConsoleTab, setCurrentConsoleTab] = useState(CONSOLE_TABS.LOGS);
  // Set up a window of the last 100 logs
  const logWindow = useRef([]);

  const isCurrentTab = (tabName) => {
    return currentConsoleTab === tabName;
  };

  return (
    <>
      <TabContainer isOpen={isPanelOpen} onClick={onBackdropClicked}>
        <TabLine />
        <TabLine1 />
      </TabContainer>
      <SlidingPanelContainer isOpen={isPanelOpen}>
        <div className="sliding_dev_tools_wrapper">
          <div className="sliding_tools_header">
            <h4>Console</h4>
            <CloseIcon onClick={onBackdropClicked} />
          </div>
          <div className="app_dev_tools_tabs">
            <div
              className="tab"
              onClick={() => setCurrentConsoleTab(CONSOLE_TABS.LOGS)}
              style={
                isCurrentTab(CONSOLE_TABS.LOGS)
                  ? { borderBottom: "solid 3px #6B71FB" }
                  : {}
              }
            >
              <h6>Logs</h6>
            </div>
            <div
              className="tab"
              onClick={() => setCurrentConsoleTab(CONSOLE_TABS.TERMINAL)}
              style={
                isCurrentTab(CONSOLE_TABS.TERMINAL)
                  ? { borderBottom: "solid 3px #6B71FB" }
                  : {}
              }
            >
              <h6>Terminal</h6>
            </div>
          </div>
            <div
              className="sliding_logs"
              style={
                isCurrentTab(CONSOLE_TABS.TERMINAL)
                  ? { display: "none" }
                  : {}
              }
            >
              <ContainerLogs
                summaryClickedContext={AppSummaryClickedContext}
                areLogsVisible={isPanelOpen}
                entityId={app.id}
                entityType={ENTITY_TYPES.APPLICATION}
                containerId={app.container_name}
                logWindow={logWindow.current}
              />
            </div>
            {isCurrentTab(CONSOLE_TABS.TERMINAL) && <div
              className="sliding_logs"
              ref={terminalWrapperRef}
            >
              <DockerTerminal
                containerId={app.container_name}
                terminalWrapperRef={terminalWrapperRef}
                terminalColor={"#F7F7F7"}
              />
            </div>}
        </div>
      </SlidingPanelContainer>
    </>
  );
};

export default SlidingWindow;
