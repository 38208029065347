export const organizationActionTypes = {
  GET_ORGANIZATIONS: "GET_ORGANIZATIONS",
  GET_ORGANIZATIONS_PENDING: "GET_ORGANIZATIONS_PENDING",
  GET_ORGANIZATIONS_FULFILLED: "GET_ORGANIZATIONS_FULFILLED",

  CLEAR_ORGANIZATION: "CLEAR_ORGANIZATION",

  CLEAR_ORGANIZATIONS: "CLEAR_ORGANIZATIONS",
  
  GET_ORGANIZATION: "GET_ORGANIZATION",
  GET_ORGANIZATION_PENDING: "GET_ORGANIZATION_PENDING",
  GET_ORGANIZATION_FULFILLED: "GET_ORGANIZATION_FULFILLED",

  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
  CREATE_ORGANIZATION_PENDING: "CREATE_ORGANIZATION",
  CREATE_ORGANIZATION_FULFILLED: "CREATE_ORGANIZATION_FULFILLED",

  UPDATE_ORGANIZATION: "UPDATE_ORGANIZATION",
  UPDATE_ORGANIZATION_PENDING: "UPDATE_ORGANIZATION_PENDING",
  UPDATE_ORGANIZATION_FULFILLED: "UPDATE_ORGANIZATION_FULFILLED",

  DELETE_ORGANIZATION: "DELETE_ORGANIZATION",
  DELETE_ORGANIZATION_PENDING: "DELETE_ORGANIZATION_PENDING",
  DELETE_ORGANIZATION_FULFILLED: "DELETE_ORGANIZATION_FULFILLED",

  SEAT_AVAILABILITY: "SEAT_AVAILABILITY",

  GET_ACTIVE_USER_COUNTS: "GET_ACTIVE_USER_COUNTS",
  GET_ACTIVE_USER_COUNTS_PENDING: "GET_ACTIVE_USER_COUNTS_PENDING",
  GET_ACTIVE_USER_COUNTS_FULFILLED: "GET_ACTIVE_USER_COUNTS_FULFILLED",

  GET_BUCKET: "GET_BUCKET",
  GET_BUCKET_PENDING: "GET_BUCKET_PENDING",
  GET_BUCKET_FULFILLED: "GET_BUCKET_FULFILLED",

  CLEAR_BUCKET: "CLEAR_BUCKET",

  GET_BUCKETS: "GET_BUCKETS",
  GET_BUCKETS_PENDING: "GET_BUCKETS_PENDING",
  GET_BUCKETS_FULFILLED: "GET_BUCKETS_FULFILLED",

  UPDATE_2FA_REQUIRED_STATUS: "UPDATE_2FA_REQUIRED_STATUS",
  UPDATE_2FA_REQUIRED_STATUS_PENDING: "UPDATE_2FA_REQUIRED_STATUS_PENDING",
  UPDATE_2FA_REQUIRED_STATUS_FULFILLED: "UPDATE_2FA_REQUIRED_STATUS_FULFILLED",
};
