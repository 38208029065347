import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import {
  SHARE_TYPE,
  TO_ENTITY_TYPE,
  ACCESS_LEVEL, ENTITY_TYPES
} from "../../../../utility/constants/constants";

import { forSuccess } from "utility/CommonService.js";
import SelectOrganizationUsers from "./SelectOrganizationUsers";
import StageSharedUsers from "./StageSharedUsers";
import {
  getSharedWithUsersByEntityId
} from "../../../../redux/actions/SharesActions";
import { IconButton } from "@material-ui/core";
import { ArrowBackIosNew, HighlightOff } from "@mui/icons-material";

export const ShareEntityModal = ({
                                   entity,
                                   entityType,
                                   handleMenuClose,
                                   createEntityShare,
                                   getEntities,
                                   filterParams,
                                   showModal,
                                   setShowModal
                                 }) => {
  //redux state and dispatch initialization
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.authReducer);
  const {entityShares} = useSelector((state) => state.sharesReducer)
  //local states
  // usersData - contains initial dispatch of organization users
  // sharedUsers - source of truth for the shares going to be resolved in the backend
  // orgUsersToAdd - contains the intermediate state of changes specific to users part of the organization
  const [sharedUsers, setSharedUsers] = useState([]);
  const [orgUsersToAdd, setOrgUsersToAdd] = useState([]);
  const [hasOrgShare, setHasOrgShare] = useState(false);
  const [isSelectingOrgUsers, setIsSelectingOrgUsers] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    handleMenuClose();
  }

  /*
   * Called when user selects organization users from the Select Organization
   * Users page of the modal. This goes through the orgUsersToAdd array to see
   * if the org users that the user wants to add have been already added in the
   * sharedUsers array. If so, this resolves the various changes that could have
   * been wanted, such as changing the access level or the share type. If not,
   * it adds the new org user to the sharedUsers array.
   */
  const resolveOrgUsers = () => {
    let tempEmails = [...sharedUsers]
    for (const orgUser of orgUsersToAdd) {
      const sharedUserIndex = sharedUsers.findIndex(user => user.email === orgUser.email)
      if (orgUser.share_type === SHARE_TYPE.SHARE) {
        if (sharedUserIndex >= 0) {
          // case that email is in sharedUsers - set access_level
          tempEmails[sharedUserIndex]['access_level'] = orgUser.access_level
          tempEmails[sharedUserIndex]['share_type'] = orgUser.share_type
        } else {
          // case that email is not in sharedUsers - add to sharedUsers
          tempEmails = [...tempEmails, orgUser]
        }
      } else {
        if (sharedUserIndex >= 0) {
          // case that email is in sharedUsers - set to unshare
          tempEmails[sharedUserIndex]['share_type'] = SHARE_TYPE.UNSHARE
        }
      }
    }
    setSharedUsers(tempEmails)
  }

  // Creates body for POST request to create/update user and org shares
  const handleSubmit = (e) => {
    e.preventDefault();
    let createEntityShareBody;
    let entityName;
    createEntityShareBody = {
      entity_id: entity.id.toString(),
      from_user_uid: user.uid.toString(),
      shares: sharedUsers
    }

    let orgShare = {
      to_entity_id: user.organization,
      access_level: ACCESS_LEVEL.VIEW,
      to_entity_type: TO_ENTITY_TYPE.ORGANIZATION,
    }

    if (hasOrgShare) {
      orgShare.share_type = SHARE_TYPE.SHARE
      createEntityShareBody.shares = [...sharedUsers, orgShare]
    } else if (!hasOrgShare && entityShares && entityShares.some((share) => share.to_entity_type === "Organization")) {
      orgShare.share_type = SHARE_TYPE.UNSHARE
      createEntityShareBody.shares = [...sharedUsers, orgShare]
    }

    // TODO: [ENG-1260] Change ENTITY_TYPES.Project_V2 constant to Project
    // TODO: [ENG-1260] Remove Notebook entityName when Notebooks are deprecated
    if (entityType === ENTITY_TYPES.PROJECT_V2) {
      entityName = 'Project'
    } else if (entityType === ENTITY_TYPES.PROJECT) {
      entityName = 'Notebook'
    } else {
      entityName = entityType
    }

    dispatch(createEntityShare(createEntityShareBody)).then((res) => {
      if (res.value?.success && !res.value.message) {
        forSuccess(`${entityName} shared!`, "Success");
      } else if (res.value?.success && res.value.message) {
        forSuccess(`${res.value.message}`, "Success");
      }
      dispatch(getEntities(filterParams));
      handleClose();
    });
  };

  // Set currently shared users and org upon showing the modal
  useEffect(() => {
    if (showModal && entity) {
      dispatch(getSharedWithUsersByEntityId(entity.id, {entityType: entityType.toLowerCase()}));
    }
  }, [dispatch, entity, entityType, showModal])

  useEffect(() => {
    let userData;
    userData = entityShares.map((share) => ({
      share_id: share.id,
      to_entity_id: share.to_entity_id,
      to_entity_uid: share.to_entity_uid,
      share_type: SHARE_TYPE.SHARE,
      email: share.email,
      access_level: share.access_level,
      to_entity_type: share.to_entity_type,
    }));
    setHasOrgShare(userData.some((share) => share.to_entity_type === "Organization"));
    setSharedUsers(userData);
  }, [entityShares])

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        animation={false}
        className="share_entity_modal"
      >
        <Modal.Header className="border-0 share_header">
          {isSelectingOrgUsers ? (
            <div className="select_org_users_header">
              <IconButton onClick={() => setIsSelectingOrgUsers(false)}
                          aria-label="Back">
                <ArrowBackIosNew></ArrowBackIosNew>
              </IconButton>
              <div className="header_text">Select from organization</div>
            </div>
          ) : (
            <div className="header_text">Share</div>
          )}
          <IconButton onClick={handleClose} aria-label="Close">
            <HighlightOff></HighlightOff>
          </IconButton>
        </Modal.Header>
        <form onSubmit={handleSubmit} data-toggle="validator">
          <Modal.Body className="share_entity_modal_body">
            {isSelectingOrgUsers ? (
              <SelectOrganizationUsers entity={entity} entityType={entityType}
                                       user={user} sharedUsers={sharedUsers}
                                       orgUsersToAdd={orgUsersToAdd}
                                       setOrgUsersToAdd={setOrgUsersToAdd}>
              </SelectOrganizationUsers>
            ) : (
              <StageSharedUsers entityType={entityType}
                                hasOrgShare={hasOrgShare}
                                setHasOrgShare={setHasOrgShare}
                                setIsSelectingOrgUsers={setIsSelectingOrgUsers}
                                sharedUsers={sharedUsers}
                                setSharedUsers={setSharedUsers} user={user} entity={entity}>
              </StageSharedUsers>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 share_entity_modal_footer">
            {isSelectingOrgUsers ? (
              <>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    setIsSelectingOrgUsers(false)
                    resolveOrgUsers()
                  }}
                  className="btn share_modal_done_btn"
                >
                  Apply
                </Button>
              </>
            ) : (
              <Button type="submit" value="Submit"
                      className="btn share_modal_done_btn">
                Done
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
