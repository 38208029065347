import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMetricsDetails, getMetricsDetails } from "../../../redux/actions/MetricsActions/Action";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment-timezone";
import { DEFAULT_DATETIME_OPTION } from "../../../utility/constants/constants";
import { capitalizeFirstLetter } from "../../../utility/utility";

const MetricsTable = ({ organizationId, show, entity, timeRange }) => {
  const dispatch = useDispatch();
  const initialFetchDone = useRef(false);
  const user = useSelector((state) => state.authReducer.user);
  const metricsDetails = useSelector((state) => state.metricsReducer.metricsDetails);
  const metricsDetailsCount = useSelector((state) => state.metricsReducer.metricsDetailsCount);
  const [metricsPagination, setMetricsPagination] = useState({
    page: 1,
    limit: 10,
  });

  const handleMetricsPagination = (newPagination) => {
    setMetricsPagination({
      page: newPagination.page,
      limit: newPagination.limit
    })
  }

  const handleEntityHeader = (entity) => {
    if (entity === "applications") {
      return "Insights"
    } else {
      return capitalizeFirstLetter(entity)
    }
  }

  useEffect(() => {
    dispatch(clearMetricsDetails());
    if (show && initialFetchDone.current) {
      handleMetricsPagination({
        page: 1,
        limit: 10
      })
    } else if (show && !initialFetchDone.current) {
      initialFetchDone.current = true
    }
  }, [dispatch, show, timeRange])

  useEffect(() => {
    if (show) {
      dispatch(getMetricsDetails(entity, {
        period: timeRange,
        page: metricsPagination.page,
        limit: metricsPagination.limit,
        organizationId: organizationId
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, show, entity, metricsPagination])

  return (
    <InfiniteScroll
      dataLength={metricsDetails.length}
      next={() => {
        handleMetricsPagination({ ...metricsPagination, page: metricsPagination.page + 1 })
      }}
      hasMore={metricsDetails.length < parseInt(metricsDetailsCount)}
    >
      <table className="ludis_table infinite_scroll">
        <thead className="metrics_table_header">
          <tr className="ludis_table_row">
            <th className="ludis_table_header_cell">{handleEntityHeader(entity)}</th>
            <th className="ludis_table_header_cell">Name</th>
            <th className="ludis_table_header_cell">Email</th>
            <th className="ludis_table_header_cell">Action</th>
            <th className="ludis_table_header_cell">Date</th>
          </tr>
        </thead>
        <tbody>
          {
            metricsDetails && metricsDetails?.map((detail, index) =>
            (
              <tr key={index} className="ludis_table_row">
                <td className="ludis_table_cell">
                  {detail.entity_name}
                </td>
                <td className="ludis_table_cell">
                  {detail.first_name} {detail.last_name}
                </td>
                <td className="ludis_table_cell">
                  {detail.email}
                </td>
                <td className="ludis_table_cell">
                  {detail.action}
                </td>
                <td className="ludis_table_cell">
                  {moment(detail.date).format(user.date_format ? `${user.date_format} HH:mm:ss`: `${DEFAULT_DATETIME_OPTION} HH:mm:ss`)}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </InfiniteScroll>
  )
}

export default MetricsTable