const pythonCode = (url) => (
`import pandas as pd
import os

url = '${url}'
headers = {'x-api-key': os.getenv('LUDIS_API_TOKEN')}

df = pd.read_csv(url, storage_options=headers)
print(df.head())`)

const rCode = (url) => (
`require(httr)
require(readr)

headers = c(
  'X-api-key' = Sys.getenv('LUDIS_API_TOKEN')
)

res <- httr::GET(url = "${url}", httr::add_headers(.headers=headers))

df <- readr::read_csv(rawToChar(res$content))`)

const bashCode = (url) => (
`#!/bin/bash

API_KEY="$LUDIS_API_TOKEN"
CSV_URL="${url}"

# Make the HTTP request with API key
response=$(curl -sL -H "x-api-key: $API_KEY" "$CSV_URL")

# Process the response (CSV data)
if [[ $response ]]; then
  # Perform operations on the CSV data
  echo "$response"
else
  echo "Error: Failed to retrieve CSV data."
fi`)

export const getCsvImportCode = (language, url) => {
  switch (language) {
    case "Python":
      return pythonCode(url)

    case "R":
      return rCode(url);

    case "Bash":
      return bashCode(url);

    default:
      return "error";
  }
};
