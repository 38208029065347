import React, {useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "redux/actions/ProjectActions";
import { PAGE_SIZE_CONSTANTS } from "utility/constants/constants";
import ProjectData from "components/Projects/ProjectList";

const ProjectList = ({history}) => {
  const [filterParams, setFilterParams] = useState({
    page: 1,
    limit: PAGE_SIZE_CONSTANTS.PROJECTS,
    order_by: "DESC",
    sort: "updated_at"
  });
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.authReducer);

  const handleFilter = (e) => {
    let order_by= filterParams.order_by === "DESC" ? "ASC" : "DESC";
    const newParams = {...filterParams,order_by, sort: e.value};
    setFilterParams(newParams);
    dispatch(actions.getProjects({params: newParams}));

  };
  return (
      <div className="lds_right_box_wrapper lds_right_po_box_wrapper">
        <div className="container">
          <ProjectData user={user} history={history} handleFilter={handleFilter}/>
        </div>
      </div>
  )
};

export default ProjectList;