import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getWorkflowMetricsByOrg
} from "../../../redux/actions/MetricsActions/Action";
import TimeRangeIndicator
  from "../../shared/Graph/TimeRangeIndicator/TimeRangeIndicator";
import StackedBarGraph from "../../shared/Graph/StackedBarGraph";
import EmptyGraph from "../../shared/Graph/EmptyGraph";
import Loader from "../../shared/Loader/Loader";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton } from "@material-ui/core";
import { ENTITY_TYPES } from "../../../utility/constants/constants";
import { CloseFullscreen } from "@mui/icons-material";
import MetricsTable from "./MetricsTable";

const WorkflowsUsageGraph = ({organizationId, graphTitle, graphOptions, expand, handleExpand, handleCollapse}) => {
  const dispatch = useDispatch();
  const workflowMetrics = useSelector((state) => state.metricsReducer.workflowMetrics);
  const [isWorkflowMetricsLoaded, setIsWorkflowMetricsLoaded] = useState(false);
  const [workflowsUsageTimeRange, setWorkflowsUsageTimeRange] = useState('week')

  useEffect(() => {
    setIsWorkflowMetricsLoaded(false)
    dispatch(getWorkflowMetricsByOrg(organizationId, {
      period: workflowsUsageTimeRange,
    }))
  }, [dispatch, workflowsUsageTimeRange, organizationId])

  useEffect(() => {
    setIsWorkflowMetricsLoaded(true)
  }, [workflowMetrics])

  const handleTimeChange = (event, newTime) => {
    if (newTime !== null) {
      setWorkflowsUsageTimeRange(newTime)
    }
  }

  return (
    <>
      <div className={expand ? "panel entity_usage_details_graph_container" : "panel entity_usage_graph_container"}>
        <div className={expand ? "panel_header_row expanded" : "panel_header_row"}>
          {graphTitle && <div className="panel_description no_margin">{graphTitle}</div>}
          <div className="time_range_expand_icon_group">
            <TimeRangeIndicator timeRange={workflowsUsageTimeRange}
                                handleTimeChange={handleTimeChange}
                                className={"time_range_button_group align_items_end no_margin"} />
            {
              expand ?
                <IconButton onClick={() => handleCollapse()}>
                  <CloseFullscreen></CloseFullscreen>
                </IconButton> :
                <IconButton onClick={() => handleExpand(ENTITY_TYPES.WORKFLOW)}>
                  <OpenInFullIcon></OpenInFullIcon>
                </IconButton>
            }
          </div>
        </div>
        <>
          {
            isWorkflowMetricsLoaded ?
              <>
                {
                  Object.keys(workflowMetrics).length > 0 ?
                    <StackedBarGraph data={workflowMetrics} options={graphOptions}
                                     barLabels={Object.values(workflowMetrics).map(value => value.name)}
                                     barPercentage={0.2}
                                     barSectionLabels={Object.keys(Object.values(workflowMetrics)[0]).filter(el => el !== 'name')}
                                     className={expand ? "admin_portal_graph" : "entity_usage_graph"} /> :
                    <div className={"empty_graph_container"}>
                      <EmptyGraph />
                    </div>
                }
              </> :
              <Loader loading={!isWorkflowMetricsLoaded}></Loader>
          }
        </>
      </div>
      {
        expand &&
        <MetricsTable organizationId={organizationId} show={expand} entity={"workflows"} timeRange={workflowsUsageTimeRange}></MetricsTable>
      }
    </>
  )
}

export default WorkflowsUsageGraph