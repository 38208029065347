import { Link, useHistory } from "react-router-dom";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { checkSeatAvailability } from "../../redux/actions/OrganizationActions/Action";
import Table from "./UserList/Table";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import { routes } from "utility/constants/constants";

export default function (props) {
  const { user } = useSelector((state) => state.authReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const checkAvailability = () => {
    dispatch(
      checkSeatAvailability({ id: props.match.params.organizationId })
    ).then((res) => {
      if (res && res.value && res.value.success)
        history.push(
          `/organizations/${props.match.params.organizationId}/users/new`
        );
    });
  };

  const goBack = () => {
    return history.push(routes.ADMIN_PORTAL)
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-sm-6">
          <div className="lds_po_heading">
            <BreadCrumb currentLoc={'Organization Users'} previousLoc={'Admin Page'} goBack={goBack} />
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <p
            className="float-right"
            data-toggle="modal"
            data-target="#gitModal"
          >
            {user.role === "superadmin" ? (
              <Link
                to={`/organizations/${props.match.params.organizationId}/flight-settings`}
                className="btn cursor-pointer mr-2"
              >
                Manage Flight Config
              </Link>
            ) : (
              ""
            )}
            <button onClick={checkAvailability} className="btn">
              {" "}
              Add User{" "}
            </button>{" "}
          </p>
        </div>
        <Table {...props} />
      </div>
    </div>
  );
}
