import { AWSActionTypes } from "./actionType";
import * as API from "../../../api/awsAPI";

export const getAwsKeys = () => (dispatch) =>
  dispatch({
    type: AWSActionTypes.GET_AWS_KEYS,
    payload: API.getAwsKeys(),
  });

export const getDirectoryAwsKeys = () => (dispatch) =>
  dispatch({
    type: AWSActionTypes.GET_AWS_KEYS,
    payload: API.getDirectoryAwsKeys(),
  });
