import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { Storage } from "aws-amplify";
import { Form } from "react-bootstrap";
import { Button } from "components/shared/ui-components/form-components";
import * as actions from "../../redux/actions/DataSetActions";
import { forEach } from "lodash";
import * as commonService from "../../utility/CommonService";
import { Modal, ProgressBar } from "react-bootstrap";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import { ENTITY_TYPES } from "utility/constants/constants";

const DatasetForm = ({ defaultForm, history, handleCloseRemoteDataset }) => {
  const [datasetForm, setDatasetForm] = useState(defaultForm);
  const { dataSet } = useSelector((state) => state.dataSetReducer);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const isTemplate = handleCloseRemoteDataset ? true : false;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDatasetForm({ ...datasetForm, [name]: value });
  };

  const loadFile = (event) => {
    setDatasetForm({ ...datasetForm, files: event.target.files });
  };

  useEffect(() => {
    const { files } = datasetForm;
    if (dataSet) {
      if (files) {
        setShow(true);
        forEach(files, (file, index) => {
          Storage.put(`${dataSet.id}/${file.name}`, file, {
            progressCallback(progress) {
              setProgress((progress.loaded / progress.total) * 100);
            },
          })
            .then((res) => {
              setShow(false);
              setProgress(0);
              if (index === files.length - 1) {
                if (!isTemplate) {
                  history.push(`/datasets/${dataSet.id}`);
                } else {
                  handleCloseRemoteDataset(dataSet)
                }
                commonService.isLoading.onNext(false);
              }
            })
            .catch((err) => {
              swal({
                text: "File(s) had an error while uploading.",
                className: "logout-alert-modal",
                buttons: [true, "Ok"],
              });
            });
        });
      }
    }
  }, [dataSet, datasetForm, history, handleCloseRemoteDataset, isTemplate]);

  const onFileChange = (e) => {
    if (!e.nativeEvent.data || e.target.value.trim()) loadFile(e);
    return e;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      actions.createDataSet({
        body: {
          dataset: {
            name: datasetForm.name,
            description: datasetForm.description,
          },
        },
      })
    ).then((res) => {
      if (!datasetForm.files) {
        if (!isTemplate) {
          history.push(`/datasets/${res.value.dataSetInfo.id}`);
        } else {
          handleCloseRemoteDataset(res.value.dataSetInfo)
        }
      }
    });
  };

  return (
    <>
      <Modal
        className="s3_object_modal"
        size="sm"
        show={show}
        scrollable={true}
      >
        <Modal.Header>Uploading...</Modal.Header>
        {
          <Modal.Body>
            <ProgressBar striped now={progress} />
          </Modal.Body>
        }
      </Modal>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className={`lds_right_box_wrapper${handleCloseRemoteDataset ?"_template" : ""}`}>
        <div className="lds_cp_heading">
            {!isTemplate && (
              <BreadCrumb
                currentLoc={`Create ${ENTITY_TYPES.DATASET}`}
                previousLoc={`${ENTITY_TYPES.DATASET} List`}
                goBack={() => history.goBack()}
              />
            )}
          </div>
          <div className="lds_cp_box mt-5">
            <div className="row">
              <div className="col-md-12">
                <div className="lds_cp_box_heading">
                  <h4>Name</h4>
                  <input
                    type="text"
                    placeholder="My new dataset"
                    name="name"
                    required
                    autoFocus
                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                    value={datasetForm.name}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="lds_cp_box_heading lds_cp_box_heading_second">
                  <h4>Description</h4>
                  <textarea
                    rows="4"
                    placeholder="Type Here"
                    name="description"

                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                    value={datasetForm.description}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div>
                  <Form.File
                    type="file"
                    name="file"
                    accept=".csv, .json, .xml, .txt, .xlsx, .xls"
                    multiple={true}
                    onChange={onFileChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="lds_cp_box_heading lds_cp_box_heading_third">
                  <Button className="btn btn-primary" type="submit">
                    Add Dataset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default DatasetForm;
