import { DataSetActionTypes } from "../actions/DataSetActions/actionType";
import { AWS_CONSTANTS, DATASET_TABS } from "utility/constants/constants";
import { nextStateObject } from "utility/utility";
import { appendToBaseUrl } from "utility/CommonService";

export const initialState = {
  datasets: [],
  dataSet: null,
  directoryFiles: null,
  presignedUrl: null,
  filePreview: null,
  ludisDatasets: null,
  datasetSubscriptions: null,
  datasetShares: [],
  datasetScreen: DATASET_TABS.MY_DATASETS,
  datasetsPageCount: 1,
  datasetSharesPageCount: 1,
  currentAccessUrl: null,
};

const getFiles = (files) => {
  let newFilesList = files.CommonPrefixes
  newFilesList.push(...files.Contents)
  //remove folder name as file in sub directory
  newFilesList = newFilesList.filter(file => file.Key !== files.Prefix)
  //create object to return what we need
  const filesObj = {
    Contents: newFilesList,
    IsTruncated: files.IsTruncated,
    Prefix: files.Prefix,
    FileCount: newFilesList.length,
    ContinuationToken: files.ContinuationToken,
    NextContinuationToken: files.NextContinuationToken,
    Name: files.Name
  };
  return filesObj
}

const appendFiles = (state, files) => {
  //append new files/folders to existin contents object
  const { Contents } = state.directoryFiles;
  let newFilesList = [...Contents]
  newFilesList.push(...files.CommonPrefixes)
  newFilesList.push(...files.Contents)
  const filesObj = {
    Contents: newFilesList,
    IsTruncated: files.IsTruncated,
    Prefix: files.Prefix,
    FileCount: newFilesList.length,
    ContinuationToken: files.ContinuationToken,
    NextContinuationToken: files.NextContinuationToken,
    Name: files.Name
  };
  return filesObj
};

const appendSingleDatasetFile = (state, file) => {
  const {
    Contents,
    IsTruncated,
    Prefix,
    FileCount,
    ContinuationToken,
    NextContinuationToken,
    Name,
  } = state.directoryFiles;

  const filesObj = {
    Contents: [{Key: `${AWS_CONSTANTS.AMPLIFY_PREFIX}/${file.key}`}, ...Contents],
    IsTruncated: IsTruncated,
    Prefix: Prefix,
    FileCount: FileCount,
    ContinuationToken: ContinuationToken,
    NextContinuationToken: NextContinuationToken,
    Name: Name,
  };
  return filesObj;
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps
  };
};

export const dataSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case DataSetActionTypes.SET_DATASET_SCREEN_FULFILLED:
      return updateObject(state, { datasetScreen: action.payload });
    case DataSetActionTypes.GET_DATASETS_FULFILLED:
      return updateObject(state, { dataSet: null, datasets: action.payload.datasets, datasetsPageCount: action.payload.pageCount });
    case DataSetActionTypes.GET_DATASET_FULFILLED:
      return updateObject(state, { dataSet: action.payload.entity });
    case DataSetActionTypes.GET_DATASET_FILES_FULFILLED:
      return updateObject(state, { directoryFiles: getFiles(action.payload.listS3FolderContents) });
    case DataSetActionTypes.GET_MORE_DATASET_FILES_FULFILLED:
      return updateObject(state, { directoryFiles: appendFiles(state, action.payload.listS3FolderContents) });
    case DataSetActionTypes.GET_PRESIGNED_URL_FULFILLED:
      return updateObject(state, { presignedUrl: action.payload.geturl });
    case DataSetActionTypes.CREATE_DATASET_FULFILLED:
      return updateObject(state, { dataSet: action.payload.dataSetInfo, datasets: null })
    case DataSetActionTypes.UPDATE_DATASET_FULFILLED:
      let stateObject = {datasets: [...state.datasets], datasetShares: [...state.datasetShares]}
      const nextState = nextStateObject(stateObject, action.payload)
      return updateObject(state, {
        datasets: nextState.datasets,
        datasetShares: nextState.datasetShares
      })
    case DataSetActionTypes.DELETE_DATASET_FULFILLED:
      let preDatasets = [...state.datasets];
      if(action.payload.deleteResp) {
        preDatasets.splice(preDatasets.findIndex(dataset => dataset.id === action.payload.deleteResp.id), 1 );
        return updateObject(state, { dataSet: null, datasets: preDatasets });
      }
      return state
    case DataSetActionTypes.GET_FILE_PREVIEW_FULFILLED:
      return updateObject(state, { filePreview: action.payload.filePreview })
    case DataSetActionTypes.GET_LUDIS_DATASETS_FULFILLED:
      return updateObject(state, { ludisDatasets: action.payload.datasets })
    case DataSetActionTypes.GET_NEW_DATASETS_FULFILLED:
      return updateObject(state, { datasets: action.payload.datasets })
    case DataSetActionTypes.GET_DATASET_SUBSCRIPTIONS_FULFILLED:
      return updateObject(state, { datasetSubscriptions: action.payload.datasetSubscriptions })
    case DataSetActionTypes.CREATE_DATASET_SUBSCRIPTION_FULFILLED:
      return updateObject(state, { datasetSubscriptions: null })
    case DataSetActionTypes.GET_DATASET_SHARES_FULFILLED:
      return updateObject(state, { datasetShares: action.payload.entityShares, datasetSharesPageCount: action.payload.pageCount})
    case DataSetActionTypes.GET_LUDIS_URL_FULFILLED:
      return updateObject(state, {currentAccessUrl:  appendToBaseUrl(action.payload.ludisUrl)})
    case DataSetActionTypes.PREPEND_FILE_TO_LIST:
      return updateObject(state, {directoryFiles: appendSingleDatasetFile(state, action.payload)})
    default:
      return state;
  }
};
