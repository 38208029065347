import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "redux/actions/NotebookActions";
import * as commonService from "utility/CommonService.js";

import NotebookDetailsPage from "components/NoteBooks/NotebookDetails";

export const NotebookDetails = ({ history, location }) => {
  const dispatch = useDispatch();
  const { notebook, iframe } = useSelector((state) => state.notebookReducer);
  const [localURL, setURL] = useState("");
  const [loaded, setLoaded] = useState(false);
  let id = history.location.pathname.split("/")[2];

  //effect for get project
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(actions.openNotebook({ id })).then((res) => {
        if (res.value.success) {
          console.log("open success");
          commonService.isLoading.onNext(true);
          setTimeout((e) => {
            setURL(res.value.iframe);
            commonService.isLoading.onNext(false);
          }, 5000);
        }
      });
    }
  }, [notebook, dispatch, history, iframe, id, loaded]);

  return (
    <NotebookDetailsPage
      history={history}
      notebook={notebook}
      iframe={iframe}
      localURL={localURL}
      project={location.state.project}
    />
  );
};

export default NotebookDetails;
