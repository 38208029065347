import { CommentActionTypes } from "./actionType";
import * as API from "../../../api/commentAPI";

export const createComment = (params) => (dispatch) =>
  dispatch({
    type: CommentActionTypes.CREATE_COMMENT,
    payload: API.createComment({ comment: { ...params } })
  });
export const fetchComments = (reference_id) => (dispatch) =>
  dispatch({
    type: CommentActionTypes.FETCH_COMMENTS,
    payload: API.fetchComments(reference_id)
  });

export const clearComments = () => (dispatch) =>
  dispatch({
    type: CommentActionTypes.CLEAR_COMMENTS
  });
