import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';

const HelpIconButton = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);


  const handleMouseEnter = () => {
    setShowTooltip(true);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="help-icon-button-container"  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Tooltip title={text} open={showTooltip}>
        <IconButton
          className="help-icon-button"
        >
          <img
            src={require("assets/custom-styles/images/thumbnails/help_icn.png")}
            alt="icn"
            className="help-icon-image"
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default HelpIconButton;
