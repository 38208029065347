import React, { useEffect, useState } from "react";
import TimeRangeIndicator
  from "../shared/Graph/TimeRangeIndicator/TimeRangeIndicator";
import LineGraph from "../shared/Graph/LineGraph";
import Loader from "../shared/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../../utility/constants/constants";
import {
  getNumLogins
} from "../../redux/actions/UserActivitiesActions/Action";

const UserActivitiesGraph = ({user}) => {
  const dispatch = useDispatch();
  const [userActivitiesTimeRange, setUserActivitiesTimeRange] = useState('week')
  const {
    numLogins,
  } = useSelector((state) => state.userActivitiesReducer);
  const [isNumLoginsLoaded, setIsNumLoginsLoaded] = useState(false);
  const userActivitiesOptions = {
    legend: {
      position: 'top'
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    },
  }

  useEffect(() => {
    setIsNumLoginsLoaded(false)
    if (user.role === ROLES.ADMINISTRATOR) {
      dispatch(getNumLogins({
        organization: user.organization,
        period: userActivitiesTimeRange
      }))
    } else if (user.role === ROLES.SUPERADMIN) {
      dispatch(getNumLogins({ period: userActivitiesTimeRange, limit: 5 }))
    }
  }, [userActivitiesTimeRange, user.organization, user.role, dispatch])

  useEffect(() => {
    setIsNumLoginsLoaded(true)
  }, [numLogins])

  const handleTimeChange = (event, newTime) => {
    if (newTime !== null) {
      setUserActivitiesTimeRange(newTime)
    }
  }

  return (
    <>
      <div className="panel_horizontal_graph">
        <div className="panel_info">
          <div className="panel_description">Active users over time</div>
          <TimeRangeIndicator timeRange={userActivitiesTimeRange}
                              handleTimeChange={handleTimeChange}
                              className={"time_range_button_group"} />
        </div>
        <div className="panel_horizontal_graph_container">
          <>
            {
              isNumLoginsLoaded ?
                <>
                  {Object.keys(numLogins).length > 0 &&
                    <LineGraph data={numLogins} x={'date'} y={'logins'}
                               lineLabel={'orgName'} type={'line'}
                               options={userActivitiesOptions}
                               className="overview_user_activities_graph" />
                  }
                </> :
                <Loader loading={!isNumLoginsLoaded}></Loader>
            }
          </>
        </div>
      </div>
    </>
  )
}

export default UserActivitiesGraph