import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "redux/actions/NotebookActions";
import { JUPYTERHUB_URL } from "../../config";
import { isEmpty } from "lodash";

const NoteBooks = ({ history, notebook, iframe, localURL, project }) => {
  const dispatch = useDispatch();
  const closeBtn = useRef();
  const [message, setMessage] = useState("");
  const { user } = useSelector((state) => state.authReducer);
  const { devPreview } = useSelector((state) => state.notebookReducer);
  const [portNumber, setPortNumber] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    const { id: userId } = user;
    if (userId) {
      dispatch(actions.generateDevEnvironment({ userId }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (devPreview) {
      const { dev_port, dev_url } = devPreview;

      setPortNumber(dev_port);
      setPreviewUrl(dev_url);
    }
  }, [devPreview]);

  const commitChanges = (e) => {
    e.preventDefault();
    dispatch(
      actions.commitChanges({ body: { message }, id: notebook.id })
    ).then((res) => {
      if (res.value.success) {
        closeBtn.current.click();
        history.push(`/projects/${notebook.project_id}`);
      }
    });
  };

  const openPreviewTab = () => {
    if (!isEmpty(previewUrl)) {
      window.open(previewUrl, "_blank");
    }
  };

  return (
    <div
      id="lds_nbk_det"
      className="lds_right_box_wrapper lds_right_po_box_wrapper notebook-open-wrapper"
    >
      <Link
        to={`/projects/${project.id}`}
        className="cursor-pointer float-left ml-4 mt-2"
      >
        <i className="flaticon-left-arrow"></i> Back
      </Link>
      {/* TODO (mjenkins): don't hardcode the url here, the iframe host can be used */}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${JUPYTERHUB_URL()}user/${
          project.jupyter_name
        }/terminals/1`}
        className="btn float-right"
      >
        terminal
      </a>
      <button
        className="float-right btn mb-2"
        data-toggle="modal"
        data-target="#gitModal"
      >
        Commit
      </button>
      <button className="float-right btn" onClick={openPreviewTab}>
        Preview
      </button>
      <h4 className="float-right previewPortNumber">
        Port Number: {portNumber}
      </h4>
      {localURL && (
        <>
          <div className="iframe_title_cover"></div>
          <div className="iframe_logout_cover"></div>
          <div className="iframe_options_cover"></div>
        </>
      )}
      <div className={`iframe-container ${localURL ? "show" : "fade"}`}>
        <iframe
          src={iframe}
          id="test"
          name="test"
          title="notebookOpenCommit"
        ></iframe>
        <div className="lds_pl_model">
          <div className="modal fade in" id="gitModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4>Commit</h4>
                  <button
                    type="button"
                    ref={closeBtn}
                    className="close close-project-modal"
                    data-dismiss="modal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={commitChanges}>
                    <input
                      type="text"
                      placeholder="Enter Commit Message"
                      value={message}
                      name="message"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button type="submit" className="w-100 btn">
                      submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteBooks;
