import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/Web3Actions"
import { StarmapGenerator } from "./StarmapGenerator";
import { BubblewrapGenerator } from "./BubblewrapGenerator";
import { Select } from "components/shared/ui-components/form-components";
import { Box, Tab, Tabs } from "@mui/material";

const Web3Apps = () => {
    const dispatch = useDispatch();

    const { nodes, links, dropdownOptions, bubblewrap_nodes } = useSelector(
        (state) => state.web3Reducer
    )
    // tabs
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    // vertical spacing
    const rowStyle = {
        marginBottom: '10px',
    };

    useEffect(() => {
        dispatch(
            actions.getCollections()
        ).then(() => {
            dispatch(
                actions.getStarMap({
                    slug: 'bayc'
                })
            )
            dispatch(
                actions.getBubbleWrap({
                    slug: 'bayc'
                })
            )
        })
    }, [dispatch])

    // get starmap for bayc and get collections
    const [collection, setCollection] = useState('Bored Ape Yacht Club')
    const handleChange = (e) => {
        setCollection(e.name)
        dispatch(
            actions.getStarMap({
                slug: e.value
            })
        )
    };

    // get bubblewrap for bayc
    const [bubble_collection, setBubbleCollection] = useState('Bored Ape Yacht Club')
    const handleBubbleChange = (e) => {
        setBubbleCollection(e.name)
        dispatch(
            actions.getBubbleWrap({
                slug: e.value
            })
        )
    };

    return (
        <div className="lds_right_box_wrapper lds_right_po_box_wrapper">
            <div className="container">
                <div className="col-md-6 col-sm-6">
                    <div className="lds_po_heading">
                        <h1>Web3</h1>
                    </div>
                </div>
                <Box square>
                    <Tabs value={tabIndex} onChange={handleTabChange} >
                        <Tab label="Transaction Graph" />
                        <Tab label="Overlapping Collections Graph" />
                    </Tabs>
                </Box>
                <Box sx={{ padding: 2 }}>
                    {tabIndex === 0 && (
                        <Box>
                            <div className="container">
                                <div className="row" style={rowStyle}>
                                    <div className="col-md-9 col-sm-9">
                                        <h3>{collection} Transaction Graph</h3>
                                        <div className="col-md-9 col-sm-9">
                                            <Select
                                                placeholder={collection}
                                                name="collection"
                                                options={dropdownOptions}
                                                onChange={(e) =>
                                                    handleChange({
                                                        name: e.label,
                                                        value: e.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <StarmapGenerator linksData={links} nodesData={nodes} />
                            </div>
                        </Box>
                    )}
                    {tabIndex === 1 && (
                        <Box>
                            <div className="container">
                                <div className="row" style={rowStyle}>
                                    <div className="col-md-9 col-sm-9">
                                        <h3>{bubble_collection} Overlapping Collections Graph</h3>
                                        <div className="spamText"></div>
                                        <div className="col-md-9 col-sm-9">
                                            <Select
                                                placeholder={bubble_collection}
                                                name="bubble_collection"
                                                options={dropdownOptions}
                                                onChange={(e) =>
                                                    handleBubbleChange({
                                                        name: e.label,
                                                        value: e.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <BubblewrapGenerator nodesData={bubblewrap_nodes} />
                            </div>
                        </Box>
                    )}
                </Box>
            </div>
        </div >
    );
};
export default Web3Apps;
