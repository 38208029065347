import { useSelector } from "react-redux";
import { find } from "lodash";

export function useFlighting(featureKey) {
  const { config } = useSelector((state) => state.flightingReducer);
  const { user } = useSelector((state) => state.authReducer);

  const featureConfig = find(config, (property) => {
    if (featureKey === property.key) {
      return true;
    }
    return false;
  });
  return featureConfig ? featureConfig.user_roles?.includes(user?.role) : true;
}
