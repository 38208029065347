import { ArrowForwardIos, ExpandCircleDown } from "@mui/icons-material";
import React from "react";

const BreadCrumb = ({currentLoc, previousLoc, goBack}) => {
  return (
    <div className="lds_breadcrumb">
      <ExpandCircleDown className="back_btn" onClick={goBack} />
      <div className="list_breadcrumb" onClick={goBack}>{previousLoc}</div>
      <ArrowForwardIos className="arrow_forward" />
      <div className="details_breadcrumb">{currentLoc}</div>
    </div>
  );
};

export default BreadCrumb;
