export const DirectoryActionTypes = {
  GET_DIRECTORY: "GET_DIRECTORY",
  GET_DIRECTORY_PENDING: "GET_DIRECTORY_PENDING",
  GET_DIRECTORY_FULFILLED: "GET_DIRECTORY_FULFILLED",

  CREATE_DIRECTORY: "CREATE_DIRECTORY",

  UPDATE_DIRECTORY: "UPDATE_DIRECTORY",

  DELETE_DIRECTORY: "DELETE_DIRECTORY",

  GET_PRESIGNED: "GET_PRESIGNED",

  CREATE_DIR_PIN: "CREATE_DIR_PIN",

  GET_DIR_PIN: "GET_DIR_PIN",
  GET_DIR_PIN_PENDING: "GET_DIR_PIN_PENDING",
  GET_DIR_PIN_FULFILLED: "GET_DIR_PIN_FULFILLED",

  GET_DIRECTORY_SHARE: "GET_DIRECTORY_SHARE",
  GET_DIRECTORY_SHARE_PENDING: "GET_DIRECTORY_SHARE_PENDING",
  GET_DIRECTORY_SHARE_FULFILLED: "GET_DIRECTORY_SHARE_FULFILLED",


};
