import React, { useState } from "react";
// user action
import * as actions from "redux/actions/AuthActions";
import { useDispatch } from "react-redux";
// service
import * as commonService from "utility/CommonService.js";
// ui components
import { Input, Button } from "components/shared/ui-components/form-components";
import { enable2FA } from "redux/actions/AuthActions";

const LoginForm = ({ history, login }) => {
  const [form, setLoginForm] = useState({});
  const dispatch = useDispatch();

  const handle2FAChange = async (checked, userId) => {
    return await dispatch(
      enable2FA({
        body: {
          enabled_2fa: checked
        },
        id: userId
      })
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.login({ user: form })).then(async (res) => {
      if (res.value.success) {
        if (res.value.user.is_2fa_required && !res.value.user.enabled_2fa) {
          await handle2FAChange(true, res.value.user.uid);
          history.push("/two-factor-auth-enable");
        } else if (
          res.value.user.enabled_2fa
        ) {
          history.push("/two-factor-auth-verify");
        } else if (!res.value.user.is_2fa_required && !res.value.enabled_2fa) {
          setTimeout(
            () => commonService.forSuccess("Signed in successfully!", "Success"),
            500
          );
          history.push("/");
        }
      }
    });
    //return form;
  };

  const handleChange = (e) => {
    setLoginForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="lds_login_logo">
          <img
            src={require("assets/custom-styles/images/thumbnails/main_logo_white.png")}
            alt="img-ins"
            height="75"
            width="150"
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="lds_login_box">
          <div className="lds_login_heading">
            <h1>Sign In</h1>
            {/* <p>Don't have an account? <Link to="/register">Sign Up now</Link> </p> */}
          </div>
          <form className="lds_login_form">
            <div className="lds_login_form_input">
              <Input
                type="text"
                placeholder="Enter Email"
                name="email"
                onChange={handleChange}
                value={form.email}
              />
              <div className="lds_login_form_input">
                <Input
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  onChange={handleChange}
                  value={form.password}
                />
              </div>
              <Button className='lds_login_form_btn btn' onClick={handleSubmit}>
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
