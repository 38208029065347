import React from "react";
import { APP_TEMPLATES_IMAGES } from "../../../../utility/constants/constants";
import { StepTitle } from "./StepTitle";
import "./TemplateCreation.scss";
import { TemplateImage } from "./TemplateImage";

export const TemplatePicker = (props) => {
  const { currStep, form, setForm, setNextPageReady } = props;

  const handleImageClick = (image) => {
    setNextPageReady(true);
    setForm({ ...form, app_type: image.title, img_path: image.img });
  };

  return (
    <div className="appInputPanel">
      <StepTitle currStep={currStep} />
      <div className="appContentPanel">
        <div className="imageSelectionContainer">
          {APP_TEMPLATES_IMAGES.map((item, index) => (
            <div
              key={index}
              className={`templateImage ${
                form.app_type === item.title ? "activeImage" : "notActiveImage"
              } templateImageId templateImageIa`}
              onClick={() => handleImageClick(item)}
            >
              <TemplateImage item={item}></TemplateImage>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
