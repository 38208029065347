import React, { useState } from "react";
import InsightTiles from "./InsightTiles";
import ImportInsightModal from "./ImportInsightModal";
import { Button } from "components/shared/ui-components/form-components";
import { Link } from "react-router-dom";
import FilterInsights from "./FilterInsights";
import { DEFAULT_INSIGHT_OPTIONS } from "utility/constants/constants";

const Insights = () => {
  const [appTypeSort, setAppTypeSort] = useState(DEFAULT_INSIGHT_OPTIONS);

  return (
    <div className="lds_right_box_wrapper lds_right_po_box_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="lds_po_heading">
              <h1>Insights</h1>
              <FilterInsights
                setAppTypeSort={setAppTypeSort}
                appTypeSort={appTypeSort}
              >
                {" "}
              </FilterInsights>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="lds_il_right_btn">
              <ul style={{ gap: "5px", display: "flex" }}>
                <li>
                  <Link
                    to="/add-template"
                    className="cursor-pointer"
                    appTypeSort={appTypeSort}
                  >
                    <Button className="btn">create</Button>
                  </Link>
                </li>
                <li>
                  <ImportInsightModal appTypeSort={appTypeSort} />
                </li>
              </ul>
            </div>
          </div>
          <InsightTiles
            setAppTypeSort={setAppTypeSort}
            appTypeSort={appTypeSort}
          ></InsightTiles>
        </div>
      </div>
    </div>
  );
};
export default Insights;
