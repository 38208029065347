import { FlightingActionTypes } from "./actionType";
import * as API from "../../../api/flightingAPI";

export const getConfig = () => (dispatch) =>
  dispatch({
    type: FlightingActionTypes.GET_CONFIG,
    payload: API.getConfig()
  });
  
  export const getOrganizationFlighting = (organization) => (dispatch) =>
  dispatch({
    type: FlightingActionTypes.GET_ORGANIZATION_FLIGHTING,
    payload: API.fetchFlighting({ organization })
  });

export const getFlighting = (organization) => (dispatch) =>
  dispatch({
    type: FlightingActionTypes.GET_FLIGHTING,
    payload: API.fetchFlighting({ organization })
  });

export const createFlightingConfig = ({ params }) => (dispatch) =>
  dispatch({
    type: FlightingActionTypes.CREATE_FLIGHT,
    payload: API.createFlightingConfig(params)
  });

export const updateFlighting = ( id, body ) => (dispatch) =>
  dispatch({
    type: FlightingActionTypes.UPDATE_FLIGHT,
    payload: API.updateFlighting(id, body)
  });

export const clearFlighting = () => (dispatch) =>
  dispatch({
    type: FlightingActionTypes.CLEAR_FLIGHTING,
  });

export const getCurrentOrgFlighting = (organization) => (dispatch) =>
  dispatch({
    type: FlightingActionTypes.GET_CURRENT_ORG_FLIGHTING,
    payload: API.fetchFlighting({ organization })
  })

export const clearCurrentOrgFlighting = () => (dispatch) =>
  dispatch({
    type: FlightingActionTypes.CLEAR_CURRENT_ORG_FLIGHTING,
  })