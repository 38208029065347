import toastr from "toastr";
import { BehaviorSubject } from "rx";
import moment from "moment-timezone";
import { BASE_URL } from "config";

toastr.options = {
  positionClass: "toast-top-right",
  //showDuration: "300",
  timeOut: 3000,
  closeButton: true,
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};
// for global loader service
export const isLoading = new BehaviorSubject(false);

export const forSuccess = (message, title) => toastr.success(message, title);

export const forError = (message, title) => toastr.error(message, title);

export const forWarning = (message, title) => toastr.warning(message, title);

export const isDialogOpen = new BehaviorSubject(false);

export const getFullName = obj => {
  if (obj) return obj.first_name + " " + obj.last_name;
  else return "";
};

export const currencyFormat = number => {
  let fixedTwo = parseFloat(number.toFixed(2));
  return new Intl.NumberFormat("en-IN").format(fixedTwo);
};
export const getSearchParams = (history, key) => {
  const queryParamsString = history.location.search.substring(1), // remove the "?" at the start
      searchParams = new URLSearchParams( queryParamsString );
  return searchParams.get(key)
};

// call this function, passing-in your date
export const dateToFromNowDaily = ( myDate ) => {

  // get from-now for this date
  var fromNow = moment( myDate ).fromNow();
  // ensure the date is displayed with today and yesterday
  return moment( myDate ).calendar( null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay:  'hh:mm A [Yesterday]',
    sameDay:  'hh:mm A [Today] ',
    //nextDay:  '[Tomorrow]',
    //nextWeek: 'dddd',
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    }
  });
}
export const TextTruncate = ({ text, limit }) => {
  if (text && text.length >= limit) return text.substring(0, limit) + "...";
  return text;
};

export const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
}

export const appendToBaseUrl = (urlRoute) => {
  return BASE_URL() + urlRoute
}

export const handleLoadOptions = (displayedItems, pagination, setPagination, paginationCount) => {
  return (_, prevOptions) => {
    const slicedOptions = displayedItems.slice(
      prevOptions.length,
      prevOptions.length + pagination.limit
    )
    const hasMore = paginationCount > prevOptions.length + pagination.limit
    if (hasMore) {
      setPagination({ ...pagination, page: pagination.page + 1 })
    }
    return {
      options: slicedOptions.map(option => {
        return {
          value: option.id,
          label: option.name
        }
      }),
      hasMore: hasMore
    }
  }
}