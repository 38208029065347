import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HelpIconButton from "components/ProjectsV2/ProjectDetails/HelpIconButton";
import * as workflowActions from "../../../redux/actions/WorkflowActions"
import { Modal, Button } from "react-bootstrap";
import * as commonService from "utility/CommonService.js";
import { useHistory } from "react-router-dom";
import {Select} from "components/shared/ui-components/form-components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
export const WorkflowPublishModal = ({projectID, show, setShowWorkflowPublishModal, setShowCreateWorkflowModal, setShowPublishModal}) => {
    const { repoContents } = useSelector(
        (state) => state.projectsReducer
    );
    const history = useHistory();
    const [ selectedWorkflow, setSelectedWorkflow ] = useState(null)
    const { projectWorkflows } = useSelector((state) => state.workflowReducer)

    const dispatch = useDispatch();

    const handleWorkflowChange = (selectedOption) => {
        const selectedWorkflow = projectWorkflows.find(workflow => workflow.id === selectedOption.value);
        setSelectedWorkflow({value: selectedOption.value, label:selectedOption.label, visual:selectedWorkflow.visual});
    };

    const [selectedFiles, setSelectedFiles] = useState([])


    const handleFileClick = (e, fileName) => {
      e.preventDefault();
      if (selectedFiles.includes(fileName)) {
        setSelectedFiles(selectedFiles.filter(file => file !== fileName));
      } else {
        setSelectedFiles([...selectedFiles, fileName]);
      }
    };
    useEffect(() => {
        dispatch(
          workflowActions.getProjectWorkflows({
            projectId: projectID,
            dags: true
          })
        );
        dispatch(
          workflowActions.getSharedProjectWorkflows({
            projectId: projectID
          })
        );
    }, [dispatch, projectID, show]);

    const handleWorkflowSubmit = async (e) => {
        e.preventDefault();
        if (!selectedWorkflow) {
          commonService.forError("Please select or create a workflow")
          return
        }

        if (selectedWorkflow.visual) {
          dispatch(workflowActions.publishVisualWorkflow({
            body: {
              project_id: projectID
            },
            id: selectedWorkflow.value
          })).then((res) => {
            if (res.value?.success) {
              commonService.forSuccess("Workflow Published Successfully", "Success")
              history.push("/workflows");
            }
          })
        } else {
          dispatch(
            workflowActions.publishWorkflow({
              body: {
                project_id: projectID,
                filename: selectedWorkflow.value + '_dag.py',
                files: selectedFiles
              },
              id: selectedWorkflow.value
            })
          ).then((res) => {
            if (res.value && res.value.success) {
              commonService.forSuccess(`Workflow Published Successfully`, "Success");
              history.push("/workflows");
            }
          })
        }
    }

    return (
      projectWorkflows &&
        <>
          <Modal
              className="import_modal"
              show={show}
              onHide={() => setShowWorkflowPublishModal(false)}
              animation={false}
              scrollable={true}
          >
            <Modal.Header closeButton>
              <div className="d-flex align-items-center">
                <button
                  className="publish_back_button"
                  onClick={() => {
                  setShowWorkflowPublishModal(false);
                  setShowPublishModal(true);
                  }}
                >
                    <ArrowBackIosIcon/>
                </button>
                <div className="modal-header-text"> Publish a Workflow </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="publish_modal publish_workflow ">
                <div className="workflow_header">
                  <h4 className="publish_project_text">Select the created workflow <span className="asterisk">*</span></h4>
                  <HelpIconButton text="Please initialize a workflow for this project if none appear in the dropdown below." />
                </div>
                <Select
                  placeholder="Select Workflow"
                  name="workflow"
                  options={projectWorkflows.map((item) => ({ name: item.id.substring(0,7) +': ' + item.name, id: item.id }))}
                  value={selectedWorkflow}
                  onChange={handleWorkflowChange}
                  required
                />
                <h5 className="publish_workflow_text">Don't see the workflow you wish to publish?</h5>
                <div>
                    <div className="left_align_button">
                      <span className="initialize_workflow_button" onClick={() => {
                        setShowCreateWorkflowModal(true)
                        setShowWorkflowPublishModal(false)
                      }}>
                        Create New Workflow
                      </span>
                    </div>
                </div>
                <div>
                  <div>
                    <div className="other_files">
                      <h5 className="publish_workflow_text"> Other files (optional) </h5>
                    </div>
                     <div className="file_select_container">
                     {repoContents.map((item) => (
                        <div key={item.sha}>
                          <div className={`publish_file_select_row`}>
                            <button
                              className={`publish_file_button cursor-pointer  ${selectedFiles.includes(item.name) ? 'selected' : ''}`}
                              type="button"
                              onClick={(e) => handleFileClick(e, item.name)}
                            >
                              {item.name}
                            </button>
                          </div>
                        </div>
                      ))}
                      </div>
                      <div className="workflow_selected">
                      <p>Selected files: <span className="purple_text">{selectedFiles.join(',  ')}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleWorkflowSubmit}
                type="submit"
                value="Submit"
                className="btn float-right"
              >
              publish
               </Button>
            </Modal.Footer>
         </Modal>
        </>
    );
};
export default WorkflowPublishModal;
