import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import UserForm from "../../components/Users/Form";
import { getConfig } from "../../redux/actions/FlightingActions/action";
const Createuser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);
  return (
    <div>
      <UserForm isEdit={false}/>
    </div>
  );
};
export default Createuser;
