import React, { useState } from "react";
import { getCsvImportCode } from "utility/constants/codeTemplates";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClipboard } from "utility/CommonService";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const CodeSnippetPopup = ({ toggleShowSnippet, popupPosition }) => {
  const { currentAccessUrl } = useSelector((state) => state.dataSetReducer);
  const snippetLanguages = ["Python", "R", "Bash"];
  const [currentLanguage, setCurrentLanguage] = useState("Python");
  const handleCodeCopy = () => {
    copyToClipboard(getCsvImportCode(currentLanguage, currentAccessUrl));
    toggleShowSnippet();
  };
  return (
    <div
      className="code_snippet_wrapper"
      style={{ top: popupPosition.top, left: popupPosition.left }}
    >
      <div className="snippet_tools">
        <div className="snippet_languages">
          {snippetLanguages.map((language) => {
            return (
              <div
                style={{
                  fontWeight: currentLanguage === language ? "bold" : "normal",
                }}
                key={language}
                onClick={() => setCurrentLanguage(language)}
              >
                {language}
              </div>
            );
          })}
        </div>
        <div className="snippet_icns">
          <ContentCopyIcon onClick={handleCodeCopy} />
          <CloseIcon onClick={toggleShowSnippet} />
        </div>
      </div>
      <SyntaxHighlighter customStyle={{margin: 0}} language={currentLanguage.toLowerCase()} style={docco}>
        {getCsvImportCode(currentLanguage, currentAccessUrl)}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeSnippetPopup;
