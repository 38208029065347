import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { HighlightOff } from "@mui/icons-material";
import { Button, Input, Select } from "../shared/ui-components/form-components";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";
import { TIER } from "../../config";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import {
  clearBucket,
  clearOrganization,
  createOrganization,
  getActiveUserCounts,
  getBucket,
  getBuckets,
  getOrganization,
  updateOrganization
} from "../../redux/actions/OrganizationActions/Action";
import * as commonService from "utility/CommonService.js";
import {
  getConfig,
  updateFlighting,
  clearFlighting,
  getCurrentOrgFlighting,
  clearCurrentOrgFlighting
} from "../../redux/actions/FlightingActions";
import { ROLES } from "../../utility/constants/constants";
import { AsyncPaginate } from "react-select-async-paginate";
import { handleLoadOptions } from "utility/CommonService.js";

const initialFormState = {
  name: "",
  admin_email: "",
  tier: "",
  max_seats: 0,
  bucket_id: ""
}

const initialFlightingState =
  {
    insights: false,
    projects: false,
    workflows: false,
    datasets: false,
    notebooks: false,
  }

const initialFlightingPayload = [
  {
    "key": "insights",
    "name": "Insights",
    "active": false,
    "user_roles": [
      "developer",
      "management",
      "administrator",
      "superadmin"
    ]
  },
  {
    "key": "projects",
    "name": "Projects",
    "active": false,
    "user_roles": [
      "developer",
      "management",
      "administrator",
      "superadmin"
    ]
  },
  {
    "key": "workflows",
    "name": "Workflows",
    "active": false,
    "user_roles": [
      "developer",
      "management",
      "administrator",
      "superadmin"
    ]
  },
  {
    "key": "datasets",
    "name": "Datasets",
    "active": false,
    "user_roles": [
      "developer",
      "management",
      "administrator",
      "superadmin"
    ]
  },
  {
    "key": "notebooks",
    "name": "Notebooks",
    "active": false,
    "user_roles": [
      "developer",
      "management",
      "administrator",
      "superadmin"
    ]
  }
]

const OrganizationModal = ({
                             currentUser,
                             showModal,
                             setShowModal,
                             isEdit,
                             organizationId = "",
                             setOrganizationId = () => {},
                           }) => {
  const dispatch = useDispatch();
  const simpleValidator = useRef(new SimpleReactValidator());
  const { currentOrgFlighting } = useSelector(
    (state) => state.flightingReducer
  );
  const { organization, bucket, buckets, bucketCount } = useSelector((state) => state.organizationReducer);
  const [isSubmitted, setSubmitted] = useState(false);

  // This holds the checkboxes' states
  const [flightingState, setFlightingState] = useState(initialFlightingState)
  const [form, setForm] = useState(initialFormState);
  const { insights, projects, workflows, datasets, notebooks } = flightingState;

  // This is submitted as the flighting config when the 'Create Organization' button is clicked
  const [flightingPayload, setFlightingPayload] = useState(initialFlightingPayload)

  // Pagination for the buckets dropdown
  const [bucketsPagination, setBucketsPagination] = useState({
    page: 1,
    limit: 10,
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFlightingStateChange = (e) => {
    setFlightingState({
      ...flightingState,
      [e.target.name]: e.target.checked
    })
    const tempFlightingPayload = [ ...flightingPayload ]
    const index = tempFlightingPayload.findIndex(entry => entry.key === e.target.name)
    if (index === -1) {
      // Need to add the missing entry to the flighting payload
      const newEntryName = e.target.name
      tempFlightingPayload.push({
        "key": newEntryName,
        "name": newEntryName.charAt(0).toUpperCase() + newEntryName.slice(1),
        "active": true,
        "user_roles": [
          "developer",
          "management",
          "administrator",
          "superadmin"
        ]
      })
    } else {
      const checkState = tempFlightingPayload[index]["active"]
      tempFlightingPayload[index]["active"] = !checkState
    }
    setFlightingPayload(tempFlightingPayload)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let res;
      if (organization) {
        await dispatch(
          updateFlighting(organization.id, { flightings: flightingPayload })
        );

        res = await dispatch(
          updateOrganization({
            body: {
              organization: form,
            },
            id: organization.id,
          })
        );
      } else {
        res = await dispatch(
          createOrganization({
            body: {
              organization: { ...form, flightings: flightingPayload },
            },
          })
        );
      }

      if (res?.value.success) {
        commonService.forSuccess(
          organization ? "Organization updated successfully!"
            : "Organization created successfully!",
          "Success"
        )
      }

      setForm(initialFormState)
      setFlightingState(initialFlightingState)
      setFlightingPayload(initialFlightingPayload)
      handleClose()
    } else {
      setSubmitted(true);
      simpleValidator.current.showMessages(); //show validation messages
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setForm(initialFormState)
    setFlightingState(initialFlightingState)
    setFlightingPayload(initialFlightingPayload)
    setOrganizationId("");
    setBucketsPagination({
      page: 1,
      limit: 10
    })
    dispatch(clearOrganization())
    dispatch(clearFlighting())
    dispatch(clearCurrentOrgFlighting())
    dispatch(getActiveUserCounts())
  }

  useEffect(() => {
    if (organizationId.length === 0) {
      setForm(initialFormState)
      setFlightingState(initialFlightingState)
      setFlightingPayload(initialFlightingPayload)
      dispatch(clearOrganization())
      dispatch(clearFlighting())
      dispatch(clearCurrentOrgFlighting())
      dispatch(getActiveUserCounts())
      dispatch(clearBucket())
    }
  }, [dispatch, organizationId])

  useEffect(() => {
    if (showModal) {
      if (isEdit && organizationId.length > 0) {
        dispatch(getConfig());
        dispatch(getCurrentOrgFlighting(organizationId));
        dispatch(getOrganization({ id: organizationId }));
      }
    }
  }, [dispatch, showModal, isEdit, organizationId])

  useEffect(() => {
    if (showModal) {
      if (currentUser.role === ROLES.SUPERADMIN) {
        dispatch(getBuckets({ ...bucketsPagination }))
      }
    }
  }, [dispatch, bucketsPagination, currentUser.role, showModal])

  useEffect(() => {
    if (organization && Object.keys(organization).length > 0) {
      dispatch(getBucket({ id: organization.bucket_id }));
      setForm({
        name: organization.name,
        admin_email: organization.admin_email,
        tier: organization.tier,
        max_seats: organization.max_seats,
        bucket_id: organization.bucket_id
      })
    } else {
      setForm(initialFormState)
    }

    if (currentOrgFlighting?.length > 0) {
      let existingFlightingState = { ...initialFlightingState }
      Object.keys(existingFlightingState).forEach(key => {
        // Find key in flighting config
        const index = currentOrgFlighting.findIndex(el => el.key === key)
        if (index > -1) {
          existingFlightingState[key] = currentOrgFlighting[index]["active"]
        } else {
          existingFlightingState[key] = false
        }
      })
      setFlightingPayload(currentOrgFlighting)
      setFlightingState(existingFlightingState)
    } else {
      setFlightingPayload(initialFlightingPayload)
      setFlightingState(initialFlightingState)
    }
  }, [dispatch, currentOrgFlighting, organization])

  return (
    <Modal show={showModal}
           onHide={handleClose}
           animation={false}>
      <Modal.Header className="border-0 modal_header">
        <div className="header_text">
          {organization ? 'Edit organization' : 'Add an organization'}
        </div>
        <IconButton onClick={handleClose} aria-label="Close">
          <HighlightOff></HighlightOff>
        </IconButton>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="add_org_modal_body">
            <div className="add_user_field_header">
              Bucket Name
            </div>
            <AsyncPaginate
              placeholder={bucket ? `${bucket.name} - ${bucket.region}` : "select the bucket"}
              name="bucket_id"
              onChange={(e) => {
                handleChange({
                  target: {value: e.value, name: "bucket_id"},
                })
              }}
              className="add_user_select"
              isDisabled={currentUser.role !== ROLES.SUPERADMIN}
              loadOptions={handleLoadOptions(buckets, bucketsPagination, setBucketsPagination, bucketCount)}
            />
            {simpleValidator.current.message(
              "bucket_id",
              form.bucket_id,
              "required"
            )}
            <div className="field_wrapper">
              <div className="add_user_field_header">
                Organization name
              </div>
              <Input
                type="text"
                name="name"
                placeholder="enter organization name"
                onChange={handleChange}
                value={form.name}
                className="add_user_field"
              />
              {simpleValidator.current.message(
                "name",
                form.name,
                "required"
              )}
            </div>
            <div className="field_wrapper">
              <div className="add_user_field_header">
                Admin Email
              </div>
              <Input
                type="text"
                name="admin_email"
                placeholder="enter admin's email"
                onChange={handleChange}
                value={form.admin_email}
                className="add_user_field"
              />
              {simpleValidator.current.message(
                "admin_email",
                form.admin_email,
                "required|email"
              )}
            </div>
            <div className="add_user_field_header">
              Flighting Configurations
            </div>
            <FormControl
              required
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                <FormControlLabel
                  classes="flighting_configuration_label"
                  control={
                    <Checkbox checked={insights}
                              onChange={handleFlightingStateChange}
                              name="insights" size="small" />
                  }
                  label="Insights"
                />
                <FormControlLabel
                  classes="flighting_configuration_label"
                  control={
                    <Checkbox checked={projects}
                              onChange={handleFlightingStateChange}
                              name="projects" size="small" />
                  }
                  label="Projects"
                />
                <FormControlLabel
                  classes="flighting_configuration_label"
                  control={
                    <Checkbox checked={workflows}
                              onChange={handleFlightingStateChange}
                              name="workflows" size="small" />
                  }
                  label="Workflows"
                />
                <FormControlLabel
                  classes="flighting_configuration_label"
                  control={
                    <Checkbox checked={datasets}
                              onChange={handleFlightingStateChange}
                              name="datasets" size="small" />
                  }
                  label="Datasets"
                />
                <FormControlLabel
                  classes="flighting_configuration_label"
                  control={
                    <Checkbox checked={notebooks}
                              onChange={handleFlightingStateChange}
                              name="notebooks" size="small" />
                  }
                  label="Notebooks"
                />
              </FormGroup>
            </FormControl>
            <div className="add_user_field_header">
              Tier
            </div>
            <Select
              placeholder="Tier"
              name="tier"
              options={TIER}
              onBlur={() =>
                isSubmitted
                  ? simpleValidator.current.showMessageFor("tier")
                  : true
              }
              onChange={(e) =>
                handleChange({
                  target: { value: e.value, name: "tier" },
                })
              }
              value={{
                label: form.tier,
                value: form.tier,
              }}
              className="add_user_select"
              required
            />
            {simpleValidator.current.message("tier", form.tier, "required")}
            <div className="add_user_field_header">
              Max Seats
            </div>
            <div className="field_wrapper">
              <Input
                type="number"
                min="0"
                name="max_seats"
                placeholder="Max Seats"
                value={form.max_seats}
                className="add_user_field"
                onChange={handleChange}
                onBlur={() =>
                  isSubmitted
                    ? simpleValidator.current.showMessageFor("max_seats")
                    : true
                }
              />
              {simpleValidator.current.message("max_seats", form.max_seats, "required")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="add_org_save_button_container">
            <Button type="submit" className="add_org_save_button btn">
              {organization ? 'Update organization' : 'Create organization'}
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default OrganizationModal