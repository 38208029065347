export const FlightingActionTypes = {
  GET_CONFIG: "GET_CONFIG",
  GET_CONFIG_PENDING: "GET_CONFIG_PENDING",
  GET_CONFIG_FULFILLED: "GET_CONFIG_FULFILLED",

  GET_FLIGHTING: "GET_FLIGHTING",
  GET_FLIGHTING_PENDING: "GET_FLIGHTING_PENDING",
  GET_FLIGHTING_FULFILLED: "GET_FLIGHTING_FULFILLED",

  GET_ORGANIZATION_FLIGHTING: "GET_ORGANIZATION_FLIGHTING",
  GET_ORGANIZATION_FLIGHTING_PENDING: "GET_ORGANIZATION_FLIGHTING_PENDING",
  GET_ORGANIZATION_FLIGHTING_FULFILLED: "GET_ORGANIZATION_FLIGHTING_FULFILLED",

  CREATE_FLIGHT: "CREATE_FLIGHT",
  CREATE_FLIGHT_FULFILLED: "CREATE_FLIGHT_FULFILLED",

  UPDATE_FLIGHT: "UPDATE_FLIGHT",
  UPDATE_FLIGHT_FULFILLED: "UPDATE_FLIGHT_FULFILLED",

  CLEAR_FLIGHTING: "CLEAR_FLIGHTING",

  GET_CURRENT_ORG_FLIGHTING: "GET_CURRENT_ORG_FLIGHTING",
  GET_CURRENT_ORG_FLIGHTING_PENDING: "GET_CURRENT_ORG_FLIGHTING_PENDING",
  GET_CURRENT_ORG_FLIGHTING_FULFILLED: "GET_CURRENT_ORG_FLIGHTING_FULFILLED",

  CLEAR_CURRENT_ORG_FLIGHTING: "CLEAR_CURRENT_ORG_FLIGHTING",
};
