import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getUser } from "redux/actions/UserActions/Action";
import { getConfig } from "../../redux/actions/FlightingActions/action";
import Form from "components/Users/Form";

const EditUser = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfig());
    dispatch(getUser({ id: match.params.userId }));
  }, [dispatch, match.params.userId]);

  return (
    <div>
      <Form isEdit={true}/>
    </div>
  );
};
export default EditUser;
