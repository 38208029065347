import React from "react";

//import LinkDataSets from "components/DataSets";
import NoteBooks from "components/NoteBooks";

import { ProjectHeader } from "./ProjectHeader";
import { RightSidebar } from "./RightSidebar";

const ProjectDetails = ({ project, projectId, history }) => (
  <>
    <ProjectHeader project={project}/>
    <div className="lds_project_bottom_box_wrapper float_left">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              {project && (
                <div className="col-md-12">
                  <div className="lds_project_over_box float_left">
                    <h4>Overview</h4>

                    <p>{project.description}</p>
                  </div>
                  {project.id === projectId && <NoteBooks history={history} projectId={project.id} />}
                  {/*<LinkDataSets /> */}
                </div>
              )}
            </div>
          </div>
          {project && <RightSidebar project={project} />}
        </div>
      </div>
    </div>
  </>
);

export default ProjectDetails;
