import React from 'react';
import { Link } from "react-router-dom";
import Table from "./OrganizationsList/Table";

const Organizations = (props) => {
  return (
  <div>
  <div className="lds_cp_heading">
  <Link
    to={`/manage-organizations`  }
    className="cursor-pointer float-left"
  >
    <i className="flaticon-left-arrow"></i> Back
  </Link>
</div>
  <div className="row">
      <div className="col-md-6 col-sm-6">
        <div className="lds_po_heading">
          <h1>Organizations</h1>
        </div>
      </div>
      <div className="col-md-6 col-sm-6">
        <p className="float-right" data-toggle="modal" data-target="#gitModal">
          <Link to="/organizations/new" className="btn">
            {" "}
            Add Organizations{" "}
          </Link>{" "}
        </p>
      </div>
      <Table {...props}/>
    </div>
    </div>
  );
};
export default Organizations;
