export const NotebookActionTypes = {
  GET_NOTEBOOKS: "GET_NOTEBOOKS",
  GET_NOTEBOOKS_PENDING: "GET_NOTEBOOKS_PENDING",
  GET_NOTEBOOKS_FULFILLED: "GET_NOTEBOOKS_FULFILLED",

  GET_NOTEBOOK: "GET_NOTEBOOK",
  GET_NOTEBOOK_PENDING: "GET_NOTEBOOK_PENDING",
  GET_NOTEBOOK_FULFILLED: "GET_NOTEBOOK_FULFILLED",

  OPEN_NOTEBOOK: "OPEN_NOTEBOOK",
  OPEN_NOTEBOOK_PENDING: "OPEN_NOTEBOOK_PENDING",
  OPEN_NOTEBOOK_FULFILLED: "OPEN_NOTEBOOK_FULFILLED",

  COMMIT_NOTEBOOK: "COMMIT_NOTEBOOK",
  COMMIT_NOTEBOOK_PENDING: "COMMIT_NOTEBOOK_PENDING",
  COMMIT_NOTEBOOK_FULFILLED: "COMMIT_NOTEBOOK_FULFILLED",

  CREATE_NOTEBOOK: "CREATE_NOTEBOOK",
  CREATE_NOTEBOOK_PENDING: "CREATE_NOTEBOOK_PENDING",
  CREATE_NOTEBOOK_FULFILLED: "CREATE_NOTEBOOK_FULFILLED",

  PREVIEW_NOTEBOOK: "PREVIEW_NOTEBOOK",
  PREVIEW_NOTEBOOK_PENDING: "PREVIEW_NOTEBOOK_PENDING",
  PREVIEW_NOTEBOOK_FULFILLED: "PREVIEW_NOTEBOOK_FULFILLED",

  IMPORT: "IMPORT_NOTEBOOK",
  IMPORT_NOTEBOOK_PENDING: "IMPORT_NOTEBOOK_PENDING",
  IMPORT_NOTEBOOK_FULFILLED: "IMPORT_NOTEBOOK_FULFILLED",

  DELETE_NOTEBOOK: "DELETE_NOTEBOOK",
  DELETE_NOTEBOOK_PENDING: "DELETE_NOTEBOOK_PENDING",
  DELETE_NOTEBOOK_FULFILLED: "DELETE_NOTEBOOK_FULFILLED",

  UPDATE_NOTEBOOK: "UPDATE_NOTEBOOK",
  UPDATE_NOTEBOOK_PENDING: "UPDATE_NOTEBOOK_PENDING",
  UPDATE_NOTEBOOK_FULFILLED: "UPDATE_NOTEBOOK_FULFILLED",

  GENERATE_DEV_ENVIRONMENT: "GENERATE_DEV_ENVIRONMENT",
  GENERATE_DEV_ENVIRONMENT_FULFILLED: "GENERATE_DEV_ENVIRONMENT_FULFILLED"
};
