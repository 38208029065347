import React from 'react';
import EntityUsageDetails from "./EntityUsageDetails";
import EntityUsageOverview from "./EntityUsageOverview";
import { ROLES } from "utility/constants/constants";
import EntityUsageGraph from "./Graphs/EntityUsageGraph";

const EntityUsage = ({
                         user,
                         isSelected,
                         currentOrganizationId,
                         setCurrentOrganizationId,
                         setOrgAppDetailsClicked
                       }) => {
  return (
    <>
      {
        user.role === ROLES.SUPERADMIN && currentOrganizationId.length === 0 ?
          <EntityUsageOverview isSelected={isSelected}
                               setCurrentOrganizationId={setCurrentOrganizationId}
                               setOrgAppDetailsClicked={setOrgAppDetailsClicked}
                               insightsGraph={<EntityUsageGraph />}>
          </EntityUsageOverview> :
          <EntityUsageDetails isSelected={isSelected}
                              organizationId={currentOrganizationId}
                              setCurrentOrganizationId={setCurrentOrganizationId}>
          </EntityUsageDetails>
      }
    </>
  )
}

export default EntityUsage