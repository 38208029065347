import React from "react";
import AppRouter from "./router/approuter";
import { removeConsoleLog } from "./utility/utility";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import RouteChangeListener from "./utility/RouteChangeListener";
import { ReactNotifications } from 'react-notifications-component'
import "react-notifications-component/dist/theme.css";
import Oux from "./hoc/Oux/Oux";
import Amplify from "aws-amplify";
import * as datasetActions from "./redux/actions/AWSActions";
import * as flightingActions from "./redux/actions/FlightingActions";
import { isEmpty, isEqual } from "lodash";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  configureAmplify = (
    customer_data_bucket,
    customer_data_bucket_region,
    identity_region,
    identity_pool_id
  ) => {
    Amplify.configure({
      Auth: {
        identityPoolId: identity_pool_id,
        region: identity_region,
      },
      Storage: {
        AWSS3: {
          bucket: customer_data_bucket,
          region: customer_data_bucket_region,
        },
      },
    });
  };

  reConfigureAWSAmplify = () => {
    let data_bucket, data_bucket_region;
    if (this.props.awsKeys.out_config.customer_data_bucket) {
      const {
        out_config: { customer_data_bucket, customer_data_bucket_region },
      } = this.props.awsKeys;
      data_bucket = customer_data_bucket;
      data_bucket_region = customer_data_bucket_region;
    } else {
      const {
        out_config: { direcotry_data_bucket, directory_data_bucket_region },
      } = this.props.awsKeys;
      data_bucket = direcotry_data_bucket;
      data_bucket_region = directory_data_bucket_region;
    }
    const {
      out_config: { identity_region, identity_pool_id },
    } = this.props.awsKeys;
    this.configureAmplify(
      data_bucket,
      data_bucket_region,
      identity_region,
      identity_pool_id
    );
  };

  componentDidMount = () => {
    const { dispatch, user } = this.props;
    if (!isEmpty(user)) {
      dispatch(flightingActions.getFlighting(user.organization));
    }

    if (!isEmpty(this.props.awsKeys)) {
      this.reConfigureAWSAmplify();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { awsKeys, dispatch, user } = this.props;
    if (isEmpty(prevProps.user) && !isEmpty(user)) {
      this.props.dispatch(datasetActions.getAwsKeys());
    }

    if (!isEmpty(user) && !isEqual(prevProps.awsKeys, awsKeys)) {
      this.reConfigureAWSAmplify();
    }

    if (isEmpty(prevProps?.user) && !isEmpty(user)) {
      if (!isEmpty(user?.organization)) {
        dispatch(flightingActions.getFlighting(user.organization));
      }
    }
  };

  componentWillMount = () => {
    if (process.env.REACT_APP_ENV === "production") {
      removeConsoleLog();
    }
  };

  render() {
    return (
      <Oux>
        <RouteChangeListener />
        <ReactNotifications />
        <AppRouter {...this.props} />
      </Oux>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  awsKeys: state.awsReducer.awsKeys,
});

export default connect(mapStateToProps, null)(withRouter(App));
