import React from "react";
import "./TemplateCreation.scss";

export const StepTitle = (props) => {
  const { currStep } = props;

  const stepTitle = () => {
    if (currStep === 0) {
      return "Select App Template";
    } else if (currStep === 1) {
      return "Select Dataset";
    } else if (currStep === 2) {
      return "Select App Name and Description";
    } else {
      return "Review App Creation Details";
    }
  };

  return (
    <div className="stepTitleText">
      <>
        Step {currStep + 1}: {stepTitle(currStep)}
      </>
    </div>
  );
};
