import { organizationActionTypes } from "./ActionType";
import * as API from "../../../api/OrganizationAPI";

export const getOrganizations = (params) => (dispatch) =>
  dispatch({
    type: organizationActionTypes.GET_ORGANIZATIONS,
    payload: API.getOrganizations(params),
  });
export const getOrganization = ({ id }) => (dispatch) =>
  dispatch({
    type: organizationActionTypes.GET_ORGANIZATION,
    payload: API.getOrganization(id),
  });
export const createOrganization = ({ body }) => (dispatch) =>
  dispatch({
    type: organizationActionTypes.CREATE_ORGANIZATION,
    payload: API.createOrganization(body),
  });
export const clearOrganization = () => (dispatch) =>
  dispatch({
    type: organizationActionTypes.CLEAR_ORGANIZATION,
  });
export const clearOrganizations = () => (dispatch) =>
  dispatch({
    type: organizationActionTypes.CLEAR_ORGANIZATIONS,
  });

export const updateOrganization = ({ body, id }) => (dispatch) =>
  dispatch({
    type: organizationActionTypes.UPDATE_ORGANIZATION,
    payload: API.updateOrganization(body, id),
  });
export const deleteOrganization = ({ id }) => (dispatch) =>
  dispatch({
    type: organizationActionTypes.DELETE_ORGANIZATION,
    payload: API.deleteOrganization(id),
  });
export const checkSeatAvailability = ({ id }) => (dispatch) =>
  dispatch({
    type: organizationActionTypes.SEAT_AVAILABILITY,
    payload: API.checkSeatAvailability(id),
  });

export const getActiveUserCounts = () => (dispatch) =>
  dispatch({
    type: organizationActionTypes.GET_ACTIVE_USER_COUNTS,
    payload: API.getActiveUserCounts(),
  });

export const getBucket = ({ id }) => (dispatch) =>
  dispatch({
    type: organizationActionTypes.GET_BUCKET,
    payload: API.getBucket(id)
  })

export const clearBucket = () => (dispatch) =>
  dispatch({
    type: organizationActionTypes.CLEAR_BUCKET
  })

export const getBuckets = (params) => (dispatch) => {
  dispatch({
    type: organizationActionTypes.GET_BUCKETS,
    payload: API.getBuckets(params)
  })
}

export const update2FARequiredStatus = ({ orgId, body }) => (dispatch) =>
  dispatch({
    type: organizationActionTypes.UPDATE_2FA_REQUIRED_STATUS,
    payload: API.update2FARequiredStatus(orgId, body)
  })