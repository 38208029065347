import { userActionTypes } from "../actions/UserActions/ActionType";

export const initialState = {
  user: null,
  users: [],
  config: null,
  userCount: 0,
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const userReducer = (state = initialState, action) => {
  let users;
  let index;
  switch (action.type) {
    case userActionTypes.GET_USERS_PENDING:
      return updateObject(state, {});
    case userActionTypes.GET_USERS_FULFILLED:
      users = [...state.users];
      if (users.length < action.payload.count){
        users.push(...action.payload.users);
      }
      return updateObject(state, { user: null, userCount: action.payload.count, users: users});
    case userActionTypes.GET_USER_PENDING:
      return updateObject(state, { });
    case userActionTypes.GET_USER_FULFILLED:
      return updateObject(state, { user: action.payload.user });
    case userActionTypes.DELETE_USER_FULFILLED:
      users=[...state.users]
      index = users.findIndex(e => e.id ===action.payload.id)
      users.splice(index, 1)
      return updateObject(state, { users, userCount: state.userCount-1 });
    case userActionTypes.CLEAR_USERS:
      users = [];
      return updateObject(state, { users, userCount: 0 });
    default:
      return state;
  }
};
