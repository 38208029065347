export const SharesActionTypes = {

  GET_SHARED_WITH_USERS: "GET_SHARED_WITH_USERS",
  GET_SHARED_WITH_USERS_PENDING: "GET_SHARED_WITH_USERS_PENDING",
  GET_SHARED_WITH_USERS_FULFILLED: "GET_SHARED_WITH_USERS_FULFILLED",

  GET_SHARED_WITH_USERS_BY_ENTITY: "GET_SHARED_WITH_USERS_BY_ENTITY",
  GET_SHARED_WITH_USERS_BY_ENTITY_PENDING: "GET_SHARED_WITH_USERS_BY_ENTITY_PENDING",
  GET_SHARED_WITH_USERS_BY_ENTITY_FULFILLED: "GET_SHARED_WITH_USERS_BY_ENTITY_FULFILLED",

}