import { MetricsActionTypes } from "../actions/MetricsActions/ActionType";

export const initialState = {
  metrics: [],
  appMetrics: [],
  projectMetrics: [],
  datasetMetrics: [],
  workflowMetrics: [],
  metricsDetails: [],
  metricsDetailsCount: 0,
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const metricsReducer = (state = initialState, action) => {
  let metricsDetails;

  switch (action.type) {
    case MetricsActionTypes.GET_ALL_METRICS_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_ALL_METRICS_FULFILLED:
      return updateObject(state, { metrics: action.payload.metrics });
    case MetricsActionTypes.GET_APP_METRICS_BY_ORG_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_APP_METRICS_BY_ORG_FULFILLED:
      return updateObject(state, { appMetrics: action.payload.metrics });
    case MetricsActionTypes.CLEAR_APP_METRICS:
      return updateObject(state, { appMetrics: [] });
    case MetricsActionTypes.GET_PROJECT_METRICS_BY_ORG_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_PROJECT_METRICS_BY_ORG_FULFILLED:
      return updateObject(state, { projectMetrics: action.payload.metrics });
    case MetricsActionTypes.CLEAR_PROJECT_METRICS:
      return updateObject(state, { projectMetrics: [] });
    case MetricsActionTypes.GET_DATASET_METRICS_BY_ORG_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_DATASET_METRICS_BY_ORG_FULFILLED:
      return updateObject(state, { datasetMetrics: action.payload.metrics });
    case MetricsActionTypes.CLEAR_DATASET_METRICS:
      return updateObject(state, { datasetMetrics: [] });
    case MetricsActionTypes.GET_WORKFLOW_METRICS_BY_ORG_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_WORKFLOW_METRICS_BY_ORG_FULFILLED:
      return updateObject(state, { workflowMetrics: action.payload.metrics });
    case MetricsActionTypes.CLEAR_WORKFLOW_METRICS:
      return updateObject(state, { workflowMetrics: [] });
    case MetricsActionTypes.GET_METRICS_DETAILS_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_METRICS_DETAILS_FULFILLED:
      metricsDetails = [...state.metricsDetails];
      if (metricsDetails.length < action.payload.count){
        metricsDetails.push(...action.payload.metricsDetails);
      }
      return updateObject(state, { metricsDetails: metricsDetails, metricsDetailsCount: action.payload.count });
    case MetricsActionTypes.CLEAR_METRICS_DETAILS:
      return updateObject(state, { metricsDetails: [] });
    default:
      return state;
  }
}