import React from 'react';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const TimeRangeIndicator = ({timeRange, handleTimeChange, className}) => {

  return (
    <ToggleButtonGroup value={timeRange} exclusive
                       onChange={handleTimeChange}
                       className={className}>
      <ToggleButton value="day" className="time_range_button">
        <div className="time_range_button_text">1D</div>
      </ToggleButton>
      <ToggleButton value="week" className="time_range_button">
        <div className="time_range_button_text">1W</div>
      </ToggleButton>
      <ToggleButton value="month" className="time_range_button">
        <div className="time_range_button_text">1M</div>
      </ToggleButton>
      <ToggleButton value="year" className="time_range_button">
        <div className="time_range_button_text">1Y</div>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
export default TimeRangeIndicator
