import React, { useState } from "react";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight, LockPerson,
  PeopleOutline,
} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import swal from "sweetalert";
import {
  getActiveUserCounts
} from "../../redux/actions/OrganizationActions/Action";
import * as commonService from "../../utility/CommonService";
import { useDispatch } from "react-redux";

const Carousel = ({
                    itemList,
                    title,
                    limit,
                    id,
                    setId,
                    setShowDetailPage,
                    showMoreMenu = false,
                    setShowEditModal = (s) => {},
                    setShowFlightingModal = (s) => {},
                    itemName = undefined,
                    itemDeleteFunction = (s) => {}
                  }) => {
  const dispatch = useDispatch();
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(limit);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const handleCardClick = (currentId) => {
    setShowDetailPage(true)
    setId(currentId)
  }

  const handleMenuOpen = (event, currentId) => {
    event.stopPropagation();
    setId(currentId)
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = (e) => {
    if (e) e.stopPropagation()
    setMenuAnchor(false);
  };

  const handleDelete = (currentId) => {
    swal({
      title: "",
      text: "Are you sure?",
      className: "logout-alert-modal",
      buttons: true,
    }).then(async (result) => {
      if (result) {
        const res = await dispatch(itemDeleteFunction({ id: currentId }));
        if (res?.value?.success) {
          commonService.forSuccess(`${itemName} Deleted!`, "Success");
        }
        await dispatch(
          getActiveUserCounts()
        )
        setId("")
      }
    })
  };

  const setPrevIndices = () => {
    if (!((currentEndIndex - limit) < 0)) {
      setCurrentEndIndex(currentStartIndex)
    }

    if ((currentStartIndex - limit) < 0) {
      setCurrentStartIndex(0)
    } else {
      setCurrentStartIndex(currentStartIndex - limit)
    }
  }
  const setNextIndices = (itemListLength) => {
    if (!((currentStartIndex + limit) > itemListLength - 1)) {
      setCurrentStartIndex(currentEndIndex)
    }

    if ((currentEndIndex + limit) > itemListLength - 1) {
      setCurrentEndIndex(itemListLength)
    } else {
      setCurrentEndIndex(currentEndIndex + limit)
    }
  }

  return (
    <div className="carousel">
      <div className="carousel_header">
        <div className="panel_header">{title}</div>
        <div className="carousel_controls">
          <div
            className={currentStartIndex === 0 ? "arrow arrow_disabled" : "arrow clickable"}
            onClick={() => setPrevIndices()}>
            <KeyboardArrowLeft></KeyboardArrowLeft>
          </div>
          <div
            className={currentEndIndex === Object.keys(itemList).length ? "arrow arrow_disabled" : "arrow clickable"}
            onClick={() => setNextIndices(Object.keys(itemList).length)}>
            <KeyboardArrowRight></KeyboardArrowRight>
          </div>
        </div>
      </div>
      <div className="carousel_tile_container">
        <div className="carousel_panel_row">
          {itemList.length > 0 && itemList.slice(currentStartIndex, currentEndIndex).map(item =>
            {
              const itemId = item.id
              return (
                <div
                  key={itemId}
                  className="panel panel_small clickable"
                  onClick={() => handleCardClick(itemId)}
                >
                  <div className="org_card_top_row">
                    <div className="org_card_header">{item.name}</div>
                    {
                      showMoreMenu &&
                        <div className="org_menu clickable">
                          <IconButton
                            className="menu_button"
                            aria-label="more"
                            id="long-menu-button"
                            aria-controls={menuOpen ? "long-menu" : undefined}
                            aria-expanded={menuOpen ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e, itemId)}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </div>
                    }
                  </div>
                  <div className="num_active_users">
                    <PeopleOutline
                      sx={{width: '1.5rem', height: '1.5rem'}}>
                    </PeopleOutline>
                    <div
                      className="users_licenses_ratio">{item.count}/{item.maxSeats} users
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
      <Menu
        id="long-menu-button"
        disableScrollLock={true}
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          dense={true}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            setShowEditModal(true)
            handleMenuClose()
            e.stopPropagation()
          }}
        >
          <ListItemIcon className="edit">
            <div className="entity_menu_item">
              <img
                src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                alt="Edit Entity"
                className="cursor-pointer optionsIcon"
              />
              <p>Edit</p>
            </div>
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          dense={true}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            setShowFlightingModal(true)
            handleMenuClose()
            e.stopPropagation()
          }}
        >
          <ListItemIcon className="flighting_config">
            <LockPerson fontSize="small" />
            <p>Flighting</p>
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleDelete(id);
            handleMenuClose();
            e.stopPropagation();
          }}
        >
          <ListItemIcon className="delete">
            <DeleteIcon fontSize="small" />
            <p>Delete</p>
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default Carousel