import React from "react";
import { Select } from "components/shared/ui-components/form-components";
import { styled } from '@mui/material/styles';
import { Slider } from "@mui/material";
import Switch from '@mui/material/Switch';

const placeHolder = "Select a Value"

const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
        ...styles,
        cursor: 'pointer',
        borderRadius: "8px",
        background: "#DFEDFF",
    }),
}

const PauseSwitch = styled(Switch)(() => ({
    padding: 4,
    '& .MuiSwitch-track': {
        borderRadius: "20px",
        backgroundColor: "#818181",
        opacity: "1 !important",
        "&:after, &:before": {
            color: "white",
            fontSize: "11px",
            position: "absolute",
            top: "6px"
        },
        "&:after": {
            content: "'On'",
            left: "10px"
        },
        "&:before": {
            content: "'Off'",
            right: "9px"
        }
    },
    '& .MuiSwitch-thumb': {
        color: "white",
        width: "20px",
        height: "20px",
        margin: "1px"
    },
  }));

export function CampaignSidebar(
    {columnNames,
        handleOptMetricChange,
        handleComparingMetricChange,
        handleCohortChange,
        handleForecastChange,
        handleXAxisChange,
        handleYAxisChange,
        handleKChange,
        loading
    }
) {
    return(
        <div className="optAnalysisSidebar">
            <div className="sidebarOptimizationMetricContainer">
                <div className="sidebarOptimizationMetric">
                    <div className="sidebarOptimizationMetricHeader">
                        Optimization Metric
                    </div>
                    <div className="sidebarOptimizationMetricBody">
                        <div className="sidebarOptimizationMetricYContainer">
                            <div className="sidebarOptimizationMetricY">
                                <div className="sidebarOptimizationMetricTitle">
                                    Optimization Metric
                                </div>
                                <div className="sidebarOptimizationMetricCaption">
                                    The optimization metric will be displayed on y-axis for your graph.
                                </div>
                                <div className="sidebarOptimizationMetricDropdown">
                                    <Select
                                            styles={customStyles}
                                            placeholder={placeHolder}
                                            options={columnNames}
                                            onChange={(e) =>
                                                handleOptMetricChange({
                                                    name: e.label,
                                                    value: e.value
                                                })
                                            }
                                    />
                                </div>
                            </div>
                            <div className="sidebarOptimizationMetricY">
                                <div className="sidebarOptimizationMetricTitle">
                                    Comparing Metric
                                </div>
                                <div className="sidebarOptimizationMetricCaption">
                                    The comparing metric will be displayed on x-axis for your graph.
                                </div>
                                <div className="sidebarOptimizationMetricDropdown">
                                    <Select
                                            styles={customStyles}
                                            placeholder={placeHolder}
                                            options={columnNames}
                                            onChange={(e) =>
                                                handleComparingMetricChange({
                                                    name: e.label,
                                                    value: e.value
                                                })
                                            }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sidebarOptimizationMetricSwitchContainer">
                            <div className="sidebarOptimizationMetricSwitch">
                                <PauseSwitch disableRipple
                                    disabled={loading}
                                    color="success"
                                    onChange={(e) =>
                                        handleForecastChange(e.target.checked)
                                    }

                                />
                                <div className="sidebarOptimizationMetricSwitchText">
                                    Forecast
                                </div>
                            </div>
                            <div className="sidebarOptimizationMetricSwitch">
                                <PauseSwitch disableRipple
                                    disabled={loading}
                                    color="success"
                                    onChange={(e) =>
                                        handleCohortChange(e.target.checked)
                                    }
                                />
                                <div className="sidebarOptimizationMetricSwitchText">
                                    Show Cohort Analysis
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sidebarCohortAnalysisContainer">
                <div className="sidebarCohortAnalysis">
                    <div className="sidebarCohortAnalysisHeader">
                        <div className="sidebarCohortAnalysisTitle">
                            Cohort Analysis
                        </div>
                        <div className="sidebarCohortAnalysisCaption">
                            Cohort analysis helps discover distinct groups and their relationship to the optimization metric.
                        </div>
                    </div>
                    <div className="sidebarCohortAnalysisOptions">
                        <div className="sidebarOptimizationMetricDropdown">
                            X-Axis
                            <Select
                                styles={customStyles}
                                placeholder={placeHolder}
                                options={columnNames}
                                onChange={(e) =>
                                    handleXAxisChange({
                                        name: e.label,
                                        value: e.value
                                    })
                                }
                            />
                        </div>
                        <div className="sidebarOptimizationMetricDropdown">
                            Y-Axis
                            <Select
                                styles={customStyles}
                                placeholder={placeHolder}
                                options={columnNames}
                                onChange={(e) =>
                                    handleYAxisChange({
                                        name: e.label,
                                        value: e.value
                                    })
                                }
                            />
                        </div>
                        <div className="sidebarCohortAnalysisClusters">
                            <div className="sidebarCohortAnalysisClustersHeader">
                                <div className="sidebarCohortAnalysisClustersTitle">
                                    Cluster Number
                                </div>
                                <div className="sidebarCohortAnalysisClustersCaption">
                                    The optimal cluster number is 4
                                </div>
                            </div>
                            <Slider
                                defaultValue={4}
                                valueLabelDisplay="on"
                                step={1}
                                min={2}
                                max={8}
                                onChangeCommitted={handleKChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignSidebar