import axios from "axios";
import storage from "utility/storage";
import * as commonService from "utility/CommonService";

const environmentUrls = {
  development: 'localhost:3005',
  staging: 'staging-backend.ludisanalytics.com',
  production: 'prod-backend.ludisanalytics.com',
};

export const HOST_URL = () => {
  const environment = process.env.REACT_APP_ENV || 'development';
  return environmentUrls[environment] || environmentUrls.development;
};

export const BASE_URL = () => {
  const protocol = process.env.REACT_APP_ENV === 'development' ? 'http' : 'https';
  return `${protocol}://${HOST_URL()}`;
};

export const JUPYTERHUB_URL = () => {
  let url;
  if (process.env.REACT_APP_ENV === "development") {
    url = "https://ludis-jhub.ludisanalytics.com/";
  }
  if (process.env.REACT_APP_ENV === "staging") {
    url = "https://ludis-jhub.ludisanalytics.com/";
  }
  if (process.env.REACT_APP_ENV === "production") {
    url = "https://ludis-jhub-prod.ludisanalytics.com/";
  }
  return url;
};
export const LANGUAGES = ["Python", "R"];
export const ROLE = ["developer", "management", "administrator"];
export const TIER = ["paid","free"]

export const PUBLIC_ROUTES = [
  "/login",
  "/register",
  "/resetpassword",
  "/forgot_password"
];
export const API_VERSION = "/v1";
export const API_VERSION_2 = "/v2";

const instance = axios.create({
  baseURL: BASE_URL()
});

//this function shows the loader during requests

instance.interceptors.request.use((config) => {
  // show loader
  const { url } = config;
  if (url && !url.includes("/comments/list")) {
    // we do not want to show the loading spinner during this network call
    commonService.isLoading.onNext(false);
  } else {
    commonService.isLoading.onNext(true);
  }
  return config;
});
instance.interceptors.response.use((config) => {
  // hide loader
  commonService.isLoading.onNext(false);
  return config;
});
const token = storage.get("token", null);
if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default instance;
