import React from "react";

import { dateToString } from "utility/utility";
export const RightSidebar = ({ project }) => (
  <div className="col-md-3">
    <div className="lds_pd_right_client lds_pd_top float_left">
      <h5>created by</h5>
      <div className="lds_pd_client_box float_left">
        <div className="lds_pd_client_img">
          <img
            src={require("assets/custom-styles/images/thumbnails/user1.png")}
            alt="img"
          />
        </div>
        <div className="lds_pd_client_img_cont">
          <p to=".">{project.author ? project.author : project.repo_author}</p>
        </div>
      </div>
    </div>
    <div className="lds_pd_right_client lds_pd_right_client_second float_left">
      <h5>created</h5>
      <p>{dateToString(new Date(project.created_at))}</p>
    </div>
    <div className="lds_pd_right_client lds_pd_right_client_second float_left">
      <h5>Status</h5>
      <p>{project.status}</p>
    </div>
    { project.tags && <div className="lds_pd_right_client lds_pd_right_client_second float_left">
      <h5>Tags</h5>
      <p>{project.tags.join(",")}</p>
    </div>}
    {/*<div className="lds_pd_right_client lds_pd_right_client_second float_left">
      <h5>Project team</h5>
      <div className="lds_pd_client_box float_left">
        <div className="lds_pd_client_img">
          <img
            src={require("assets/custom-styles/images/thumbnails/user1.png")}
            alt="img"
          />
        </div>
        <div className="lds_pd_client_img_cont">
          <Link to=".">craig</Link>
        </div>
      </div>
      <div className="lds_pd_client_box float_left">
        <div className="lds_pd_client_img">
          <img
            src={require("assets/custom-styles/images/thumbnails/user1.png")}
            alt="img"
          />
        </div>
        <div className="lds_pd_client_img_cont">
          <Link to=".">juily</Link>
        </div>
      </div>
      <div className="lds_pd_client_box float_left">
        <div className="lds_pd_client_img">
          <img
            src={require("assets/custom-styles/images/thumbnails/user1.png")}
            alt="img"
          />
        </div>
        <div className="lds_pd_client_img_cont">
          <Link to=".">Kaylen</Link>
        </div>
      </div>
    </div>*/}
  </div>
);
