import React from "react";
import { getFileNameFromPath } from "utility/utility";
import { ToggleButtonGroup, ToggleButton } from '@mui/material'

import "../ClusteringTemplate/ClusteringTemplate.css";
import "../SliceAndDiceTemplate/SliceAndDiceTemplate.css"

const toggleButtonStyle = {
    display: 'flex',
    width: '140px',
    height: '32px',
    'justify-content': 'flex-end',
    'align-items': 'center',
    background: 'var(--Disabled, #CDCDCD)',


    "&.Mui-selected": {
        'border': '1px solid #6B71FB',
        'background': 'var(--Primary-Color, #6B71FB)',
        color:'#fff',

        /* CTA shadow */
        'box-shadow': '2px 8px 15px 0px rgba(107, 113, 251, 0.25)',
    },

    "&.MuiToggleButtonGroup-grouped": {
        borderRadius: "30px !important",
        mx: 1,
        border: "1px solid lightgrey !important"
    }
}

export function AppHeader({name, description, directoryFiles, kmeansMode, handleModeChange}) {
    return (
        <div className="headingStyle">
            <div className="headingStyleAppInfo">
                <div  className="headingStyleTitle">
                    {name}
                </div>
                <div className="headingStyleDescription">
                    {description}
                </div>
            </div>
            <div className="headingStyleDataInfo">
                <div className="DataInputTitle">
                    Data Input
                </div>
                {directoryFiles && <div className="DataInput">
                    <div className="dataInputImage">
                        <img
                            src={require("assets/custom-styles/images/templates/dataInput.svg")}
                            alt="img-ins"
                            height='100%'
                            width='100%'
                        />
                    </div>
                    <div className="dataInputName">
                        {getFileNameFromPath(directoryFiles.Contents[0].Key)}
                    </div>
                </div>}
            </div>
            {kmeansMode && <div className="headingStyleChartInfo">
                <div className="graphTitle">
                    Cluster Chart
                </div>
                <div className="clusterToggle">
                        <ToggleButtonGroup
                            color="primary"
                            value={kmeansMode}
                            exclusive
                            onChange={handleModeChange}
                            aria-label="kmeansMode"
                            sx={{gap:"10px"}}
                        >
                            <ToggleButton sx={toggleButtonStyle} value="kmeans2d">
                                <div className="clusterModes">
                                    <div className="clusterImage">
                                        <img
                                            src={require("assets/custom-styles/images/templates/2dclustering.svg")}
                                            alt="img-ins"
                                            height='100%'
                                            width='100%'
                                        />
                                    </div>
                                    <div>
                                        2D Mode
                                    </div>
                                </div>
                            </ToggleButton>
                            <ToggleButton sx={toggleButtonStyle} value="kmeans3d">
                                <div className="clusterModes">
                                    <div className="clusterImage">
                                        <img
                                            src={require("assets/custom-styles/images/templates/3dclustering.svg")}
                                            alt="img-ins"
                                            height='100%'
                                            width='100%'
                                        />
                                    </div>
                                    <div>
                                        3D Mode
                                    </div>
                                </div>
                            </ToggleButton>
                        </ToggleButtonGroup>
                </div>
            </div>}
        </div>
    )
}
export default AppHeader