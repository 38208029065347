import React from "react";

import VisualWorkflowsPage from "components/Workflows/VisualWorkflows";

const VisualWorkflows = ({ history }) => {
  let id = history.location.pathname.split("/")[2];
  return (
    <VisualWorkflowsPage id={id} history={history}/>
  )
};

export default VisualWorkflows;