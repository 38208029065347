import React, { useEffect, useState } from 'react';

const Loader = ({ loading, waitBeforeShow = 500 }) => {
  const [rotate, setRotate] = useState(45);
  const [isShown, setIsShown] = useState(false)
  const loaderShapeStyle = {
    background: 'rgb(107, 113, 251)',
    height: '4px',
    width: '4px',
    transition: 'transform 1s',
  };

  const loaderLabelStyle = {
    color: 'rgb(107, 113, 251)',
    fontFamily: "'Lato', sans-serif",
    fontSize: 18,
  }

  const loaderStyle = {
    display:'inline-flex',
    gap: '10px',
    width: '100%',
    height: '25px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  }

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setIsShown(true)
      }, waitBeforeShow)
      return () => {
        clearTimeout(timer);
      }
    }
  }, [loading, waitBeforeShow])

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => setRotate(rotate + 45), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [loading, rotate])

  return (
    <>
      {
        isShown ?
          (
            <div style={loaderStyle}>
              <div style={loaderLabelStyle}> Loading </div>
              <div style={{...loaderShapeStyle, transform: `rotate(${rotate}deg)`}}></div>
              <div style={{...loaderShapeStyle, transform: `rotate(${rotate + 45}deg)`}}></div>
              <div style={{...loaderShapeStyle, transform: `rotate(${rotate}deg)`}}></div>
            </div>
          ) : <></>
      }
    </>
  )

}

export default Loader