import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getUsers } from "redux/actions/UserActions/Action";

import UserList from "../../components/Users";

const Users = ({ match }) => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    dispatch(
      getUsers({ ...pagination, organization: match.params.organizationId })
    );
  }, [dispatch, match.params.organizationId, pagination]);
  return (
    <div className="lds_right_box_wrapper lds_right_po_box_wrapper">
      <div className="container">
        <UserList
          pagination={pagination}
          setPagination={setPagination}
          match={match}
        />
      </div>
    </div>
  );
};
export default Users;
