import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const NoteBooks = ({ iframe, localURL, history }) => {
  const project = useSelector(state => state.projectReducer.project);
  return (
    <div id="lds_nbk_det" className="lds_right_box_wrapper lds_right_po_box_wrapper notebook-open-wrapper">
      
      <div className={`iframe-container3 ${localURL ? "show" : "fade"}`}>
      <Link
      to={`/projects/${project.id}`}
        className="cursor-pointer float-left ml-4 mt-2"
      >
        <i className="flaticon-left-arrow"></i> Back
      </Link>
        <iframe
        src={iframe}
        id="test"
        name="test"
        title="notebookOpenCommit">
        </iframe>

      </div>
    </div>
  );
};

export default NoteBooks;
