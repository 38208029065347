import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { TextArea } from "components/shared/ui-components/form-components";
import { forError, forSuccess } from "utility/CommonService.js";
import ImageSelector from "components/shared/ImageSelector";
import {
  AWS_CONSTANTS,
  ENTITY_TYPES,
} from "../../../utility/constants/constants";
import { getAssetImgPath, uploadAssetImg } from "utility/utility";

export const EditEntityModal = ({
  entity,
  entityType,
  handleMenuClose,
  updateEntity,
  imgSrc,
  showModal,
  setShowModal
}) => {
  const defaultForm = {
    name: entity.name,
    description: entity.description,
  };
  const dispatch = useDispatch();
  const [form, setForm] = useState(defaultForm);
  const { user } = useSelector((state) => state.authReducer);
  const [selectedImg, setSelectedImg] = useState();

  const handleClose = () => {
    setShowModal(false);
    handleMenuClose();
  };
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const isSupported = (entityType) => {
    return [ENTITY_TYPES.APPLICATION.toLowerCase(), ENTITY_TYPES.DATASET.toLowerCase(), ENTITY_TYPES.PROJECT.toLowerCase(), ENTITY_TYPES.PROJECT_V2.toLowerCase(), ENTITY_TYPES.WORKFLOW.toLowerCase()].includes(entityType.toLowerCase());
  }

  const isValidUpload = (selectedImg) => {
    if (typeof selectedImg === 'string' && !selectedImg.includes(AWS_CONSTANTS.GET_OBJECT)) {
      return true
    } else if (typeof selectedImg === 'object') {
      return true
    }
    return false
  }

  // validate user input
  function Validation() {
    if (!form.name || !form.name.trim()) {
      forError("Empty name not allowed", "Error");
      return false;
    }
    if ((!form.description || !form.description.trim()) && entityType !== ENTITY_TYPES.WORKFLOW) {
      forError("Empty description not allowed", "Error");
      return false;
    }
    return true;
  }

  const dispatchUpdateEntity = async (e) => {
    e.preventDefault();
    if (Validation()) {
      uploadAssetImg(selectedImg, user)
      dispatch(
        updateEntity({
          body: {
            entity: {
              // TODO: [ENG-1256] Cleanup generic entity logic in editEntityModal.js
              ...(isSupported(entityType) && (entityType !== ENTITY_TYPES.WORKFLOW && {description: form.description})),
              //conditionally render name / img if it is an application
              ...(isSupported(entityType) && (entityType !== ENTITY_TYPES.PROJECT && { name: form.name })),
              ...(isSupported(entityType) && isValidUpload(selectedImg) && (entityType !== ENTITY_TYPES.PROJECT && entityType !== ENTITY_TYPES.PROJECT_V2 && entityType !== ENTITY_TYPES.WORKFLOW && {
                  img_path: getAssetImgPath(selectedImg, user),
                })),
            },
          },
          id: entity.id,
        })
      ).then((res) => {
        if (res.value && res.value.success) {
          forSuccess(entityType + " updated!", "Success");
        } else {
          forError("Update " + entityType + " failed", "Error");
        }
        handleClose();
      });
    }
  };
  return (
    <>
      <Modal
        id="edit_modal"
        show={showModal}
        onHide={handleClose}
        animation={false}
        className="edit-app-modal"
      >
        <Modal.Header className="header modal-header"closeButton>Edit {entityType}</Modal.Header>
        <form data-toggle="validator" onSubmit={dispatchUpdateEntity}>
          <Modal.Body>
            {entityType === ENTITY_TYPES.APPLICATION &&
              <div className="form-img-selector">
                <ImageSelector
                  selectedImg={selectedImg || imgSrc}
                  setSelectedImg={setSelectedImg}
                />
              </div>
            }
            <div className="col-md-12">
              <div className="publish_modal">
                <h4>{entityType} Name</h4>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={form.name}
                  disabled={
                    entityType.toLowerCase() ===
                    ENTITY_TYPES.PROJECT.toLowerCase()
                      ? true
                      : false
                  }
                  required
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                />
              </div>
            </div>
            {(isSupported(entityType) && entityType !== ENTITY_TYPES.WORKFLOW) ? (
              <div className="col-md-12">
                <div className="publish_modal lds_cp_box_heading">
                  <h4>Description</h4>
                  <TextArea
                    rows="4"
                    placeholder="Type Here"
                    name="description"
                    value={form.description}
                    onChange={handleChange}
                    required
                  ></TextArea>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer className="footer">
            <Button type="submit" value="Submit" className="btn float-right">
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
