import { AWSActionTypes } from "../actions/AWSActions/actionType";

export const initialState = {
  awsKeys: {},
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const awsReducer = (state = initialState, action) => {
  switch (action.type) {
    case AWSActionTypes.GET_AWS_KEYS_FULFILLED:
      return updateObject(state, { awsKeys: action.payload });
    default:
      return state;
  }
};
