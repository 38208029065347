import { ProjectActionTypes } from '../actions/ProjectActions/actionType';
import { PROJECT_TABS } from 'utility/constants/constants';
import { nextStateObject } from 'utility/utility'

export const initialState = {
  projects: null,
  project: null,
  projectScreen: PROJECT_TABS.MY_PROJECTS,
  ludisProject: null,
  config: null,
  notebooks: [],
  projectShares: [],
  projectsPageCount: 1,
  projectSharesPageCount: 1,
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps
  }
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectActionTypes.SET_PROJECT_SCREEN_FULFILLED:
      return updateObject(state, { projectScreen: action.payload });
    case ProjectActionTypes.GET_PROJECTS_FULFILLED:
      return updateObject(state, { projects: action.payload.projects, projectsPageCount: action.payload.pageCount});
    case ProjectActionTypes.GET_PROJECT_PENDING:
      return updateObject(state, { project: null});
    case ProjectActionTypes.GET_PROJECT_FULFILLED:
      return updateObject(state, { project: {...action.payload.entity } });
      case ProjectActionTypes.GET_LUDIS_PROJECTS_FULFILLED:
        return updateObject(state, { ludisProject: action.payload.projects });
    case ProjectActionTypes.CREATE_PROJECT_FULFILLED:
      return updateObject(state, { projects: null });
    case ProjectActionTypes.UPDATE_PROJECT_FULFILLED:
      const stateObject = {projects: [...state.projects], projectShares: [...state.projectShares]}
      const nextState = nextStateObject(stateObject, action.payload);
      return updateObject(state, {
        projects: nextState.projects,
        projectShares: nextState.projectShares,
      });
    case ProjectActionTypes.DELETE_PROJECT_FULFILLED:
      return updateObject(state, { projects: null });
    case ProjectActionTypes.DELETE_NOTEBOOK_FULFILLED:
      let preNotebooks = [...state.notebooks];
      preNotebooks.splice(preNotebooks.findIndex(note => note.id === action.payload), 1 );
      return updateObject(state, { notebooks: preNotebooks });
    case ProjectActionTypes.GET_DATASETS_FULFILLED:
      const { dataset } = action.payload;
      return updateObject(state, { datasets: dataset });
    case ProjectActionTypes.GET_NOTEBOOKS_PENDING:
      return updateObject(state, { notebooks: [] });
    case ProjectActionTypes.GET_NOTEBOOKS_FULFILLED:
      const { notebooks } = action.payload;
      return updateObject(state, { notebooks });
    case ProjectActionTypes.GET_PROJECT_SHARES_FULFILLED:
      return updateObject(state, { projectShares: action.payload.entityShares, projectSharesPageCount: action.payload.pageCount });
    default: return state;
  }
}