import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as commonService from "../../../utility/CommonService";
import { StepBar } from "./TemplateCreation/StepBar";
import { Button } from "components/shared/ui-components/form-components";
import { INSIGHTS_TABS } from "../../../utility/constants/constants";
import { AppCreation } from "./TemplateCreation/AppCreation";
import * as actions from "../../../redux/actions/AppActions";
import "./TemplateCreation/TemplateCreation.scss";

const InsightTemplateCreation = ({ history }) => {
  const defaultForm = {
    img_path: "",
    app_type: "",
    app_url: "",
    dataset_ids: [],
    description: "",
    name: "",
    file: "",
    currStep: 0, // TODO: Need to set 'currStep' here, so we can see where we are in Child components
  };

  const [form, setForm] = useState(defaultForm);
  const [nextName, setNextName] = useState("Next");
  const [currStep, setStep] = useState(0);
  const [nextPageReady, setNextPageReady] = useState(null);
  const dispatch = useDispatch();

  // Make step bar clickable
  const clickStep = document.querySelectorAll(".rc-steps-item");
  if (clickStep) {
    clickStep.forEach((element) => {
      element.addEventListener("click", function () {
        let step =
          element.children[1].innerText || element.children[1].textContent;
        setStep(parseFloat(step) - 1);
        if (parseFloat(step) === 4) {
          setNextName("Create");
        } else {
          setNextName("Next");
        }
      });
    });
  }

  // Need to decrement down if clicking back, or return to Insights if 'currStep==0'
  const prevStep = () => {
    if (currStep > 0) {
      setStep(currStep - 1);
      setNextName("Next");
    } else {
      setStep(0);
      setForm(defaultForm);
      dispatch(actions.setCurrentScreen(INSIGHTS_TABS.APPLICATIONS));
      history.push("/insights");
    }
  };

  // TODO: [ENG-1343] Need to re-enable the Datset Validation step here, and change the 'currStep' to reset at 4
  // Need to increment up if clicking next, or importApp and return to Insights if 'currStep==3'
  const nextStep = () => {
    if (nextPageReady) {
      setNextPageReady(false);
      if (currStep < 3) {
        setStep(currStep + 1);
        if (currStep === 2) {
          setNextName("Create");
        }
      } else {
        importApp(form);
        setStep(0);
        setForm(defaultForm);
        dispatch(actions.setCurrentScreen(INSIGHTS_TABS.APPLICATIONS));
        history.push("/insights");
      }
    }
  };

  // Check if form contains the correct info before creating
  function validation(form) {
    // TODO: [ENG-1234] Move Error messages to utility/constants file
    if (!form.name || !form.name.trim()) {
      commonService.forError("Empty App name not allowed", "Error");
      return false;
    }
    if (form.dataset_ids.length === 0) {
      commonService.forError("Dataset must be selected!", "Error");
      return false;
    }
    if (!form.app_type) {
      commonService.forError("Template App Type must be selected!", "Error");
      return false;
    }
    return true;
  }

  // Pass the completed form to the database
  const importApp = async () => {
    if (validation(form)) {
      dispatch(
        actions.importApp({
          application: {
            img_path: form.img_path,
            app_type: form.app_type,
            dataset_ids: form.dataset_ids,
            name: form.name,
            description: form.description,
          },
        })
      );
    }
  };

  const renderPinnedContent = () => {
    return (
      <>
        {/* Nav Buttons */}
        <div className="navButtons">
          <div>
            <Button className="btn backButton" onClick={prevStep}>
              Back
            </Button>
          </div>
          <div>
            <Button
              className={
                nextPageReady ? "btn nextButtonSelected" : " btn nextButton"
              }
              onClick={nextStep}
            >
              {nextName}
            </Button>
          </div>
        </div>
        <div className="mainCreationComponent">
          {/* StepBar */}
          <StepBar currStep={currStep}></StepBar>
          {/* Main App Creation */}
          <AppCreation
            currStep={currStep}
            form={form}
            setForm={setForm}
            setNextPageReady={setNextPageReady}
            validation={validation}
          />
        </div>
      </>
    );
  };

  //If no pins, render predefined content
  return <div className="row">{renderPinnedContent()}</div>;
};

export default InsightTemplateCreation;
