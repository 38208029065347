import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/AppActions";
import DashboardApps from "components/Insights/InsightList/InsightDashboardTab";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  FLIGHTING_CONSTANTS,
  INSIGHTS_TABS,
  PAGE_SIZE_CONSTANTS,
} from "utility/constants/constants";
import { useFlighting } from "../../hooks/useFlighting";

const Home = () => {
  const { user } = useSelector((state) => state.authReducer);
  const { pinnedApplications } = useSelector((state) => state.appReducer);
  const isInsightsEnabled = useFlighting(FLIGHTING_CONSTANTS.INSIGHTS);
  const dispatch = useDispatch();

  const changeScreen = (screenName) => {
    dispatch(actions.setCurrentScreen(screenName));
  };

  if (user.date_format === null) {
    user.date_format = "MM/DD/YY";
  }

  useEffect(() => {
    dispatch(actions.getAppPins({ params: null }));
  }, [dispatch]);

  const handlePin = (app) => {
    dispatch(
      actions.createAppPin({
        body: {
          app_pin: {
            app_id: app.id,
            user_id: user.id,
            pinned: false,
          },
        },
      })
      //update local pinnedApps in reducer to reflect new / updated app pin
    ).then((res) => {
      dispatch(
        actions.updatePinnedApps({
          body: {
            app: app,
            pinned: res.value.applicationPinInfo.pinned,
          },
        })
      );
    });
  };

  const RenderDashboardPreview = () => {
    if (pinnedApplications?.length > 0) {
      return (
        <div className="home-apps-wrapper">
          <div className="nav-link-wrapper">
            <Link
              onClick={() => changeScreen(INSIGHTS_TABS.DASHBOARD)}
              to={"/insights"}
              className="nav-link"
            >
              <h5>Go to Dashboard</h5>
              <ArrowForwardIcon />
            </Link>
            <div className="recently-pinned-wrapper">
              <h5>Recently Pinned Insights</h5>
              <img
                src={require(`assets/custom-styles/images/thumbnails/nh2.png`)}
                alt="img"
                className="corner-icn"
              />
            </div>
          </div>
          {/* show preview of 3 recently pinned apps */}
          <DashboardApps
            handlePin={handlePin}
            pinnedApplications={pinnedApplications.slice(
              0,
              PAGE_SIZE_CONSTANTS.HOME_INSIGHTS_PREVIEW
            )}
          />
        </div>
      );
    } else {
      return (
        <div className="home-apps-wrapper">
          <div className="no-pinned-content">You have no pinned assets...</div>
        </div>
      )
    }
  };

  return (
    <>
      <div className="hero__Portion">
        <div className="bg__pic bg__home">
          <img
            src={require("assets/custom-styles/images/thumbnails/hero_home.png")}
            alt="home"
          />
        </div>
      </div>
      <div
        id="main_header"
        className="lds_right_box_wrapper lds_right_po_box_wrapper"
      >
        <div className="container">
          <section className="home__hero">
            <div className="row">
              <div className="col-md-12">
                <section className="home__Intro">
                  <img
                    src={require("assets/custom-styles/images/thumbnails/main_logo_white.png")}
                    alt="img-ins"
                    height="75"
                    width="150"
                  />
                  <div>
                    <h1 className="home__Title">
                      Welcome to Ludis, {user.first_name}.
                      <span>
                        Use the left toolbar to navigate to your custom content.
                      </span>
                    </h1>
                  </div>
                </section>
              </div>
            </div>
            {isInsightsEnabled && <RenderDashboardPreview />}
          </section>
        </div>
      </div>
    </>
  );
};

export default Home;
