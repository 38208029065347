import { Modal } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { HighlightOff } from "@mui/icons-material";
import { Button, Select } from "../shared/ui-components/form-components";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import { ROLES } from "../../utility/constants/constants"
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentOrgFlighting,
  getCurrentOrgFlighting,
  updateFlighting
} from "../../redux/actions/FlightingActions";
import {
  clearOrganization,
  getActiveUserCounts
} from "../../redux/actions/OrganizationActions/Action";
import * as commonService from "../../utility/CommonService";

// If we ever include groups, this will have to change from referring from the
// ROLES constant to whatever call we create to get all the groups for an org
const FlightingConfigModal = ({
                                showModal,
                                setShowModal,
                                organizationId,
                                setOrganizationId = s => {}
                              }) => {
  const dispatch = useDispatch();
  const { currentOrgFlighting } = useSelector(
    (state) => state.flightingReducer
  );
  // This holds the checkboxes' state. Flighting payload gets updated alongside this.
  const [flightingState, setFlightingState] = useState({})
  // This is submitted as the flighting config when the 'Create Organization' button is clicked
  const [flightingPayload, setFlightingPayload] = useState([])
  const [selectedRole, setSelectedRole] = useState({
    label: ROLES.DEVELOPER,
    value: ROLES.DEVELOPER
  })

  const handleFlightingStateChange = (e) => {
    setFlightingState(prevState => ({
      ...prevState,
      [selectedRole.value]: {
        ...prevState[selectedRole.value],
        [e.target.name]: e.target.checked
      }
    }))
    if (e.target.checked) {
      // Add check for if the payload doesn't have the entry yet
      const entryIndex = flightingPayload.findIndex(entry => entry["key"] === e.target.name)
      if (entryIndex > -1) {
        flightingPayload[entryIndex]["user_roles"].push(selectedRole.value)
      } else {
        const missingFlightingName = e.target.name
        const addedRole = selectedRole.value
        flightingPayload.push(
          {
            "key": missingFlightingName,
            "name": missingFlightingName.charAt(0).toUpperCase() + missingFlightingName.slice(1),
            "active": true,
            "user_roles": [
              addedRole,
              "superadmin"
            ]
          }
        )
      }
    } else {
      const indexToRemove = flightingPayload[flightingPayload.findIndex(entry => entry["key"] === e.target.name)]["user_roles"].findIndex(el => el === selectedRole.value)
      if (indexToRemove > -1) {
        flightingPayload[flightingPayload.findIndex(entry => entry["key"] === e.target.name)]["user_roles"].splice(indexToRemove, 1)
      }
    }
    setFlightingPayload([
      ...flightingPayload
    ])
  }

  const handleClose = () => {
    setShowModal(false);
    setOrganizationId("");
    dispatch(clearCurrentOrgFlighting())
    dispatch(clearOrganization())
    dispatch(getActiveUserCounts())
  }

  const handleRoleChange = (newRole) => {
    setSelectedRole(newRole);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let res;
    res = await dispatch(
      updateFlighting(organizationId, { flightings: flightingPayload })
    );
    if (res?.value.success) {
      commonService.forSuccess(
        "Flighting updated successfully!",
        "Success"
      )
    }
    handleClose()
  }

  const checkCheckedStatus = (key) => {
    // Check to see if the flighting configuration entry is active first. If it
    // isn't, then checking the specific user role privilege doesn't matter and
    // the checkbox will be false.
    const index = flightingPayload.findIndex(el => el.key === key)
    let isActive = false
    if (index > -1) {
      isActive = flightingPayload[index]["active"]
    }
    return isActive ? flightingState[selectedRole.value][key] : false
  }

  const checkDisabledStatus = (key) => {
    return flightingPayload.length > 0 && flightingPayload.findIndex(el => el.key === key) > -1 ?
      flightingPayload[flightingPayload.findIndex(el => el.key === key)]["active"] === false : true
  }

  useEffect(() => {
    if (showModal) {
      dispatch(getCurrentOrgFlighting(organizationId))
    }
  }, [dispatch, organizationId, showModal])

  useEffect(() => {
    // Initialize the flightingState and flightingPayload based on the org's flightingConfig
    const flightingConfigByRole = {}
    Object.values(ROLES).forEach(role => {
      flightingConfigByRole[role] = []
    })

    if (currentOrgFlighting) {
      Object.values(ROLES).forEach(role => {
        currentOrgFlighting.forEach(entry => {
          if (entry.user_roles.includes(role)) {
            flightingConfigByRole[role] = {
              ...flightingConfigByRole[role],
              [entry["key"]]: true
            }
          } else {
            flightingConfigByRole[role] = {
              ...flightingConfigByRole[role],
              [entry["key"]]: false
            }
          }
        })
      })

      const newFlightingPayload = []
      currentOrgFlighting.forEach(entry => {
        newFlightingPayload.push({
          "key": entry.key,
          "name": entry.name,
          "active": entry.active,
          "user_roles": entry.user_roles
        })
      })

      setFlightingState(flightingConfigByRole)
      setFlightingPayload(newFlightingPayload)
    }
  }, [currentOrgFlighting])

  return (
    <Modal show={showModal}
           onHide={handleClose}
           animation={false}
           className="flighting_config_modal"
    >
      <Modal.Header className="border-0 modal_header">
        <div className="header_text">
          Flighting Configuration
        </div>
        <IconButton onClick={handleClose} aria-label="Close">
          <HighlightOff></HighlightOff>
        </IconButton>
      </Modal.Header>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Modal.Body>
          <div className="flighting_config_modal_body">
            <div className="add_user_field_header">
              Role
            </div>
            <Select
              name="role"
              options={Object.values(ROLES).filter(role => role !== ROLES.SUPERADMIN)}
              value={selectedRole}
              onChange={(e) => {
                handleRoleChange(e)
              }}
              className="flighting_config_select"
              required
            />
            <div className="add_user_field_header">
              Flighting Configurations
            </div>
            {
              Object.keys(flightingState).length > 0 &&
              <FormControl
                required
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    classes="flighting_configuration_label"
                    control={
                      <Checkbox
                        checked={checkCheckedStatus("insights")}
                        onChange={(e) => handleFlightingStateChange(e)}
                        name="insights"
                        size="small"
                        disabled={checkDisabledStatus("insights")}
                      />
                    }
                    label="Insights"
                  />
                  <FormControlLabel
                    classes="flighting_configuration_label"
                    control={
                      <Checkbox
                        checked={checkCheckedStatus("projects")}
                        onChange={(e) => handleFlightingStateChange(e)}
                        name="projects"
                        size="small"
                        disabled={checkDisabledStatus("projects")}
                      />
                    }
                    label="Projects"
                  />
                  <FormControlLabel
                    classes="flighting_configuration_label"
                    control={
                      <Checkbox
                        checked={checkCheckedStatus("workflows")}
                        onChange={(e) => handleFlightingStateChange(e)}
                        name="workflows"
                        size="small"
                        disabled={checkDisabledStatus("workflows")}
                      />
                    }
                    label="Workflows"
                  />
                  <FormControlLabel
                    classes="flighting_configuration_label"
                    control={
                      <Checkbox
                        checked={checkCheckedStatus("datasets")}
                        onChange={(e) => handleFlightingStateChange(e)}
                        name="datasets"
                        size="small"
                        disabled={checkDisabledStatus("datasets")}
                      />
                    }
                    label="Datasets"
                  />
                  <FormControlLabel
                    classes="flighting_configuration_label"
                    control={
                      <Checkbox
                        checked={checkCheckedStatus("notebooks")}
                        onChange={(e) => handleFlightingStateChange(e)}
                        name="notebooks"
                        size="small"
                        disabled={checkDisabledStatus("notebooks")}
                      />
                    }
                    label="Notebooks"
                  />
                </FormGroup>
              </FormControl>
            }
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="add_org_save_button_container">
            <Button type="submit" className="add_org_save_button btn">
              Update flighting
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default FlightingConfigModal

