import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
// user action
import * as actions from "redux/actions/AuthActions";
import * as appActions from "redux/actions/ProjectActions";
import { useDispatch, useSelector } from "react-redux";
//tabs for user projects and shared projects
import Tabs from "./ProjectTabs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as commonService from "utility/CommonService.js";
import { ProjectList } from "./ProjectList";
import SharedTab from "./ProjectsSharedTab";
import { PROJECT_TABS } from "utility/constants/constants";
import swal from "sweetalert";

const ProjectData = ({ handleFilter, user, history }) => {
  const dispatch = useDispatch();
  const [gitForm, setGitForm] = useState({
    git_username: "",
    git_token: "",
  });
  const projectScreen = useSelector(
    (state) => state.projectReducer.projectScreen
  );
  const closeBtn = useRef();
  const addGitToken = (e) => {
    e.preventDefault();
    dispatch(actions.gitDetailsAccess({ body: gitForm })).then((res) => {
      if (res.value.success) {
        closeBtn.current.click();
        history.push("/projects/new");
      }
    });
  };

  const deleteProject = (id) => {
    swal({
      text: "Are you sure you want to delete this Project?",
      className: "logout-alert-modal",
      buttons: [true, "Delete"],
    }).then((result) => {
      if (result) {
        dispatch(appActions.deleteProject({ id })).then((res) => {
          if (res.value && res.value.success) {
            commonService.forSuccess("Project Deleted!", "Success");
          }
        });
      }
    });
  };

  const handleChange = (e) => {
    setGitForm({ ...gitForm, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <div className="lds_po_heading">
            <h1 style={{"text-wrap":"nowrap"}}>Notebooks - Deprecated</h1>
          </div>
        </div>
        <div className="col-md-9 col-sm-12">
          <div className="lds_po_cp_btn">
            {user.git_token ? (
              <Link to="/projects/new" className="btn">
                <AddCircleOutlineIcon /> create project
              </Link>
            ) : (
              <button
                className="float-right btn"
                data-toggle="modal"
                data-target="#gitModal"
              >
                <AddCircleOutlineIcon /> create project
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
        <Tabs handleFilter={handleFilter} />
        </div>
      </div>
      <div className="lds_pl_model">
        <div className="modal fade in" id="gitModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4>
                  Please add Git access to create project
                  <a
                    href="https://help.github.com/en/github/authenticating-to-github/creating-a-personal-access-token-for-the-command-line"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("assets/custom-styles/images/thumbnails/help_icn.png")}
                      alt="icn"
                    />
                  </a>
                </h4>
                <button
                  type="button"
                  ref={closeBtn}
                  className="close close-project-modal"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={addGitToken}>
                  <h4>Github Username</h4>
                  <input
                    type="text"
                    placeholder="Enter username"
                    value={gitForm.git_username}
                    name="git_username"
                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                  />
                  <h4>Personal Access Token</h4>
                  <input
                    type="text"
                    placeholder="Enter token"
                    value={gitForm.git_token}
                    name="git_token"
                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                  />
                  <button type="submit" className="btn">
                    submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab-content">
        <div
          className={`tab-pane container ${
            projectScreen === PROJECT_TABS.MY_PROJECTS ? "active" : "fade"
          }`}
          id="myProjects"
        >
          <ProjectList
            deleteProject={deleteProject}
          />
        </div>
        <div
          className={`tab-pane container ${
            projectScreen === PROJECT_TABS.SHARED ? "active" : "fade"
          }`}
          id="shared"
        >
          <SharedTab
            deleteProject={deleteProject}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectData;
