import React from "react";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
import * as actions from "redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import HamburgerMenu from "hoc/Layout/HamburgerMenu";
import {
  AssignmentOutlined,
  HelpOutlineOutlined,
  IntegrationInstructionsOutlined,
  MenuBookOutlined,
  PowerSettingsNew,
  StorageOutlined,
  HomeOutlined,
  AccountTreeOutlined,
  TimelineOutlined,
  AdminPanelSettingsOutlined
} from "@mui/icons-material";
import { FLIGHTING_CONSTANTS } from "utility/constants/constants";
import { Tooltip } from "@material-ui/core";

/*
 * params set sidebar toggle and responsive sidebar toggle
 * */
const NavMenu = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const { config } = useSelector((state) => state.flightingReducer);

  const hasFlightingPermission = (flightingOption) => {
    const featureConfig =
      config && Array.isArray(config)
        ? config.find((e) => e.key === flightingOption)
        : null;
    return !!(featureConfig?.active && featureConfig?.user_roles.includes(user.role));
  };

  const support = () => {
    var el = document.createElement("a");
    el.href = "mailto:info@ludisanalytics.com";
    el.innerText = "info@ludisanalytics.com";
    el.style.textTransform = "lowercase";
    el.target = "_blank";
    swal({
      title: "Support",
      buttons: {
        cancel: "Close",
      },
      text: "For support, please email: ",
      content: el,
      className: "logout-alert-modal",
    });
  };

  const logout = () => {
    swal({
      title: "",
      text: "Are you sure you would like to logout?",
      className: "logout-alert-modal",
      buttons: true,
    }).then((result) => {
      if (result) dispatch(actions.logout());
    });
  };
  return (
    <>
      <div className="side_lds_main_menu_wrapper">
        <div id="sidebar_logo">
          <img
            src={require("assets/custom-styles/images/thumbnails/LUDIS-icon.svg")}
            alt="Ludis Logo"
          />
        </div>
        <div className="side_menu_items">
          <ul>
            <li>
              <NavLink to="/home" exact={true} activeClassName="active">
                <HomeOutlined />
                <span>Home</span>
              </NavLink>
            </li>
            {hasFlightingPermission(FLIGHTING_CONSTANTS.INSIGHTS) && (
              <li>
                <NavLink to="/insights" exact={false} activeClassName="active">
                  <TimelineOutlined />
                  <span>Insights</span>
                </NavLink>
              </li>
            )}
            {hasFlightingPermission(FLIGHTING_CONSTANTS.PROJECTS) && (
              <li>
                <NavLink to="/projectsv2" exact={false} activeClassName="active">
                  <AssignmentOutlined />
                  <span>Projects</span>
                </NavLink>
              </li>
            )}
            {hasFlightingPermission(FLIGHTING_CONSTANTS.WORKFLOWS) && (
              <li>
                <NavLink to="/workflows" exact={false} activeClassName="active">
                  <AccountTreeOutlined />
                  <span>Workflows</span>
                </NavLink>
              </li>
            )}
            {hasFlightingPermission(FLIGHTING_CONSTANTS.DATASETS) && (
              <li>
                <NavLink to="/datasets" exact={false} activeClassName="active">
                  <StorageOutlined />
                  <span>Datasets</span>
                </NavLink>
              </li>
            )}
            {hasFlightingPermission(FLIGHTING_CONSTANTS.NOTEBOOKS) && (
              <li>
                <Tooltip title="Deprecated: You can continue to use Jupyter Notebooks but they are no longer supported" placement="top">
                  <NavLink to="/projects" exact={false} activeClassName="active">
                    <IntegrationInstructionsOutlined />
                    <span>Notebooks</span>
                  </NavLink>
                </Tooltip>
              </li>
            )}
            <li onClick={support} className="cursor-pointer">
              <HelpOutlineOutlined />
              <span>Support</span>
            </li>
            <li className="cursor-pointer">
              <a
                href="https://ludisanalytics.github.io/documentation/"
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <MenuBookOutlined />
                <span>Documentation</span>
              </a>
            </li>
            {["administrator", "superadmin"].includes(user.role) && (
              <li className="cursor-pointer">
                <NavLink to="/admin-portal" activeClassName="active">
                  <AdminPanelSettingsOutlined />
                  <span>Admin</span>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="/profile-setting" activeClassName="active">
                <img
                  src={
                    user && user.photo_path
                      ? user.photo_path.small
                      : require("assets/custom-styles/images/thumbnails/user1.png")
                  }
                  alt="side_img"
                  className="border-round"
                />
                <span className="last_child_name">Account</span>
              </NavLink>
            </li>
          </ul>
          <ul id="logout_button">
            <li onClick={logout} className="cursor-pointer">
              <PowerSettingsNew />
              <span>Logout</span>
            </li>
          </ul>
        </div>
      </div>
      {/* Menu icon for smaller viewports */}
      <div className="hamburger-menu">
        <HamburgerMenu
          logout={logout}
          support={support}
          hasFlightingPermission={hasFlightingPermission}
        />
      </div>
    </>
  );
};

export default NavMenu;
