import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppMetrics,
  clearProjectMetrics,
  clearDatasetMetrics,
  clearWorkflowMetrics,
} from "../../redux/actions/MetricsActions/Action";
import {
  getActiveUserCounts,
} from "../../redux/actions/OrganizationActions/Action";
import Carousel from "./Carousel";


const EntityUsageOverview = ({
                             isSelected,
                             setCurrentOrganizationId,
                             setOrgAppDetailsClicked,
                             insightsGraph,
                           }) => {
  const dispatch = useDispatch();
  const {activeUserCounts} = useSelector((state) => state.organizationReducer)

  useEffect(() => {
    if (isSelected) {
      dispatch(clearAppMetrics())
      dispatch(clearProjectMetrics())
      dispatch(clearDatasetMetrics())
      dispatch(clearWorkflowMetrics())
      dispatch(getActiveUserCounts())
    }
  }, [dispatch, isSelected])

  return (
    <div className="admin_tab_panel_container">
      <div className="panel_header">Overview</div>
      <div className="panel entity_usage_overview_graph_container">
        {insightsGraph}
      </div>
      <div className="org_tiles_row">
        <Carousel itemList={activeUserCounts}
                  title="Organizations' entity statuses"
                  limit={4}
                  setShowDetailPage={setOrgAppDetailsClicked}
                  setId={setCurrentOrganizationId} />
      </div>
    </div>
  )
}

export default EntityUsageOverview