import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import KMeans2D from "../TemplateComponents/KMeans2D";
import KMeans3D from "../TemplateComponents/KMeans3D";
import DropdownCustomContainer from "./Dropdown";
import ClusterSlider from "./SliderComponent";
import MUIDataTable from "mui-datatables";
import * as appActions from "../../../../../redux/actions/AppActions";
import * as DataSetActions from "../../../../../redux/actions/DataSetActions";
import {getClusters} from "../template.util";
import DataPointInfo from "../TemplateComponents/DataPointInfo";
import "./ClusteringTemplate.css";
import { AppHeader } from "../TemplateComponents/AppHeader";

export function ClusteringTemplate(
    {application}
) {
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const [kmeansMode, setMode] = useState('kmeans2d');
    const [xval, setXval] = useState('');
    const [yval, setYval] = useState('');
    const [zval, setZval] = useState('');
    const [xAxis, setXAxis] = useState('');
    const [yAxis, setYAxis] = useState('');
    const [zAxis, setZAxis] = useState('');
    const [k, setK] = useState(4);
    const [dataTableStartNum, setDataTableStartNum] = useState(0)
    const [dataTableRows, setDataTableRows] = useState(10)

    useEffect(() => {
        dispatch(DataSetActions.getDataSet({ id:application.datasets[0] })).then((res) => {
            let path = res.value.entity.path
            let dataSetId = res.value.entity.id
            //get files associated with dataset
            dispatch(
                DataSetActions.getDataSetFiles({
                body: { path: path },
                id: dataSetId
              })
            )
        });
        dispatch(appActions.getColumnNames({id: application.id}));
      }, [application, dispatch]);

    useEffect(() => {
        if (xval && yval) {
            dispatch(appActions.getGraphData({id: application.id, body: {columns:[xval,yval,zval] }}));
        }
    }, [application, xval, yval, zval, dispatch]);

    useEffect(() => {
        dispatch(appActions.paginateData({body: {start:dataTableStartNum, count:dataTableRows}, id: application.id}));
    }, [application, dataTableStartNum, dataTableRows, dispatch]);

    const { directoryFiles } = useSelector(
        (state) => state.dataSetReducer
    );

    const { templateData, appColumnNames, paginateData, datapoint  } = useSelector(
        (state) => state.appReducer
    );

    // run clustering analysis
    useEffect(() => {
        if (templateData) {
            let clusteredKMeansData = getClusters(templateData[0], xAxis, yAxis, null, k)
            setData(clusteredKMeansData)
        }
    }, [templateData, xAxis, yAxis, k])

    //slider screen reader options
    function valuetext(value) {
        return `${value}`;
    }
    const onChangePages = (page) => {
        setDataTableStartNum(page * dataTableRows)
    }

    // X Axis dropdown

    const handleXAxisChange = (e) => {
        setXAxis(e.name);
        setXval(e.value);
    };

    // Y Axis dropdown
    const handleYAxisChange = (e) => {
        setYAxis(e.name);
        setYval(e.value);
    };

    // Z Axis dropdown
    const handleZAxisChange = (e) => {
        setZAxis(e.name);
        setZval(e.value);
    };

    // cluster number slider
    const handleKChange = (event, newK) => {
        setK(newK);
    };

    //2D/3D toggle
    const handleModeChange = (event, newMode) => {
        if (newMode !== null) {
            setMode(newMode);
        }
    };

    const handleClick = (event) => {
        const point = event.points[0];
        dispatch(appActions.datapoint(
            {body:
                {
                    start:parseInt(point.text) - 1, //backend skips the first row
                    count:1
                },
            id: application.id
            }))
    }

    return(
        <div className="templateContainer">
            {application &&
            <AppHeader
                name={application.name}
                description={application.description}
                directoryFiles={directoryFiles}
                kmeansMode={kmeansMode}
                handleModeChange={handleModeChange}
            >
            </AppHeader>}
            <div className="gridContainer">
                <div className="axisDisplay">
                    <div className="axisDisplayTextCluster">
                        Axis Display
                    </div>
                    {appColumnNames &&
                        <div className="dropdownsCustom">
                            <DropdownCustomContainer
                                order="0"
                                align="flex-end"
                                axisOptions={appColumnNames}
                                handleAxisChange={handleXAxisChange}
                                value={xAxis}
                                label='X-Axis'
                                name='xAxis'
                                val={xval}
                                templateData={templateData}
                            >
                            </DropdownCustomContainer>
                            <DropdownCustomContainer
                                order="1"
                                align="flex-start"
                                axisOptions={appColumnNames}
                                handleAxisChange={handleYAxisChange}
                                value={yAxis}
                                label='Y-Axis'
                                name='yAxis'
                                val={yval}
                                templateData={templateData}>
                            </DropdownCustomContainer>
                            {kmeansMode === "kmeans3d"
                            ?<DropdownCustomContainer
                                order="2"
                                align="flex-start"
                                axisOptions={appColumnNames}
                                handleAxisChange={handleZAxisChange}
                                value={zAxis}
                                label='Z-Axis'
                                name='zAxis'
                                val={zval}
                                templateData={templateData}>
                            </DropdownCustomContainer>
                            :<></>
                            }
                        </div>
                    }
                </div>
                <ClusterSlider k={k} defaultValue={4} step={1} min={1} max={9} handleKChange={handleKChange} valuetext={valuetext}></ClusterSlider>
                <div className="clusterPlot2">
                            {kmeansMode === "kmeans2d"
                                ? <KMeans2D nodesData={data} xvar={xval} yvar={yval} k={k} handlePlotClick={handleClick} />
                                : <KMeans3D nodesData={data} xvar={xval} yvar={yval} zvar={zval} k={k} handlePlotClick={handleClick} />
                            }
                </div>
                <div className="myInfo">
                    <div className="datapoint">
                        <id id="DataPointInfoHeader">
                            Data Point
                        </id>
                        <div className="datapointInfo">
                            <DataPointInfo columns={appColumnNames} data={datapoint}></DataPointInfo>
                        </div>
                    </div>
                </div>
            </div>
            {appColumnNames &&
            <div className="datatableStyle">
                {paginateData && templateData && <MUIDataTable
                    title={"Data"}
                    data={paginateData}
                    columns={appColumnNames}
                    options={{
                        serverSide:true,
                        count:templateData[1],
                        onTableChange: (action, tableState) => {
                            switch (action) {
                            case 'changePage':
                                onChangePages(tableState.page);
                                break;
                            case 'changeRow':
                                setDataTableRows(tableState.rows)
                                break;
                            default:
                                break;
                            }
                        },
                        selectableRows: 'none'
                    }}
                />}
            </div>
            }
        </div>
    );

}

export default ClusteringTemplate;