import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Organizations = () => {
  const { user } = useSelector((store) => store.authReducer);

  return (
    <div className="row">
      <div className="lds_right_box_wrapper w-100">
        <div className="lds_cp_box w-100">
          <div className="row">
            <div className="col-md-12 w-100">
              <h2 className="text-center">Organization{user.role !== 'superadmin' || 's'}</h2>
              {
                user.role === 'superadmin' ?
                  <div>
                    <div className=" mt-5">
                      <h4 className="mb-3">Organizations</h4>
                      <Link to={"/organizations"} className="btn cursor-pointer">
                        Manage Organizations
                      </Link>
                    </div>
                    <div className=" mt-5">
                      <h4 className="mb-3">Collections</h4>
                      <Link to={"/collections"} className="btn cursor-pointer">
                        Manage Collections
                      </Link>
                    </div>
                  </div>
                  :
                  <div className=" mt-5">
                    <h4 className="mb-3">Users</h4>
                    <Link to={`/organizations/${user.organization}/users`} className="mb-1 btn cursor-pointer">
                      Manage Users
                    </Link>
                    <h4 className="mb-3 mt-3 ">Flighting Configuration</h4>
                    <Link to={"/flight-settings"} className="btn cursor-pointer">
                      Manage Flight Config
                    </Link>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Organizations;
