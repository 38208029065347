import { userActionTypes } from "./ActionType";
import * as API from "../../../api/UserAPI";

export const getUsers = (params) => (dispatch) =>
  dispatch({
    type: userActionTypes.GET_USERS,
    payload: API.getUsers(params),
  });
export const clearUsers = () => (dispatch) =>
  dispatch({
    type: userActionTypes.CLEAR_USERS,
  });
export const getUser = ({ id }) => (dispatch) =>
  dispatch({
    type: userActionTypes.GET_USER,
    payload: API.getUser(id),
  });
export const createUser = ({ body }) => (dispatch) =>
  dispatch({
    type: userActionTypes.CREATE_USER,
    payload: API.createUser(body),
  });

export const updateUser = ({ body, id }) => (dispatch) =>
  dispatch({
    type: userActionTypes.UPDATE_USER,
    payload: API.updateUser(body, id),
  });
export const deleteUser = (id) => (dispatch) =>
  dispatch({
    type: userActionTypes.DELETE_USER,
    payload: API.deleteUser(id).then(() => ({id})),
  });
