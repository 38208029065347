import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../redux/actions/ProjectActions";
import { PROJECT_TABS } from "utility/constants/constants";



const ProjectTabs = ({handleFilter}) => {
  const dispatch = useDispatch();
  const projectScreen = useSelector((state) => state.projectReducer.projectScreen);
  

  const changeProjectScreen = (screenName) => {
    dispatch(actions.setProjectScreen(screenName));
  };
  

  return (
    <div className="col-md-12" style={{paddingRight: "0px"}}>
      <div className="lds_po_tabs_main_box">
        <div className="row">
          <div className="col-md-6">
            <div className="lds_po_tabs">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${projectScreen === PROJECT_TABS.MY_PROJECTS ? "active" : ""
                      }`}
                    data-toggle="pill"
                    to="/projects"
                    onClick={() => changeProjectScreen(PROJECT_TABS.MY_PROJECTS)}
                  >
                    My Projects
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/projects"
                    className={`nav-link ${projectScreen === PROJECT_TABS.SHARED ? "active" : ""
                      }`}
                    data-toggle="pill"
                    onClick={() => changeProjectScreen(PROJECT_TABS.SHARED)}
                  >
                    Shared With Me
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default ProjectTabs;
