import styled from "styled-components";

export const PanelContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  overflow-y: hidden;
  flex-direction: column;
`;

export const DiscussionContainer = styled.div`
  width: 100%;
  padding-left: 23px;
`;

export const SlidingPanelContainer = styled.div`
  position: fixed;
  background-color: #ffffff;
  flex-direction: column;
  display: flex;
  top: 0;
  right: 0px;
  height: 100%;
  z-index: 1;
  ${({ isOpen }) =>
    isOpen
      ? `width: 500px;`
      : `width: 0px;`
  }
  transition: 0.5s;
  overflow: visible;
`;

export const TabContainer = styled.div`
  width: 28px;
  height: 88px;
  background: #ffffff;
  box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  position: fixed;
  top: 50%;
  z-index: 1;
  ${({ isOpen }) =>
    isOpen
      ? `right: 495px;`
      : `right: -5px;`
  }
  transition: 0.5s;
  cursor: pointer;
`;

export const TabLine = styled.div`
  width: 3px;
  height: 54px;
  position: absolute;
  opacity: 0.3;
  /* Charcoal */
  z-index: 1;
  background: #4f4f4f;
  left: 8px;
  top: 17px;
`;

export const TabLine1 = styled.div`
  width: 3px;
  height: 54px;
  position: absolute;
  opacity: 0.3;
  /* Charcoal */

  background: #4f4f4f;
  z-index: 1;
  left: 13px;
  top: 17px;
`;
