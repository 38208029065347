import { AppActionTypes } from "./actionType";
import * as API from "../../../api/AppAPI";

export const getReports = (params) => (dispatch) =>
  dispatch({
    type: AppActionTypes.GET_REPORTS,
    payload: API.getReports(params)
  });
export const getApp = ({ id }) => (dispatch) =>
  dispatch({
    type: AppActionTypes.GET_APP,
    payload: API.getApp(id)
  });

export const getAppPins = ({params}) => (dispatch) =>
  dispatch({
    type: AppActionTypes.GET_APP_PINS,
    payload: API.getAppPins(params)
});

export const updatePinnedApps = ({body}) => (dispatch) =>
  dispatch({
    type: AppActionTypes.UPDATE_PINNED_APPS,
    payload: body
  })

export const createApp = (body) => (dispatch) =>
  dispatch({
    type: AppActionTypes.CREATE_APP,
    payload: API.createApp(body)
  });

export const createAppV2 = (body) => (dispatch) =>
  dispatch({
    type: AppActionTypes.CREATE_APP_V2,
    payload: API.createAppV2(body)
  });

export const createAppPreview = (body) => (dispatch) =>
  dispatch({
    type: AppActionTypes.CREATE_APP_PREVIEW,
    payload: API.createAppV2(body)
  });

  export const importApp = (body) => (dispatch) =>
  dispatch({
    type: AppActionTypes.IMPORT_APP,
    payload: API.importApp(body)
  });

export const importReport = (body) => (dispatch) =>
  dispatch({
    type: AppActionTypes.IMPORT_REPORT,
    payload: API.importReport(body)
  });

export const createAppShare = (body) => dispatch =>
  dispatch({
    type: AppActionTypes.CREATE_APP_SHARE,
    payload: API.createAppShare(body).then(res => ({...res, ...body}))
  });

export const unshareApp = (body) => dispatch =>
  dispatch({
    type: AppActionTypes.UNSHARE_APP,
    payload: API.unshareApp(body.app_id, body).then(res => ({...res, ...body}))
  });

export const deleteAppShare = (id) => dispatch =>
  dispatch({
    type: AppActionTypes.DELETE_APP_SHARE,
    payload: API.deleteAppShare(id)
  });

  export const reStartAppShare = (body) => dispatch =>
  dispatch({
    type: AppActionTypes.RESTART_APP_SHARE,
    payload: API.reStartAppShare(body).then(res => ({...res, ...body}))
  });

  export const stopAppShare = (body) => dispatch =>
  dispatch({
    type: AppActionTypes.STOP_APP_SHARE,
    payload: API.stopAppShare(body).then(res => ({...res, ...body}))
  });

  export const statusAppShare = (body) => dispatch =>
  dispatch({
    type: AppActionTypes.STATUS_APP_SHARE,
    payload: API.statusAppShare(body).then(res => ({...res, ...body}))
  });


  export const createAppPin = ({id, body}) => (dispatch) =>
  dispatch({
    type: AppActionTypes.CREATE_APP_PIN,
    payload: API.createAppPin(body)
  });

export const deleteApp = ({ id }) => (dispatch) =>
  dispatch({
    type: AppActionTypes.DELETE_APP,
    payload: API.deleteApp(id)
  });

export const updateApp = ({ body, id }) => (dispatch) =>
  dispatch({
    type: AppActionTypes.UPDATE_APP,
    payload: API.updateApp(body, id)
  });

export const getApplications = (params) => (dispatch) => {
  dispatch({
    type: AppActionTypes.GET_APPLICATIONS,
    payload: API.getApplications(params)
  });
};

export const getInsights = (params) => (dispatch) => {
  dispatch({
    type: AppActionTypes.GET_INSIGHTS,
    payload: API.getInsights(params)
  });
};

export const getAppShares = (params) => (dispatch) => {
  dispatch({
    type: AppActionTypes.GET_APP_SHARES,
    payload: API.getAppShares(params)
  });
};

export const togglePinnedApp = (params) => (dispatch) => {
  dispatch({
    type: AppActionTypes.TOGGLE_PINNED_CARDS_FULFILLED,
    payload: params
  });
};

export const setCurrentScreen = (params) => (dispatch) => {
  dispatch({
    type: AppActionTypes.SET_CURRENT_SCREEN_FULFILLED,
    payload: params
  });
};

export const getColumnNames = ({id}) => (dispatch) =>
  dispatch({
    type: AppActionTypes.GET_COLUMN_NAMES,
    payload: API.getColumnNames(id),
  });

export const getGraphData = ({body, id}) => (dispatch) => {
  try {
    dispatch({
      type: AppActionTypes.GET_GRAPH_DATA,
      payload: API.getGraphData(body, id),
    });
  } catch (error) {
    console.log(error)
  }
}

export const getForecastData = ({body, id}) => (dispatch) =>
  dispatch({
    type: AppActionTypes.GET_FORECAST_DATA,
    payload: API.getForecastData(body, id),
  });

export const paginateData = ({body, id}) => (dispatch) =>
  dispatch({
    type: AppActionTypes.GET_DATA_PAGINATION,
    payload: API.paginateData(body, id),
  });

export const datapoint = ({body, id}) => (dispatch) =>
  dispatch({
    type: AppActionTypes.GET_DATA_POINT,
    payload: API.datapoint(body, id),
  });

export const redeployApp = (body, id) => (dispatch) =>
  dispatch({
    type: AppActionTypes.REDEPLOY_APP,
    payload: API.redeployApp(body, id)
  })