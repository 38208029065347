import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import * as commonService from "utility/CommonService.js";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import {DEFAULT_DATETIME_OPTION } from "../../../utility/constants/constants";
import { deleteUser } from "../../../redux/actions/UserActions/Action";
import style from "./users.module.css";
import { clearUsers } from "../../../redux/actions/UserActions/Action";
import { Check, Close } from "@mui/icons-material";
import { SUCCESS_COLOR, FAILURE_COLOR } from "utility/constants/constants";

const Table = ({ pagination, setPagination }) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const { users, userCount } = useSelector((state) => state.userReducer);
  
  const handleDelete = (uid) => {
    swal({
      title: "",
      text: "Are you sure?",
      className: "logout-alert-modal",
      buttons: true,
    }).then((result) => {
      if (result) {
        dispatch(deleteUser(uid)).then((res) => {
          if (res.value && res.value.success) {
            commonService.forSuccess("User Deleted", "Success");
          }
        });
      }
    });
  };

  useEffect(() => {
    dispatch(clearUsers());
  }, [dispatch]);
  
  return (
    <div className="col-md-12  ">
      <div className="lds_po_tabs_main_box">
        <div className={style.card_medium}>
          <div 
          className={`${style.db_ListView} ${style.db_ListView_withFooter}}`}
          >
            <InfiniteScroll 
              dataLength={users.length}
              next={() => {
                setPagination({ ...pagination, page: pagination.page + 1 })
              }}
              hasMore={users.length < parseInt(userCount)}
            >
            <table className={style.Table}>
              <thead>
                <tr className={style.db_ListViewItem_header}>
                  <th
                    className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                  >
                    First Name
                  </th>
                  <th
                    className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                  >
                    Last Name
                  </th>
                  <th
                    className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                  >
                    Email
                  </th>
                  <th
                    className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                  >
                    Role
                  </th>
                  <th
                    className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                  >
                    2FA Enabled
                  </th>
                  <th
                    className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                  >
                    Created At
                  </th>
                  <th
                    className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                  ></th>
                </tr>
              </thead>
              <tbody className={style.Table_body}>
                {users && users?.map((user, index) => (
                  <tr
                    key={index}
                    className={`${style.Table_row} ${style.db_ListViewItem} ${style.db_ListViewItem_hasLink}`}
                  >
                    <td className={`${style.Table_cell_I_inn} ${style.pl_20}`}>
                      {user.first_name}
                    </td>
                    <td className={style.Table_cell_I_inn}>
                      <span>{user.last_name}</span>
                    </td>
                    <td className={style.Table_cell_I_inn}>
                      <span>{user.email}</span>
                    </td>
                    <td className={style.Table_cell_I_inn}>
                      <span>{user.role}</span>
                    </td>
                    <td className={`${style.Table_cell_I_inn} ${style.center}`}>
                      <span>{user.enabled_2fa ? <Check sx={{ color: SUCCESS_COLOR }} /> : <Close sx={{ color: FAILURE_COLOR }} />}</span>
                    </td>
                    <td className={`${style.Table_cell_I_inn} ${style.pr_20}`}>
                      <span>
                        {moment(user.created_at).format((user.date_format ? user.date_format: DEFAULT_DATETIME_OPTION), "HH:mm A")}
                      </span>
                    </td>
                    <td className={`${style.Table_cell_I_inn} ${style.pr_20}`}>
                      <button className={ ` ${style.btn_transperent} ${style.ldsToolTips}`}>
                        <div className="show__tool mr-2">
                          <Link
                            to={`/organizations/${match.params.organizationId}/users/edit/${user.uid}`}
                          >
                            <i
                              className={`fa fa-edit ${style.color_primary}`}
                            ></i>{" "}
                            <span className="show_pin_rep">Edit</span>
                          </Link>
                        </div>
                      </button>
                      <button className={ ` ${style.btn_transperent} ${style.ldsToolTips}`}
                        onClick={() => handleDelete(user.uid)}>
                        {" "}
                        <div className="show__tool mr-2">
                          <i className="fa fa-trash text-danger"></i>
                          <span className="show_pin_rep">Delete</span>
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </InfiniteScroll>
          </div>
          <div className={style.Divider_light_bottom}>
            <div className={style.Box_root}>
              <div className={style.left_Side}>
                <div className={style.left_Side}>
                  <span className={style.result_All}>
                    <strong>{userCount}</strong> results
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;

