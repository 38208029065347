import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/DataSetActions";
import DataSetsDetailPage from "components/DataSets/DataSetDetails/index";

const DataSetDetails = ({ history }) => {
  const { directoryFiles } = useSelector((state) => state.dataSetReducer);
  const [loaded, setLoaded] = useState(false);
  const [accessLevel, setAccessLevel] = useState("edit")
  const dispatch = useDispatch();
  let id = history.location.pathname.split("/")[2];

  useEffect(() => {
    if(!loaded){
      //get DataSet
      dispatch(actions.getDataSet({ id })).then((res) => {
        let path = res.value.entity.path
        let dataSetId = res.value.entity.id
        setAccessLevel(res.value.entity.access_level)
        //get files associated with dataset
        dispatch(
          actions.getDataSetFiles({
            body: { path: path },
            id: dataSetId
          })
        ).then((res) => {
          setLoaded(true);
        });
      });
    }
  }, [dispatch, loaded, id, directoryFiles]);

  if(loaded){
    return <DataSetsDetailPage accessLevel = {accessLevel} />
  }
  else{
    return null
  }

};

export default DataSetDetails;