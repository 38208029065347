import React, { useEffect, useState } from "react";
import TimeRangeIndicator
  from "../shared/Graph/TimeRangeIndicator/TimeRangeIndicator";
import { useDispatch, useSelector } from "react-redux";
import StackedBarGraph from "../shared/Graph/StackedBarGraph";
import { ENTITY_TYPES } from "../../utility/constants/constants";
import EmptyGraph from "../shared/Graph/EmptyGraph";
import Loader from "../shared/Loader/Loader";
import Select from "react-select";
import {
  getAppMetricsByOrg, getDatasetMetricsByOrg,
  getProjectMetricsByOrg, getWorkflowMetricsByOrg
} from "../../redux/actions/MetricsActions/Action";
import { isFinite } from "lodash";

const OverviewEntityPanel = ({user}) => {
  const dispatch = useDispatch();
  const {
    appMetrics,
    projectMetrics,
    datasetMetrics,
    workflowMetrics
  } = useSelector((state) => state.metricsReducer);

  const [timeRange, setTimeRange] = useState('week')
  const [dropdownValue, setDropdownValue] = useState({ value: ENTITY_TYPES.INSIGHT, label: 'Insights' })
  const [selectedEntity, setSelectedEntity] = useState(ENTITY_TYPES.INSIGHT)
  const [metrics, setMetrics] = useState({})
  const [isMetricsLoaded, setIsMetricsLoaded] = useState(false);

  const entityGraphOptions = {
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true
      }]
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
          if (label) {
            label += `: ${value}`;
          }
          return label
        },
        footer: (tooltipItems, data) => {
          let sum = 0;
          for (let i = 0; i < data.datasets.length; i++) {
            if (isFinite(data.datasets[i].data[tooltipItems[i].index])) {
              sum += data.datasets[i].data[tooltipItems[i].index];
            }
          }
          return `Total: ${sum.toString()}`
        }
      }
    }
  }

  const handleTimeChange = (event, newTime) => {
    if (newTime !== null) {
      setTimeRange(newTime)
    }
  }

  const handleEntityChange = (option) => {
    setDropdownValue(option)
    setSelectedEntity(option.value)
  }

  const options = [
    { value: ENTITY_TYPES.INSIGHT, label: 'Insights' },
    { value: ENTITY_TYPES.PROJECT_V2, label: 'Projects' },
    { value: ENTITY_TYPES.DATASET, label: 'Datasets'},
    { value: ENTITY_TYPES.WORKFLOW, label: 'Workflows'}
  ]

  useEffect(() => {
    setIsMetricsLoaded(false)
    if (selectedEntity === ENTITY_TYPES.INSIGHT) {
      dispatch(getAppMetricsByOrg(user.organization, {
        period: timeRange,
        limit: 10
      }))
    } else if (selectedEntity === ENTITY_TYPES.PROJECT_V2) {
      dispatch(getProjectMetricsByOrg(user.organization, {
        period: timeRange,
        limit: 10
      }))
    } else if (selectedEntity === ENTITY_TYPES.DATASET) {
      dispatch(getDatasetMetricsByOrg(user.organization, {
        period: timeRange,
        limit: 10
      }))
    } else if (selectedEntity === ENTITY_TYPES.WORKFLOW) {
      dispatch(getWorkflowMetricsByOrg(user.organization, {
        period: timeRange,
        limit: 10
      }))
    }
  }, [dispatch, selectedEntity, timeRange, user.organization])

  useEffect(() => {
    if (selectedEntity === ENTITY_TYPES.INSIGHT) {
      setMetrics(appMetrics)
    } else if (selectedEntity === ENTITY_TYPES.PROJECT_V2) {
      setMetrics(projectMetrics)
    } else if (selectedEntity === ENTITY_TYPES.DATASET) {
      setMetrics(datasetMetrics)
    } else if (selectedEntity === ENTITY_TYPES.WORKFLOW) {
      setMetrics(workflowMetrics)
    }
  }, [appMetrics, projectMetrics, datasetMetrics, selectedEntity, workflowMetrics])

  useEffect(() => {
    setIsMetricsLoaded(true)
  }, [metrics])

  return (
    <>
      <div className="panel_sidebar">
        <div className="panel_info">
          <TimeRangeIndicator timeRange={timeRange}
                              handleTimeChange={handleTimeChange}
                              className={"time_range_button_group"} />
        </div>
        <Select
          name="role"
          options={options}
          value={dropdownValue}
          onChange={(e) => {
            handleEntityChange(e)
          }}
          className="flighting_config_select"
          required
        >
        </Select>
      </div>
      <div className="panel_horizontal_graph_container">
        <>
          {
            isMetricsLoaded ?
              <>
                {
                  Object.keys(metrics).length > 0 ?
                    <StackedBarGraph data={metrics} options={entityGraphOptions}
                                     barLabels={Object.values(metrics).map(value => value.name)}
                                     barPercentage={0.2}
                                     barSectionLabels={Object.keys(Object.values(metrics)[0]).filter(el => el !== 'name')}
                                     className={"overview_insights_usage_graph"} /> :
                    <EmptyGraph />
                }
              </> :
              <Loader loading={!isMetricsLoaded}></Loader>
          }
        </>
      </div>
    </>
  )
}

export default OverviewEntityPanel