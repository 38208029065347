import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {groupAndSummarize2Vars, groupAndSummarize, getClusters} from "../template.util";
import * as appActions from "../../../../../redux/actions/AppActions";
import * as DataSetActions from "../../../../../redux/actions/DataSetActions";
import ClusterChart from "../TemplateComponents/ClusterChart";
import ForecastChart from "../TemplateComponents/ForecastChart";
import DataPointInfo from "../TemplateComponents/DataPointInfo";
import SummaryBox from "../TemplateComponents/SummaryBox";
import CampaignSidebar from "../TemplateComponents/CampaignSidebar";
import "./campaignApp.css";

export function CampaignApp(
    {application}
) {
    const dispatch = useDispatch();
    const [groupedData, setGroupedData] = useState(null)
    const [data, setData] = useState([])
    const [allLines, setAllLines] = useState([])
    const [CohortLines, setCohortLines] = useState([])
    const [showCohortLines, setShowCohortLines] = useState(false)
    const modelLines = ["ALL","Fitted Values","Moving Average", "Forecast"]
    const [forecastLines, setForecastLines] = useState(modelLines)
    const [showforecastLines, setShowForecastLines] = useState(false)
    const [optMetricName, setOptMetricName] = useState(null)
    const [comparingMetricName, setComparingMetricName] = useState(null)
    const [xAxis, setXAxis] = useState(null)
    const [yAxis, setYAxis] = useState(null)
    const [k, setK] = useState(4)
    const [forecastLoading, setForecastLoading] = useState(false)

    const { templateData, appColumnNames, datapoint, forecastData } = useSelector(
        (state) => state.appReducer
    );

    useEffect(() => {
        //Update appColumnNames variable
        dispatch(appActions.getColumnNames({id: application.id}));

        //Update templateData variable
        dispatch(appActions.getGraphData({id: application.id}));

        //get files associated with dataset
        dispatch(DataSetActions.getDataSet({ id:application.datasets[0] })).then((res) => {
            let path = res.value.entity.path
            let datasetId = res.value.entity.id
            dispatch(
                DataSetActions.getDataSetFiles({
                body: { path: path },
                id: datasetId
              })
            )
        });
    }, [application, dispatch]);

    //get forecasted data in forecastData variable and start forecast loading
    useEffect(() => {
        if (comparingMetricName && optMetricName && showforecastLines) {
            dispatch(appActions.getForecastData({id: application.id, body:{x:comparingMetricName,y:optMetricName}}));
        }

    }, [application, appColumnNames, comparingMetricName ,optMetricName, showforecastLines, dispatch]);

    useEffect(() => {
        if (showforecastLines && !forecastData) {
            setForecastLoading(true)
        } else {
            setForecastLoading(false)
        }

    }, [forecastData, showforecastLines])

    // Handle click in cluster graph
    const handleClick = (event, elements, chart) => {
        if (elements[0]) {
            let index = elements[0]._index
            let datasetIndex = elements[0]._datasetIndex
            dispatch(appActions.datapoint(
                {body:
                    {
                        start:parseInt(elements[0]._chart.config.data.datasets[datasetIndex].text[index]) - 1, //backend skips the first row
                        count:1
                    },
                id: application.id
            }))
        }
    }

    // calculate grouped data variable then end forecast loading
    useEffect(() => {
        if (optMetricName && comparingMetricName) {
            // for each k group by comparing metric and summarize optmetric
            let groupedData = groupAndSummarize2Vars(data, comparingMetricName, "k", optMetricName)

            // for all ks group by comparing metric and summarize optmetric
            let optMetric = groupAndSummarize(data, comparingMetricName, optMetricName)

            // consolodate each group (all, ks, and forecast data) into one dataset
            if (forecastData) {
                setGroupedData([...groupedData, ...optMetric, ...Object.values(forecastData).slice(0,-1)])
            } else {
                setGroupedData([...groupedData, ...optMetric])
            }
        }
    }, [data, optMetricName, comparingMetricName, forecastData])
    // run clustering analysis
    useEffect(() => {
        if (templateData) {
            let clusteredKMeansData = getClusters(templateData[0], xAxis, yAxis, null, k)
            setData(clusteredKMeansData)
        }
    }, [templateData, xAxis, yAxis, k])

    // Update y axis change in forecast graph
    const handleOptMetricChange = (e) => {
        setOptMetricName(e.name);
    };

    // Update x axis change in forecast graph
    const handleComparingMetricChange = (e) => {
        setComparingMetricName(e.name);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleCohortChange = (e) => {
        setShowCohortLines(e)
        e ? setCohortLines([...Array.from({length: k}, (_, i) => i + 1), "ALL"]) : setCohortLines(["ALL"])
    };

    // handle showing cohort lines in the forecast graph
    useEffect(() => {
        showCohortLines ? setCohortLines([...Array.from({length: k}, (_, i) => i + 1),"ALL"]) : setCohortLines(["ALL"])
    }, [showCohortLines, k]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleForecastChange = (e) => {
        setShowForecastLines(e)
        e ? setForecastLines(modelLines) : setForecastLines(["ALL"])
    };

    // handle showing forecast lines in the forecast graph
    useEffect(() => {
        showforecastLines ? setForecastLines(modelLines) : setForecastLines(["ALL"])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showforecastLines, k]);

    // combine forecast and cohort lines to show in forecast graph
    useEffect(() => {
        let lines = new Set([...forecastLines,...CohortLines])
        setAllLines([...lines])

    }, [forecastLines, CohortLines])

    // handle x axis change in cluster chart
    const handleXAxisChange = (e) => {
        setXAxis(e.name);
    };

    // handle y axis change in cluster chart
    const handleYAxisChange = (e) => {
        setYAxis(e.name);
    };

    // handle k change in cluster chart
    const handleKChange = (event, newK) => {
        setK(newK);
    };

    return(
        <div className="campaignContainer">
            <div className="optAnalysis">
                {appColumnNames && <CampaignSidebar
                                        columnNames={appColumnNames}
                                        handleOptMetricChange={handleOptMetricChange}
                                        handleComparingMetricChange={handleComparingMetricChange}
                                        handleCohortChange={handleCohortChange}
                                        handleForecastChange={handleForecastChange}
                                        handleXAxisChange={handleXAxisChange}
                                        handleYAxisChange={handleYAxisChange}
                                        handleKChange={handleKChange}
                                        loading={forecastLoading}
                                        >
                                    </CampaignSidebar>}
                <div className="optAnalysisMain">
                    <div className="optAnalysisSummary">
                        <div className="optAnalysisSummaryCurrent">
                            <div className="optAnalysisSummaryCurrentTitle">
                                Optimization Metric Summary
                            </div>
                            <div className="summaryBoxes">
                                <SummaryBox plotData={groupedData} axis={"mean"} forecast={false} k={"ALL"}></SummaryBox>
                                <SummaryBox plotData={groupedData} axis={"min"} forecast={false} k={"ALL"}></SummaryBox>
                                <SummaryBox plotData={groupedData} axis={"max"} forecast={false} k={"ALL"}></SummaryBox>
                            </div>
                        </div>
                        <div className="optAnalysisSummaryCurrent">
                            <div className={showforecastLines?"optAnalysisSummaryForecastTitle":"optAnalysisSummaryForecastTitleGray"}>
                                Forecast
                            </div>
                            {forecastData
                            ?<div className="summaryBoxes">
                                <SummaryBox plotData={Object.values(forecastData).slice(0,-1)} axis={"mean"} forecast={true} k={"Forecast"}></SummaryBox>
                            </div>
                            :<div className="summaryBoxes">
                                <SummaryBox plotData={null} axis={"forecast"} forecast={true} k={"Forecast"}></SummaryBox>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="optAnalysisForecast">
                        {groupedData ?<div className="chartBox">
                            {!forecastLoading?<><div className="myChartForecast">
                                    <ForecastChart
                                        data={groupedData}
                                        xval={comparingMetricName}
                                        groupVal={"k"}
                                        type={'line'}
                                        isStacked={false}
                                        summaryStat={"mean"}
                                        label={optMetricName}
                                        lines={allLines}>
                                    </ForecastChart>
                            </div>
                            <div className="forecastLegend">

                            </div>
                            </>
                            :<div style={{width:"100%", height:"100%", display:"flex", justifyContent: "center"}}><img className="spinner" src={require("assets/custom-styles/images/templates/loading-spinner.gif")} alt="" /></div>}
                        </div>
                        : <div className="waitingForDataContainer">
                                <div className="waitingForData">
                                    Please select the optimization and comparing metric to view the graph.
                                </div>
                            </div>
                        }
                    </div>
                    <div className="optAnalysisCluster">
                        <div className="chartBoxCluster">
                            <div className="myChartClusterContainer">
                                <div className="myChartCluster">
                                    {xAxis && yAxis ?<ClusterChart
                                        data={data}
                                        xval={xAxis}
                                        yval={yAxis}
                                        k={k}
                                        handleClick={handleClick}
                                        htmlLegend={true}
                                        vars = {forecastLines.length}
                                    />
                                    :<div className="waitingForDataContainer">
                                        <div className="waitingForData">
                                            Please select the x and y axis to view the graph.
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="myInfo">
                                <div className="datapoint">
                                    <id id="DataPointInfoHeader">
                                        <h5>
                                            Data Point
                                        </h5>
                                    </id>
                                    <div className="datapointInfo">
                                        <DataPointInfo columns={appColumnNames} data={datapoint}></DataPointInfo>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignApp;