import axios from '../config';
import {API_VERSION} from "config";
export const login = (credentials) =>   axios.post(`${API_VERSION}/auth/login`, credentials);

export const signup = (credentials) => axios.post(`${API_VERSION}/auth/signup`, credentials);

export const forgotPassword = (credentials) =>   axios.post(`${API_VERSION}/auth/forgotpassword`, credentials);

export const changePassword = (credentials) =>   axios.put(`${API_VERSION}/auth/changepassword`, credentials);

export const resetPassword = (body) => axios.put(`${API_VERSION}/auth/resetpassword`, body);

export const getImageAccess = (params) => axios.get(`${API_VERSION}/users/presign_url`, {params: {...params, ext: ".png"}});

export const updateUser = (body, id) => axios.put(`${API_VERSION}/users/${id}`, body);

export const gitDetailsAccess = (body, id) => axios.put(`${API_VERSION}/git_detail`, body);

export const refreshToken = () =>  axios.post(`${API_VERSION}/auth/refreshtoken`);

export const enable2FA = (body, id) => axios.post(`${API_VERSION}/auth/enable2fa/${id}`, body);

export const verify2FA = (body) => axios.post(`${API_VERSION}/auth/verify2fa`, body);