import axios from "../config";
import { API_VERSION } from "config";

export const getProjects = (params) =>
  axios.get(`${API_VERSION}/projects`, { params });

export const getProject = (id) => axios.get(`${API_VERSION}/projects/${id}`);

export const getLudisProjects = () => axios.post(`${API_VERSION}/projects/ludisproject`);

export const createProject = (body) =>
  axios.post(`${API_VERSION}/projects`, body);

export const updateProject = (body, id) =>
  axios.put(`${API_VERSION}/projects/${id}`, body);

export const deleteProject = (id) =>
  axios.delete(`${API_VERSION}/projects/${id}`);

export const datasets = () => axios.get(`${API_VERSION}/datasets`);

export const getProjectNotebooks = (id) =>
  axios.get(`${API_VERSION}/notebook/${id}`);

export const deleteNotebook = (id) =>
  axios.delete(`${API_VERSION}/notebook/${id}`);

export const createProjectShare = (body) => 
  axios.post(`${API_VERSION}/projects/share`, body)

export const getProjectShares = (params) => 
  axios.get(`${API_VERSION}/projects/share/list`,{params})