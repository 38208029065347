import React from "react";

const OrganizationPanelInfo = ({ activeUserCounts }) => {
  return (
    <div className="organization_panel_info">
      <table className="organizations_table">
        <thead>
          <tr>
            <th className="org_table_header">
              Organization
            </th>
            <th className="org_table_header column_centered">
              Active Users
            </th>
          </tr>
        </thead>
        <tbody>
        {
          activeUserCounts.length > 0 && activeUserCounts.map((entry, index) => (
            <tr
              key={index}
            >
              <td className="org_table_datum">
                {entry.name}
              </td>
              <td className="org_table_datum column_centered">
                {entry.count}
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  )
}

export default OrganizationPanelInfo