import { DirectoryActionTypes } from "../actions/DirectoryActions/actionType";

export const initialState = {
  directory: null,
  directories: [],
  pinnedDirectories: [],
  config: null,
  count: 0,
  directoryShares: [],
};
const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};
export const directoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DirectoryActionTypes.GET_DIRECTORY_PENDING:
      return updateObject(state, {});
    case DirectoryActionTypes.GET_DIRECTORY_FULFILLED:
      return updateObject(state, {
        directory: null,
        directories: action.payload.directories,
        count: action.payload.count,
      });
    case DirectoryActionTypes.GET_DIR_PIN_FULFILLED:
      return updateObject(state, {
        pinnedDirectories: action.payload.directoryPins,
      });
    case DirectoryActionTypes.GET_DIRECTORY_SHARE_FULFILLED:
      return updateObject(state, {
        directoryShares: action.payload.directoryShares,
      });
    default:
      return state;
  }
};
