import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as datasetActions from "../../../redux/actions/DataSetActions";
import DatasetListItem from "./DatasetListItem";
import { PAGE_SIZE_CONSTANTS } from "utility/constants/constants";

export const ProjectDatasets = ({handleShowSnippet, getDownloadUrl}) => {
  const dispatch = useDispatch();
  const [datasetType, setDatasetType] = useState("myFiles")
  const { datasets, datasetShares } = useSelector((state) => state.dataSetReducer);

  useEffect(() => {
    dispatch(datasetActions.getDataSets({pageSize: PAGE_SIZE_CONSTANTS.PROJECT_DATASETS_MAX }));
    dispatch(datasetActions.getDatasetShares({pageSize: PAGE_SIZE_CONSTANTS.PROJECT_DATASETS_MAX}))
  }, [dispatch]);

  return (
    <>
      <h5>Datasets</h5>
      <div className="project_datasets_list">
        <div className="button_group">
          <div
            className="my_files"
            style={{
              backgroundColor: datasetType === "myFiles" ? "#FFFFFF" : null,
            }}
            onClick={() => setDatasetType("myFiles")}
          >
            My Files
          </div>
          <div
            className="shared"
            style={{
              backgroundColor: datasetType === "shared" ? "#FFFFFF" : null,
            }}
            onClick={() => setDatasetType("shared")}
          >
            Shared
          </div>
        </div>
        <div className="project_files">
          {datasets && datasetType === "myFiles" ? (
            datasets.map((dataset) => {
              return (
                <DatasetListItem
                  key={dataset.id}
                  datasetId={dataset.id}
                  item={dataset}
                  path={dataset.path}
                  handleShowSnippet={handleShowSnippet}
                  getDownloadUrl={getDownloadUrl}
                />
              );
            })
          ) : datasetShares ? (
            datasetShares.map((dataset) => {
              return (
                <DatasetListItem
                  key={dataset.id}
                  datasetId={dataset.id}
                  item={dataset}
                  path={dataset.path}
                  handleShowSnippet={handleShowSnippet}
                  getDownloadUrl={getDownloadUrl}
                />
              );
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};
