import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import ProgressBarComponent from "./ProgressBarComponent";
import swal from "sweetalert";

const FileUploadDownloadModal = ({
  files,
  show,
  uploadPath,
  handleToggleModal,
  isUpload,
}) => {
  const fileCount = files.length;
  const [completedUploads, setCompletedUploads] = useState(0);

  const handleIncrementCompletedFiles = useCallback(() => {
    setCompletedUploads((completedUploads) => completedUploads + 1);
  },[]);

  useEffect(() => {
    //handle the modal and alert when the process is completed
    if (completedUploads === files.length) {
      handleToggleModal();
      swal({
        text: `File(s) ${isUpload ? 'uploaded' : 'downloaded'} successfully`,
        className: "logout-alert-modal",
        buttons: {
          confirm: {
            text: "Ok",
          },
        },
      });
    }
  }, [completedUploads, files, handleToggleModal, isUpload]);
  return (
    <Modal className="s3_object_modal" size="sm" show={show} scrollable={true}>
      <Modal.Header>
        {isUpload ? "Uploading file(s)" : "Downloading file(s)"}
      </Modal.Header>
      {
        <Modal.Body>
          {files.map((file, index) => (
            <ProgressBarComponent
              key={index}
              filePath={`${uploadPath}${file.name}`}
              file={file}
              fileCount={fileCount}
              isUpload={isUpload}
              handleIncrementCompletedFiles={handleIncrementCompletedFiles}
            />
          ))}
        </Modal.Body>
      }
    </Modal>
  );
};

export default FileUploadDownloadModal;
