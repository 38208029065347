import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getWorkflows, getWorkflowData, resetWorkflowDetails } from "redux/actions/WorkflowActions";
import { WorkflowRow } from "./WorkflowRow";

export const WorkflowList = (filterParams) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const { workflows, workflowData } = useSelector((state) => state.workflowReducer);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getWorkflows(filterParams.filterParams)).then((res) => {
        dispatch(getWorkflowData({ body: { workflows: res.value.workflows } }))
      });
    }, 60 * 1000); // 1 minute
     return () => clearInterval(interval);
  }, [dispatch, filterParams])

  useEffect(() => {
    dispatch(resetWorkflowDetails());
  }, [dispatch])

  return (
    <div>
      <div style={{ padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              "& th": {
                color: "black",
              },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell> Type </TableCell>
                <TableCell> Active </TableCell>
                <TableCell> Workflow </TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Last Run</TableCell>
                <TableCell align="left">Duration</TableCell>
                <TableCell align="left">Access Level</TableCell>
                <TableCell align="left">Creator</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflows &&
                workflows.map((workflow, index) => (
                  <WorkflowRow
                    index={index}
                    workflow={workflow}
                    user={user}
                    filterParams={filterParams}
                    workflowData={workflowData?workflowData[workflow.id]:null}
                  ></WorkflowRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default WorkflowList;
