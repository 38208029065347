import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from "moment-timezone";
import {Button} from "react-bootstrap";

const ScheduleInput = ({cronExpression, setCronExpression}) => {
  const [frequency, setFrequency] = useState([]);
  const [time, setTime] = useState('');
  const [noSchedule, setNoSchedule] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const { user } = useSelector((state) => state.authReducer);

  const handleTimeChange = (e) => {
    setTime(e.target.value);
    setNoSchedule(false)
  };

  const handleDayToggle = (day) => {
    const updatedFrequency = [...frequency];
    const index = updatedFrequency.indexOf(day);
    if (index === -1) {
      updatedFrequency.push(day);
    } else {
      updatedFrequency.splice(index, 1);
    }
    setFrequency(updatedFrequency);
    setNoSchedule(false);
  };

  const noScheduleClick = () => {
    setNoSchedule(!noSchedule);
  }

  useEffect(() => {
    const generateCronExpression = () => {
      let expression = '';
      if (noSchedule) {
        expression = 'None'
      } else {
        const newTime = moment.tz(time, "HH:mm", user.timezone)
        let hour = newTime.utc().hour();
        let minute = newTime.utc().minute();
        // if no time given set to 0:00
        if (time === '') {
          hour = 0;
          minute = 0;
        }
        // map days to numbers for cron
        let selectedDays = frequency.join(",")
        if (selectedDays) {
          expression = `${minute} ${hour} * * ${selectedDays}`;
        } else {
          expression = 'None'
        }
      }
      setCronExpression(expression);
    };

    generateCronExpression();
  }, [time, frequency, noSchedule, setCronExpression, user.timezone])

  useEffect(() => {
    const translateCron = (cronExpr) => {
      if (cronExpr !== 'None') {
        let cronArr = cronExpr.split(' ');
        const min = cronArr[0];
        const hour = cronArr[1];
        const userLocalTime = moment.tz(`${moment().format('YYYY-M-D')} ${hour}:${min}`, user.timezone);
        const days = cronArr[4].split(',')
        setTime(userLocalTime.format('HH:mm'))
        setFrequency(days)
      } else {
        setNoSchedule(true);
      }
    }

    if (initialLoad && cronExpression) {
      translateCron(cronExpression);
      setInitialLoad(false);
    }
  },[cronExpression, initialLoad, user.timezone])

  return (
    <div className="publish_modal">
      <h4>Schedule</h4>
        <div className={`day-input ${noSchedule ? 'disabled-element' : ''}`} >
          <label className='schedule-week'>
            Repeat on:
            <div className='freq-buttons'>
              <button className={`freq-button ${frequency.includes('1') ? 'active' : ''}`} onClick={() => handleDayToggle('1')}>M</button>
              <button className={`freq-button ${frequency.includes('2') ? 'active' : ''}`} onClick={() => handleDayToggle('2')}>T</button>
              <button className={`freq-button ${frequency.includes('3') ? 'active' : ''}`} onClick={() => handleDayToggle('3')}>W</button>
              <button className={`freq-button ${frequency.includes('4') ? 'active' : ''}`} onClick={() => handleDayToggle('4')}>Th</button>
              <button className={`freq-button ${frequency.includes('5') ? 'active' : ''}`} onClick={() => handleDayToggle('5')}>F</button>
              <button className={`freq-button ${frequency.includes('6') ? 'active' : ''}`} onClick={() => handleDayToggle('6')}>Sat</button>
              <button className={`freq-button ${frequency.includes('0') ? 'active' : ''}`} onClick={() => handleDayToggle('0')}>Sun</button>
            </div>
          </label>
        </div>
      <div>
        <label>
          At:
          <div className={`time-input ${noSchedule ? 'disabled-element' : ''}`}>
            <input type="time" value={time} onChange={handleTimeChange} />
            <p className='timezone'>{user.timezone}</p>
          </div>
        </label>
        <div>
          <Button
            className={`no-schedule-button ${noSchedule ? 'no-schedule' : ''}`}
            onClick={noScheduleClick}
            >No Schedule
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleInput;
