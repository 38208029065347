import { Modal } from "react-bootstrap";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/DataSetActions";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { copyToClipboard } from "utility/CommonService";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip, Typography } from "@material-ui/core";

const PresignedUrlModal = ({ fileName, filePath }) => {
  const LUDIS_URL_TAB = "Permanent URL";
  const PRESIGNED_URL_TAB = "Temporary URL";
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(LUDIS_URL_TAB);
  const dispatch = useDispatch();
  const path = filePath + fileName;
  const { presignedUrl, currentAccessUrl, dataSet } = useSelector(
    (state) => state.dataSetReducer
  );

  const getLudisUrl = useCallback(
    (path) => {
      dispatch(
        actions.getLudisUrl({ filePath: path }, dataSet.id)
      );
    },
    [dispatch, dataSet.id]
  );

  const getPresignedUrl = useCallback((path) => {
    const encoded = encodeURIComponent(fileName).replace(
      /[!'()*]/g,
      function (c) {
        return "%" + c.charCodeAt(0).toString(16);
      }
    );
    if (!presignedUrl || !presignedUrl?.includes(encoded)) {
      dispatch(
        actions.getPresignedUrl({
          body: { path: path },
          id: dataSet.id
        })
      );
    }
  }, [dispatch, fileName, presignedUrl, dataSet.id]);

  const handleSetActive = (tabText) => {
    setActiveTab(tabText);
  };

  const handleShowModal = () => {
    setShow(!show);
  };

  const isActiveTab = (tabText) => {
    return tabText === activeTab;
  };

  const handleCodeCopy = (text) => {
    copyToClipboard(text);
    handleShowModal();
  };

  useEffect(() => {
    if(show) {
      getLudisUrl(path);
      getPresignedUrl(path);
    }
  }, [getLudisUrl, getPresignedUrl, path, show]);

  return (
    <>
      <Tooltip title={<Typography>Access Url</Typography>} placement="top" arrow>
        <img
          src={require("assets/custom-styles/images/thumbnails/access_url_icn_dark.png")}
          onMouseOver={(e) =>
            (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/access_url_icn.png"))
          }
          onMouseLeave={(e) =>
            (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/access_url_icn_dark.png"))
          }
          height="50px"
          onClick={handleShowModal}
          className="cursor-pointer"
          alt="Not Found"
        />
      </Tooltip>

      {show && <Modal
        className="s3_object_modal"
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <div
            onClick={() => handleSetActive(LUDIS_URL_TAB)}
            className={`access_url_link ${
              isActiveTab(LUDIS_URL_TAB) && "active"
            }`}
          >
            {LUDIS_URL_TAB}
          </div>
          <div
            onClick={() => handleSetActive(PRESIGNED_URL_TAB)}
            className={`access_url_link ${
              isActiveTab(PRESIGNED_URL_TAB) && "active"
            }`}
          >
            {PRESIGNED_URL_TAB}
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "left" }}>
          {isActiveTab(LUDIS_URL_TAB) && (
            <>
              <div className="url_info">
                <div className="info_left">
                  <InfoOutlinedIcon />
                  <p>API Key Required - No Expiration</p>
                </div>
                <ContentCopyIcon onClick={() => handleCodeCopy(currentAccessUrl)} />
              </div>
              <div className="link_div">
                <p>{currentAccessUrl}</p>
              </div>
            </>
          )}
          {isActiveTab(PRESIGNED_URL_TAB) && (
            <>
              <div className="url_info">
                <div className="info_left">
                  <InfoOutlinedIcon />
                  <p>API Key Not Required - 7 Day Expiration</p>
                </div>
                <ContentCopyIcon onClick={() => handleCodeCopy(presignedUrl)} />
              </div>
              <div className="link_div">
                <p>{presignedUrl}</p>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>}
    </>
  );
};

export default PresignedUrlModal;
