import React, { useEffect, useState } from "react";
import style from "../../components/Users/UserList/users.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/Web3Actions";
import moment from "moment-timezone";
import { DEFAULT_DATETIME_OPTION } from "utility/constants/constants";
import { Button } from "react-bootstrap";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TextTruncate } from "utility/CommonService";
import Tooltip from '@mui/material/Tooltip';

const Collections = () => {
  const dispatch = useDispatch();
  const { collections } = useSelector((state) => state.web3Reducer);
  const user = useSelector((state) => state.authReducer);
  const count = collections.length

  //TODO: [ENG-1032] Collections pagination
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(actions.getCollections())
  }, [dispatch])

  const refreshCollections = () => {
    dispatch(actions.getCollections())
  }

  const displayStatusIcon = (status) => {
    if (status === "running") {
      return <HourglassBottomIcon color="primary" />
    } else if (status === "success") {
      return <CheckCircleIcon color="success" />
    }
  }

  return (
    <div className="lds_right_box_wrapper lds_right_po_box_wrapper">
      <div className="container">
        <div className="lcol_right_po_box_wrapper">
          <div className="lds_cp_heading">
            <Link
              to={`/manage-organizations`}
              className="cursor-pointer float-left"
            >
              <i className="flaticon-left-arrow"></i> Back
            </Link>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="lds_po_heading">
                <h1>Collections</h1>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 lds_po_cp_btn">
              <p className="float-right" data-toggle="modal" data-target="#gitModal">
                <Link to="/collections/new" className="btn">
                  {" "}
                  Add Collections{" "}
                </Link>{" "}
              </p>
            </div>
            <div className="col-md-12">
              <div className="lds_po_tabs_main_box">
                <div className={style.card_large}>
                  <div>
                    <InfiniteScroll
                      dataLength={count}
                      next={() => setPagination({ ...pagination, page: pagination.page + 1 })}
                    >
                      <table className={style.Table}>
                        <thead className={style.Table_head}>
                          <tr className={style.db_ListViewItem_header}>
                            <th className={style.collection_list}>
                              Contract Address
                            </th>
                            <th className={style.collection_list}>
                              Name
                            </th>
                            <th className={style.collection_list}>
                              Symbol
                            </th>
                            <th className={style.collection_list}>
                              Created Date
                            </th>
                            <th className={style.collection_list}>
                              Contract Type
                            </th>
                            <th className={style.collection_list}>
                              Slug Name
                            </th>
                            <th className={style.collection_list}>
                              Chain
                            </th>
                            <th className={style.collection_list}>
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className={style.Table_body}>
                          {collections &&
                            collections.map((testCollection, index) => (
                              <tr
                                key={index}
                                className={`${style.Table_row} ${style.db_ListViewItem} ${style.db_ListViewItem_hasLink}`}
                              >
                                <td
                                  className={`${style.Table_cell_I_inn} ${style.pl_10}`}
                                >
                                  {testCollection.contract_address}
                                </td>
                                <td className={style.Table_cell_I_inn}>
                                  <span>{testCollection.name}</span>
                                </td>
                                <td
                                  className={`${style.Table_cell_I_inn} ${style.pr_10}`}
                                >
                                  <Tooltip title={testCollection.symbol} placement="top">
                                    <span>{TextTruncate({ text: testCollection.symbol, limit: 6 })}</span>
                                  </Tooltip>
                                </td>
                                <td
                                  className={`${style.Table_cell_I_inn} ${style.pr_10}`}
                                >
                                  <span>{testCollection.created_date ? moment(testCollection.created_date).format(user.date_format ? user.date_format : DEFAULT_DATETIME_OPTION) : null}</span>
                                </td>
                                <td
                                  className={`${style.Table_cell_I_inn} ${style.pr_10}`}
                                >
                                  <span>{testCollection.contract_type}</span>
                                </td>
                                <td
                                  className={`${style.Table_cell_I_inn} ${style.pr_10}`}
                                >
                                  <span>{testCollection.gcp_slug}</span>
                                </td>
                                <td
                                  className={`${style.Table_cell_I_inn} ${style.pr_10}`}
                                >
                                  <span>{testCollection.chain}</span>
                                </td>
                                <td
                                  className={`${style.Table_cell_I_inn} ${style.pr_10}`}
                                >
                                  <span>{displayStatusIcon(testCollection.status)}</span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                  <div className={style.Divider_light_bottom}>
                    <div className={style.Box_root}>
                      <div className={style.left_Side}>
                        <div className={style.left_Side}>
                          <span className={style.result_All}>
                            <strong>{count}</strong> results
                          </span>
                        </div>
                      </div>
                      <div className={style.right_Side}>
                        <Button
                          onClick={() => refreshCollections()}
                          className="fa fa-refresh"
                          type="btn"
                        >
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collections