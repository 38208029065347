import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import swal from "sweetalert";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";

import * as commonService from "utility/CommonService.js";
import { deleteOrganization, clearOrganizations } from "../../../redux/actions/OrganizationActions/Action";

import style from "../../Users/UserList/users.module.css";
import {DEFAULT_DATETIME_OPTION } from "../../../utility/constants/constants";

const Table = ({ pagination, setPagination }) => {
  const dispatch = useDispatch();
  const { orgCount, organizations } = useSelector(
    (state) => state.organizationReducer
  );
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(clearOrganizations());
  }, [dispatch])

  const handleDelete = (id) => {
    swal({
      title: "",
      text: "Are you sure?",
      className: "logout-alert-modal",
      buttons: true,
    }).then((result) => {
      if (result) {
        dispatch(deleteOrganization({ id })).then((res) => {
          if (res.value && res.value.success) {
            commonService.forSuccess("Organization Deleted!", "Success");
          }
        });
      }
    });
  };

  return (
    <div className="col-md-12  ">
      <div className="lds_po_tabs_main_box">
        <div className={style.card_medium}>
          <div
            className={`${style.db_ListView} ${style.db_ListView_withFooter}}`}
          >
            <InfiniteScroll
              dataLength = {organizations.length}
              next = {() => setPagination({ ...pagination, page: pagination.page + 1 })}
              hasMore = {organizations.length < orgCount}
            >
              <table className={style.Table}>
                <thead className={style.Table_head}>
                  <tr className={style.db_ListViewItem_header}>
                    <th
                      className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                    >
                      Name
                    </th>
                    <th
                      className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                    >
                      Tier
                    </th>
                    <th
                      className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                    >
                      Max Seats
                    </th>
                    <th
                      className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                    >
                      Bucket ID
                    </th>
                    <th
                      className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                    >
                      Created At
                    </th>
                    <th
                      className={`${style.Table_cell} ${style.pl_20} ${style.th_block}`}
                    ></th>
                  </tr>
                </thead>
                <tbody className={style.Table_body}>
                  {organizations &&
                    organizations.map((org, index) => (
                      <tr
                        key={index}
                        className={`${style.Table_row} ${style.db_ListViewItem} ${style.db_ListViewItem_hasLink}`}
                      >
                        <td
                          className={`${style.Table_cell_I_inn} ${style.pl_20}`}
                        >
                          {org.name}
                        </td>
                        <td className={style.Table_cell_I_inn}>
                          <span>{org.tier}</span>
                        </td>
                        <td
                          className={`${style.Table_cell_I_inn} ${style.pr_20}`}
                        >
                          <span>{org.max_seats}</span>
                        </td>
                        <td
                          className={`${style.Table_cell_I_inn} ${style.pr_20}`}
                        >
                          <span>{org.bucket_id}</span>
                        </td>
                        <td
                          className={`${style.Table_cell_I_inn} ${style.pr_20}`}
                        >
                          <span>
                            {moment(org.created_at).format((user.date_format ? user.date_format: DEFAULT_DATETIME_OPTION), "HH:mm A")}
                          </span>
                        </td>
                        <td
                          className={`${style.Table_cell_I_inn} ${style.pr_20}`}
                        >
                          <div className="ml_icn">
                            <button className={`${style.btn_transperent} ${style.ldsToolTips}`}>
                              <Link to={`/organizations/${org.id}/users`}>
                                <div className="show__tool mr-2">
                                  <i className={`fa fa-users  ${style.color_primary}`}></i>
                                  <span className="show_pin_rep">Manage Users</span>
                                </div>
                              </Link>
                            </button>
                            <button className={`${style.btn_transperent} ${style.ldsToolTips}`}>
                              <Link to={`/organizations/${org.id}`}>
                                <div className="show__tool mr-2">
                                  <i className={`fa fa-edit ${style.color_primary}`}></i>
                                  <span className="show_pin_rep">Edit</span>
                                </div>
                              </Link>
                            </button>
                            <button
                              onClick={() => handleDelete(org.id)}
                              className={`${style.btn_transperent} ${style.ldsToolTips}`}
                            >
                              <div className="show__tool mr-2">
                                <i className="fa fa-trash text-danger"></i>
                                <span className="show_pin_rep">Delete</span>
                              </div>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
          <div className={style.Divider_light_bottom}>
            <div className={style.Box_root}>
              <div className={style.left_Side}>
                <div className={style.left_Side}>
                  <span className={style.result_All}>
                    <strong>{orgCount}</strong> results
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;
