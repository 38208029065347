import ModalHeader from "react-bootstrap/ModalHeader";
import {Button, Modal, ModalBody, ModalFooter} from "react-bootstrap";
import React, {useRef} from "react";

const ImportProjectModal = ({ showImportModal, setShowImportModal, newProject, setNewProject, createProject }) => {
  const importProjectInputRef = useRef()

  //process url passed from user
  const getProjectDetails = (url) => {
    let repoName = url.slice(url.lastIndexOf("/") + 1);
    repoName =
      repoName.indexOf(".") > 0
        ? repoName.slice(0, repoName.indexOf("."))
        : repoName;
    let repoAuthor = url.slice(0, url.indexOf(repoName) - 1);
    repoAuthor = repoAuthor.slice(repoAuthor.lastIndexOf("/") + 1);
    return [repoAuthor, repoName];
  };

  return (
    <Modal
      className="import_modal"
      show={showImportModal}
      onHide={() => setShowImportModal(false)}
      onEntered={() => importProjectInputRef.current.focus()}
    >
      <ModalHeader closeButton>Import Project</ModalHeader>
      <ModalBody>
        <form>
          <div className="col-md-12">
            <div className="publish_modal">
              <h4>Github URL</h4>
              <input
                onChange={(e) =>
                  setNewProject({
                    ...newProject,
                    name: getProjectDetails(e.target.value)[1],
                    repoAuthor: getProjectDetails(e.target.value)[0],
                  })
                }
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    e.preventDefault()
                    createProject()
                  }
                }}
                ref={importProjectInputRef}
              ></input>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            createProject();
          }}
          className="btn"
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ImportProjectModal