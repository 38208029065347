import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "redux/actions/AppActions";
import * as commonService from "utility/CommonService.js";

import AppDetailsPage from "components/Insights/AppDetails/AppDetails";

export const AppDetails = ({ history }) => {
  const dispatch = useDispatch();
  const { app, iframe } = useSelector((state) => state.appReducer);
  const [localURL, setURL] = useState("");
  const [loaded, setLoaded] = useState(false);
  let id = history.location.pathname.split("/")[2];

  //effect for get project
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(actions.getApp({ id })).then((res) => {
        if (res.value.success) {
          console.log("open success");
          commonService.isLoading.onNext(true);
          setTimeout((e) => {
            setURL(
              res.value.application.app_url
                ? `${res.value.application.app_url}`
                : "http://www.wikipedia.org"
            );
            commonService.isLoading.onNext(false);
          }, 1000);
        }
      });
    }
  }, [app, dispatch, history, iframe, id, loaded]);
  return (
    <>
      {app && <AppDetailsPage
        history={history}
        application={app}
        iframe={localURL}
        localURL={localURL}
      />}
    </>
  );
};

export default AppDetails;
