import { SharesActionTypes } from "../actions/SharesActions/ActionType";

export const initialState = {
  sharedWithUsers: {},
  entityShares: []
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const sharesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SharesActionTypes.GET_SHARED_WITH_USERS_PENDING:
      return updateObject(state, {});
    case SharesActionTypes.GET_SHARED_WITH_USERS_FULFILLED:
      return updateObject(state, { sharedWithUsers: action.payload.sharedWithUsers });
    case SharesActionTypes.GET_SHARED_WITH_USERS_BY_ENTITY_PENDING:
      return updateObject(state, {});
    case SharesActionTypes.GET_SHARED_WITH_USERS_BY_ENTITY_FULFILLED:
      return updateObject(state, { entityShares: action.payload.entityShares });
    default:
      return state;
  }
}