import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DiscussionContainer,
  PanelContainer,
  SlidingPanelContainer,
  TabContainer,
  TabLine,
  TabLine1
} from "./InfoPanel.styled";
import * as commentActions from "../../redux/actions/CommentActions";
import Conversation from "../Comment/Conversation/Conversation";
import Collapsible from "react-collapsible";

const InfoPanel = (props) => {
  const { conversation, isPanelOpen, onBackdropClicked } = props;
  const dispatch = useDispatch();
  const { currentComments } = useSelector((state) => state.commentsReducer);

  useEffect(() => {
    dispatch(commentActions.clearComments());
    if (conversation) dispatch(commentActions.fetchComments(conversation.id));
  }, [dispatch, conversation]);

  return (
    <>
      <TabContainer isOpen={isPanelOpen} onClick={onBackdropClicked}>
        <TabLine />
        <TabLine1 />
      </TabContainer>
      <SlidingPanelContainer isOpen={isPanelOpen}>
        <PanelContainer>
          <DiscussionContainer>
            <Collapsible
              open={true}
              className="Collapsible"
              trigger="Discussion"
            >
              {conversation && isPanelOpen && (
                <Conversation
                  referenceId={conversation.id}
                  comments={currentComments}
                />
              )}
            </Collapsible>
          </DiscussionContainer>
        </PanelContainer>
      </SlidingPanelContainer>
    </>
  );
};

export default InfoPanel;
