import { MetricsActionTypes } from "./ActionType";
import * as API from "../../../api/MetricsAPI";

export const getAllMetrics = (params) => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.GET_ALL_METRICS,
    payload: API.getAllMetrics(params),
  });

export const getAppMetricsByOrg = (orgId, params) => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.GET_APP_METRICS_BY_ORG,
    payload: API.getAppMetricsByOrg(orgId, params),
  });

export const clearAppMetrics = () => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.CLEAR_APP_METRICS
  })

export const getProjectMetricsByOrg = (orgId, params) => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.GET_PROJECT_METRICS_BY_ORG,
    payload: API.getProjectMetricsByOrg(orgId, params),
  });

export const clearProjectMetrics = () => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.CLEAR_PROJECT_METRICS
  })

export const getDatasetMetricsByOrg = (orgId, params) => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.GET_DATASET_METRICS_BY_ORG,
    payload: API.getDatasetMetricsByOrg(orgId, params),
  });

export const clearDatasetMetrics = () => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.CLEAR_DATASET_METRICS
  })

export const getWorkflowMetricsByOrg = (orgId, params) => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.GET_WORKFLOW_METRICS_BY_ORG,
    payload: API.getWorkflowMetricsByOrg(orgId, params),
  });

export const clearWorkflowMetrics = () => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.CLEAR_WORKFLOW_METRICS
  })

export const getMetricsDetails = (entity, params) => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.GET_METRICS_DETAILS,
    payload: API.getMetricsDetails(entity, params)
  })

export const clearMetricsDetails = () => (dispatch) =>
  dispatch({
    type: MetricsActionTypes.CLEAR_METRICS_DETAILS
  })