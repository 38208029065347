import React from "react";
import { animateScroll as scroll } from "react-scroll";

const buttonStyle = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  padding: "10px",
  cursor: "pointer",
  background: "#6B71FB",
  borderRadius: "50%",
};

const scrollToTop = () => {
  scroll.scrollToTop();
};

const ScrollToTop = () => {
  return (
    <div onClick={scrollToTop} style={buttonStyle}>
      <img
        src={require("assets/custom-styles/images/icons/arrow-up.svg")}
        alt="img-ins"
        height="40"
        width="40"
      ></img>
    </div>
  );
};

export default ScrollToTop;
