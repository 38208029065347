import { CommentActionTypes } from "../actions/CommentActions/actionType";

export const initialState = {
  createdComment: null,
  currentComments: null
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps
  };
};

const filterCommentsList = (commentsResponse) => {
  return commentsResponse.comments;
};

const insertComment = (state, payload) => {
  let { currentComments } = state;
  const commentValues = payload.attributes;

  if (currentComments) {
    if (commentValues) {
      currentComments.conversation.push(commentValues);
    }
  }
  return currentComments;
};

export const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CommentActionTypes.CREATE_COMMENT_FULFILLED:
      const currentComment = insertComment(state, action.payload);
      return updateObject(state, {
        createdComment: action.payload,
        currentComments: currentComment
      });
    case CommentActionTypes.FETCH_COMMENTS_FULFILLED:
      return updateObject(state, {
        currentComments: filterCommentsList(action.payload)
      });
    case CommentActionTypes.CLEAR_COMMENTS:
      return updateObject(state, initialState);
    default:
      return state;
  }
};
