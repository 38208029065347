import styled from "styled-components";

export const CommentContainer = styled.div`
  height: 60px;
  width: 100%;
  flex-direction: column;
  display: flex;
  margin-top: 15px;
`;

export const AuthorContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 2px;
`;

export const ContentContainer = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

export const SeparatorLine = styled.div`
  margin-right: 20px;
  height: 0px;
  opacity: 0.1;
  border: 1px solid #4f4f4f;
  margin-bottom: 15px;
`;

export const TimeStamp = styled.label`
  font-size: 10px;
  line-height: 18px;
  color: #00000060;
`;
