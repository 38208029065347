import axios from "../config";
import { API_VERSION } from "config";
// Notebook  api
export const getNotebooks = (params) =>
  axios.get(`${API_VERSION}/notebook`, { params });

export const getNotebook = (id) =>
  axios.get(`${API_VERSION}/notebook/detail/${id}`);

export const openNotebook = (id) =>
  axios.post(`${API_VERSION}/notebook/${id}/open`);

export const commitChanges = (body, id) =>
  axios.post(`${API_VERSION}/notebook/${id}/commit`, body);

export const createNotebook = (body) =>
  axios.post(`${API_VERSION}/notebook`, body);

export const importNotebook = (body) =>
  axios.post(`${API_VERSION}/notebook/import`, body);

export const previewNotebook = (id) =>
  axios.post(`${API_VERSION}/notebook/${id}/preview`);

export const updateNotebook = (body, id) =>
  axios.put(`${API_VERSION}/notebook/${id}`, body);

export const deleteNotebook = (id) =>
  axios.delete(`${API_VERSION}/notebook/${id}`);

export const generateDevEnvironment = (id) =>
  axios.put(`${API_VERSION}/users/${id}/getJupyterDevUrl`);
