import React from "react";
import { AppTile } from "./AppTile";

const DashboardTab = ({ handlePin, pinnedApplications }) => {
  const renderPinnedContent = () => {
    return (
      <>
        {/* App Tiles */}
        {pinnedApplications?.map((app) => {
          return (
            <AppTile
              app={app}
              key={app.id}
              handlePin={handlePin}
              isPinned={true}
            />
          );
        })}
      </>
    );
  };

  //If no pins, render predefined content
  return <div className="row">{renderPinnedContent()}</div>;
};

export default DashboardTab;
