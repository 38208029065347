import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getOrganizationFlighting,
  getConfig,
} from "../../redux/actions/FlightingActions/action";
import FlightingForm from "components/Account/FlightingForm";
const ManageFlight = ({ match }) => {
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    let id =
      user.role === "superadmin"
        ? match.params.organizationId
        : user.organization;
    dispatch(getConfig()).then((res) => {
      dispatch(getOrganizationFlighting(id));
    });
  });

  return (
    <div>
      <FlightingForm type="edit" organizationId={match.params.organizationId} />
    </div>
  );
};
export default ManageFlight;
