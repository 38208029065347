import React, { useEffect, useRef, useState } from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { getContainerTerminalWebSocketUrl } from "utility/websocketUrls";
import { useSelector } from "react-redux";
import "xterm/css/xterm.css";


const DockerTerminal = ({ containerId, terminalWrapperRef, terminalColor }) => {
  const [socket, setSocket] = useState(null);
  const {user} = useSelector((state) => state.authReducer)
  useEffect(() => {
    const newSocket = new WebSocket(
      getContainerTerminalWebSocketUrl(containerId, user.uid)
    );
    setSocket(newSocket);

    return () => {
      // Close WebSocket connection when the component is unmounted
      if (newSocket) {
        newSocket.close();
      }
    };
  }, [containerId, user.uid]);

  return (
      socket && <TerminalContainer socket={socket} terminalWrapperRef={terminalWrapperRef} containerId={containerId} terminalColor={terminalColor} />
  );
};

export default DockerTerminal;

const TerminalContainer = ({ socket, terminalWrapperRef, containerId, terminalColor }) => {
  const terminalRef = useRef(null);

  useEffect(() => {
    const fitAddon = new FitAddon();
    if (socket) {
      const terminal = new Terminal({
        theme: { foreground: "black", background: terminalColor, cursor: "black" },
        rows: 0,
        cols: 0,
      });

      terminal.open(terminalRef.current);
      terminal.loadAddon(fitAddon);
      fitAddon.fit();

      //use fitAddOn function to resize the terminal according the the wrapping div size
      const resizeObserver = new ResizeObserver(() => {
          fitAddon.fit()
      });
      if (terminalWrapperRef.current) {
        resizeObserver.observe(terminalWrapperRef.current);
      }

      socket.onmessage = (event) => {
        terminal.write(event.data);
      };

      terminal.onData(data => {
        socket.send(data)
      })
      return () => {
        // Close WebSocket connection when the component is unmounted
        if (socket) {
          socket.close();
        }
        resizeObserver.disconnect();
      };
    }
  }, [containerId, socket, terminalWrapperRef, terminalColor]);
  return <div className="terminal_ref" ref={terminalRef} />;
};

