import styled from "styled-components";

export const ConversationContainer = styled.div`
  height: 300px;
  margin-top: 20px;
`;

export const CommentListContainer = styled.div`
  height: 200px;
`;

export const NewCommentBox = styled.input`
  width: 95%;
  height: 43px;
  border: 1px solid #4f4f4f;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 38px;
  justify-content: center;
  color: black;
  auto-complete: false;
`;

export const TypeText = styled.input`
  width: 166px;
  height: 18px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  /* Black */

  color: #000000;
  opacity: 0.3;
`;

export const EmptyConversation = styled.div``;
