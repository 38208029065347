import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SimpleReactValidator from "simple-react-validator";
import timezones from "google-timezones-json";
import * as commonService from "utility/CommonService.js";

import { createUser, updateUser } from "redux/actions/UserActions/Action";
import { ShareUserModal } from "./ShareUserModel";
import {
  Select,
  Input,
  Button,
} from "components/shared/ui-components/form-components";
import { ROLE } from "config";

const UserForm = ({isEdit}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const { configOptions } = useSelector((state) => state.flightingReducer);
  const currentUser = useSelector((state) => state.authReducer.user);
  const { user } = useSelector((state) => state.userReducer);
  let roles = configOptions.user_roles
    ? configOptions.user_roles.filter(
        (e) =>
          e !== "superadmin" || currentUser?.role === "superadmin"
      )
    : ROLE;
  const simpleValidator = useRef(new SimpleReactValidator());

  const [isSubmitted, setSubmitted] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
    timezone: "",
    organization: match.params.organizationId,
  });
  const [, forceUpdate] = useState();
  const [isDisable, setDisable] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let res;

      if (user) {
        if ("password" in form && form.password === "") {
          // Assume we're not actually editing the password
          delete form.password
        }

        res = await dispatch(
          updateUser({
            body: {
              user: form,
            },
            id: user.uid,
          })
        );
      } else {
        try {
          res = await dispatch(
            createUser({
              body: {
                user: form,
              },
            })
          );
        } catch (err) {
          if (err.data.message.limitExceded) {
            setMessage(err.data.message.message);
            setShow(true);
          }
        }
      }
      if (res) {
        if (res.value.success) {
          commonService.forSuccess(
            user ? "User Updated successfully!" : " User created successfully!",
            "Success"
          );
        }
        history.push(
          "/organizations/" + match.params.organizationId + "/users"
        );
      }
    } else {
      setSubmitted(true);
      simpleValidator.current.showMessages(); //show validation messages
      forceUpdate(1);
      setDisable(true);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if (isDisable) setDisable(false);
  };

  useEffect(() => {
    if (user && !loaded) {
      setLoaded(true);
      const { first_name, last_name, email, role, timezone } = user;
      setForm({ first_name, last_name, email, role, timezone });
    }
  }, [loaded, user]);

  return (
    <div className="lds_right_box_wrapper">
      <ShareUserModal {...{ show, setShow, message, setMessage }} />
      <form onSubmit={handleSubmit}>
        <div className="lds_cp_heading">
          <Link
            to={`/organizations/${match.params.organizationId}/users`}
            className="cursor-pointer float-left"
          >
            <i className="flaticon-left-arrow"></i> Back
          </Link>
        </div>

        <div className="lds_cp_box">
          <div className="row">
            <div className="col-md-12">
              <div className="lds_cp_box_heading">
                <h4>First Name</h4>
                <Input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  value={form.first_name}
                  onChange={handleChange}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("first_name")
                      : true
                  }
                />
                {simpleValidator.current.message(
                  "first_name",
                  form.first_name,
                  "required"
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Last Name</h4>
                <Input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={form.last_name}
                  onChange={handleChange}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("last_name")
                      : true
                  }
                />
                {simpleValidator.current.message(
                  "last_name",
                  form.last_name,
                  "required"
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Email</h4>
                <Input
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={form.email}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("email")
                      : true
                  }
                />{" "}
                {simpleValidator.current.message(
                  "email",
                  form.email,
                  "required|email"
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Role</h4>
                <Select
                  placeholder="Role"
                  name="role"
                  options={roles}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("role")
                      : true
                  }
                  onChange={(e) =>
                    handleChange({
                      target: { value: e.value, name: "role" },
                    })
                  }
                  value={{
                    label: form.role,
                    value: form.role,
                  }}
                  required
                />
                {simpleValidator.current.message("role", form.role, "required")}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Time Zone</h4>
                <Select
                  options={Object.keys(timezones)}
                  placeholder="Timezone"
                  name="timezone"
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("timezone")
                      : true
                  }
                  onChange={(e) =>
                    handleChange({
                      target: { value: e.value, name: "timezone" },
                    })
                  }
                  value={{
                    label: form.timezone,
                    value: form.timezone,
                  }}
                  required
                />
                {simpleValidator.current.message(
                  "timezone",
                  form.timezone,
                  "required"
                )}
              </div>
            </div>
            {
              // Password field should be shown during these conditions:
              // 1) New user creation by an admin/superadmin
              // 2) Editing a user as an admin as long as the target user is not a superadmin
              // 3) Editing a user as a superadmin
              (!match.params.userId) ||
              (match.params.userId && currentUser.role === "administrator" && user && user.role !== "superadmin") ||
              (match.params.userId && user && currentUser.role === "superadmin") ? (
                <div className="col-md-12">
                  <div className="lds_cp_box_heading mt-3">
                    <h4>Password</h4>
                    <Input
                        type="password"
                        placeholder={isEdit ? "********" : "Password"}
                        name="password"
                        value={form.password}
                        onChange={handleChange}
                        onBlur={() =>
                            isSubmitted
                                ? simpleValidator.current.showMessageFor("password")
                                : true
                        }
                    />
                    {!isEdit && simpleValidator.current.message(
                        "password",
                        form.password,
                        "required"
                    )}
                  </div>
                </div>
              ) : ("")
            }
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_third">
                <Button type="submit" className="btn" disabled={isDisable}>
                  {user ? "Update" : "Create"} User
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default UserForm;
