import axios from "../config";
import { API_VERSION } from "config";


export const getConfig = () =>
  axios.get(`${API_VERSION}/config`);

export const fetchFlighting = (params) =>
  axios.get(`${API_VERSION}/flighting`, {params});

export const createFlightingConfig = (requestBody) => {
  return axios.post(
    `${API_VERSION}/flighting/createFlightingConfig`,
    requestBody
  );
};

export const updateFlighting = (id, requestBody) =>
  axios.put(`${API_VERSION}/flighting?organization=${id}`, requestBody);