import { DataSetActionTypes } from "./actionType";
import * as API from "../../../api/DataSetsAPI";

export const getDataSets = (params) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.GET_DATASETS,
    payload: API.getDataSets(params),
  });

export const getDataSet = ({id}) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.GET_DATASET,
    payload: API.getDataSet(id),
  });

export const getDataSetFiles = ({body, id}) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.GET_DATASET_FILES,
    payload: API.getDataSetFiles(body, id),
  });

export const getMoreDatasetFiles = ({body, id}) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.GET_MORE_DATASET_FILES,
    payload: API.getDataSetFiles(body, id),
  });

export const createDataSet = ({ body }) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.CREATE_DATASET,
    payload: API.createDataSet(body),
  });

export const updateDataSet = ({ body, id }) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.UPDATE_DATASET,
    payload: API.updateDataSet(body, id),
  });

export const getPresignedUrl = ({ body, id }) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.GET_PRESIGNED_URL,
    payload: API.getPresignedUrl(body, id),
  });

export const getFilePreview = ({ body, id }) => (dispatch) =>
dispatch({
  type: DataSetActionTypes.GET_FILE_PREVIEW,
  payload: API.getFilePreview(body, id),
});

export const getLudisDatasets = () => (dispatch) =>
dispatch({
  type: DataSetActionTypes.GET_LUDIS_DATASETS,
  payload: API.getLudisDatasets(),
});

export const getNewDataSets = () => (dispatch) =>
dispatch({
  type: DataSetActionTypes.GET_NEW_DATASETS,
  payload: API.getNewDataSets(),
});

export const createDataSetSubscription = ({body}) => (dispatch) =>
dispatch({
  type: DataSetActionTypes.CREATE_DATASET_SUBSCRIPTION,
  payload: API.createDataSetSubscription(body),
});

export const getDataSetSubscriptions = () => (dispatch) =>
dispatch({
  type: DataSetActionTypes.GET_DATASET_SUBSCRIPTIONS,
  payload: API.getDataSetSubscriptions(),
});

export const createDataSetDirectory = ({body}) => (dispatch) =>
dispatch({
  type: DataSetActionTypes.CREATE_DATASET_DIRECTORY,
  payload: API.createDataSetDirectory(body),
});

export const deleteDataSetObject = ({body, id}) => (dispatch) =>
dispatch({
  type: DataSetActionTypes.DELETE_DATASET,
  payload: API.deleteDataSetObject(body, id),
});

export const updateDatasetShare = (body) => dispatch =>
  dispatch({
    type: DataSetActionTypes.UPDATE_DATASET_SHARE,
    payload: API.updateDatasetShare(body).then(res => ({...res, ...body}))
  });

export const getDatasetShares = (params) => (dispatch) => {
  dispatch({
    type: DataSetActionTypes.GET_DATASET_SHARES,
    payload: API.getDatasetShares(params)
  });
}

export const setDatasetScreen = (params) => (dispatch) => {
  dispatch({
    type: DataSetActionTypes.SET_DATASET_SCREEN_FULFILLED,
    payload: params
  });
};

export const getLudisUrl = (body, id) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.GET_LUDIS_URL,
    payload: API.getLudisUrl(body, id)
  });

export const prependFileToFileList = (file) => (dispatch) =>
  dispatch({
    type: DataSetActionTypes.PREPEND_FILE_TO_LIST,
    payload: file,
  })
