import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getOrganizations } from "redux/actions/OrganizationActions/Action";

import OrganizationList from "../../components/Organizations";

const Organizations = () => {

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrganizations({ ...pagination })
    );
  }, [dispatch, pagination]);

  return (
    <div className="lds_right_box_wrapper lds_right_po_box_wrapper">
      <div className="container">
        <OrganizationList
          pagination={pagination}
          setPagination={setPagination}/>
      </div>
    </div>
  );
};
export default Organizations;
