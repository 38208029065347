import React, { useCallback, useRef } from 'react';
import ReactFlow, {
  addEdge,
  useReactFlow,
  getOutgoers,
  Background,
  useOnSelectionChange,
} from 'reactflow';
import 'reactflow/dist/style.css';

import TaskNode from './TaskNode';
const nodeTypes = { taskNode: TaskNode };

const WorkflowDesigner = ({handleTaskSelect, handleNodeClick, nodes, edges, setNodes, setEdges, onNodesChange, onEdgesChange}) => {
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const { screenToFlowPosition } = useReactFlow();

  const onConnect = useCallback(
    (params) => {
      // reset the start node on connections
      connectingNodeId.current = null;
      setEdges((eds) => addEdge(params, eds))
    },
    [setEdges],
  );

  const { getNodes, getEdges } = useReactFlow();

  const findHighestId = () => {
    let highestId = 0;
    nodes.forEach(node => {
      const nodeId = parseInt(node.id);
      if (!isNaN(nodeId) && nodeId > highestId) {
        highestId = nodeId;
      }
    });
    return highestId;
  };

  let id = findHighestId() + 1;

  const getId = useCallback(() => {
    return `${id++}`;
  }, [id]);

  const isValidConnection = useCallback(
    (connection) => {
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);
    },
    [getNodes, getEdges],
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        const id = getId();
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          type: 'taskNode',
          data: { label: '-' , taskType: [], taskData:[], taskFunction: handleTaskSelect},
          origin: [0.5, 0.0],
        };
        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({ id, source: connectingNodeId.current, target: id }),
        );
      }
    },
    [screenToFlowPosition, getId, setEdges, setNodes, handleTaskSelect],
  );

  useOnSelectionChange({
    onChange: ({ nodes }) => {
      handleNodeClick(nodes[0])
    },
  });

  const proOptions = { hideAttribution: true };

  return (
    <div className="wrapper" ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        isValidConnection={isValidConnection}
        nodeTypes={nodeTypes}
        fitView
        fitViewOptions={{ padding: 2 }}
        nodeOrigin={[0.5, 0]}
        proOptions={proOptions}
      >
        <Background color="#ccc" variant="dots" size={2} />
      </ReactFlow>
    </div>
  );
};

export default WorkflowDesigner;
