import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TaskNode = ({ selected, data}) => {

  const [taskType, setTaskType] = useState('')
  const [showTaskOptions, setShowTaskOptions] = useState(false);
  const [connectable, setConnectable] = useState(false);

  const openTaskOptions = () => {
    setShowTaskOptions(true);
  };

  const closeTaskOptions = () => {
    setShowTaskOptions(false);
  };

  const handleRunScriptTask = () => {
    setTaskType('script')
    data.taskFunction(true)
    data.taskType.push('script')
    closeTaskOptions()
  }

  const handleUploadTask = () => {
    setTaskType('upload')
    data.taskFunction(true)
    data.taskType.push('upload')
    closeTaskOptions()
  }

  useEffect(() => {
    if (data.taskType.length !== 0) {
      setTaskType(data.taskType[0]);
    }
    if (taskType !== '') {
      setConnectable(true);
    } else {
      setConnectable(false);
    }
  }, [taskType, data]);

  return (
    <div className={`task-node ${selected ? 'highlighted-border' : ''}`}>
      {taskType === '' && (
        <div className='add-task-node' onClick={openTaskOptions}>
          <button className='add-task-button' >
            <AddCircleOutlineIcon/>
          </button>
          <h4> add a task </h4>
        </div>
      )}
      {taskType === 'script' && (
        <div className='run-script-node'>
          <h4> run script </h4>
          <h4 className='task-node-file'>{data.taskData.fileName}</h4>
        </div>
      )}
      {taskType === 'upload' && (
        <div className='upload-node'>
          <h4> upload </h4>
          <h4 className='task-node-file'>{data.taskData.fileName}</h4>
        </div>
      )}
      <Handle className = 'task-handle' id="a" type="target" position={Position.Left} isConnectableEnd={true} isConnectableStart={false} />
      <Handle className = 'task-handle' id="b" type="source"  position={Position.Right} isConnectable={connectable}/>
     {showTaskOptions &&(
      <div className="task-modal">
        <div className="task-modal-content">
          <div className='task-modal-header'>
            <p>Select task option:</p>
            <span className="task-close" onClick={closeTaskOptions}>&times;</span>
          </div>
          <button className='task-select-button' onClick={handleRunScriptTask}>run script task</button>
          <div className='task-info'>
            <InfoOutlinedIcon className='info-icon'/>
            <p>Use for running a Python or R script</p>
          </div>
          <button className='task-select-button' onClick={handleUploadTask}>upload task</button>
          <div className='task-info'>
            <InfoOutlinedIcon className='info-icon'/>
            <p>Use for defining a dataset destination</p>
          </div>
        </div>
      </div>)}
    </div>
  );
}

export default TaskNode