import { NotebookActionTypes } from "../actions/NotebookActions/actionType";

export const initialState = {
  notebooks: null,
  notebook: null,
  devPreview: null
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps
  };
};

export const notebookReducer = (state = initialState, action) => {
  switch (action.type) {
    case NotebookActionTypes.GET_NOTEBOOKS_FULFILLED:
      return updateObject(state, { notebooks: action.payload.notebooks });
    case NotebookActionTypes.GET_NOTEBOOK_FULFILLED:
      return updateObject(state, { notebook: action.payload.notebooks });
    case NotebookActionTypes.UPDATE_NOTEBOOK_PENDING:
      return updateObject(state, { notebook: null, notebooks: null });
    case NotebookActionTypes.UPDATE_NOTEBOOK_FULFILLED:
      return updateObject(state, { notebook: action.payload.notebook });
    case NotebookActionTypes.DELETE_NOTEBOOK_FULFILLED:
      let preNotebooks = [...state.notebooks];
      //preNotebooks.splice(preNotebooks.findIndex(note => note.id === action.params.id), 1 );
      return updateObject(state, { notebooks: preNotebooks });
    case NotebookActionTypes.OPEN_NOTEBOOK_PENDING:
      return updateObject(state, { notebook: null, iframe: null });
    case NotebookActionTypes.OPEN_NOTEBOOK_FULFILLED:
      return updateObject(state, {
        notebook: action.payload.notebook,
        iframe: action.payload.iframe
      });
    case NotebookActionTypes.PREVIEW_NOTEBOOK_PENDING:
      return updateObject(state, { notebook: null, iframe: null });
    case NotebookActionTypes.PREVIEW_NOTEBOOK_FULFILLED:
      return updateObject(state, {
        notebook: action.payload.notebook,
        iframe: action.payload.iframe
      });
    case NotebookActionTypes.GENERATE_DEV_ENVIRONMENT_FULFILLED:
      return updateObject(state, {
        devPreview: action.payload
      });
    default:
      return state;
  }
};
