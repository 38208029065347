import React from "react";
import { Modal } from "react-bootstrap";

export const ShareUserModal = ({ message, setMessage, show, setShow }) => {
  return (
    <>
      <Modal
        id="share_modal"
        show={show}
        onHide={() => [setMessage(""), setShow(false)]}
        animation={false}
      >
        <Modal.Header closeButton>
          <h4>Limit Reached</h4>
        </Modal.Header>
        <form data-toggle="validator">
          <Modal.Body>
            <div>{message}</div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => setShow(false)}
              style={{ background: "#fff", color: " #9b51e0" }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
