import React from "react";
import {ENTITY_TYPES} from "../../../utility/constants/constants";

const EmptyScreenStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    width: '100%',
    height: '100%',
    gap: '30px',
    color: 'var(--gray-1, #8384A1)',
	fontFamily: 'Lato',
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: '400',
	lineHeight: '140%',
}

const EmptyScreen = ({entity}) => {
    var text = ''

    if (entity === ENTITY_TYPES.INSIGHT) {
        text = 'It looks like you don’t have any insights yet.'
    } else if (entity === ENTITY_TYPES.PROJECT_V2) {
        text = 'It looks like you don’t have any projects yet. \n You can click “Create Project” to create your first project.'
    } else if (entity === ENTITY_TYPES.DATASET) {
        text = 'It looks like you don’t have any datasets yet. \n You can choose to upload local files or connect to a database to get started.'
    } else if (entity === ENTITY_TYPES.WORKFLOW) {
        text = 'It looks like you don’t have any workflows yet.'
    } else {
        // leave blank
    }

    return (
        <div style={EmptyScreenStyle}>
            <img
                src={require(`assets/custom-styles/images/icons/empty${entity}Page.svg`)}
                alt="img-ins"
                height="108"
                width="98"
            ></img>
            <div>{text}</div>
            <div>If you need any help, you can use
                <a
                    href="https://ludisanalytics.github.io/documentation/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                > documentation </a>
                to guide you through the process.
            </div>
        </div>
    );
};

export default EmptyScreen;
