import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { TextTruncate } from "utility/CommonService";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ShareEntityModal } from "../Modals/ShareEntityModal/ShareEntityModal";
import { EditEntityModal } from "../Modals/EditEntityModal";

import {
  ACCESS_LEVEL,
  ENTITY_COLORS,
  ENTITY_TYPES, PAGE_SIZE_CONSTANTS,
} from "utility/constants/constants";
import {
  createProjectShare, getProjects, updateProject
} from "redux/actions/ProjectsActions";
import { getDataSets, updateDataSet, updateDatasetShare } from "redux/actions/DataSetActions";
import EntityInfo from "./EntityInfo";
import swal from "sweetalert";
import * as datasetActions from "../../../redux/actions/DataSetActions";
import * as commonService from "../../../utility/CommonService";
import * as projectsActions from "../../../redux/actions/ProjectsActions";
import { useDispatch } from "react-redux";
import { ReplyOutlined } from "@mui/icons-material";
import { getWorkflows, updateWorkflow, createWorkflowShare } from "redux/actions/WorkflowActions";

export const Row = ({entity, entityType, accessLevel, isSharedWithMe, filterParams }) => {
  const dispatch = useDispatch()
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const history = useHistory();
  const [showEditModal, setShowEditModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const handleMenuClick = (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    if (e) e.stopPropagation()
    setMenuAnchor(false);
  };

  const deleteEntity = (entity, entityType) => {
    let entityName = ""

    if (entityType === ENTITY_TYPES.PROJECT_V2) {
      entityName = "projects"
    } else if (entityType === ENTITY_TYPES.DATASET) {
      entityName = "datasets"
    } else if (entityType === ENTITY_TYPES.WORKFLOW) {
      entityName = "workflows"
    }

    swal({
      text: `Are you sure you want remove this from the ${entityName} page?`,
      className: "logout-alert-modal",
      buttons: [true, "Delete"],
    }).then((result) => {
      if (result && entityType === ENTITY_TYPES.PROJECT_V2) {
        dispatch(projectsActions.deleteProject({id: entity.id})).then((res) => {
          if (res.value && res.value.success) {
            commonService.forSuccess("Project Deleted!", "Success");
          }
        })
      } else if (result && entityType === ENTITY_TYPES.DATASET) {
        dispatch(datasetActions.deleteDataSetObject({
          body: {path: entity.path},
          id: entity.id
        })).then((res) => {
          if (res.value && res.value.success) {
            commonService.forSuccess("Dataset Deleted!", "Success")
          }
        })
      }
    })
  };

  let urlPrefix = ""
  let getEntities = null
  let updateEntity = null
  let shareEntity = null
  let entityColor = ""

  if (entityType === ENTITY_TYPES.PROJECT_V2) {
    urlPrefix = '/projectsv2'
    getEntities = getProjects
    updateEntity = updateProject
    shareEntity = createProjectShare
    entityColor = ENTITY_COLORS.PROJECT_V2
  } else if (entityType === ENTITY_TYPES.DATASET) {
    urlPrefix = '/datasets'
    getEntities = getDataSets
    updateEntity = updateDataSet
    shareEntity = updateDatasetShare
    entityColor = ENTITY_COLORS.DATASET
  } else if (entityType === ENTITY_TYPES.WORKFLOW) {
    urlPrefix = '/workflows'
    getEntities = getWorkflows
    updateEntity = updateWorkflow
    shareEntity = createWorkflowShare
    entityColor = ENTITY_COLORS.WORKFLOW
  }

  return (
    <>
      <div
        onClick={() => history.push(`${urlPrefix}/${entity.id}`)}
        className="project_row d-flex flex-column"
        style={{borderLeftColor: entityColor}}
      >
        <div className="row flex-grow-1">
          <div className="col-sm-4">
            <div className="project_text_left">
              <div className="project_title" to={`${urlPrefix}/${entity.id}`}>{entity.name}</div>
              <p className="word-break">
                {TextTruncate({
                  text: entity.description, limit: 75,
                })}
              </p>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="project_text_right">
              <div className="show_tool">
                {
                  accessLevel === ACCESS_LEVEL.EDIT && (
                    <Tooltip title="More" placement="top">
                      <IconButton
                        className="app_menu mr-2"
                        aria-label="more"
                        id="long-menu-button"
                        aria-controls={menuOpen ? "long-menu" : undefined}
                        aria-expanded={menuOpen ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }
                <Menu
                  id="long-menu-button"
                  disableScrollLock={true}
                  anchorEl={menuAnchor}
                  open={menuOpen}
                  onClose={handleMenuClose}
                >
                  {
                    accessLevel === ACCESS_LEVEL.EDIT && (
                      <MenuItem
                        dense={true}
                        onKeyDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          setShowEditModal(true)
                          handleMenuClose()
                          e.stopPropagation()
                        }}
                      >
                        <ListItemIcon className="edit">
                          <div className="entity_menu_item">
                            <img
                              src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                              alt="Edit Entity"
                              className="cursor-pointer optionsIcon"
                            />
                            <p>Edit</p>
                          </div>
                        </ListItemIcon>
                      </MenuItem>
                    )}
                  <MenuItem dense={true}
                            onKeyDown={(e) => e.stopPropagation()}
                            onClick={(e) => {
                              setShowShareModal(true)
                              handleMenuClose()
                              e.stopPropagation()
                            }}
                  >
                    <ListItemIcon className="share">
                      <div className="entity_menu_item">
                        <ReplyOutlined sx={{transform: 'scaleX(-1)', width: '25px', height: '25px'}} />
                        <p>Share</p>
                      </div>
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      deleteEntity(entity, entityType);
                      handleMenuClose();
                      e.stopPropagation();
                    }}
                  >
                    <ListItemIcon className="delete">
                      <DeleteIcon fontSize="small" />
                      <p>Delete</p>
                    </ListItemIcon>
                  </MenuItem>
                </Menu>
              </div>
                <EntityInfo entity={entity} isSharedWithMe={isSharedWithMe} />
            </div>
          </div>
        </div>
      </div>
      <EditEntityModal
        entity={entity}
        entityType={entityType}
        updateEntity={updateEntity}
        handleMenuClose={handleMenuClose}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
      />
      <ShareEntityModal
        entity={entity}
        entityType={entityType}
        createEntityShare={shareEntity}
        getEntities={getEntities}
        handleMenuClose={handleMenuClose}
        entityLimit={PAGE_SIZE_CONSTANTS.PROJECTS}
        filterParams={filterParams}
        showModal={showShareModal}
        setShowModal={setShowShareModal}
      />
    </>

  );
};

export default Row;
