import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import Overview from "./Overview";
import Users from "./Users";
import UserActivities from "./UserActivities";
import EntityUsage from "./EntityUsage";
import AddUserModal from "./AddUserModal";
import EntityUsageDetails from "./EntityUsageDetails";
import { ROLES } from "../../utility/constants/constants";
import OrganizationModal from "./OrganizationModal";
import FlightingConfigModal from "./FlightingConfigModal";
import EntityUsageGraph from "./Graphs/EntityUsageGraph";
import UserActivitiesGraph from "./UserActivitiesGraph";
import OverviewEntityPanel from "./OverviewEntityPanel";
import Security from "./Security";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="admin_tab_panel">{children}</div>
      )}
    </div>
  );
}

export const UserPaginationContext = React.createContext();

const AdminNavContainer = () => {
  const tabNames = ['Overview', 'Users', 'User activities', 'Entity Usage', 'Security'];
  const [value, setValue] = React.useState(0);
  const { user } = useSelector((store) => store.authReducer);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddOrganizationModal, setShowAddOrganizationModal] = useState(false);
  const [showFlightingModal, setShowFlightingModal] = useState(false);
  const [orgAppDetailsClicked, setOrgAppDetailsClicked] = useState(false);
  const [currentOrganizationId, setCurrentOrganizationId] = useState(user.role === ROLES.ADMINISTRATOR ? user.organization : "")
  const [userPagination, setUserPagination] = useState({
    page: 1,
    limit: 10,
  })

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (user.role === ROLES.SUPERADMIN) {
      setCurrentOrganizationId("");
    }
  };

  const handleUserPagination = (newPagination) => {
    setUserPagination({
      page: newPagination.page,
      limit: newPagination.limit
    })
  }

  useEffect(() => {
    if (value !== 1) {
      handleUserPagination(
        {
          page: 1,
          limit: 10
        }
      )
    }
  }, [value])

  return (
    <UserPaginationContext.Provider value={{ userPagination, handleUserPagination }}>
      <div className={`admin_portal_wrapper`}>
        {orgAppDetailsClicked ? (
          <EntityUsageDetails user={user}
                              isSelected={orgAppDetailsClicked}
                              organizationId={currentOrganizationId}
                              setCurrentOrganizationId={setCurrentOrganizationId}
                              orgAppDetailsClicked={orgAppDetailsClicked}
                              setOrgAppDetailsClicked={setOrgAppDetailsClicked}></EntityUsageDetails>
        ) : (
          <div>
            <div className="admin_nav">
              <div className="heading">Admin</div>
              <div className="controls">
                <div className="tabs">
                  <Tabs value={value} onChange={handleChange}>
                    {
                      tabNames.map(
                        (tabName, i) => <Tab key={tabName} className="tab"
                                             label={tabName} value={i}
                                             disableRipple={true} />
                      )
                    }
                  </Tabs>
                </div>
                <div className="admin_buttons">
                  {
                    user.role === ROLES.SUPERADMIN &&
                    <Button
                      onClick={() => {
                        setShowAddOrganizationModal(true)
                      }}
                      className="btn add_organization"
                    >
                      Add organization
                    </Button>
                  }
                  {
                    user.role === ROLES.ADMINISTRATOR &&
                    <Button
                      onClick={() => {
                        setShowFlightingModal(true)
                      }}
                      className="btn add_organization"
                    >
                      Change Flighting
                    </Button>
                  }
                  <Button
                    onClick={() => {
                      setShowAddUserModal(true)
                    }}
                    className="btn add_user"
                  >
                    Add users
                  </Button>
                </div>
              </div>
            </div>
            <CustomTabPanel value={value} index={0}>
              <Overview user={user} isSelected={value === 0} setValue={setValue}
                        userActivitiesGraph={<UserActivitiesGraph user={user} />}
                        entityUsageGraph={user.role === ROLES.SUPERADMIN ? <EntityUsageGraph /> : <OverviewEntityPanel user={user}/>} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Users isSelected={value === 1}
                     organizationId={currentOrganizationId}
                     setOrganizationId={setCurrentOrganizationId}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <UserActivities user={user} isSelected={value === 2} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <EntityUsage
                user={user}
                isSelected={value === 3}
                currentOrganizationId={currentOrganizationId}
                setCurrentOrganizationId={setCurrentOrganizationId}
                setOrgAppDetailsClicked={setOrgAppDetailsClicked}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Security isSelected={value === 4} />
            </CustomTabPanel>
            <OrganizationModal currentUser={user}
                               showModal={showAddOrganizationModal}
                               setShowModal={setShowAddOrganizationModal}
                               isEdit={false}
                               organizationId={currentOrganizationId}
                               setOrganizationId={setCurrentOrganizationId}
            />
            <AddUserModal showModal={showAddUserModal}
                          setShowModal={setShowAddUserModal}
            />
            <FlightingConfigModal showModal={showFlightingModal}
                                  setShowModal={setShowFlightingModal}
                                  organizationId={currentOrganizationId}>
            </FlightingConfigModal>
          </div>)
        }
      </div>
    </UserPaginationContext.Provider>
  )
}

export default AdminNavContainer