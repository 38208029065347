import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/ProjectActions";
import { ProjectRow } from "../ProjectRow";
import PaginationComponent from "components/shared/Pagination/Pagination";
import { PAGE_SIZE_CONSTANTS } from "utility/constants/constants";
import { getEditAccess } from "utility/utility";

export const ProjectList = ({deleteProject}) => {
  const dispatch = useDispatch();
  const { projects, projectsPageCount } = useSelector((state) => state.projectReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [filterParams, setFilterParams] = useState({
    page: 1,
    pageSize: PAGE_SIZE_CONSTANTS.PROJECTS,
    sortOperator: "DESC",
    orderBy: "created_at",
  });
  useEffect(() => {
    dispatch(actions.getProjects(filterParams));
  }, [dispatch, filterParams]);

  const renderProjects = () => {
    if (projects && projects.length > 0){
      return projects.map((project, index) => {
        if (project?.type?.includes("report")) {
          return (
            <ProjectRow
              project={project}
              key={project.id}
              index={index}
              deleteProject={deleteProject}
              accessLevel={getEditAccess(project, user)}
            />
          );
        } else {
          return null
        }
      });
    }
  };
  return (
    <>
      {/* Project Rows */}
      <div className="tabu__Sper">
        {projects && <div className="row">{renderProjects()}</div>}
      </div>
      <div className="pagination_container">
        <div className="entity_pagination">
          <PaginationComponent
            setNextPage={() => setFilterParams({...filterParams, page: filterParams.page + 1})}
            setLastPage={() => setFilterParams({...filterParams, page: filterParams.page - 1})}
            page={filterParams.page}
            count={projectsPageCount}
          />
        </div>
      </div>
    </>
  );
};
