import { organizationActionTypes } from "../actions/OrganizationActions/ActionType";

export const initialState = {
  organization: null,
  organizations: [],
  config: null,
  orgCount: 0,
  activeUserCounts: [],
  bucket: null,
  buckets: [],
  bucketCount: 0
};
const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};
export const organizationReducer = (state = initialState, action) => {

  let organizations;
  let buckets;
  let index;
  switch (action.type) {
    case organizationActionTypes.GET_ORGANIZATIONS_PENDING:
      return updateObject(state, { });
    case organizationActionTypes.GET_ORGANIZATIONS_FULFILLED:
      organizations = [...state.organizations];
      if(organizations.length < action.payload.count){
        organizations.push(...action.payload.organizations);
      }
      return updateObject(state, { organizations: organizations, orgCount: action.payload.count });
    case organizationActionTypes.GET_ORGANIZATION_PENDING:
      return updateObject(state, { });
    case organizationActionTypes.GET_ORGANIZATION_FULFILLED:
      return updateObject(state, { organization: action.payload.organization });
    case organizationActionTypes.DELETE_ORGANIZATION_FULFILLED:
      organizations=[...state.organizations]
      index = organizations.findIndex(e => e.id ===action.payload.id)
      organizations.splice(index, 1)
      return updateObject(state, { organizations });
    case organizationActionTypes.CREATE_ORGANIZATION_FULFILLED:
      organizations=[...state.organizations];
      return updateObject(state, {organizations: []})
    case organizationActionTypes.CLEAR_ORGANIZATION:
      return updateObject(state, { organization: null })
    case organizationActionTypes.CLEAR_ORGANIZATIONS:
      organizations=[];
      return updateObject(state, { organizations, orgCount: 0})
    case organizationActionTypes.GET_ACTIVE_USER_COUNTS_PENDING:
      return updateObject(state, []);
    case organizationActionTypes.GET_ACTIVE_USER_COUNTS_FULFILLED:
      return updateObject(state, { activeUserCounts: action.payload.activeUserCounts })
    case organizationActionTypes.GET_BUCKET_PENDING:
      return updateObject(state, {})
    case organizationActionTypes.GET_BUCKET_FULFILLED:
      return updateObject(state, { bucket: action.payload.bucket })
    case organizationActionTypes.CLEAR_BUCKET:
      return updateObject(state, { bucket: null })
    case organizationActionTypes.GET_BUCKETS_PENDING:
      return updateObject(state, {})
    case organizationActionTypes.GET_BUCKETS_FULFILLED:
      buckets = [...state.buckets];
      if (buckets.length < action.payload.bucketCount) {
        buckets.push(...action.payload.buckets);
      }
      return updateObject(state, { buckets: buckets, bucketCount: action.payload.bucketCount})
    default: return state;
  }
};
