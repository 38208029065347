import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import ImageSelector from "components/shared/ImageSelector";
import { getAssetImgPath } from "utility/utility";
import * as actions from "../../../redux/actions/AppActions";
import * as commonService from "utility/CommonService.js";
import {
  Select,
  TextArea,
} from "components/shared/ui-components/form-components";
import { useHistory } from "react-router-dom";
import { INSIGHTS_TABS } from "utility/constants/constants";
import { Storage } from "aws-amplify";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const ProjectPublishModal = ({ projectID, show, setShowProjectPublishModal, setShowPublishModal}) => {
  const { repoContents } = useSelector(
    (state) => state.projectsReducer
  );

  const defaultForm = {
    dataset_ids: [],
    description: "",
    name: "",
    language: "",
    project_id: projectID,
    publish_filenames: [],
    img_path: "",
  };

  const { user } = useSelector((state) => state.authReducer);
  const [form, setForm] = useState(defaultForm);
  const [selectedImg, setSelectedImg] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedFileName, setSelectedFileName] = useState('')

  const handleImgSelect = (imgUrl) => {
    setSelectedImg(imgUrl);
    setForm({ ...form, img_path: getAssetImgPath(imgUrl, user) });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };


  const handleAppSubmit = async (e) => {
    if (typeof selectedImg === "object") {
      await Storage.put(getAssetImgPath(selectedImg, user), selectedImg, {
        contentType: selectedImg.type,
      });
    }
    e.preventDefault();
    dispatch(
      actions.createAppV2({
        ...form,
      })
    ).then((res) => {
      setSelectedImg("");
      if (res.value && res.value.success) {
        commonService.forSuccess(`App Created Successfully`, "Success");
      }
      dispatch(actions.setCurrentScreen(INSIGHTS_TABS.APPLICATIONS));
      history.push('/insights')
    });
  }

  const handleFileSelect = (fileName) => {
    setForm({
      ...form,
      publish_filenames: [fileName],
    });
    setSelectedFileName(fileName);
  };

  return (
    repoContents &&
    <>
      <Modal
        className="import_modal"
        size="lg"
        show={show}
        onHide={() => setShowProjectPublishModal(false)}
        animation={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
        <div className="d-flex align-items-center">
        <button
        className="publish_back_button"
        onClick={() => {
          setShowProjectPublishModal(false);
          setShowPublishModal(true);
        }}
      >
        <ArrowBackIosIcon/>
        </button> 
          <div className="modal-header-text">
            Publish an insight
          </div>
        </div>
       </Modal.Header>
        <Modal.Body>
            <div>
              <form data-toggle="validator">
            <div className="form_img_selector">
              <ImageSelector
                selectedImg={selectedImg}
                setSelectedImg={handleImgSelect}
              />
            </div>
            <div className="col-md-12">
              <div className="publish_modal publish_select_btn">
              <h4 class="publish_project_text">Language Selection <span class="asterisk">*</span></h4>
                <Select
                  placeholder="select the language"
                  name="language"
                  options={["python", "r"]}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        value: e.value,
                        name: "language",
                      },
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="publish_modal">
                <h4 class="publish_project_text">Select a File <span class="asterisk">*</span></h4>
                <div className="file_select_container">
                  {repoContents.filter((item) => item.name !== 'dags').map((item) => (
                    <div key={item.sha}>
                      <div className="publish_file_select_row">
                        <button
                          className={`publish_file_button cursor-pointer ${selectedFileName === item.name ? "selected" : ""}`}
                          type="button"
                          onClick={() => handleFileSelect(item.name)}
                         >
                        {item.name}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="publish_modal">
                <h4 class="publish_project_text">Insight Name <span class="asterisk">*</span></h4>
                <input
                  type="text"
                  placeholder="enter insight name here"
                  value={form.name}
                  name="name"
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="publish_modal lds_cp_box_heading">
                <h4 className="publish_project_text">Insight Description</h4>
                <TextArea
                  rows="4"
                  placeholder="enter description here"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  required={false}
                ></TextArea>
              </div>
            </div>
          </form>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleAppSubmit}
            type="submit"
            value="Submit"
            className="btn float-right"
          >
            publish
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectPublishModal;
