import { HOST_URL } from "config";
import * as commonService from "utility/CommonService";

export const getContainerLogsWebSocketUrl = (containerId, userId) => {
  if (!containerId || !userId) {
    commonService.forError(
      "Something went wrong",
      "Error"
    );
    return
  }
  const protocol = process.env.REACT_APP_ENV === 'development' ? 'ws' : 'wss';
  return `${protocol}://${HOST_URL()}/containerLogs?id=${containerId}&uid=${userId}`;
};

export const getContainerTerminalWebSocketUrl = (containerId, userId) => {
  const protocol = process.env.REACT_APP_ENV === 'development' ? 'ws' : 'wss';
  return `${protocol}://${HOST_URL()}/terminalConnection?id=${containerId}&uid=${userId}`;
}
