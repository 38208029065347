import React, { useEffect, useState, useRef } from 'react';
import { CAMPAIGN_APP_COLORS } from "utility/constants/constants";
import Chart from 'chart.js';
import { htmlLegend } from '../template.util';
import "../CampaignApp/campaignApp.css";
import moment from 'moment-timezone';
import { createAxes } from '../template.util';
import { dateFormats } from 'utility/constants/constants';

const ForecastChart = ({data, xval, groupVal, type, isStacked, summaryStat, label, lines}) => {
  let chartRef = useRef(null);
  let datasets = [];
  const [context, setContext] = useState(null);
  let xValues = data.map((d) => d[xval]).sort((a, b) => a - b);
  let yValues = data.map((d) => d[summaryStat]).sort((a, b) => b - a);

  let [xAxes, xScale, yAxes] = createAxes(xValues, xval, yValues, summaryStat, isStacked)

  lines.forEach(function (item, index) {
    if (context) {
      const groupData = data.filter((d) => d[groupVal] === lines[index]);
      if(groupData.length > 0) {
        let xy = groupData.map(d => ({
          x:d[xval],
          y:d[summaryStat]
        }))

        if (xScale === "time") {
          xy = xy.sort((a, b) => moment(a.x, dateFormats).diff(moment(b.x, dateFormats)))
        } else {
          xy = xy.sort((a, b) => a.x - b.x)
        }

        let gradient = context.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, CAMPAIGN_APP_COLORS.kColor[index] + ".5)");
        gradient.addColorStop(.8, CAMPAIGN_APP_COLORS.fadeColor);

        datasets.push({
          backgroundColor: gradient,
          label: lines[index],
          data: xy,
          pointBackgroundColor : CAMPAIGN_APP_COLORS.kColor[index] + ".2)",
          pointBorderColor : CAMPAIGN_APP_COLORS.kColor[index] + "1)",
          pointHighlightFill: CAMPAIGN_APP_COLORS.kColor[index] + ".2)",
          pointHighlightStroke: CAMPAIGN_APP_COLORS.kColor[index] + "1)",
          tension: 0.4,
        });
      }
    }
  })

  useEffect(() => {
    let ctx = chartRef.current.getContext('2d');
    setContext(ctx)
    // Sample data for the line graph
    const data = {
      labels: xValues,
      datasets: datasets,
    };

    const config = {
      type: type,
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        legendCallback: function(chart) {
          htmlLegend(chart, '.forecastLegend', 'forecastLegendID')
        },
        scales: {
          xAxes: xAxes,
          yAxes: yAxes
        },
      },
    };

    // Create the line graph
    const chart = new Chart(ctx, config);
    let currentLegend = document.getElementById("forecastLegendID");
    if (currentLegend) {
      currentLegend.remove()
    }
    document.getElementsByClassName('forecastLegend').innerHTML = chart.generateLegend()

    return () => {
      // Cleanup chart on component unmount
      chart.destroy();
    };
  // Chart should not update when datasets or handleclick are updated. This causes a bug when point is clicked
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, datasets, data, xval, lines, label]);

  return <canvas ref={chartRef}/>;
};

export default ForecastChart;
