import React from "react";

import WorkflowDetailsPage from "components/Workflows/WorkflowDetails";

const WorkflowDetails = ({ history }) => {
  let id = history.location.pathname.split("/")[2];
  return (
    <WorkflowDetailsPage id={id} history={history}/>
  )
};

export default WorkflowDetails;