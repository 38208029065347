import React from "react";
import ProjectIDE from "components/ProjectsV2/ProjectDetails/ProjectIDE";
import UnsavedChangesPrompt from "components/shared/Modals/UnsavedChangesPrompt";

const ProjectDetailsV2 = () => {
  return (
    <>
      <UnsavedChangesPrompt />
      <ProjectIDE />
    </>
  )
};

export default ProjectDetailsV2;
