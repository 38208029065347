import React, { useEffect, useRef } from "react";
import Chart from "chart.js";
import { LINE_GRAPH_COLORS } from "utility/constants/constants";

const LineGraph = ({data, x, y, lineLabel, options, className}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      let ctx = chartRef.current.getContext('2d')
      const config = {
        type: 'line',
        data: {
          labels: data[Object.keys(data)[0]].map(row => row[x]),
          datasets: Object.keys(data).map((org, i) =>
            {
              return (
                {
                  label: data[org][0][lineLabel],
                  data: data[org].map(row => row[y]),
                  fill: false,
                  borderColor: LINE_GRAPH_COLORS[i % LINE_GRAPH_COLORS.length]
                }
              )
            }
          )
        },
        options: options
      }

      let chart = new Chart(ctx, config)
      return () => {
        chart.destroy()
      }
    }
  }, [data, lineLabel, options, x, y])

  return <canvas className={className} ref={chartRef} />
}

export default LineGraph