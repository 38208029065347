import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { listDockerImages } from "../../../redux/actions/ProjectsActions";
import { NavLink } from "react-router-dom";
import RunTaskConfigOptions from './RunTaskConfigOptions';
import UploadTaskConfigOptions from './UploadTaskConfigOptions';
import NoTaskSelectedPage from './NoTaskSelectedPage';
import { AssignmentOutlined, AccountTreeOutlined } from '@mui/icons-material';
import { getDataSets, getDatasetShares } from "../../../redux/actions/DataSetActions";
import { ACCESS_LEVEL } from 'utility/constants/constants';

const VisualWorkflowConfig = ({ workflow, selectedNode, setNodes, id, setEdges, edges, getConnectedEdges, accessLevel, name}) => {
  const dispatch = useDispatch();
  const { project, dockerImages } = useSelector((state) => state.projectsReducer);
  const { datasets, datasetShares } = useSelector((state) => state.dataSetReducer);
  useEffect(() => {
    dispatch(listDockerImages())
    dispatch(getDataSets());
    dispatch(getDatasetShares())
  }, [dispatch]);

  const [formData, setFormData] = useState(null);

  const handleSave = () => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNode.id) {
          node.data = {
            ...node.data,
            label: formData.fileName,
            taskData: formData,
          };
        }
        return node;
      })
    );
  };

  const handleDelete = () => {
    const connectedEdges = getConnectedEdges([selectedNode], edges);
    setEdges((eds) =>
      eds.filter(edge => !connectedEdges.includes(edge))
    );
    setNodes((nds) =>
      nds.filter(node => node.id !== selectedNode.id)
    );
  };
  const [fileOptions, setFileOptions] = useState([])
  const [dockerImageOptions, setDockerImageOptions] = useState([])
  const [datasetPathOptions, setDatasetPathOptions] = useState([])

  useEffect(() => {
    if (project?.repoContents) {
      setFileOptions(project.repoContents.map((obj) => obj["name"]));
    }
  }, [project])

  useEffect(() => {
    if (datasets || datasetShares) {
      let allDatasets = [...datasets, ...datasetShares]
      setDatasetPathOptions(allDatasets.map((obj) => ({ path: obj["path"], id: obj["id"], name: obj["name"] })));
    }
  }, [datasets, datasetShares])

  useEffect(() => {
    if (dockerImages) {
      setDockerImageOptions(dockerImages.map(obj => obj["name"]));
    }
  }, [dockerImages])

  return (
    <div className="visual_workflow_config_container">
      <div className="configuration">
        {selectedNode != null && selectedNode.data.taskType.length > 0 ? (
          <>
            {selectedNode.data.taskType[0] === "upload" ? (
              <UploadTaskConfigOptions
                selectedNode={selectedNode}
                setFormData={setFormData}
                formData={formData}
                handleSave={handleSave}
                handleDelete={handleDelete}
                datasetPathOptions={datasetPathOptions}
              />
            ) : (
              <RunTaskConfigOptions
                selectedNode={selectedNode}
                setFormData={setFormData}
                formData={formData}
                handleSave={handleSave}
                handleDelete={handleDelete}
                fileOptions={fileOptions}
                dockerImageOptions={dockerImageOptions}
              />
            )}
          </>
        ) : (
          <NoTaskSelectedPage />
        )}
      </div>
      <div className="project-link">
        <div>Linked Pages</div>
        <div className="project-link-name">
          <AssignmentOutlined className='project-link-icon' />
          <div className="label">Project: </div>{" "}
          <NavLink to={`/projectsv2/${id}`} exact={true} className={accessLevel !== ACCESS_LEVEL.NONE ? "navlink-active" : "navlink-inactive"}>
          {name}
        </NavLink>
        </div>
        <div className="project-link-name">
          <AccountTreeOutlined className='project-link-icon' />
          <div className="label">Details: </div>{" "}
          <NavLink to={`/workflows/${workflow?.id}`} exact={true} className={workflow?.published ? "navlink-active" : "navlink-inactive"}>
          {workflow?.published? workflow?.name : "Unpublished"}
        </NavLink>
        </div>
      </div>
    </div>
  );
};

export default VisualWorkflowConfig;