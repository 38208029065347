import storeAndPersistor from "../../redux/store/store";

const authInterceptor = (config) => {
  const { store } = storeAndPersistor;
  const state = store.getState();
  if (state.authReducer.token && config) {
    config.headers["Authorization"] =
      "Bearer " + state.authReducer.token;
  }
  return config;
};

export default authInterceptor;
