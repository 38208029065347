export const AppActionTypes = {
  GET_REPORTS: "GET_REPORTS",
  GET_REPORTS_PENDING: "GET_REPORTS_PENDING",
  GET_REPORTS_FULFILLED: "GET_REPORTS_FULFILLED",

  GET_APP: "GET_APP",
  GET_APP_PENDING: "GET_APP_PENDING",
  GET_APP_FULFILLED: "GET_APP_FULFILLED",

  GET_APP_PINS: "GET_APP_PINS",
  GET_APP_PINS_PENDING: "GET_APP_PINS_PENDING",
  GET_APP_PINS_FULFILLED: "GET_APP_PINS_FULFILLED",

  UPDATE_PINNED_APPS: "UPDATE_PINNED_APPS",

  GET_APP_SHARES: "GET_APP_SHARES",
  GET_APP_SHARES_PENDING: "GET_APP_SHARES_PENDING",
  GET_APP_SHARES_FULFILLED: "GET_APP_SHARES_FULFILLED",

  CREATE_APP: "CREATE_APP",
  CREATE_APP_PENDING: "CREATE_APP_PENDING",
  CREATE_APP_FULFILLED: "CREATE_APP_FULFILLED",

  CREATE_APP_V2: "CREATE_APP_V2",
  CREATE_APP_V2_PENDING: "CREATE_APP_V2_PENDING",
  CREATE_APP_V2_FULFILLED: "CREATE_APP_V2_FULFILLED",

  CREATE_APP_PREVIEW: "CREATE_APP_PREVIEW",
  CREATE_APP_PREVIEW_PENDING: "CREATE_APP_PREVIEW_PENDING",
  CREATE_APP_PREVIEW_FULFILLED: "CREATE_APP_PREVIEW_FULFILLED",

  IMPORT_APP: "IMPORT_APP",
  IMPORT_APP_PENDING: "IMPORT_APP_PENDING",
  IMPORT_APP_FULFILLED: "IMPORT_APP_FULFILLED",

  IMPORT_REPORT: "IMPORT_REPORT",
  IMPORT_REPORT_PENDING: "IMPORT_REPORT_PENDING",
  IMPORT_REPORT_FULFILLED: "IMPORT_REPORT_FULFILLED",

  CREATE_APP_PIN: "CREATE_APP_PIN",
  CREATE_APP_PIN_PENDING: "CREATE_APP_PIN_PENDING",
  CREATE_APP_PIN_FULFILLED: "CREATE_APP_PIN_FULFILLED",

  UNSHARE_APP: "UNSHARE_APP",
  UNSHARE_APP_PENDING: "UNSHARE_APP_PENDING",
  UNSHARE_APP_FULFILLED: "UNSHARE_APP_FULFILLED",

  CREATE_APP_SHARE: "CREATE_APP_SHARE",
  CREATE_APP_SHARE_PENDING: "CREATE_APP_SHARE_PENDING",
  CREATE_APP_SHARE_FULFILLED: "CREATE_APP_SHARE_FULFILLED",

  DELETE_APP: "DELETE_APP",
  DELETE_APP_PENDING: "DELETE_APP_PENDING",
  DELETE_APP_FULFILLED: "DELETE_APP_FULFILLED",

  DELETE_APP_SHARE: "DELETE_APP_SHARE",
  DELETE_APP_SHARE_PENDING: "DELETE_APP_SHARE_PENDING",
  DELETE_APP_SHARE_FULFILLED: "DELETE_APP_SHARE_FULFILLED",

  UPDATE_APP: "UPDATE_APP",
  UPDATE_APP_PENDING: "UPDATE_APP_PENDING",
  UPDATE_APP_FULFILLED: "UPDATE_APP_FULFILLED",

  GET_APPLICATIONS: "GET_APPLICATIONS",
  GET_APPLICATIONS_PENDING: "GET_APPLICATIONS_PENDING",
  GET_APPLICATIONS_FULFILLED: "GET_APPLICATIONS_FULFILLED",

  GET_INSIGHTS: "GET_INSIGHTS",
  GET_INSIGHTS_PENDING: "GET_INSIGHTS_PENDING",
  GET_INSIGHTS_FULFILLED: "GET_INSIGHTS_FULFILLED",

  GET_APPLICATIONS_TEMPLATE: "GET_APPLICATIONS_TEMPLATE",
  GET_APPLICATIONS_TEMPLATE_PENDING: "GET_APPLICATIONS_TEMPLATE_PENDING",
  GET_APPLICATIONS_TEMPLATE_FULFILLED: "GET_APPLICATIONS_TEMPLATE_FULFILLED",

  TOGGLE_PINNED_CARDS: "TOGGLE_PINNED_CARDS",
  TOGGLE_PINNED_CARDS_PENDING: "TOGGLE_PINNED_CARDS_PENDING",
  TOGGLE_PINNED_CARDS_FULFILLED: "TOGGLE_PINNED_CARDS_FULFILLED",

  SET_CURRENT_SCREEN: "SET_CURRENT_SCREEN",
  SET_CURRENT_SCREEN_FULFILLED: "SET_CURRENT_SCREEN_FULFILLED",

  RESTART_APP_SHARE: "RESTART_APP_SHARE",
  RESTART_APP_SHARE_PENDING: "RESTART_APP_SHARE_PENDING",
  RESTART_APP_SHARE_FULFILLED: "RESTART_APP_SHARE_FULFILLED",

  STOP_APP_SHARE: "STOP_APP_SHARE",
  STOP_APP_SHARE_PENDING: "STOP_APP_SHARE_PENDING",
  STOP_APP_SHARE_FULFILLED: "STOP_APP_SHARE_FULFILLED",

  STATUS_APP_SHARE: "STATUS_APP_SHARE",
  STATUS_APP_SHARE_PENDING: "STATUS_APP_SHARE_PENDING",
  STATUS_APP_SHARE_FULFILLED: "STATUS_APP_SHARE_FULFILLED",

  GET_COLUMN_NAMES: "GET_COLUMN_NAMES",
  GET_COLUMN_NAMES_PENDING: "GET_COLUMN_NAMES_PENDING",
  GET_COLUMN_NAMES_FULFILLED: "GET_COLUMN_NAMES_FULFILLED",

  GET_FORECAST_DATA: "GET_FORECAST_DATA",
  GET_FORECAST_DATA_PENDING: "GET_FORECAST_DATA_PENDING",
  GET_FORECAST_DATA_FULFILLED: "GET_FORECAST_DATA_FULFILLED",

  GET_GRAPH_DATA: "GET_GRAPH_DATA",
  GET_GRAPH_DATA_PENDING: "GET_GRAPH_DATA_PENDING",
  GET_GRAPH_DATA_FULFILLED: "GET_GRAPH_DATA_FULFILLED",

  GET_DATA_PAGINATION: "GET_DATA_PAGINATION",
  GET_DATA_PAGINATION_PENDING: "GET_DATA_PAGINATION_PENDING",
  GET_DATA_PAGINATION_FULFILLED: "GET_DATA_PAGINATION_FULFILLED",

  GET_DATA_POINT: "GET_DATA_POINT",
  GET_DATA_POINT_PENDING: "GET_DATA_POINT_PENDING",
  GET_DATA_POINT_FULFILLED: "GET_DATA_POINT_FULFILLED",


  REDEPLOY_APP: "REDEPLOY_APP",
  REDEPLOY_APP_PENDING: "REDEPLOY_APP_PENDING",
  REDEPLOY_APP_FULFILLED: "REDEPLOY_APP_FULFILLED",
};
