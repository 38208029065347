import React from "react";
import { Slider } from "@mui/material";

export function ClusterSlider(
    {k, defaultValue, step, min, max, handleKChange, valuetext}
) {
    return (
        <div className="clusterNumber">
          <div className="clusterNumberHeader">
              <p>Number of Clusters</p>
          </div>
          <div className="clusterNumberSlider">
              <Slider
                  aria-label="clusterNumber"
                  defaultValue={defaultValue}
                  valueLabelDisplay="on"
                  step={step}
                  min={min}
                  max={max}
                  onChangeCommitted={handleKChange}
              />
          </div>
        </div>
    );
}

export default ClusterSlider;