import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import authInterceptor from "./utility/interceptors/authInterceptor";
import successHandler from "./utility/interceptors/successHandler";
import errorHandler from "./utility/errorHandler/errorHandler";
import axios from "./config";
import storeAndPersistor from "./redux/store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

axios.interceptors.request.use(authInterceptor, (error) =>
  Promise.reject(error)
);
axios.interceptors.response.use(successHandler, errorHandler);

// eslint-disable-next-line no-extend-native
String.prototype.hashCode = function () {
  var hash = 0;
  if (this.length === 0) {
    return hash;
  }
  for (var i = 0; i < this.length; i++) {
    var char = this.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

// Uncomment below line to purge store
// storeAndPersistor.persistor.purge();
const app = (
  <Provider store={storeAndPersistor.store}>
    <PersistGate loading={null} persistor={storeAndPersistor.persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
