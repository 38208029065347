import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as actions from "../../../redux/actions/ProjectsActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { toastMsg } from "utility/utility";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

const EnvVars = ({ handleShowEnvVariables, divHeight }) => {
  const { envVariables, project } = useSelector(
    (state) => state.projectsReducer
  );
  const dispatch = useDispatch();
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    dispatch(actions.getEnvVariables({ id: project.id }));
  }, [dispatch, project.id]);

  const handleNewVariable = () => {
    if (newKey.length > 0 && newValue.length > 0) {
      dispatch(
        actions.createEnvVariable({
          project_uid: project.id,
          key: newKey,
          value: newValue,
        })
      ).then(() => {
        setNewKey("");
        setNewValue("");
        dispatch(actions.getEnvVariables({ id: project.id }));
      });
    } else {
      toastMsg("Please add valid inputs", true, 5000);
    }
  };

  const handleVariableDelete = (id) => {
    dispatch(actions.deleteEnvVariable(id)).then(() => {
      dispatch(actions.getEnvVariables({ id: project.id }));
    });
  };

  return (
    envVariables && (
      <div className="env_vars_wrapper" style={{height: divHeight}}>
        <div className="dev_tool_header">
          <VpnKeyOutlinedIcon />
          <CloseIcon className="float-right" onClick={handleShowEnvVariables} />
        </div>
        <div className="env_vars_table">
          <div className="env_var_row">
            <div className="env_var_info">
              <span className="secret_key">
                <input
                  placeholder="Add Key"
                  value={newKey}
                  onChange={(e) => setNewKey(e.target.value)}
                />
              </span>
              <span className="secret_value">
                <input
                  placeholder="Add Value"
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                />
              </span>
            </div>
            <div className="env_var_options">
              <AddCircleOutlineIcon id="add_icn" onClick={handleNewVariable} />
            </div>
          </div>

          {envVariables.map((variable) => (
            <EnvVarRow
              key={variable.key}
              variable={variable}
              handleVariableDelete={handleVariableDelete}
            />
          ))}
        </div>
      </div>)
  );
};

const EnvVarRow = ({ variable, handleVariableDelete }) => {
  const [envValue, setNewEnvValue] = useState(variable.value);
  const [isViewable, setIsViewable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const popperProps = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -15],
        },
      },
    ],
  };

  const handleChange = (e) => {
    setIsEditing(true);
    setNewEnvValue(e.target.value);
  };

  const handleEditVariable = (e) => {
    dispatch(actions.updateEnvVariable({ value: envValue }, variable.id)).then(
      (res) => {
        if (res.value.success) {
          setIsEditing(false);
          setIsViewable(false);
        }
      }
    );
  };

  return (
    <div className="env_var_row">
      <div className="env_var_info">
        <div className="secret_key" style={{ cursor: "default" }}>
          <Tooltip
            title={variable.key}
            placement="top-start"
            PopperProps={popperProps}
            enterDelay={"300"}
          >
            <span>{variable.key}</span>
          </Tooltip>
        </div>
        <span className={`secret_value${isViewable ? "" : "_hidden"}`}>
          <input value={envValue} onChange={handleChange} />
        </span>
      </div>
      <div className="env_var_options">
        {isViewable && (
          <VisibilityIcon
            onClick={() => setIsViewable(false)}
            id="visibility_icn"
          />
        )}
        {!isViewable && (
          <VisibilityOffIcon
            onClick={() => setIsViewable(true)}
            id="visibility_icn"
          />
        )}
        {!isEditing && (
          <HighlightOffIcon
            id="remove_icn"
            onClick={() => handleVariableDelete(variable.id)}
          />
        )}
        {isEditing && (
          <CheckCircleIcon id="add_icn" onClick={handleEditVariable} />
        )}
      </div>
    </div>
  );
};

export default EnvVars;
