import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import UsersTable from "../Users/UserList/UsersTable";
import Carousel from "./Carousel";
import {
  ROLES
} from "../../utility/constants/constants";
import {
  getActiveUserCounts, getOrganization,
  deleteOrganization,
} from "../../redux/actions/OrganizationActions/Action";
import {
  clearUsers,
  getUsers
} from "../../redux/actions/UserActions/Action";
import OrganizationModal from "./OrganizationModal";
import FlightingConfigModal from "./FlightingConfigModal";
import { UserPaginationContext } from "./AdminNavContainer";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

const Users = ({ isSelected, organizationId, setOrganizationId }) => {
  const { user } = useSelector((state) => state.authReducer);
  const { userPagination, handleUserPagination } = useContext(UserPaginationContext);
  const { organization, activeUserCounts } = useSelector((state) => state.organizationReducer);
  const { users, userCount } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showEditOrganizationModal, setShowEditOrganizationModal] = useState(false);
  const [showFlightingModal, setShowFlightingModal] = useState(false);
  const [showUsersTable, setShowUsersTable] = useState(user.role === ROLES.ADMINISTRATOR);

  const handleClick = (organizationId) => {
    history.push(`/organizations/${organizationId}/users`)
  }

  useEffect(() => {
    if (isSelected) {
      dispatch(
        clearUsers()
      )

      if (user.role === ROLES.SUPERADMIN) {
        if (organizationId) {
          dispatch(
            getOrganization({ id: organizationId })
          )

          handleUserPagination(
            {
              page: 1,
              limit: 10
            }
          )
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSelected, organizationId, user.role])

  useEffect(() => {
    if (user.role === ROLES.SUPERADMIN) {
      dispatch(
        getActiveUserCounts()
      )
    }
  }, [dispatch, user.role]);

  useEffect(() => {
    if (organizationId.length > 0) {
      dispatch(
        getUsers({ ...userPagination, organization: organizationId })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userPagination])

  return (
    <>
      <div className="admin_tab_panel_container">
        {user.role === ROLES.SUPERADMIN && activeUserCounts &&
          <Carousel itemList={activeUserCounts} title={"Organizations"} limit={4}
                    id={organizationId}
                    setId={setOrganizationId}
                    setShowDetailPage={setShowUsersTable} showMoreMenu={true}
                    setShowEditModal={setShowEditOrganizationModal}
                    setShowFlightingModal={setShowFlightingModal}
                    itemName={"Organization"}
                    itemDeleteFunction={deleteOrganization}></Carousel>
        }
        {
          showUsersTable && organizationId.length > 0 && (
            <>
              <div className="user_table_header">
                {organization &&
                  <div className="panel_header">{organization.name}</div>
                }
                <Button onClick={() => handleClick(organizationId)} className="btn legacy_mode_button">View in Legacy Mode</Button>
              </div>
              <UsersTable users={users}
                          userCount={userCount}
                          organization={organizationId}
              />
            </>
          )
        }
      </div>
      <FlightingConfigModal showModal={showFlightingModal}
                            setShowModal={setShowFlightingModal}
                            organizationId={organizationId}
                            setOrganizationId={setOrganizationId}>
      </FlightingConfigModal>
      <OrganizationModal currentUser={user}
                         showModal={showEditOrganizationModal}
                         setShowModal={setShowEditOrganizationModal}
                         isEdit={true}
                         organizationId={organizationId}
                         setOrganizationId={setOrganizationId}>
      </OrganizationModal>
    </>
  )
}

export default Users