import React, { useEffect, useRef, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ROLES } from "../../utility/constants/constants";
import { handleLoadOptions } from "../../utility/CommonService";
import { AsyncPaginate } from "react-select-async-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganization,
  getOrganizations,
  update2FARequiredStatus
} from "../../redux/actions/OrganizationActions/Action";
import SimpleReactValidator from "simple-react-validator";
import { Button } from "../shared/ui-components/form-components";
import * as commonService from "../../utility/CommonService";

const Security = ({ isSelected }) => {
  const dispatch = useDispatch();
  const simpleValidator = useRef(new SimpleReactValidator());
  const currentUser = useSelector((state) => state.authReducer.user);
  const {
    organization,
    organizations,
    orgCount
  } = useSelector((state) => state.organizationReducer);
  const [form, setForm] = useState({
    organization: currentUser.role === ROLES.ADMINISTRATOR ? currentUser.organization : "",
  })
  const [is2FARequired, setIs2FARequired] = useState("no")
  const [isDisabled, setIsDisabled] = useState(false)
  const [orgPagination, setOrgPagination] = useState({
    page: 1,
    limit: 10,
  });

  const handleChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
    if (isDisabled) {
      setIsDisabled(false);
    }
  };

  const handle2FARequiredChange = (e) => {
    setIs2FARequired(e.target.value);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      const body = { new2faRequiredStatus: is2FARequired };
      const res = await dispatch(update2FARequiredStatus({ orgId: organization.id, body: body }));

      if (res?.value?.success) {
        commonService.forSuccess(
          "2FA Status Updated Successfully!",
          "Success"
        );
      }

    } else {
      simpleValidator.current.showMessages(); //show validation messages
      setIsDisabled(true);
    }
  }

  useEffect(() => {
    if (isSelected && currentUser.role === ROLES.SUPERADMIN) {
      dispatch(
        getOrganizations({ ...orgPagination })
      );
    }
  }, [currentUser.role, dispatch, orgPagination, isSelected]);

  useEffect(() => {
    if (isSelected) {
      if (currentUser.role === ROLES.ADMINISTRATOR) {
        dispatch(getOrganization({ id: currentUser.organization }));
      } else if (form.organization.length > 0) {
        dispatch(getOrganization({ id: form.organization }));
      }
    }
  }, [currentUser.organization, currentUser.role, dispatch, form.organization, isSelected])

  useEffect(() => {
    if (organization?.is_2fa_required) {
      setIs2FARequired("yes")
    } else {
      setIs2FARequired("no")
    }
  }, [organization])

  return (
    <div className="admin_tab_panel_container">
      <div className="security_header">
        <div className="panel_header security_title">2-Step Verification</div>
        <div className="security_org_dropdown">
          <AsyncPaginate
            placeholder={currentUser.role !== ROLES.SUPERADMIN && organization ? organization.name : "select the organization"}
            name="organization"
            onChange={(e) => {
              handleChange({
                target: { value: e.value, name: "organization" },
              })
            }}
            className="security_org_selector"
            required
            isDisabled={currentUser.role !== ROLES.SUPERADMIN}
            loadOptions={handleLoadOptions(organizations, orgPagination, setOrgPagination, orgCount)}
          />
          {currentUser.role === ROLES.SUPERADMIN && simpleValidator.current.message(
            "organization",
            form.organization,
            "required"
          )}
        </div>
      </div>
      <div className="panel">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="add_user_field_header">
            Make 2-step verification mandatory for all users
          </div>
          <RadioGroup value={is2FARequired} onChange={handle2FARequiredChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          <div className="add_user_save_button_container">
            <Button type="submit" className="add_user_save_button btn" disabled={isDisabled}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Security