import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as datasetActions from "../../../redux/actions/DataSetActions";
import { TextTruncate } from "utility/CommonService";
import { getFileNameFromPath, getFolderNameFromPath } from "utility/utility";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import CodeIcon from "@mui/icons-material/Code";

const DatasetListItem = ({
  item,
  datasetId,
  path,
  handleShowSnippet,
  getDownloadUrl,
}) => {
  const [children, setChildren] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const isDataset = item.name
  const isDatasetFolder = item.Prefix
  const isFolder = isDataset || isDatasetFolder

  const toggleIsOpen = () => {
    if (!isFolder) return;
    if (!children) {
      dispatch(
        datasetActions.getDataSetFiles({ body: { path }, id: datasetId })
      ).then((res) => {
        setChildren(res.value.listS3FolderContents.CommonPrefixes.slice(0));
      });
    }
    setIsOpen(!isOpen);
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    getDownloadUrl(path, datasetId).then(() => {
      setIsCopied(true);
    });
  };

  //get label depending on what object type it is
  const getItemLabel = () => {
    let label = ""

    if (isDataset)
      label = item.name;
    else if (isDatasetFolder)
      label = getFolderNameFromPath(path);
    else
      label = getFileNameFromPath(path)

    return label
  }

  let itemLabel = getItemLabel();
  const truncatedLabel = TextTruncate({ text: itemLabel, limit: 25 });

  return (
    <>
      <div
        onMouseLeave={() => setIsCopied(false)}
        onClick={toggleIsOpen}
        id="dataset_filename"
        className={
          isFolder ? "project_dataset_folder" : "project_dataset_file"
        }
      >
        <div>
          {isFolder && (
            <ChevronRightIcon
              className={`chevron_${isOpen ? "closed" : "open"}`}
            />
          )}
          <Tooltip title={itemLabel} placement="top" enterDelay={500}>
            <span>{truncatedLabel}</span>
          </Tooltip>
        </div>
        {isFolder ? (
          <div onClick={handleDownload}>
            <Tooltip
              title={isCopied ? "Copied!" : "Copy Access Url"}
              placement="top"
            >
              <LinkIcon />
            </Tooltip>
          </div>
        ) : (
          <div>
            <Tooltip title={"Code Snippet"} placement="top">
              <CodeIcon onClick={(e) => handleShowSnippet(e, path, datasetId)} />
            </Tooltip>
            <Tooltip
              title={isCopied ? "Copied!" : "Copy Access Url"}
              placement="top"
            >
              <LinkIcon onClick={handleDownload} />
            </Tooltip>
          </div>
        )}
      </div>
      {isOpen &&
        children &&
        children.map((childItem, index) => {
          if (childItem.Key !== path) {
            return (
              <DatasetListItem
                key={index}
                datasetId={datasetId}
                item={childItem}
                path={childItem.Key || childItem.Prefix}
                handleShowSnippet={handleShowSnippet}
                getDownloadUrl={getDownloadUrl}
              />
            );
          } else {
            return <React.Fragment key={index} />;
          }
        })}
    </>
  );
};

export default DatasetListItem;
