import React, { useState } from "react";
import { Link } from "react-router-dom";

import * as commonService from "utility/CommonService.js";
import InfoPanel from "../../InfoPanel/InfoPanel";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

export const ProjectHeader = ({ project, deleteProject }) => {
  const [isPanelOpen, setPanelOpen] = useState(false);

  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };

  return (
    <div className="lds_project_right_top_box_wrapper float_left">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="lds_pss_top_left float_left">
              <Link to="/projects" className="cursor-pointer ">
                <ExpandCircleDownIcon
                  style={{ transform: 'rotate(90deg)' }}
                /> Projects
              </Link>
              <h1 className="text-capitalize">{project && project.name}</h1>
              {project && (
                <p>
                  Last edited:{" "}
                  {commonService.dateToFromNowDaily(project.updated_at)}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            {project && (
              <div className="lds_pss_top_right float_left">
                <div
                  className="modal fade"
                  id="exampleModalCenter"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">
                      <div className="modal-body">
                        <h4 className="h4_title">Commit changes</h4>
                        <div className="modal_btms">
                          <Link
                            to={`/projects/${project.id}`}
                            className="theme_bg_btm custom_btn"
                          >
                            <code className="commit_btn code_title">
                              Commit
                            </code>
                          </Link>
                          <Link
                            to={`/projects/${project.id}`}
                            className="theme_outline_btn custom_btn"
                            data-dismiss="modal"
                          >
                            <code className="code_title discard_btn">
                              Discard
                            </code>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <InfoPanel
        conversation={project}
        isPanelOpen={isPanelOpen}
        onBackdropClicked={togglePanel}
      />
    </div>
  );
};
