import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkHasUnsavedChanges, confirmUnsavedChanges } from "utility/utility";
import { Tooltip } from "@material-ui/core";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import { FLIGHTING_CONSTANTS } from "utility/constants/constants";

const Header = ({
  setShowPublishModal,
  setShowCreateWorkflowModal,
  accessLevel
}) => {
  const { project, projectFilesContent } = useSelector(
    (state) => state.projectsReducer
  );
  const history = useHistory();
  const prevLocation = history.location.state?.prevLocation;

  const handleGoBack = () => {
    if (checkHasUnsavedChanges(projectFilesContent)) {
      confirmUnsavedChanges().then((res) => {
        if (res)
          return history.length > 2 ? history.goBack() : history.push("/projectsv2")
      })
    } else {
      return history.length > 2 ? history.goBack() : history.push("/projectsv2")
    }
  };

  return (
    project && <div className="projects_header">
      <BreadCrumb
        currentLoc={project.name}
        previousLoc={`${prevLocation || FLIGHTING_CONSTANTS.PROJECTS} List`}
        goBack={handleGoBack}
      />
      <div className="projects_header_right">
        <Tooltip title={["view"].includes(accessLevel) ? "You need execute or edit access" : ""}>
          <span>
            <button
              className="btn_small"
              onClick={() => setShowCreateWorkflowModal(true)}
              disabled={["view"].includes(accessLevel)}
            >
              Initialize Workflow
            </button>
          </span>
        </Tooltip>
        <Tooltip title={["view"].includes(accessLevel) ? "You need execute or edit access" : ""}>
          <span>
            <button
              className="btn_small"
              onClick={() => setShowPublishModal(true)}
              disabled={["view"].includes(accessLevel)}
            >
              publish
            </button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
