import {Button} from "react-bootstrap";
import React, {useRef, useState} from "react";
import * as actions from "../../../redux/actions/AuthActions";
import {useDispatch} from "react-redux";

const AddGitAccessModal = () => {
  const dispatch = useDispatch();
  const [gitForm, setGitForm] = useState({
    git_username: "",
    git_token: "",
  });
  const closeBtn = useRef();

  const addGitToken = (e) => {
    e.preventDefault();
    dispatch(actions.gitDetailsAccess({ body: gitForm })).then((res) => {
      if (res.value.success) {
        closeBtn.current.click();
      }
    });
  };

  const handleChange = (e) => {
    setGitForm({ ...gitForm, [e.target.name]: e.target.value });
  };

  return (
    <div className="lds_pl_model">
      <div className="modal fade in" id="gitModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                Please add Git access to create project
                <a
                  href="https://help.github.com/en/github/authenticating-to-github/creating-a-personal-access-token-for-the-command-line"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("assets/custom-styles/images/thumbnails/help_icn.png")}
                    alt="icn"
                  />
                </a>
              </h4>
              <Button
                type="button"
                ref={closeBtn}
                className="close close-project-modal"
                data-dismiss="modal"
              >
                &times;
              </Button>
            </div>
            <div className="modal-body">
              <form onSubmit={addGitToken}>
                <h4>Github Username</h4>
                <input
                  type="text"
                  placeholder="Enter username"
                  value={gitForm.git_username}
                  name="git_username"
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                />
                <h4>Personal Access Token</h4>
                <input
                  type="text"
                  placeholder="Enter token"
                  value={gitForm.git_token}
                  name="git_token"
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                />
                <Button type="submit" className="btn">
                  submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddGitAccessModal