import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as actions from "redux/actions/NotebookActions";
import * as commonService from "utility/CommonService.js";

import NotebookForm from "components/NoteBooks/Form";

export const AddNoteBook = ({history}) => {
  const project = useSelector(state => state.projectReducer.project);
  const defaultForm = {
    name: "",
    description: "",
    project_id: null,
  };
  const dispatch = useDispatch();

  const handleSubmit = (notebook) => {
    const project_id = commonService.getSearchParams(history, "project");
    dispatch(
      actions.createNotebook({
        body: { notebook: { ...notebook, project_id } },
      })
    ).then((res) => {
      commonService.forSuccess("Notebook created successfully!", "Success");
      history.push({
        pathname: `/notebooks/${res.value.notebook.id}`,
         state: {
           project: project
         }
      });
    });
  };
  return <NotebookForm defaultForm={defaultForm} handleSubmit={handleSubmit} history={history} />;
};

export default AddNoteBook;
