import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import UpdateIcon from "@mui/icons-material/Update";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/ProjectsActions/action";
import * as appActions from "../../../redux/actions/AppActions/action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

const PublishModal = ({
  handleShowProjectPublishModal,
  show,
  setShowPublishModal,
  handleShowWorkflowPublishModal,
}) => {
  const [showRedeployOption, setShowRedeployOption] = useState(false);
  const [showRedeployApps, setShowRedeployApps] = useState(false);
  const [projectApps, setProjectApps] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  let projectId = history.location.pathname.split("/")[2];
  const [appToRedeployId, setAppToRedeployId] = useState("");
  const handleClose = () => {
    setShowPublishModal(false);
  };
  const { repoContents } = useSelector((state) => state.projectsReducer);
  const [selectedFileName, setSelectedFileName] = useState();

  const redeployApp = (appId) => {
    if (selectedFileName) {
      dispatch(
        appActions.redeployApp(
          {
            projectId: projectId,
            publish_filenames: [selectedFileName]
          },
          appId
        )
      ).then(() => {
        history.push("/insights");
      });
    }
  };

  useEffect(() => {
    dispatch(actions.getProjectApps(projectId)).then((res) => {
      setProjectApps(res.value.projectApps);
    });

  }, [show, projectId, dispatch]);

  const PublishOptionButtons = () => (
    <>
      <div className="modal-prompts">
       What would you like to publish?
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="project_button"
          onClick={() => setShowRedeployOption(true)}
        >
          <span className="button_icon">
            <LeaderboardIcon />
          </span>
          <span className="project_button_text">Insight</span>
        </button>
        <button
          className="project_button"
          onClick={handleShowWorkflowPublishModal}
        >
          <span className="button_icon">
            <AccountTreeIcon />
          </span>
          <span className="project_button_text">Workflow</span>
        </button>
      </div>
    </>
  );

  const RedeployOptionButtons = () => {
    if (!showRedeployApps)
      return (
        <>
          <div className="modal-prompts">
            Is this a new insight?
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="project_button"
              onClick={handleShowProjectPublishModal}
            >
              <span className="button_icon">
                <AddIcon />
              </span>
              <span className="project_button_text">New Insight</span>
            </button>
            <button
              className="project_button"
              onClick={() => setShowRedeployApps(true)}
            >
              <span className="button_icon">
                <UpdateIcon />
              </span>
              <span className="project_button_text">
                Existing Insight
              </span>
            </button>
          </div>
        </>
      );
    else
      return (
        <div className="redeploy_selection_container">
          <div className="d-flex justify-content-left">
            <p>
              This app has already been deployed, select an app you would like
              to replace
            </p>
          </div>
          <InputLabel>Select an App to Replace</InputLabel>
          <Select
            placeholder="Select the App"
            name="app-selection"
            onChange={(e) => setAppToRedeployId(e.target.value)}
            value={appToRedeployId}
            required
            fullWidth
          >
            {projectApps.map((app) => (
              <MenuItem key={app.id} value={app.id}>{app.name}</MenuItem>
            ))}
          </Select>
          <div className="publish_modal">
            <h4 class="publish_project_text">
              Select a Driver File <span class="asterisk">*</span>
            </h4>
            <div className="file_select_container">
              {repoContents
                .filter((item) => item.name !== "dags")
                .map((item) => (
                  <div key={item.sha}>
                    <div className="publish_file_select_row">
                      <button
                        className={`publish_file_button cursor-pointer ${
                          selectedFileName === item.name ? "selected" : ""
                        }`}
                        type="button"
                        onClick={() => setSelectedFileName(item.name)}
                      >
                        {item.name}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <Button
            onClick={() => redeployApp(appToRedeployId)}
            className="btn float-right"
          >
            Publish
          </Button>
        </div>
      );
  };

  return (
    <Dialog
      className="publish-dialog-box"
      open={show}
      onClose={() => setShowPublishModal(false)}
    >
      <DialogTitle>
        <div className="modal-header">
          Publish a Project
          <IconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="publish_container">
          {showRedeployOption ? (
            <RedeployOptionButtons />
          ) : (
            <PublishOptionButtons />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PublishModal;
