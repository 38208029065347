import React from "react";
import EditDatasetForm from "components/DataSets/EditForm";
import { useSelector } from "react-redux";

export const EditDataset = ({history}) => {
  const { dataSet } = useSelector((state) => state.dataSetReducer);

  const defaultForm = {
    name: dataSet.name,
    description: dataSet.description,
    img_path: dataSet.img_path,
  };
  
  return <EditDatasetForm defaultForm={defaultForm} history={history} />;
};

export default EditDataset;
