import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verify2FA } from "redux/actions/AuthActions";
import * as commonService from "utility/CommonService";
import { Modal, Button } from "react-bootstrap";

const TwoFactorAuthForm = ({ history }) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const { user, verified_2fa } = useSelector((state) => state.authReducer);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(verify2FA({
      body: {
        secret: user.secret_2fa,
        token: code
      }
    })).then((res) => {
      if (res.value && res.value.success) {
        commonService.forSuccess("2FA Verification Successful", "Success");
        history.push("/");
      } else {
        commonService.forError("2FA Verification Failed", "Error");
      }
    });
    setCode("");
  };
  return (
    <Modal
      show={!verified_2fa}
      className="two_factor_auth_verify_modal"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header className="border-0 two_factor_auth_verify_header">
          <div className="header_text">Verify your account</div>
        </Modal.Header>
        <Modal.Body className="two_factor_auth_verify_body">
          <label htmlFor='code' style={{ marginBottom: "10px" }}>
            A 6-digit code has been sent your Authenticator
          </label>
          <div className="row" style={{ justifyContent: "center" }}>
            <input
              type='text'
              name='code'
              id='code'
              className='code'
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 two_factor_auth_verify_footer">
          <Link
            to={`/login`}
            className="cursor-pointer float-left"
          >
            <div className="two_factor_auth_verify_back_link">
              <i className="flaticon-left-arrow"></i>
              <div className="back_text">Back</div>
            </div>
          </Link>
          <Button type="submit" variant="primary" size="sm">Verify</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default TwoFactorAuthForm;