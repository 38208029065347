export const DataSetActionTypes = {

    GET_DATASETS: "GET_DATASETS",
    GET_DATASETS_PENDING: "GET_DATASETS_PENDING",
    GET_DATASETS_FULFILLED: "GET_DATASETS_FULFILLED",

    GET_DATASET: "GET_DATASET",
    GET_DATASET_PENDING: "GET_DATASET_PENDING",
    GET_DATASET_FULFILLED: "GET_DATASET_FULFILLED",

    GET_DATASET_FILES: "GET_DATASET_FILES",
    GET_DATASET_FILES_PENDING: "GET_DATASET_FILES_PENDING",
    GET_DATASET_FILES_FULFILLED: "GET_DATASET_FILES_FULFILLED",

    GET_MORE_DATASET_FILES: "GET_MORE_DATASET_FILES",
    GET_MORE_DATASET_FILES_PENDING: "GET_MORE_DATASET_FILES_PENDING",
    GET_MORE_DATASET_FILES_FULFILLED: "GET_MORE_DATASET_FILES_FULFILLED",

    CREATE_DATASET: "CREATE_DATASET",
    CREATE_DATASET_PENDING: "CREATE_DATASET_PENDING",
    CREATE_DATASET_FULFILLED: "CREATE_DATASET_FULFILLED",

    UPDATE_DATASET: "UPDATE_DATASET",
    UPDATE_DATASET_PENDING: "UPDATE_DATASET_PENDING",
    UPDATE_DATASET_FULFILLED: "UPDATE_DATASET_FULFILLED",

    GET_PRESIGNED_URL: "GET_PRESIGNED_URL",
    GET_PRESIGNED_URL_PENDING: "GET_PRESIGNED_URL_PENDING",
    GET_PRESIGNED_URL_FULFILLED: "GET_PRESIGNED_URL_FULFILLED",

    GET_FILE_PREVIEW: "GET_FILE_PREVIEW",
    GET_FILE_PREVIEW_PENDING: "GET_FILE_PREVIEW_PENDING",
    GET_FILE_PREVIEW_FULFILLED: "GET_FILE_PREVIEW_FULFILLED",

    GET_LUDIS_DATASETS: "GET_LUDIS_DATASETS",
    GET_LUDIS_DATASETS_PENDING: "GET_LUDIS_DATASETS_PENDING",
    GET_LUDIS_DATASETS_FULFILLED: "GET_LUDIS_DATASETS_FULFILLED",

    GET_NEW_DATASETS: "GET_NEW_DATASETS",
    GET_NEW_DATASETS_PENDING: "GET_NEW_DATASETS_PENDING",
    GET_NEW_DATASETS_FULFILLED: "GET_NEW_DATASETS_FULFILLED",

    UPDATE_DATASET_SHARE: "UPDATE_DATASET_SHARE",
    UPDATE_DATASET_SHARE_PENDING: "UPDATE_DATASET_SHARE_PENDING",
    UPDATE_DATASET_SHARE_FULFILLED: "UPDATE_DATASET_SHARE_FULFILLED",

    GET_DATASET_SHARES: "GET_DATASET_SHARES",
    GET_DATASET_SHARES_PENDING: "GET_DATASET_SHARES_PENDING",
    GET_DATASET_SHARES_FULFILLED: "GET_DATASET_SHARES_FULFILLED",

    CREATE_DATASET_SUBSCRIPTION: "CREATE_DATASET_SUBSCRIPTION",
    CREATE_DATASET_SUBSCRIPTION_PENDING: "CREATE_DATASET_SUBSCRIPTION_PENDING",
    CREATE_DATASET_SUBSCRIPTION_FULFILLED: "CREATE_DATASET_SUBSCRIPTION_FULFILLED",

    GET_DATASET_SUBSCRIPTIONS: "GET_DATASET_SUBSCRIPTIONS",
    GET_DATASET_SUBSCRIPTIONS_PENDING: "GET_DATASET_SUBSCRIPTIONS_PENDING",
    GET_DATASET_SUBSCRIPTIONS_FULFILLED: "GET_DATASET_SUBSCRIPTIONS_FULFILLED",

    CREATE_DATASET_DIRECTORY: "CREATE_DATASET_DIRECTORY",
    CREATE_DATASET_DIRECTORY_PENDING: "CREATE_DATASET_DIRECTORY_PENDING",
    CREATE_DATASET_DIRECTORY_FULFILLED: "CREATE_DATASET_DIRECTORY_FULFILLED",

    DELETE_DATASET: "DELETE_DATASET",
    DELETE_DATASET_PENDING: "DELETE_DATASET_PENDING",
    DELETE_DATASET_FULFILLED: "DELETE_DATASET_FULFILLED",

    SET_DATASET_SCREEN_FULFILLED: "SET_DATASET_SCREEN_FULFILLED",

    GET_LUDIS_URL: "GET_LUDIS_URL",
    GET_LUDIS_URL_PENDING: "GET_LUDIS_URL_PENDING",
    GET_LUDIS_URL_FULFILLED: "GET_LUDIS_URL_FULFILLED",

    PREPEND_FILE_TO_LIST: "PREPEND_FILE_TO_LIST",
  };
