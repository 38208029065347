import React, { useState, useEffect } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Input } from "components/shared/ui-components/form-components";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';

const ImageSelector = ({ selectedImg, setSelectedImg, isDataset }) => {
  const [showSelector, setShowSelector] = useState(false);
  const [defaultImages, setDefaultImages] = useState([]);

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setSelectedImg(e.target.files[0])
    }
    return;
  }

  const getImgPath = () => {
    if (typeof selectedImg === 'object') {
      return URL.createObjectURL(selectedImg)
    }
    return selectedImg
  }

  useEffect(() => {
    //retrieve list of local image paths stored in images/defaults folder
  const renderImages = () => {
      const ctx = require.context(
        "assets/custom-styles/images/defaults",
        false,
        /\.(png|jpe?g|svg)$/
      );
      let images = [];
      ctx.keys().map((item) => {
        images.push(ctx(item));
        return null;
      });
      setDefaultImages(images);
      //set initially to random image when modal is rendered
      if (!selectedImg)
        setSelectedImg(images[Math.floor(Math.random() * images.length)]);
      return;
    };

    renderImages();
  }, [selectedImg, setSelectedImg]);

  return (
    <>
      <div
        className="img_selector_wrapper"
      >
        <div className="img-preview" onClick={() => setShowSelector(true)}>
          <img src={getImgPath()} alt="" />
        </div>
        <div className="img-options">
          <div className="option" onClick={() => setShowSelector(true)}>
              <ImageSearchIcon/>
              <span>Select from default images</span>
          </div>
          {!isDataset && <div className="option">
              <div className="img-input-wrapper">
                  <label htmlFor="imageUpload">
                    <FileUploadIcon />
                    <span>Upload new image</span>
                  </label>
                  <Input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFile}
                  />
              </div>
          </div>
          }
        </div>
      </div>

      {showSelector && (
        <div className="img-selector-modal">
          <div className="selector-back-button">
            <ArrowBackOutlinedIcon onClick={() => setShowSelector(false)} />
          </div>
          <div className="imgs-section">
            {defaultImages.map((path) => {
              return (
                <img
                  className={
                    path === selectedImg ? "selected-img" : "img-option"
                  }
                  onMouseDown={() => {setSelectedImg(path)}}
                  onMouseUp={() => {setShowSelector(false)}}
                  src={path}
                  key={path}
                  alt=""
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ImageSelector;
