import React from "react";
import ValueBox from "../ClusteringTemplate/ValueBox.js"
import { Select } from "components/shared/ui-components/form-components";
import { Modal } from "react-bootstrap";

// selectorStyles object
const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
        ...styles,
        cursor: 'pointer',
        borderRadius: "8px",
        background: "#DFEDFF",
    }),
}

export function DropdownCustomContainer(
    {order,
        label,
        value,
        name,
        axisOptions,
        handleAxisChange,
        align,
        val,
        templateData,
        handleDivClick,
        selectedOption,
        isOpen,
        handleCloseModal,
        agg_options,
        handleRadioChange
    }
) {
    return(
        <div className= {order?'dropdownRow':'dropdownRowSAD'} style={{order:order,align:align}}>
            <div className="dropdownText">
                <p>
                    {label}
                </p>
            </div>
            <div className="dropdownSelect">
                <Select
                        styles={customStyles}
                        placeholder={value}
                        name={name}
                        label={label}
                        options={axisOptions}
                        onChange={(e) =>
                            handleAxisChange({
                                name: e.label,
                                value: e.value
                            })
                        }
                />
            </div>
            {order && <div className="axisDisplayX">
                <ValueBox plotData={templateData} axis={val} label={value} rows={templateData}></ValueBox>
            </div>}
            {handleDivClick &&
            <>
            <div className="sadModuleHeader">
                <p>Aggregation:</p>
                <div className="sadModule" onClick={handleDivClick}>
                    {selectedOption}
                </div>
            </div>
            <Modal
                id="s3_object_modal"
                className="s3_object_modal"
                size="xs"
                show={isOpen}
                onHide={handleCloseModal}
            >
            <Modal.Header closeButton>Aggregation Options</Modal.Header>
            {agg_options.map((option) => (
                    <label key={option}>
                    <input type="radio" name="option" value={option} onChange={handleRadioChange} />
                    {option}
                    </label>
                ))}
            </Modal>
            </>}
        </div>
    )
}

export default DropdownCustomContainer