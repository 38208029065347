import axios from "../config";
import { API_VERSION } from "config";
//Organization API

export const getOrganizations = (params) =>
  axios.get(`${API_VERSION}/organizations/list`, { params });
export const getOrganization = (id) =>
  axios.get(`${API_VERSION}/organizations/${id}`);

export const createOrganization = (body) =>
  axios.post(`${API_VERSION}/organizations`, body);

export const deleteOrganization = (id) =>
  axios.delete(`${API_VERSION}/organizations/${id}`);

export const updateOrganization = (body, id) =>
  axios.put(`${API_VERSION}/organizations/${id}`, body);

export const checkSeatAvailability = (id) =>
  axios.get(`${API_VERSION}/organizations/${id}/availableSeats`);

export const getActiveUserCounts = () =>
  axios.get(`${API_VERSION}/organizations/activeUserCounts`);

export const getBucket = (id) =>
  axios.get(`${API_VERSION}/organizations/buckets/${id}`);

export const getBuckets = (params) =>
  axios.get(`${API_VERSION}/organizations/buckets`, { params });

export const update2FARequiredStatus = (orgId, body) =>
  axios.put(`${API_VERSION}/organizations/${orgId}/2farequired`, body);
