import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getAllMetrics,
} from "../../../redux/actions/MetricsActions/Action";
import TimeRangeIndicator
  from "../../shared/Graph/TimeRangeIndicator/TimeRangeIndicator";
import StackedBarGraph from "../../shared/Graph/StackedBarGraph";
import EmptyGraph from "../../shared/Graph/EmptyGraph";
import Loader from "../../shared/Loader/Loader";
import { isFinite } from "lodash";

const EntityUsageGraph = () => {
  const dispatch = useDispatch();

  const metrics = useSelector((state) => state.metricsReducer.metrics);
  const [isMetricsLoaded, setIsMetricsLoaded] = useState(false);
  const [timeRange, setTimeRange] = useState('week')

  useEffect(() => {
    setIsMetricsLoaded(false)
    dispatch(getAllMetrics({ period: timeRange }))
  }, [dispatch, timeRange])

  useEffect(() => {
    setIsMetricsLoaded(true)
  }, [metrics])

  const entityGraphOptions = {
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true
      }]
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
          if (label) {
            label += `: ${value}`;
          }
          return label
        },
        footer: (tooltipItems, data) => {
          let sum = 0;
          for (let i = 0; i < data.datasets.length; i++) {
            if (isFinite(data.datasets[i].data[tooltipItems[i].index])) {
              sum += data.datasets[i].data[tooltipItems[i].index];
            }
          }
          return `Total: ${sum.toString()}`
        }
      }
    }
  }

  const handleTimeChange = (event, newTime) => {
    if (newTime !== null) {
      setTimeRange(newTime)
    }
  }

  const getSectionLabels = (metrics) => {
    const labels = new Set()
    Object.values(metrics).forEach(entry => {
      if (Object.keys(entry).length > 0) {
        Object.keys(entry).forEach(item => labels.add(item))
      }
    })
    return Array.from(labels)
  }

  return (
    <>
      <div className="panel_info">
        <TimeRangeIndicator timeRange={timeRange}
                            handleTimeChange={handleTimeChange}
                            className={"time_range_button_group"} />
      </div>
      <div className="panel_horizontal_graph_container">
        <>
          {
            isMetricsLoaded ?
              <>
                {
                  Object.keys(metrics).length > 0 ?
                    <StackedBarGraph data={metrics} options={entityGraphOptions}
                                     barLabels={Object.keys(metrics)}
                                     barPercentage={0.2}
                                     barSectionLabels={getSectionLabels(metrics)}
                                     className={"overview_insights_usage_graph"} /> :
                    <EmptyGraph />
                }
              </> :
              <Loader loading={!isMetricsLoaded}></Loader>
          }
        </>
      </div>
    </>
  )
}

export default EntityUsageGraph