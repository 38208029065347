import { ClusterActionTypes } from "./ActionType";
import * as API from "../../../api/ClusterAPI";

export const getClusters = (params) => (dispatch) =>
  dispatch({
    type: ClusterActionTypes.GET_CLUSTERS,
    payload: API.getClusters(params),
  });

export const getCluster = (id) => (dispatch) =>
  dispatch({
    type: ClusterActionTypes.GET_CLUSTER,
    payload: API.getCluster(id),
  });

export const clearCluster = () => (dispatch) =>
  dispatch({
    type: ClusterActionTypes.CLEAR_CLUSTER
  });

export const clearClusters = () => (dispatch) =>
  dispatch({
    type: ClusterActionTypes.CLEAR_CLUSTERS
  });