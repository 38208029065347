import { Modal, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { dateToFromNowDaily } from "utility/CommonService";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/ProjectActions";
import {DEFAULT_DATETIME_OPTION } from "../../utility/constants/constants";

const NotebooksModal = ({ projects, projectId, history }) => {
  const dispatch = useDispatch();
  const [notebookSelection, setNotebooksSelection] = useState("");
  const { user } = useSelector((state) => state.authReducer);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const {ludisProject, project} = useSelector(
    (state) => state.projectReducer
  );

  useEffect(() => {
    if (!ludisProject) {
      dispatch(actions.getLudisProjects());
    }
  }, [dispatch, ludisProject]);
  const handleSubmit = (e) => {
    if (notebookSelection === "") e.preventDefault();
    else
      history.push(`/import-notebooks?project=${projectId}`, {
        importNotebook: notebookSelection.toString(),
      });
    e.preventDefault();
  };
  const handleNotebookSelection = (e) => {
    setNotebooksSelection(e.target.value);
  };
  return (
    <>
      <Button variant="primary float-right mr-3" onClick={handleShow}>
        Import File
      </Button>

      <Modal
        className="import_modal"
        size="xl"
        show={show}
        onHide={handleClose}
        animation={false}
        scrollable={true}
      >
        <Modal.Header closeButton>Import Notebook</Modal.Header>
        <Modal.Body>
          <form data-toggle="validator">
            <div className="tabu__Sper">
              <div className="row">
                {ludisProject &&
                  ludisProject.filter((e) => e.language === project.language).map((project, index) => (
                    project.notebooks.filter((e) => e.status === "active").map((notebook, index) => (
                      <>
                        <div className="col-sm-1">
                          <input
                            className="radio_button"
                            type="radio"
                            name="selectnotebook"
                            required={true}
                            value={notebook.id}
                            onChange={handleNotebookSelection}
                          ></input>
                        </div>
                        <div className="col-sm-11" key={index}>
                          <div className="sper__Row">
                            <div className="sper__left">
                              <div className="sper__img">
                                <img
                                  src={require("assets/custom-styles/images/thumbnails/78_dzz.png")}
                                  alt="icn"
                                />
                              </div>
                            </div>
                            <div className="sper__right">
                              <Row>
                                <div className="col-sm-8">
                                  <div className="inn__L1">
                                    <h4>{notebook.name}</h4>
                                    <p className="word-break">
                                      {notebook.description}
                                    </p>
                                    <div className="modal_detail">
                                      <div className="modal_icon">
                                        <img
                                          src={require("assets/custom-styles/images/thumbnails/empty_photo.png")}
                                          alt="icn"
                                          className="inn__avtar mr-1"
                                          height="14px"
                                        />
                                        Ludis
                                      </div>
                                      <div className="modal_icon">
                                        <img
                                          src={require("assets/custom-styles/images/thumbnails/cal_icn.jpg")}
                                          alt="icn"
                                          className="mr-1"
                                          height="14px"
                                        />
                                        {notebook.created_at
                                          ? moment(notebook.created_at).format(user.date_format ? user.date_format: DEFAULT_DATETIME_OPTION)
                                          : null}
                                      </div>
                                      <div className="modal_icon">
                                        <img
                                          src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                                          alt="icn"
                                          className="mr-1"
                                          height="14px"
                                        />
                                        {dateToFromNowDaily(
                                          notebook.updated_at
                                        )}
                                      </div>
                                      <div className="modal_icon">
                                        <img
                                          src={require("assets/custom-styles/images/thumbnails/tag.png")}
                                          alt="icn"
                                          className="mr-1"
                                          height="14px"
                                        />
                                        Tags
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <Link
                                    to={{
                                      pathname: `/notebooks/${notebook.id}/preview`,
                                      state: {
                                        notebook: notebook,
                                      },
                                    }}
                                  >
                                    Preview
                                  </Link>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </>
                    ))))}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            type="submit"
            value="Submit"
            className="btn float-right"
          >
            import file
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotebooksModal;
