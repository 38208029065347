import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as commonService from "utility/CommonService.js";
import ScheduleInput from "../WorkflowDetails/ScheduleInput";
import { getEnvVariables } from 'redux/actions/ProjectsActions';
import { updateSchedule } from 'redux/actions/WorkflowActions';
import { WORKFLOW_VARIABLES } from 'utility/constants/constants';
import { ACCESS_LEVEL } from "utility/constants/constants";
import * as actions from "../../../redux/actions/WorkflowActions";

const PublishVisualWorkflowModal = ({ show, projectId, id, setShowPublishModal, setShowPublishConfirmation, workflow, nodes, edges, accessLevel}) => {
  const dispatch = useDispatch();
  const [cronExpression, setCronExpression] = useState('');
  const { envVariables } = useSelector((state) => state.projectsReducer);

  useEffect(() => {
    if (id && accessLevel !== ACCESS_LEVEL.NONE) {
      const variableKey = WORKFLOW_VARIABLES.LUDIS_SCHEDULE + id.substring(0,8)
      dispatch(
        getEnvVariables({
          id: projectId,
          key: variableKey
        })
      )
    };
  }, [id, dispatch, projectId, accessLevel])

  // set current schedule
  useEffect(() => {
    if (envVariables.length > 0) {
      setCronExpression(envVariables[0].value)
    }

  }, [envVariables])

  const updateScheduleVariable = () => {
    const varId = envVariables[0].id
    dispatch(
      updateSchedule({
        body: {
          value: cronExpression,
          project_id: projectId,
          var_id: varId
        },
          id: id
        })
    ).then((res) => {
      if (res.value && res.value.success) {
        commonService.forSuccess("Updated Schedule Successfully", "Success");
      } else {
        commonService.forError("Failed to Update Workflow Schedule", "Error");
      }
    })
  }


  const handlePublish = () => {
    updateScheduleVariable();
    dispatch(actions.saveWorkflow({
      body: {
        nodes: nodes,
        edges: edges
      },
      id: workflow.id
    })).then((res) => {
      if (res.value?.success) {
        dispatch(actions.publishVisualWorkflow({
          body: {
            project_id: workflow.project_id
          },
          id: workflow.id
        })).then((res) => {
          if (res.value?.success) {
            setShowPublishConfirmation(true);
            setShowPublishModal(false);
          }
        })
      }
    })
  }

  return (
    <Modal
      className="import_modal"
      show={show}
      onHide={() => setShowPublishModal(false)}
      animation={false}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center">
          <div className="modal-header-text"> Publish Workflow </div>
        </div>
      </Modal.Header>
      <Modal.Body>
      <div className="col-md-12">
        <h5 className="publish-modal-title">Workflow Name</h5>
        <h4>{workflow?.name}</h4>
      </div>
      <div className="col-md-12">
        <ScheduleInput setCronExpression = {setCronExpression} cronExpression = {cronExpression}></ScheduleInput>
      </div>
      </Modal.Body>
      <Modal.Footer>
          <Button
              onClick={handlePublish}
              type="submit"
              value="Submit"
              className="btn float-right"
              disabled={!ACCESS_LEVEL.ALL_EXECUTE.includes(accessLevel)}
          >
            Publish
          </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PublishVisualWorkflowModal