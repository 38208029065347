import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ImportError = ({ workflow, showError, toggleErrorModal }) => {
  return (
    <>
      <span className="show-text">
        <ErrorIcon sx={{ color: "#EB8002" }} />
      </span>

      <Dialog open={showError} onClose={toggleErrorModal}>
        <DialogTitle>
          <div className="modal-header">
              Error Details
            <IconButton edge="end" onClick={toggleErrorModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="error-container">
            <div className="error-item">{workflow?.import_error}</div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImportError;
