import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/DataSetActions";
import moment from "moment-timezone";
import { useFilePicker } from "use-file-picker";
import swal from "sweetalert";
import PresignedUrlModal from "./PresignedUrlModal";
import PreviewFileModal from "./PreviewFileModal";
import CreateDirectoryModal from "./CreateDirectoryModal";
import * as commonService from "../../../utility/CommonService";
import InfoPanel from "../../InfoPanel/InfoPanel";
import InfiniteScroll from "react-infinite-scroll-component";
import { DEFAULT_DATETIME_OPTION, FLIGHTING_CONSTANTS, routes } from "utility/constants/constants";
import Tooltip from "@mui/material/Tooltip";
import {
  getFolderNameFromPath,
  getPreviousFolderFromPath,
} from "utility/utility";
import FileUploadDownloadModal from "./UploadDownloadModal/UploadDownloadModal";
import { Typography } from "@material-ui/core";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EntityInfo from "components/shared/Row/EntityInfo";

const DatasetDetails = ({accessLevel}) => {
  const {dataSet, directoryFiles, ludisDatasets} = useSelector(
    (state) => state.dataSetReducer
  );
  const {user} = useSelector((state) => state.authReducer);
  const id = dataSet.id;
  const dispatch = useDispatch();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [show, setShow] = useState(false);
  const currentPath = directoryFiles.Prefix;
  const uploadPath = currentPath.replace(/^public\//, "");
  const [downloadFiles, setDownloadFiles] = useState([]);
  const history = useHistory();

  //check if author is ludis
  const isLudis = () => {
    if (ludisDatasets?.filter((dataSet) => dataSet.id === id).length > 0)
      return true;
    else return false;
  };

  const handleToggleModal = useCallback((toggle) => {
    setShow(toggle)
  }, [setShow])

  //iterate through folder files and download
  const downloadS3Folder = async () => {
    dispatch(
      actions.getDataSetFiles({
        body: {
          path: currentPath,
          limit: 'all'
        },
        id: dataSet.id
      })
    ).then(async (res) => {
      setDownloadFiles(res.value.listS3FolderContents.Contents);
      setShow(true);
    });
  };

  //download single s3 object
  const downloadS3File = async (file) => {
    setShow(true);
    setDownloadFiles([file]);
  };

  const loadMoreDatasetsFiles = () => {
    let currentDirectoryPath = currentPath;
    if (directoryFiles.NextContinuationToken) {
      dispatch(
        actions.getMoreDatasetFiles({
          body: {
            path: currentDirectoryPath,
            nextContinuationToken: directoryFiles.NextContinuationToken,
          },
          id: dataSet.id
        })
      );
    }
  };

  const toggleComment = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  //go back out of a directory
  const closeDirectory = (path) => {
    dispatch(
      actions.getDataSetFiles({
        body: {path: getPreviousFolderFromPath(path)},
        id: dataSet.id
      })
    );
  };

  //load files for sub-directory if clicked
  const openDirectory = (filePath) => {
    dispatch(
      actions.getDataSetFiles({
        body: {path: filePath},
        id: dataSet.id
      })
    );
  };

  const deleteDatasetObject = (filePath) => {
    swal({
      text: "Are you sure you want to delete this item?",
      className: "logout-alert-modal",
      buttons: [true, "Delete"],
    }).then((result) => {
      if (result) {
        dispatch(
          actions.deleteDataSetObject({
            body: { path: filePath },
            id: dataSet.id
          })
        ).then((res) => {
          if (res.value && res.value.success) {
            dispatch(
              actions.getDataSetFiles({
                body: {path: currentPath},
                id: dataSet.id
              })
            ).then((res) => {
              if (res.value && res.value.success)
                commonService.forSuccess("File(s) Deleted!", "Success");
            });
          }
        });
      }
    });
  };

  const renderAccessModal = (file, dataSet) => {
    if (!isLudis())
      return <PresignedUrlModal fileName={file} filePath={dataSet.path} />;
  };

  const [openFileSelector, {plainFiles}] = useFilePicker({
    multiple: true,
  })

  useEffect(() => {
    if (plainFiles.length > 0) {
      setShow(true);
    }
  }, [setShow, plainFiles]);

  const formatBytes = (sizeInBytes) => {
    const kilobyte = 1000;
    const megabyte = kilobyte * 1000;
    const gigabyte = megabyte * 1000;

    if (!sizeInBytes) {
      return '0 KB '
    }
    if (sizeInBytes < kilobyte) {
        return sizeInBytes + ' Bytes';
    } else if (sizeInBytes < megabyte) {
        return (sizeInBytes / kilobyte).toFixed(2) + ' KB ';
    } else if (sizeInBytes < gigabyte) {
        return (sizeInBytes / megabyte).toFixed(2) + ' MB ';
    } else {
        return (sizeInBytes / gigabyte).toFixed(2) + ' GB ';
    }
}

  return (
    <>
      <div>
        {(plainFiles.length > 0 || downloadFiles.length > 0) && show && (
          <FileUploadDownloadModal
            files={plainFiles.length > 0 ? plainFiles : downloadFiles}
            show={show}
            uploadPath={uploadPath}
            handleToggleModal={handleToggleModal}
            isUpload={plainFiles.length > 0}
          />
        )}
        <div className="lds_project_right_top_box_wrapper float_left">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="lds_pss_top_left float_left">
                <BreadCrumb currentLoc={dataSet.name} previousLoc={`${FLIGHTING_CONSTANTS.DATASETS} List`} goBack={() => history.push(routes.DATASETS_LIST)} />
                <div id='dataset_desc'>
                  {dataSet.description}
                </div>
                <div className="dataset_info_wrapper">
                  <EntityInfo entity={dataSet} isSharedWithMe={dataSet.user_uid !== user.uid} />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lds_project_bottom_box_wrapper float_left">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="lds_ddt_over_box lds_ddt_over_box_last float_left">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="lds_ddt_inner_box float_left">
                            <div className="lds_dts_tools">
                              <h4>
                                <span>
                                  Files {directoryFiles.IsTruncated?"(" + directoryFiles.FileCount + " +)":"(" + directoryFiles.FileCount + ")"}
                                </span>
                              </h4>
                              {!isLudis() &&
                                <div className="top_row_icons">
                                  <Tooltip title={<Typography>Download All</Typography>} placement="top" arrow>
                                    <img
                                      src={require("assets/custom-styles/images/thumbnails/download_all_icn_dark.png")}
                                      onMouseOver={(e) =>
                                        (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/download_all_icn.png"))
                                      }
                                      onMouseLeave={(e) =>
                                        (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/download_all_icn_dark.png"))
                                      }
                                      height="40px"
                                      className="cursor-pointer download_all_icn"
                                      onClick={() => downloadS3Folder()}
                                      alt="Not Found"
                                    />
                                  </Tooltip>
                                  <Tooltip title={accessLevel === "view" ? <Typography>You need edit access</Typography> : <Typography>Add File</Typography>} placement="top" arrow>
                                    <span>
                                      <img
                                        src={require("assets/custom-styles/images/thumbnails/new_file_dark_icn.png")}
                                        onMouseOver={(e) => {
                                          if (accessLevel !== "view") {
                                            (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/new_file_light_icn.png"))
                                          }
                                        }}
                                        onMouseLeave={(e) => {
                                          if (accessLevel !== "view") {
                                            (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/new_file_dark_icn.png"))
                                          }
                                        }}
                                        height="40px"
                                        className={accessLevel !== "view" ? "cursor-pointer file_icn" : "disabled-dataset-icon file_icn"}
                                        onClick={openFileSelector}
                                        alt="Not Found"
                                      />
                                    </span>
                                  </Tooltip>
                                  <CreateDirectoryModal accessLevel={accessLevel} />
                                </div>
                              }
                            </div>
                            {getFolderNameFromPath(currentPath) !==
                              dataSet.id && (
                                <div
                                  onClick={() => closeDirectory(currentPath)}
                                  className="cursor-pointer dataset_folder"
                                >
                                  <i className="flaticon-left-arrow"></i>{" "}
                                  {getFolderNameFromPath(currentPath)}
                                </div>
                              )}

                            <InfiniteScroll
                              dataLength={directoryFiles.Contents.length} //This is important field to render the next data
                              next={loadMoreDatasetsFiles}
                              hasMore={directoryFiles.IsTruncated}
                              loader={<h4>Loading...</h4>}
                            >
                              <ul className="directory_list">
                                {directoryFiles &&
                                  directoryFiles.Contents.map((file) => {
                                    if (file.Key) {
                                      let fileName = file.Key.substring(
                                        file.Key.lastIndexOf("/") + 1
                                      );
                                      return (
                                        <div key={file.Key}>
                                          <li>
                                            <div className="dataset_file_row">
                                              <div className="directory_file">
                                              <span className="object_icn">
                                                <i className="far fa-file"></i>{" "}
                                              </span>
                                                &nbsp;{fileName}
                                                <div className="text-muted">
                                                  {formatBytes(file.Size)}
                                                  Last Updated: &nbsp;
                                                  {moment(file.LastModified)
                                                    .tz(user.timezone)
                                                    .format(
                                                      (user.date_format
                                                        ? user.date_format
                                                        : DEFAULT_DATETIME_OPTION) +
                                                      " h:mm:ss a"
                                                    )}
                                                </div>
                                              </div>
                                              <div className="dataset_file_row_icons">
                                                <PreviewFileModal
                                                  filePath={file.Key}
                                                />
                                                {renderAccessModal(
                                                  fileName,
                                                  dataSet
                                                )}
                                                {!isLudis() && (
                                                  <Tooltip title={<Typography>Download</Typography>} placement="top" arrow>
                                                    <img
                                                      src={require("assets/custom-styles/images/thumbnails/download_icn_dark.png")}
                                                      onMouseOver={(e) =>
                                                        (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/download_icn.png"))
                                                      }
                                                      onMouseLeave={(e) =>
                                                        (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/download_icn_dark.png"))
                                                      }
                                                      height="50px"
                                                      onClick={() =>
                                                        downloadS3File(file)
                                                      }
                                                      className="cursor-pointer"
                                                      alt="Not Found"
                                                    />
                                                  </Tooltip>
                                                )}
                                                {!isLudis() && (
                                                  <Tooltip title={accessLevel === "view" ? <Typography>You need edit access</Typography> : <Typography>Delete</Typography>} placement="top" arrow>
                                                    <span>
                                                      <img
                                                        src={require("assets/custom-styles/images/thumbnails/delete_icn_dark.png")}
                                                        onMouseOver={(e) => {
                                                          if (accessLevel !== "view") {
                                                            (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/delete_icn.png"))
                                                          }
                                                        }}
                                                        onMouseLeave={(e) => {
                                                          if (accessLevel !== "view") {
                                                            (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/delete_icn_dark.png"))
                                                          }
                                                        }}
                                                        height="35px"
                                                        onClick={() => {
                                                          if (accessLevel !== "view") {
                                                            deleteDatasetObject(file.Key)
                                                          }
                                                        }}
                                                        className={accessLevel !== "view" ? "cursor-pointer file_icn" : "disabled-dataset-icon file_icn"}
                                                        alt="Not Found"
                                                      />
                                                    </span>
                                                  </Tooltip>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        </div>
                                      );
                                    } else {
                                      let folderPath = file.Prefix;
                                      let folderName =
                                        getFolderNameFromPath(folderPath);
                                      return (
                                        <div key={folderPath}>
                                          <li>
                                            <div className="dataset_file_row">
                                              <div className="directory_file">
                                                <div
                                                  className="cursor-pointer dataset_folder"
                                                  onClick={() =>
                                                    openDirectory(file.Prefix)
                                                  }
                                                >
                                                  <span className="object_icn">
                                                    <i className="far fa-folder"></i>{" "}
                                                  </span>
                                                  {folderName}
                                                </div>
                                              </div>
                                              <div className="dataset_file_row_icons">
                                                {!isLudis() && (
                                                  <Tooltip title={accessLevel === "view" ? <Typography>You need edit access</Typography> : <Typography>Delete</Typography>} placement="top" arrow>
                                                    <span>
                                                      <img
                                                        src={require("assets/custom-styles/images/thumbnails/delete_icn_dark.png")}
                                                        onMouseOver={(e) =>
                                                          (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/delete_icn.png"))
                                                        }
                                                        onMouseLeave={(e) =>
                                                          (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/delete_icn_dark.png"))
                                                        }
                                                        height="35px"
                                                        onClick={() =>
                                                          deleteDatasetObject(
                                                            file.Prefix
                                                          )
                                                        }
                                                        className={accessLevel !== "view" ? "cursor-pointer file_icn" : "disabled-dataset-icon file_icn"}
                                                        alt="Not Found"
                                                      />
                                                    </span>
                                                  </Tooltip>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        </div>
                                      );
                                    }
                                  })}
                              </ul>
                            </InfiniteScroll>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <InfoPanel
          conversation={dataSet}
          isPanelOpen={isPanelOpen}
          onBackdropClicked={toggleComment}
        />
      </div>
    </>
  );
};

export default DatasetDetails;
