import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch } from "react-redux";
import * as actions from "../../redux/actions/Web3Actions/Action";
import * as commonService from "utility/CommonService.js";

import { Link } from "react-router-dom";
import {
  Select,
  Input,
  Button
} from "components/shared/ui-components/form-components";

const Collections = ({ history }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    contractAddress: "",
    slugName: "",
    inProgress: "",
    success: "",
    failed: "",
  });
  const simpleValidator = useRef(new SimpleReactValidator());
  const chainOptions = Object.freeze({ "ETHEREUM": true, "POLYGON": true })

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res;
    let validContract;
    validContract = await dispatch(actions.validateContract(
      {
        body: {
          chain: form.chain
        },
        contractAddress: form.contractAddress
      })
    )
    if (validContract) {
      res = await dispatch(actions.addCollection(
        {
          body: {
            gcp_slug: form.slugName,
            chain: form.chain
          },
          contractAddress: form.contractAddress,
        })
      )
    } else {
      console.log("Contract Address Invalid or doesnt exist in Allium")
    }
    if (res) {
      if (res.value.success) {
        commonService.forSuccess(
          "Collection created successfully!"
        );
      }
      history.push("/collections");
    }
  }
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  return (
    <div className="lds_right_box_wrapper">
      <form onSubmit={handleSubmit}>
        <div className="lds_cp_heading">
          <Link to={"/collections"} className="cursor-pointer float-left">
            <i className="flaticon-left-arrow"></i> Back
          </Link>
        </div>
        <div className="lds_cp_box">
          <div className="row">
            <div className="col-md-12">
              <div className="lds_cp_box_heading">
                <h4>Contract Address</h4>
                <Input
                  type="text"
                  name="contractAddress"
                  placeholder="Contract Address"
                  value={form.contractAddress}
                  onChange={handleChange}
                />
                {simpleValidator.current.message("contractAddress", form.contractAddress, "required|alpha_num|size:42")}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Slug Name</h4>
                <Input
                  type="text"
                  name="slugName"
                  placeholder="Slug Name"
                  value={form.slugName}
                  onChange={handleChange}
                />
                {simpleValidator.current.message("slugName", form.slugName, "required|alpha_num")}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Chain</h4>
                <Select
                  options={Object.keys(chainOptions)}
                  placeholder="chain"
                  name="chain"
                  onChange={(e) =>
                    handleChange({
                      target: {
                        value: e.value,
                        name: "chain",
                      },
                    })
                  }
                  value={{
                    label: form.chain,
                    value: form.chain,
                  }}
                  required
                />
                {simpleValidator.current.message("chain", form.chain, "required")}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_third">
                <Button type="submit" className="btn">
                  Add Collection
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Collections