import React, { useEffect } from 'react';
import {
  getActiveUserCounts,
  getOrganization
} from "../../redux/actions/OrganizationActions/Action";
import { getUsers } from "redux/actions/UserActions/Action";

import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../../utility/constants/constants";
import OrganizationPanelInfo from "./OrganizationPanelInfo";
import UserPanelInfo from "./UserPanelInfo";
import moment from 'moment';
import {
  clearAppMetrics,
  clearProjectMetrics,
  clearDatasetMetrics,
  clearWorkflowMetrics
} from "../../redux/actions/MetricsActions/Action";
import {
  clearNumLogins
} from "../../redux/actions/UserActivitiesActions/Action";

const Overview = ({ user, isSelected, setValue, userActivitiesGraph, entityUsageGraph }) => {
  const dispatch = useDispatch();
  const { organization, activeUserCounts } = useSelector(
    (state) => state.organizationReducer
  );
  const { userCount } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (user.role === ROLES.ADMINISTRATOR) {
      dispatch(
        getOrganization({ id: user.organization })
      )
      dispatch(
        getUsers({ limit: 'all', organization: user.organization })
      );
    } else if (user.role === ROLES.SUPERADMIN) {
      dispatch(getActiveUserCounts())
    }
  }, [dispatch, user.organization, user.role])

  useEffect(() => {
    if (isSelected) {
      dispatch(clearNumLogins())
      dispatch(clearAppMetrics())
      dispatch(clearProjectMetrics())
      dispatch(clearDatasetMetrics())
      dispatch(clearWorkflowMetrics())
    }
  }, [dispatch, isSelected])

  const handleClickUserActivities = () => {
    dispatch(clearNumLogins())
    setValue(2)
  }

  const handleClickInsightsUsage = () => {
    dispatch(clearAppMetrics())
    dispatch(clearProjectMetrics())
    dispatch(clearDatasetMetrics())
    dispatch(clearWorkflowMetrics())
    setValue(3)
  }

  return (
    <div className="admin_tab_panel_container">
      <div className="panel_row">
        <div className="panel panel_small org_panel">
          <div className="panel_header">
            {user.role === ROLES.SUPERADMIN ? "Organizations" : "Organization"}
          </div>
          {user.role === ROLES.SUPERADMIN ? (
            <OrganizationPanelInfo
              activeUserCounts={activeUserCounts}></OrganizationPanelInfo>
          ) : (
            <>
              <div className="active_user_header">Active since: <span
                className="org_founding_date">{moment(organization?.created_at.split('T')[0]).format(user.date_format)}</span>
              </div>
              <div className="org_panel_header">Admin: <span
                className="org_founding_date">{organization?.admin_email}</span>
              </div>
            </>
          )}
        </div>
        <div className="panel_horizontal">
          <div className="panel_header_row">
            <div className="panel_header">User activities</div>
            <div className="view_details" onClick={() => handleClickUserActivities()}>View details</div>
          </div>
          {userActivitiesGraph}
        </div>
      </div>
      <div className="panel_row">
        <div className="panel panel_small user_panel">
          <div className="panel_header_row">
            <div className="panel_header">Users</div>
            <div className="view_details" onClick={() => setValue(1)}>View details</div>
          </div>
          {user.role === ROLES.SUPERADMIN ? (
            <UserPanelInfo activeUserCounts={activeUserCounts}></UserPanelInfo>
          ) : (
            <>
              <div className="active_user_header">Active users</div>
              <div className="active_user_count">
                <span className="count">{userCount}</span>
                <span className="max_seats">/{organization?.max_seats}</span>
              </div>
            </>
          )}

        </div>
        <div className="panel_horizontal">
          <div className="panel_header_row">
            <div className="panel_header">Entity Usage</div>
            <div className="view_details" onClick={() => handleClickInsightsUsage()}>View details</div>
          </div>
          <div className="panel_horizontal_graph">
            {entityUsageGraph}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview