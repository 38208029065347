import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import { createAxes } from '../template.util';
import moment from 'moment';
import { dateFormats } from 'utility/constants/constants';

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()}, .2)`;

const LineGraph = ({data, xval, groupVal, type, isStacked, summaryStat}) => {
  const chartRef = useRef(null);
  var datasets = []
  var xValues = data.map((d) => d[xval]);
  const yValues = data.map((d) => d[summaryStat]);

  // eslint-disable-next-line
  let [xAxes, xScale, yAxes] = createAxes(xValues, xval, yValues, summaryStat, isStacked)

  if( !groupVal || xval === groupVal ) {
    datasets.push({
        label: 'Data',
        data: yValues,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.4,
        });
  } else {
    const groups = [...new Set(data.map((d) => d[groupVal]))]; // get all unique group values
    xValues = [...new Set(data.map((d) => d[xval]))]; // get all unique group values

    groups.forEach((group) => {
        var color = randomRGB()
        const groupData = data.filter((d) => d[groupVal] === group);

        let xy = groupData.map(d => ({
          x:d[xval],
          y:d[summaryStat]
        }))

        if(xScale === "time") {
          xy = xy.sort((a, b) => moment(a.x, dateFormats).diff(moment(b.x, dateFormats)))
        } else {
          xy = xy.sort((a, b) => a.x - b.x)
        }

        datasets.push({
            label: group,
            data: xy,
            borderColor: color,
            backgroundColor: color,
            tension: 0.4,
            });
      });
  }

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Sample data for the line graph
    const data = {
      labels: xValues,
      datasets: datasets,
    };
    // Chart configuration
    const config = {
      type: type,
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: isStacked,
            gridLines: {
              display: false,
            }
          }],
          yAxes: [{
            stacked: isStacked,
            ticks: {
              beginAtZero: true,
            },
            type: 'linear',
          }]
        },
      },
    };

    // Create the line graph
    const chart = new Chart(ctx, config);

    return () => {
      // Cleanup chart on component unmount
      chart.destroy();
    };
  }, [data, xval, groupVal, datasets, xValues, type, isStacked]);

  return <canvas ref={chartRef} />;
};

export default LineGraph;
