export const ProjectActionTypes = {

    GET_PROJECTS: "GET_PROJECTS",
    GET_PROJECTS_PENDING: "GET_PROJECTS_PENDING",
    GET_PROJECTS_FULFILLED: "GET_PROJECTS_FULFILLED",

    GET_PROJECT: "GET_PROJECT",
    GET_PROJECT_PENDING: "GET_PROJECT_PENDING",
    GET_PROJECT_FULFILLED: "GET_PROJECT_FULFILLED",

    GET_LUDIS_PROJECTS: "GET_LUDIS_PROJECTS",
    GET_LUDIS_PROJECTS_PENDING: "GET_LUDIS_PROJECTS_PENDING",
    GET_LUDIS_PROJECTS_FULFILLED: "GET_LUDIS_PROJECTS_FULFILLED",

    CREATE_PROJECT: "CREATE_PROJECT",
    CREATE_PROJECT_PENDING: "CREATE_PROJECT_PENDING",
    CREATE_PROJECT_FULFILLED: "CREATE_PROJECT_FULFILLED",

    DELETE_PROJECT: "DELETE_PROJECT",
    DELETE_PROJECT_PENDING: "DELETE_PROJECT_PENDING",
    DELETE_PROJECT_FULFILLED: "DELETE_PROJECT_FULFILLED",

    UPDATE_PROJECT: "UPDATE_PROJECT",
    UPDATE_PROJECT_PENDING: "UPDATE_PROJECT_PENDING",
    UPDATE_PROJECT_FULFILLED: "UPDATE_PROJECT_FULFILLED",

    GET_DATASETS: "GET_DATASETS",
    GET_DATASETS_PENDING: "GET_DATASETS_PENDING",
    GET_DATASETS_FULFILLED: "GET_DATASETS_FULFILLED",
    GET_NOTEBOOKS: "GET_NOTEBOOKS",
    GET_NOTEBOOKS_PENDING: "GET_NOTEBOOKS_PENDING",
    GET_NOTEBOOKS_FULFILLED: "GET_NOTEBOOKS_FULFILLED",

    DELETE_NOTEBOOK: "DELETE_PROJECT_NOTEBOOK",
    DELETE_NOTEBOOK_PENDING: "DELETE_PROJECT_NOTEBOOK_PENDING",
    DELETE_NOTEBOOK_FULFILLED: "DELETE_PROJECT_NOTEBOOK_FULFILLED",

    CREATE_PROJECT_SHARE: "CREATE_PROJECT_SHARE",
    CREATE_PROJECT_SHARE_PENDING: "CREATE_PROJECT_SHARE_PENDING",
    CREATE_PROJECT_SHARE_FULFILLED: "CREATE_PROJECT_SHARE_FULFILLED",

    GET_PROJECT_SHARES: "GET_PROJECT_SHARES",
    GET_PROJECT_SHARES_PENDING: "GET_PROJECT_SHARES_PENDING",
    GET_PROJECT_SHARES_FULFILLED: "GET_PROJECT_SHARES_FULFILLED",

    SET_PROJECT_SCREEN_FULFILLED: "SET_PROJECT_SCREEN_FULFILLED",
};