import React from 'react'

const NoTaskSelectedPage = () => {
  return (
    <div className='content'>
      <div>
        Select a task to continue
      </div>
    </div>
  )
};

export default NoTaskSelectedPage;