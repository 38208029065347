import * as API from "../../../api/UserActivitiesAPI";
import { UserActivitiesActionTypes } from "./ActionType";

export const getNumLogins = (params) => (dispatch) =>
  dispatch({
    type: UserActivitiesActionTypes.GET_NUM_LOGINS,
    payload: API.getNumLogins(params),
  });

export const clearNumLogins = () => (dispatch) =>
  dispatch({
    type: UserActivitiesActionTypes.CLEAR_NUM_LOGINS
  })