import { Modal, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import moment from "moment-timezone";
import { dateToFromNowDaily } from "utility/CommonService";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/AppActions";
import * as appActions from "redux/actions/AppActions";
import * as commonService from "utility/CommonService.js";
import ImageSelector from "components/shared/ImageSelector";
import {
  Select,
  TextArea,
} from "components/shared/ui-components/form-components";
import {DEFAULT_DATETIME_OPTION } from "../../utility/constants/constants";
import { capitalizeFirstLetter, getAssetImgPath } from "utility/utility";

const PublishModal = ({ history }) => {
  const { project, notebooks } = useSelector((state) => state.projectReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [selectedImg, setSelectedImg] = useState();

  const defaultForm = {
    app_type: "",
    dataset_ids: [],
    description: "",
    notebook_id: "",
    featured: false,
    language: project.language,
    name: project.name,
    project_id: project.id,
    publish_filenames: [],
    img_path: "",
  };

  const [form, setForm] = useState(defaultForm);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleNotebookSelect = (e, notebookName) => {
    setForm({
      ...form,
      publish_filenames: [notebookName.toLowerCase()],
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const handleImgSelect = (imgUrl) => {
    setSelectedImg(imgUrl)
    setForm({...form, img_path: getAssetImgPath(imgUrl, user)})
  }

  function Validation() {
    if (/[^a-zA-Z0-9\-\s/]/.test(form.name)) {
      commonService.forError(
        "Please enter valid Project Name. No special character allowed.",
        "Error"
      );
      return false;
    } else if (!form.name.trim()) {
      commonService.forError("Empty Project name not allowed.", "Error");
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    if (typeof selectedImg === 'object') {
      await Storage.put(getAssetImgPath(selectedImg, user), selectedImg, {
        contentType: selectedImg.type,
      })
    }
    e.preventDefault();
    if (Validation()) {
      dispatch(
        actions.createApp({
          application: { ...form },
        })
      ).then((res) => {
        setSelectedImg('')
        if (res.value && res.value.success) {
          history.push("/insights");
          commonService.forSuccess(
            capitalizeFirstLetter(`${form.app_type} Created Successfully`),
            "Success"
          );
        }
      });
    }
    // Navigate to Reports on Insights page
    var currentScreen = ('report') ? "Reports" : "Applications";
    dispatch(appActions.setCurrentScreen(currentScreen));
  };

  return (
    <>
      <Button variant="primary float-right mr-3" onClick={handleShow}>
        Publish
      </Button>

      <Modal
        className="import_modal"
        size="xl"
        show={show}
        onHide={handleClose}
        animation={false}
        scrollable={true}
      >
        <Modal.Header closeButton>Publish</Modal.Header>
        <Modal.Body>
          <form data-toggle="validator">
          <div className="form-img-selector">
              <ImageSelector selectedImg={selectedImg} setSelectedImg={handleImgSelect} />
            </div>
              <div className="col-md-12">
                <div className="publish_modal">
                  <h4>Name</h4>
                  <input
                    type="text"
                    placeholder="Name"
                    value={form.name}
                    name="name"
                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="publish_modal lds_cp_box_heading">
                  <h4>Description</h4>
                  <TextArea
                    rows="4"
                    placeholder="Type Here"
                    name="description"
                    value={form.description}
                    onChange={handleChange}
                    required
                  ></TextArea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="publish_modal publish_select_btn">
                  <h4>App Type</h4>
                  <Select
                    placeholder="App Type"
                    name="app_type"
                    options={["Report", "Application"]}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          value: e.value.toLowerCase(),
                          name: "app_type",
                        },
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="row publish_notebooks">
                {notebooks &&
                  notebooks.map((notebook, index) => (
                    <>
                      <div className="col-sm-1">
                        <input
                          className="radio_button cursor-pointer"
                          type="radio"
                          name="notebook_id"
                          id={notebook.id.toString()}
                          required={true}
                          value={notebook.id}
                          onChange={(e) => {
                            handleNotebookSelect(e, notebook.name);
                          }}
                        />
                      </div>

                      <div className="col-sm-11" key={index}>
                        <label
                          for={notebook.id.toString()}
                          className="publish_radio_btn"
                        >
                          <div className="sper__Row">
                            <div className="sper__left">
                              <div className="sper__img">
                                <img
                                  src={require("assets/custom-styles/images/thumbnails/78_dzz.png")}
                                  alt="icn"
                                />
                              </div>
                            </div>
                            <div className="sper__right">
                              <Row>
                                <div className="col-sm-8">
                                  <div className="inn__L1">
                                    <h4>{notebook.name}</h4>
                                    <p className="word-break">
                                      {notebook.description}
                                    </p>
                                    <div className="modal_detail">
                                      <div className="modal_icon">
                                        <img
                                          src={require("assets/custom-styles/images/thumbnails/empty_photo.png")}
                                          alt="icn"
                                          className="inn__avtar mr-1"
                                          height="14px"
                                        />
                                        {project.author}
                                      </div>
                                      <div className="modal_icon">
                                        <img
                                          src={require("assets/custom-styles/images/thumbnails/cal_icn.jpg")}
                                          alt="icn"
                                          className="mr-1"
                                          height="14px"
                                        />
                                        {notebook.created_at
                                          ? moment(notebook.created_at).format(user.date_format ? user.date_format: DEFAULT_DATETIME_OPTION)
                                          : null}
                                      </div>
                                      <div className="modal_icon">
                                        <img
                                          src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                                          alt="icn"
                                          className="mr-1"
                                          height="14px"
                                        />
                                        {dateToFromNowDaily(
                                          notebook.updated_at
                                        )}
                                      </div>
                                      <div className="modal_icon">
                                        <img
                                          src={require("assets/custom-styles/images/thumbnails/tag.png")}
                                          alt="icn"
                                          className="mr-1"
                                          height="14px"
                                        />
                                        Tags
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <Link
                                    to={{
                                      pathname: `/notebooks/${notebook.id}/preview`,
                                      state: {
                                        notebook: notebook,
                                      },
                                    }}
                                  >
                                    Preview
                                  </Link>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </label>
                      </div>
                    </>
                  ))}
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            type="submit"
            value="Submit"
            className="btn float-right"
          >
            publish
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublishModal;
