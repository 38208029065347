import React from "react";
import {
  AuthorContainer,
  CommentContainer,
  ContentContainer,
  SeparatorLine,
  TimeStamp
} from "./Comment.styled";
import moment from "moment-timezone";

const Comment = ({ comment }) => {
  const timeFromNow = (date) => {
    const m = moment(date);
    return m.fromNow();
  };

  return (
    <CommentContainer>
      <SeparatorLine />
      <AuthorContainer>{comment.author_display_name}</AuthorContainer>
      <ContentContainer>
        {comment.content}{" "}
        <TimeStamp>{timeFromNow(comment.created_at)}</TimeStamp>
      </ContentContainer>
    </CommentContainer>
  );
};

export default Comment;
