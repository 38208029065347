import Axios from "axios";
import axios from "config";
//import {API_VERSION} from "config";
//store
import storeAndPersistor from "../../redux/store/store";
import storage from "../../utility/storage";

import * as actions from "redux/actions/AuthActions";
import * as commonService from "../CommonService.js";

const handleAuthentication = async (error) => {
  const config = error.config;
  const token = storage.get("refresh_token", null);
  axios.defaults.headers.common["x-refreshtoken"] = token;
  const { store } = storeAndPersistor;
  return await store.dispatch(actions.refreshToken()).then((res) => {
    config.headers.Authorization = `Bearer ${res.value.token}`;
    return axios.request(config);
  });
};

export default function errorHandler(error) {
  //hide loader
  commonService.isLoading.onNext(false);
  if (Axios.isCancel(error)) {
    return Promise.reject(error);
  }
  if (error.message === "Network Error") {
    commonService.forError(
      "Error connecting server. Please check your internet connection.",
      "Error"
    );
    return Promise.reject(error.message);
  }
  const message = error.response
    ? error.response.data.message
    : "Seems like something went wrong!";
  switch (error.response.status) {
    case 400:
      if (message === "jwt expired") handleAuthentication(error);
      else commonService.forError(message, "Error");
      break;
    case 401:
      if (message === "jwt expired") {
        const { store } = storeAndPersistor;
        store.dispatch(actions.logout());
        return handleAuthentication(error);
      } else commonService.forError(message, "Error");
      console.log("unauthorized, logging out ...");
      break;
    case 500:
      commonService.forError(message, "Error");
      break;
    case 504:
      commonService.forError(
        "Sorry, could not access the external resource to refine the data for your request, please try again later!",
        "Error"
      );
      break;
    case 700:
      commonService.forError(message, "Error");
      break;
    default:
      commonService.forError(
        message ? message : "something went wrong",
        "Error"
      );
      break;
  }
  return Promise.reject(error.response);
}
