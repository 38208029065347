import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Comment from "../Comment/Comment";
import {
  ConversationContainer,
  EmptyConversation,
  NewCommentBox
} from "./Conversation.styled";
import * as commentActions from "../../../redux/actions/CommentActions";
import { isEmpty } from "lodash";
import ScrollToBottom from "react-scroll-to-bottom";

const Conversation = ({ comments, referenceId }) => {
  const [messageText, setMessageText] = useState("Type Message");
  const dispatch = useDispatch();
  let textInput;

  useEffect(() => {
    const pollForComments = () => {
      return setInterval(() => {
        if (referenceId) dispatch(commentActions.fetchComments(referenceId));
      }, 5000);
    };

    if (referenceId) dispatch(commentActions.fetchComments(referenceId));

    // set timer onmount
    const intervalId = pollForComments();
    return () => {
      // clear timer on unmount
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [dispatch, referenceId]);

  const handleChange = (e) => {
    setMessageText(e.target.value);
  };

  const onKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      // Enter pressed
      if (!isEmpty(messageText)) {
        let commentReqBody = {
          content: messageText,
          reference_id:
            typeof referenceId === "string"
              ? referenceId
              : referenceId.toString()
        };
        dispatch(commentActions.createComment(commentReqBody));
        textInput.value = "";
      }
    }
  };

  let content = (
    <EmptyConversation> Type to start a conversation!</EmptyConversation>
  );

  if (comments && comments.conversation.length > 0) {
    content = comments.conversation.map((comment) => {
      return <Comment key={comment.id} comment={comment} />;
    });
  }

  return (
    <ConversationContainer>
      <ScrollToBottom className="conversationContainer">
        {content}
      </ScrollToBottom>
      <NewCommentBox
        type="text"
        name="name"
        placeholder="Type Message"
        placeholderTextColor="black"
        onChange={(e) => {
          if (!e.nativeEvent.data || e.target.value.trim()) handleChange(e);
          return e;
        }}
        onKeyUp={onKeyPress}
        ref={(el) => {
          textInput = el;
        }}
        autoComplete="off"
      />
    </ConversationContainer>
  );
};

export default Conversation;
