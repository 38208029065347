import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as datasetActions from "../../../../redux/actions/DataSetActions";
import { Select } from "components/shared/ui-components/form-components";
import { StepTitle } from "./StepTitle";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./TemplateCreation.scss";
import AddDataset from "components/DataSets/Form.js";

export const DatasetSelection = (props) => {
  const dispatch = useDispatch();
  const { currStep, form, setForm, setNextPageReady } = props;
  const { datasets } = useSelector((state) => state.dataSetReducer);

  const [showModal, setShowModal] = useState(false);
  const [showDataUploadModal, setShowDataUploadModal] = useState(false);

  useEffect(() => {
    if (form.dataset_ids.length > 0) {
      setNextPageReady(true);
    }
  }, [form, setNextPageReady]);

  const handleShowLudisDataset = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setShowRemoteDataset(false);
  };

  const handleCloseDataUploadModal = () => {
    setShowDataUploadModal(false);
  };

  const [showRemoteDataset, setShowRemoteDataset] = useState(false);
  const handleCloseRemoteDataset = (e) => {
    setForm({ ...form, dataset_ids: [e.id] });
    setShowModal(false);
    setShowRemoteDataset(false);
    dispatch(datasetActions.getDataSets()); // After using AddDataset component the variable datasets in dataSetReducer is wiped
  };

  const handleShowRemoteDataset = () => {
    setShowRemoteDataset(true);
    setShowModal(true);
  };

  const history = useHistory();

  const defaultForm = {
    name: "",
    description: " ",
    file: [],
  };

  useEffect(() => {
    dispatch(datasetActions.getDataSets());
  }, [dispatch]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setShowModal(false);
  };

  const getDatasetNames = (datasets) => {
    const datasetNames = [];
    for (const dataset of datasets) {
      datasetNames.push(dataset.name);
    }
    return datasetNames;
  };

  const getDatasetIds = (datasetName) => {
    const datasetIds = [];
    for (const dataset of datasets) {
      if (datasetName === dataset.name) {
        datasetIds.push(dataset.id);
      }
    }
    return datasetIds;
  };

  return (
    <div className="appInputPanel">
      <StepTitle currStep={currStep} />
      <div className=" appContentPanel publish_modal publish_select_btn">
        <div className="data-upload-and-reqs">
          {form.dataset_ids.length === 0 ? (
            <div className="data-upload-background">
              <div className="data-upload">
                <img
                  className="data-upload-ludis-image"
                  src={require("assets/custom-styles/images/thumbnails/LUDIS-icon.svg")}
                  alt="Not Found"
                ></img>
                Select from Ludis Datasets
                <button
                  className="data-upload-button"
                  onClick={handleShowLudisDataset}
                >
                  {" "}
                  Browse{" "}
                </button>
              </div>
              <div className="data-upload">
                <img
                  className="data-uploadLudisImage"
                  src={require("assets/custom-styles/images/icons/laptop.svg")}
                  alt="Not Found"
                ></img>
                Upload from my local device
                <button
                  className="data-upload-button"
                  onClick={handleShowRemoteDataset}
                >
                  {" "}
                  Browse{" "}
                </button>
              </div>
            </div>
          ) : (
            <div className="data-uploaded">
              <div className="data-uploaded-text">
                <img
                  src={require("assets/custom-styles/images/thumbnails/greenCheck.svg")}
                  alt=""
                ></img>
                {datasets
                  ? <div style={{width:'max-content'}}>{datasets.find((o) => o.id === form.dataset_ids[0]).name}</div>
                  : ""}
              </div>
              <div className="data-uploaded-reselect">
                <button
                  className="data-upload-button"
                  onClick={handleShowLudisDataset}
                >
                  {" "}
                  Re-Select Dataset{" "}
                </button>
                <button
                  className="data-upload-button"
                  onClick={handleShowRemoteDataset}
                >
                  {" "}
                  Re-Upload Dataset{" "}
                </button>
              </div>
            </div>
          )}
          <div className="data-reqs">
            <div className="data-reqs-title">
              <div className="data-reqs-title-text">Data Guidelines</div>
              <div className="data-reqs-title-text-sub">
                Please make sure your data follows the guidelines of the sample
                data below:
              </div>
            </div>
            <div className="data-reqs-content">
              <div className="data-reqs-column">
                <img
                  src={require("assets/custom-styles/images/thumbnails/greenCheck.svg")}
                  alt=""
                ></img>
                <div
                  className="data-reqs-column-text"
                  style={{ color: "var(--accent-color-1, #338BFF)" }}
                >
                  Name
                </div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                >
                  Leonardo Moines
                </div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#F8FBFF" }}
                >
                  Theodore Martinez
                </div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                >
                  Jesus Utah
                </div>
              </div>
              <div className="data-reqs-column">
                <img
                  src={require("assets/custom-styles/images/thumbnails/greenCheck.svg")}
                  alt=""
                ></img>
                <div
                  className="data-reqs-column-text"
                  style={{ color: "var(--accent-color-1, #338BFF)" }}
                >
                  Count
                </div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                >
                  10
                </div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#F8FBFF" }}
                >
                  12
                </div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                >
                  8.3
                </div>
              </div>
              <div className="data-reqs-column">
                <img
                  src={require("assets/custom-styles/images/thumbnails/redX.svg")}
                  alt=""
                  style={{ background: "#FFF" }}
                ></img>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                >
                  :
                </div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                >
                  /
                </div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                >
                  ?
                </div>
              </div>
              <div className="data-reqs-column">
                <img
                  src={require("assets/custom-styles/images/thumbnails/redX.svg")}
                  alt=""
                  style={{ background: "#FFF" }}
                ></img>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                ></div>
                <div
                  className="data-reqs-column-text"
                  style={{ background: "#FFF" }}
                ></div>
              </div>
            </div>
            <div className="data-reqs-content-label">
              <div className="data-reqs-content-label-column">
                Names can only be strings
              </div>
              <div className="data-reqs-content-label-column">
                Counts can be integers or doubles
              </div>
              <div className="data-reqs-content-label-column">
                No Special Characters
              </div>
              <div className="data-reqs-content-label-column">
                No Blank Spaces
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="add_dir_modal"
          size="md"
          show={showModal}
          onHide={handleCloseModal}
          animation={true}
          scrollable={true}
        >
          <Modal.Title>
            {showRemoteDataset === true
              ? "Upload a Dataset"
              : "Select a Dataset"}
          </Modal.Title>
          <Modal.Body className="data-upload-modal">
            {showRemoteDataset === true ? (
              <div>
                <AddDataset
                  defaultForm={defaultForm}
                  history={history}
                  handleCloseRemoteDataset={handleCloseRemoteDataset}
                ></AddDataset>
              </div>
            ) : (
              <div>
                {datasets && (
                  <Select
                    placeholder="Select Dataset..."
                    name="dataset_ids"
                    options={getDatasetNames(datasets)}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          value: getDatasetIds(e.value),
                          name: "dataset_ids",
                        },
                      });
                      setNextPageReady(true);
                      setShowDataUploadModal(true);
                    }}
                    required
                  />
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>

        {
          <Modal
            className="add_dir_modal"
            size="m"
            show={showDataUploadModal}
            onHide={handleCloseDataUploadModal}
            animation={true}
            scrollable={true}
          >
            <Modal.Title></Modal.Title>
            <Modal.Body
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              Data Uploaded Successfully!
              <Button
                type="submit"
                className="btn"
                onClick={handleCloseDataUploadModal}
              >
                OK
              </Button>
            </Modal.Body>
          </Modal>
        }
      </div>
    </div>
  );
};
