import "../ClusteringTemplate/ClusteringTemplate.css";
import React from "react";
import * as d3 from 'd3'

function DataInsight(data, columnToAverage, k) {
    // Calculate the average of the selected column
    let columnValues = data.filter((e) => e.k === k).map(row => row["mean"]);
    let currentData
    if (k === "ALL")
        if (columnToAverage === "mean") {
            currentData = d3.mean(columnValues)
        } else if (columnToAverage === "min") {
            currentData = d3.min(columnValues)
        } else {
            currentData = d3.max(columnValues)
        }
    else {

        currentData = d3.mean(columnValues.slice(-1))
    }

    let percentChange = ((currentData - columnValues[0]) / currentData) * 100;

    return(
        [percentChange, currentData]
    )
}
let metrics = [0,0]
export function ValueBox(
    {plotData, axis, forecast, k}
) {
    if (plotData && axis) {
        metrics = DataInsight(plotData, axis, k)
    }

    return(
        <div className="summaryBox">
            <div className="summaryBoxTitle">
                {axis.toUpperCase()}
            </div>
            {plotData
            ?
            <>
                <div className="summaryBoxBody">
                    {
                        metrics[1] !== undefined ?metrics[1].toFixed(2) :''
                    }
                </div>
                {!forecast
                ?
                <>
                    {/*<div className="summaryBoxFooter">
                        <div className={metrics[0] > 0 ?"percentChangePos":metrics[0] < 0 ?"percentChangeNeg":"percentChangeNeutral"}>
                            {
                                    metrics[0] > 0 ?<img src={require("assets/custom-styles/images/templates/arrow-up.svg")} alt="" className="percentChangeIcon"/>
                                    :metrics[0] < 0 ?<img src={require("assets/custom-styles/images/templates/arrow-down.svg")} alt="" className="percentChangeIcon"/>
                                    :<img src={require("assets/custom-styles/images/templates/arrow-neutral.svg")} alt="" className="percentChangeIcon"/>
                            }
                            <div className="percentChangeNumber">
                                {metrics[0].toFixed(2)}%
                            </div>
                        </div>
                        <div className="percentChangeLabel">
                            Than last period
                        </div>
                    </div>*/}
                </>
                :<div className="summaryBoxFooter"></div>
                }
            </>
            :<div className="loading"> TBA </div>
            }
        </div>
    )
}

export default ValueBox