import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { Button } from "components/shared/ui-components/form-components";
import { updateDataSet } from "redux/actions/DataSetActions";

const EditDatasetForm = ({ defaultForm, history }) => {
  const [datasetForm, setDatasetForm] = useState(defaultForm);
  const { dataSet } = useSelector((state) => state.dataSetReducer);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDatasetForm({ ...datasetForm, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateDataSet({
        body: {
          entity: {
            name: datasetForm.name,
            description: datasetForm.description,
          },
        },
        id: dataSet.id,
      })).then((res) => {
      history.goBack();
    });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <div className="lds_right_box_wrapper">
        <div className="lds_cp_heading">
          <Link
            to={`/datasets/${dataSet.id}`}
            className="cursor-pointer float-left"
          >
            <i className="flaticon-left-arrow"></i> Back
          </Link>
        </div>
        <div className="lds_cp_box">
          <div className="row">
            <div className="col-md-12">
              <div className="lds_cp_box_heading">
                <h4>Name</h4>
                <input
                  type="text"
                  name="name"
                  required
                  autoFocus
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                  value={datasetForm.name}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_second">
                <h4>Description</h4>
                <textarea
                  rows="4"
                  name="description"
                  required
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                  value={datasetForm.description}
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_third">
                <Button className="btn btn-primary" type="submit">
                  Update Dataset
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default EditDatasetForm;
