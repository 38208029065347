export const ClusterActionTypes = {
  GET_CLUSTERS: "GET_CLUSTERS",
  GET_CLUSTERS_PENDING: "GET_CLUSTERS_PENDING",
  GET_CLUSTERS_FULFILLED: "GET_CLUSTERS_FULFILLED",

  GET_CLUSTER: "GET_CLUSTER",
  GET_CLUSTER_PENDING: "GET_CLUSTER_PENDING",
  GET_CLUSTER_FULFILLED: "GET_CLUSTER_FULFILLED",

  CLEAR_CLUSTER: "CLEAR_CLUSTER",

  CLEAR_CLUSTERS: "CLEAR_CLUSTERS",
}