import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import ImportError from './ImportError';
import { Skeleton } from "@mui/material";

const StatusCell = ({ workflow }) => {
  const [showError, setShowError] = useState(false);

  const statusClass = `status-cell status-${workflow?.status}`;

  const toggleErrorModal = () => {
    setShowError(!showError);
  };

  return (
    <TableCell className={`${statusClass} custom-table-cell`}>
      {workflow ? (
        <div
          className="status-content"
          onClick={workflow?.status === "broken" ? toggleErrorModal : null}
          style={{ cursor: workflow?.status === "broken" ? 'pointer' : 'default' }}
        >
          {workflow?.status}
          {
            workflow?.status === "broken" && 
            <ImportError workflow={workflow} 
              showError={showError}
              toggleErrorModal={toggleErrorModal}
            />
          }
        </div>
      ) : (
        <Skeleton size={30}></Skeleton>
      )}

    </TableCell>
  );
};

export default StatusCell;
