import React from "react";

import TwoFactorAuthForm from "../../../components/Auth/TwoFactorAuth/TwoFactorAuthForm";

const TwoFactorAuth = ({ history }) => {
  return (
    <TwoFactorAuthForm history={history}></TwoFactorAuthForm>
  )
}

export default TwoFactorAuth;