import React from "react";
// text area component

export const TextArea = ({
    ...props
    }) => {
  return (
      <div className="lds_login_form_input">
        <textarea
            {...props}
            />
      </div>
  );
};