import { NotebookActionTypes } from "./actionType";
import * as API from "../../../api/NotebookAPI";
//
//function getHistory() {
//    const storeState = store.getState();
//    const history = storeState.historyReducer.history;
//    return history;
//}

export const getNotebooks = () => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.GET_NOTEBOOKS,
    payload: API.getNotebooks()
  });
export const getNotebook = ({ id }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.GET_NOTEBOOK,
    payload: API.getNotebook(id)
  });

export const openNotebook = ({ id }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.OPEN_NOTEBOOK,
    payload: API.openNotebook(id)
  });

export const createNotebook = ({ body }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.CREATE_NOTEBOOK,
    payload: API.createNotebook(body)
  });

export const importNotebook = ({ body }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.IMPORT_NOTEBOOK,
    payload: API.importNotebook(body)
  });

export const deleteNotebook = ({ id }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.DELETE_NOTEBOOK,
    payload: API.deleteNotebook(id),
    params: { id }
  });

export const previewNotebook = ({ id }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.PREVIEW_NOTEBOOK,
    payload: API.previewNotebook(id)
  });

export const updateNotebook = ({ body, id }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.UPDATE_NOTEBOOK,
    payload: API.updateNotebook(body, id)
  });

export const commitChanges = ({ body, id }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.COMMIT_NOTEBOOK,
    payload: API.commitChanges(body, id)
  });

export const generateDevEnvironment = ({ userId }) => (dispatch) =>
  dispatch({
    type: NotebookActionTypes.GENERATE_DEV_ENVIRONMENT,
    payload: API.generateDevEnvironment(userId)
  });
