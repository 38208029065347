import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import * as actions from "../../../redux/actions/DataSetActions";
import { Tooltip, Typography } from "@material-ui/core";

const CreateDirectoryModal = ({ accessLevel }) => {
  const { directoryFiles, dataSet } = useSelector((state) => state.dataSetReducer);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [folderName, setFolderName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const inputRef = useRef();

  const addDirectory = () => {
    let currentPath = directoryFiles.Prefix;
    dispatch(
      actions.createDataSetDirectory({
        body: { path: `${currentPath}${folderName}/` },
      })
    ).then((res) => {
      dispatch(
        actions.getDataSetFiles({
          body: {
            path: currentPath,
          },
          id: dataSet.id
        })
      );
      setFolderName("");
      handleClose();
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    addDirectory();
  };

  const handleChange = (e) => {
    setFolderName(e.target.value);
  };

  return (
    <>
      <Tooltip title={accessLevel === "view" ? <Typography>You need edit access</Typography> : <Typography>Add Folder</Typography>} placement="top" arrow>
        <span>
          <img
            alt=""
            src={require("assets/custom-styles/images/thumbnails/new_dir_dark_icn.png")}
            onMouseOver={(e) => {
              if (accessLevel !== "view") {
                (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/new_dir_light_icn.png"))
              }
            }}
            onMouseLeave={(e) => {
              if (accessLevel !== "view") {
                (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/new_dir_dark_icn.png"))
              }
            }}
            height="60px"
            className={ accessLevel === "view" ? "disabled-dataset-icon fld_icn" : "cursor-pointer fld_icn"}
            onClick={handleShow}
          />
        </span>
      </Tooltip>

      <Modal
        className="add_dir_modal"
        size="md"
        show={show}
        onHide={handleClose}
        animation={true}
        scrollable={true}
        onEntered={() => inputRef.current.focus()}
      >
        {" "}
          <Modal.Body className="float-left">
          <form onSubmit={onSubmit}>
            <div className="col-md-12">
              <div className="publish_modal">
                <h4>Folder Name</h4>
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Type Here"
                  value={folderName}
                  name="folder name"
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                />
              </div>
            </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={onSubmit} className="btn">
              Create Folder
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateDirectoryModal;
