import { ClusterActionTypes } from "../actions/ClusterActions/ActionType";

export const initialState = {
  clusters: [],
  cluster: null,
  clusterCount: 0
}

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const clusterReducer = (state = initialState, action) => {

  let clusters;
  switch (action.type) {
    case ClusterActionTypes.GET_CLUSTERS_PENDING:
      return updateObject(state, {});
    case ClusterActionTypes.GET_CLUSTERS_FULFILLED:
      clusters = [...state.clusters];
      if (clusters.length < action.payload.clusterCount) {
        clusters.push(...action.payload.clusters);
      }
      return updateObject(state, { clusters: clusters, clusterCount: action.payload.clusterCount });
    case ClusterActionTypes.GET_CLUSTER_PENDING:
      return updateObject(state, {});
    case ClusterActionTypes.GET_CLUSTER_FULFILLED:
      return updateObject(state, { cluster: action.payload.cluster });
    case ClusterActionTypes.CLEAR_CLUSTER:
      return updateObject(state, { cluster: null });
    case ClusterActionTypes.CLEAR_CLUSTERS:
      return updateObject(state, { clusters: [] });
    default:
      return state;
  }
}