import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as projectActions from "../../../redux/actions/ProjectsActions";
import * as llmActions from "../../../redux/actions/LLMActions"
import { getContainerLogsWebSocketUrl } from "utility/websocketUrls";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { AutoAwesomeOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { trimSpacesAfterNewlineCharacters } from "../../../utility/utility";
import * as commonService from "../../../utility/CommonService";


const ContainerLogs = ({ summaryClickedContext, areLogsVisible, entityId, entityType, containerId, logWindow }) => {
  const dispatch = useDispatch();
  const {
    summaryClicked,
    handleSummaryClicked,
  } = useContext(summaryClickedContext);
  const { previewLogs } = useSelector((state) => state.projectsReducer)
  const { user } = useSelector((state) => state.authReducer)
  const logsContainerRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState("down");
  const summary = useSelector((state) => state.llmReducer.summary)
  const [showSummary, setShowSummary] = useState(false)

  const closeSummary = () => {
    setShowSummary(false);
    dispatch(llmActions.clearLogSummary())
  }

  const handleLogSummary = (logs) => {
    handleSummaryClicked(true);
    setShowSummary(true);
    dispatch(llmActions.createLogSummary(entityId, {
      logs: logs.current,
      entity: entityType
    })).then((res) => {
      if (!res?.value?.success) {
        commonService.forError("Could not retrieve log summary.", "Error");
      }
      handleSummaryClicked(false);
    })
  }

  useEffect(() => {
      const socketUrl = getContainerLogsWebSocketUrl(containerId, user.uid)
      if (!socketUrl)
        return
      const socket = new WebSocket(socketUrl);
      socket.onopen = () => {
        dispatch(projectActions.reappendContainerLogs())
        logWindow.current = [];
      }
      socket.onmessage = (event) => {
        dispatch(projectActions.appendContainerLogs(event.data))
        if (logWindow.current.length < 99) {
          logWindow.current.push(event.data);
        } else {
          logWindow.current.shift()
          logWindow.current.push(event.data);
        }
      };
      return () => {
        // Close the WebSocket connection when the component is unmounted
        if (socket.readyState === WebSocket.OPEN) {
          socket.close();
          logWindow.current = [];
        }
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, containerId, logWindow, user.uid]
  );

  const handleScrollButtonClick = () => {
    if (logsContainerRef.current) {
      if (scrollDirection === "down") {
        logsContainerRef.current.scrollTo({
          top: logsContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
        setScrollDirection("up");
      } else {
        logsContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setScrollDirection("down");
      }
    }
  };

  // Handle scroll event to dynamically change button text
  const handleScroll = () => {
    if (logsContainerRef.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight
      } = logsContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setScrollDirection("up");
      } else if (scrollTop === 0) {
        setScrollDirection("down");
      }
    }
  };

  return (
    <div
      className={showSummary && summary ? "container_logs_text show_summary" : "container_logs_text"}
      ref={logsContainerRef}
      onScroll={handleScroll}
      style={{ maxHeight: "90vh", overflowY: "auto" }}
    >
      <div className="logs">
        {previewLogs && containerId ? previewLogs : "Loading..."}
        {
          areLogsVisible && showSummary && summary &&
          <div className="summary_text_box">
            <div className="summary_header_row">
              <div className="summary_header">Summary</div>
              <CloseIcon
                onClick={() => closeSummary()}
              />
            </div>
            {trimSpacesAfterNewlineCharacters(summary)}
          </div>
        }
        {areLogsVisible &&
          <button className="summary_button"
                disabled={summaryClicked === true}
                onClick={() => handleLogSummary(logWindow)}>
            <div className="summary_button_container">
              <AutoAwesomeOutlined></AutoAwesomeOutlined>
              {
                summaryClicked ? (
                  <div className="summary_button_text">Loading...</div>) : (
                  <div className="summary_button_text">Summary</div>
                )
              }
            </div>
          </button>
        }
        {
          areLogsVisible &&
            <button onClick={handleScrollButtonClick} className="scroll-button">
              {scrollDirection === "down" ? <ArrowDownwardIcon /> :
                <ArrowUpwardIcon />
              }
            </button>
        }
      </div>
    </div>
  );
};

export default ContainerLogs;
