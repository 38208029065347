import axios from "../config";
import { API_VERSION } from "config";

export const getStarMap = (params) =>
  axios.get(`${API_VERSION}/web3/starmap`, { params });

export const getCollections = () =>
  axios.get(`${API_VERSION}/web3/collections`);

export const addCollection = (body, contractAddress) =>
  axios.post(`${API_VERSION}/web3/addCollection/${contractAddress}`, body)

export const validateContract = (body, contractAddress) =>
  axios.post(`${API_VERSION}/web3/validateContract/${contractAddress}`, body)

export const getBubbleWrap = (params) =>
    axios.get(`${API_VERSION}/web3/bubblewrap`, { params });
