export const userActionTypes = {
  
    GET_USERS: "GET_USERS",
    GET_USERS_PENDING: "GET_USERS_PENDING",
    GET_USERS_FULFILLED: "GET_USERS_FULFILLED",

    CLEAR_USERS: "CLEAR_USERS",

    GET_USER: "GET_USER",
    GET_USER_PENDING: "GET_USER_PENDING",
    GET_USER_FULFILLED: "GET_USER_FULFILLED",

    CREATE_USER: "CREATE_USER",
    CREATE_USER_PENDING: "CREATE_USER",
    CREATE_USER_FULFILLED: "CREATE_USER_FULFILLED",

    UPDATE_USER: "UPDATE_ORGANIZATION_USER",
    UPDATE_USER_PENDING: "UPDATE_ORGANIZATION_USER_PENDING",
    UPDATE_USER_FULFILLED: "UPDATE_ORGANIZATION_USER_FULFILLED",

    DELETE_USER: "DELETE_USER",
    DELETE_USER_PENDING: "DELETE_USER_PENDING",
    DELETE_USER_FULFILLED: "DELETE_USER_FULFILLED",

}
