import React from "react";
import { TextTruncate } from "utility/CommonService";
import "./TemplateCreation.scss";

export const TemplateImage = (props) => {
	const { item } = props;

	return (
        <div>
            <figure className="templatePics templatePicsf">
            <img
                src={item.img}
                srcSet={item.img}
                alt={item.title}
                loading="lazy"
            />
            </figure>
            <div className="textOverlay">
                <div className="appInfoWrapper">
                    <div className="appTitle">
                    <h4>{TextTruncate({text: item.title, limit: 22})}</h4>
                    </div>
                </div>
            </div>
        </div>
	);
};
