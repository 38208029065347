import React from "react";
import { Prompt } from "react-router-dom";
import { checkHasUnsavedChanges } from "utility/utility";
import { useSelector } from "react-redux";

const UnsavedChangesPrompt = () => {
  const { projectFilesContent } = useSelector((state) => state.projectsReducer);
  return (
    <Prompt
      when={checkHasUnsavedChanges(projectFilesContent)}
      message="You have unsaved changes, are you sure you want to leave?"
    />
  );
};

export default UnsavedChangesPrompt;
