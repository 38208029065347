import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  reStartAppShare,
  stopAppShare,
} from "../../../redux/actions/AppActions";
import { Link } from "react-router-dom";
import {
  ShareEntityModal
} from "../../shared/Modals/ShareEntityModal/ShareEntityModal";
import { EditEntityModal } from "../../shared/Modals/EditEntityModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExternalIcon from "@mui/icons-material/Outbound";
import Tooltip from "@mui/material/Tooltip";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  ACCESS_LEVEL,
  APP_STATUS_CONSTANTS,
  AWS_CONSTANTS,
  ENTITY_TYPES,
} from "utility/constants/constants";
import {
  createAppShare,
  getInsights,
  updateApp,
} from "redux/actions/AppActions";
import { Storage } from "aws-amplify";
import { TextTruncate } from "utility/CommonService";
import { ReplyOutlined } from "@mui/icons-material";
import { Modal } from "react-bootstrap";
import * as datasetActions from "redux/actions/DataSetActions";

export const AppTile = ({
                          app,
                          deleteApp,
                          index,
                          handlePin,
                          isPinned,
                          accessLevel
                        }) => {
  const [show, setShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loaded, setLoaded] = useState(false)
  const [checked, setcheck] = useState(false);
  const dispatch = useDispatch();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [appTileImg, setAppTileImg] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  // only runs on initial render of component
  useEffect(() => {
    const renderAppTileImg = () => {
      const getLegacyAppImg = () => {
        const images = [
          require("assets/custom-styles/images/thumbnails/id1.jpg"),
          require("assets/custom-styles/images/thumbnails/id2.jpg"),
          require("assets/custom-styles/images/thumbnails/id3.jpg"),
          require("assets/custom-styles/images/thumbnails/id5.jpg"),
        ];

        return images[Math.abs(app.id.hashCode() % 4)];
      };

      //render img for app tile
      if (app.img_path?.includes(AWS_CONSTANTS.USER_IMG_PATH)) {
        Storage.get(app.img_path).then((res) => {
          setAppTileImg(res);
        });
      } else if (app.img_path) {
        setAppTileImg(app.img_path);
      } else {
        setAppTileImg(getLegacyAppImg());
      }
    };
    renderAppTileImg();
    if (!loaded) {
      if (app.status === APP_STATUS_CONSTANTS.RUNNING) {
        setcheck(true);
      } else {
        setcheck(false);
      }
      setLoaded(true);
    }
  }, [loaded, app.status, app.img_path, app.id]);

  const handleCheck = (e) => {
    if (checked) {
      setcheck(false);
      dispatch(
        stopAppShare({
          publish_filenames: [app.name],
          application_id: app.id,
        })
      );
    } else {
      setcheck(true);
      handleRefresh();
    }
  };

  // refresh will restart the app on the backend
  const handleRefresh = () => {
    dispatch(
      reStartAppShare({
        publish_filenames: [app.name],
        application_id: app.id,
      })
    );
  };
  const isLudis = () => {
    return app.isLudis;
  };
  const isExternal = () => {
    return app.status === APP_STATUS_CONSTANTS.EXTERNAL;
  };

  const getName = () => {
    // replace all '_' with ' '
    return app.name.replace(new RegExp("_", "g"), " ");
  };

  const menuOpen = Boolean(menuAnchor);
  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchor(false);
  };

  const renderLink = () => {
    if (app.app_type === "user_report") {
      return (
        <Link to={"/"} onClick={(e) => getUrl(e)} key={index}>
          <div>
            <div className="home_card home_card_id home_card_ia">
              <figure className="gp__pics gp__picsf">
                <img src={appTileImg} className="inner_image" alt="graph" />
              </figure>
              <div className="lds_ia_cont">
                <div className="app_info_wrapper">
                  <div className="app_title">
                    <h4>{TextTruncate({text: getName(), limit: 22})}</h4>
                    <p>{TextTruncate({text: app.description, limit: 25})}</p>
                  </div>
                  {app && (
                      <div className="views_count">
                         <Tooltip title={`${app.userMetrics} View${app.userMetrics > 1 ? 's' : ''}`} placement="top">
                          <VisibilityIcon />
                        </Tooltip>
                        <span>{app.userMetrics}</span>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    }
    //if not a report then open file
    else {
      return (
        <Link to={`app/${app.id}`} key={index}>
          <div>
            <div className="home_card home_card_id home_card_ia">
              <figure className="gp__pics gp__picsf">
                <img src={appTileImg} className="inner_image" alt="graph" />
              </figure>
              <div className="lds_ia_cont">
                <div className="app_info_wrapper">
                  <div className="app_title">
                    <h4>{TextTruncate({text: getName(), limit: 22})}</h4>
                    <p>{TextTruncate({text: app.description, limit: 25})}</p>
                  </div>
                  {app && (
                    <div className="views_count">
                      <Tooltip title={`${app.userMetrics} View${app.userMetrics > 1 ? 's' : ''}`} placement="top">
                        <VisibilityIcon />
                      </Tooltip>
                      <span>{app.userMetrics}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    }
  };

  //get Access Url for resource from S3
  const getUrl = (e) => {
    e.preventDefault();
    setShow(true);
    setLoaded(true);
    let fileKey = app.app_url;
    dispatch(
      datasetActions.getPresignedUrl({
        body: {path: `${fileKey}`},
      })
    ).then((res) => {
      setPdfUrl(res.value.geturl);
    });
  };

  const handleClose = () => {
    setShow(false);
    setLoaded(false);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="tile_header">
          <div className="checbox_wrapper d-inline-block float-left">
            {/* Render on off switch if user has access */}
            {
              ACCESS_LEVEL.ALL_EXECUTE.includes(accessLevel) && !isExternal() &&
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`customSwitch_${app.id}`}
                  onChange={(e) => [
                    handleCheck(e)
                  ]}
                  checked={checked}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customSwitch_${app.id}`}
                ></label>
              </div>
            }
          </div>
          {/* Render restart button if user has access */}
          {ACCESS_LEVEL.ALL_EXECUTE.includes(accessLevel) && !isExternal() &&
            <div
              className="icon_refresh cursor-pointer d-inline-block"
              style={{color: "#ccc6c6"}}
            >
              <i
                onClick={handleRefresh}
                className="fa fa-refresh"
                aria-hidden="true"
              ></i>
            </div>}
          {/* Ludis owned apps */}
          {isLudis() && (
            <div className="show__tool_left ludis_app_logo">
              <img
                src={require("assets/custom-styles/images/thumbnails/favicon.ico")}
                alt="graph"
                className="show__tool_left"
              />
            </div>
          )}
          {/* External Apps */}
          {isExternal() && (
            <div className="show__tool_left external_app_icon">
              <ExternalIcon />
            </div>
          )}
          <div className="show__tool">
            <Tooltip title={isPinned ? "Unpin" : "Pin"} placement="top">
              <StarIcon
                className={isPinned ? "show_pin_app" : "hide_pin_app"}
                onClick={(e) => handlePin(app)}
              />
            </Tooltip>
          </div>
          {
            accessLevel === ACCESS_LEVEL.EDIT && (
              <Tooltip title="More" placement="top">
                <IconButton
                  className="app_menu mr-2"
                  aria-label="more"
                  id="long-menu-button"
                  aria-controls={menuOpen ? 'long-menu' : undefined}
                  aria-expanded={menuOpen ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>
            )
          }
          <Menu id="long-menu-button"
                disableScrollLock={true}
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
          >
            <MenuItem
              dense={true}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowEditModal(true);
                handleMenuClose();
                e.stopPropagation();
              }}
            >
              <ListItemIcon className="edit">
                <div className="entity_menu_item">
                  <img
                    src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                    alt="Edit Application"
                    className="cursor-pointer optionsIcon"
                  />
                  <p>Edit</p>
                </div>
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              dense={true}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowShareModal(true);
                handleMenuClose();
                e.stopPropagation();
              }}
            >
              <ListItemIcon className="share">
                <div className="entity_menu_item">
                  <ReplyOutlined
                    sx={{
                      transform: "scaleX(-1)",
                      width: "25px",
                      height: "25px",
                    }}
                  />
                  <p>Share</p>
                </div>
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                deleteApp(app);
                handleMenuClose();
              }}
            >
              <ListItemIcon className="delete">
                <DeleteIcon fontSize="small" />
                <p>Delete</p>
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </div>
        <h4>{renderLink()}</h4>
        <Modal
          id="s3_object_modal"
          className="s3_object_modal"
          size="xl"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>File Preview</Modal.Header>
          {loaded && (
            <iframe
              title="Pdf Import Preview"
              id="pdf_url_preview_modal"
              src={pdfUrl}
              frameBorder="0"
              width="100%"
              height="1000"
            />
          )}
        </Modal>
      </div>
      <EditEntityModal
        entity={app}
        entityType={ENTITY_TYPES.APPLICATION}
        updateEntity={updateApp}
        handleMenuClose={handleMenuClose}
        imgSrc={appTileImg}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
      />
      <ShareEntityModal
        entity={app}
        entityType={ENTITY_TYPES.APPLICATION}
        createEntityShare={createAppShare}
        getEntities={getInsights}
        handleMenuClose={handleMenuClose}
        showModal={showShareModal}
        setShowModal={setShowShareModal}
      />
    </>
  );
};
