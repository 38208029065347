import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as actions from "redux/actions/ProjectActions";
import EditProjectForm from "components/Projects/Form";

const EditForm = ({history}) => {
  const dispatch = useDispatch();
  const {project, datasets} = useSelector(state => state.projectReducer);

  const [loaded, setLoaded] = useState(false);
  const handleSubmit = (form) => {
   const {description, type, size, author, dataset_ids} = form;
    dispatch(actions.updateProject({body: {project: {description, type, size, author, dataset_ids}}, id: form.id})).then(res => {
      if (res.value.success){
        history.push("/projects")
      }
    });
  };
  //effect for get project details
  useEffect(() => {
    let id = history.location.pathname.split("/")[2];
    if (!loaded) {
      setLoaded(true);
      if ((!project || project.id !== parseInt(id))) dispatch(actions.getProject({id}));
      //if (!datasets) dispatch(actions.datasets());
    }
  },[loaded, datasets, project, dispatch, history]);

  return(
      <>{
        project && <EditProjectForm handleSubmit={handleSubmit} defaultForm={project} datasets={datasets} history={history}/>
      }</>
  )
};

export default EditForm
