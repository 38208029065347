import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form as FormBootstrap } from "react-bootstrap";
import { forEach, isEmpty } from "lodash";

import * as commonService from "utility/CommonService.js";
import { updateFlighting } from "../../redux/actions/FlightingActions/action";

import {
  Select,
  Button,
} from "components/shared/ui-components/form-components";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FlightingForm = ({ organizationId }) => {
  const { user } = useSelector((state) => state.authReducer);
  const history = useHistory();
  let backPath =
    user.role === "superadmin"
      ? `/organizations/${organizationId}/users`
      : "/manage-organizations";
  const { flightingConfig, configOptions } = useSelector(
    (state) => state.flightingReducer
  );
  let userRoles = configOptions.user_roles
    ? configOptions.user_roles.filter(
        (e) => e !== "administrator" && e !== "superadmin"
      )
    : ["developer", "management"];
    if(user.role === "superadmin") userRoles.push("administrator");

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [selectedRole, setRole] = useState("developer");

  const [flightingChanges, setFlightingChanges] = useState([]);
  const onFormChange = (e, role) => {
    const tempFlights = [...flightingConfig];
    const index = tempFlights.findIndex((f) => f.key === e.target.id);
    const currentFlight = { ...tempFlights[index] };
    if (e.target.checked) currentFlight.user_roles.push(role);
    else {
      const roleIndex = currentFlight.user_roles.indexOf(role);
      currentFlight.user_roles.splice(roleIndex, 1);
    }
    tempFlights[index] = currentFlight;
    setFlightingChanges(tempFlights);
  };

  const returnFeatureCheckBoxes = (role) => {
    const features = [];
    if (!isEmpty(flightingConfig)) {
      forEach(flightingConfig, (feature) => {
        if (feature.key) {
          const { user_roles, key, name, active } = feature;
          if (active)
            features.push(
              <div key={`${role}${key}`} className="mb-3">
                <FormBootstrap.Check
                  defaultChecked={user_roles.includes(role)}
                  inline
                  type="checkbox"
                  id={key}
                  label={name}
                />
              </div>
            );
        }
        return null;
      });
      return (
        <FormBootstrap
          flexDirection="row"
          onChange={(e) => onFormChange(e, role)}
        >
          {features}
        </FormBootstrap>
      );
    }
    return null;
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      const tempData = [...flightingConfig];
      setFlightingChanges(tempData);
    }
  }, [flightingConfig, loaded]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateFlighting(user.organization, { flightings: flightingChanges })
    );
    commonService.forSuccess("Flighting configuration updated!", "Success");
  };
  return (
    <div className="lds_right_box_wrapper">
      <form onSubmit={handleSubmit}>
        <div className="lds_cp_heading mb-4" >
          <BreadCrumb currentLoc={"Flighting Configuration"} previousLoc={"Admin Page"} goBack={() => history.push(backPath)} />
        </div>
        <div className="lds_cp_box">
          <div className="row">
            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <Select
                  placeholder="Please select the role"
                  name="role"
                  options={userRoles}
                  onChange={(e) => setRole(e.value)}
                  value={{ label: selectedRole, value: selectedRole }}
                  required
                />
              </div>
            </div>
            <div class="col-md-12" id="advancedSettings">
              <div
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <h4 className="mb-2 text-capitalize">
                  {selectedRole} Flighting Configuration{" "}
                </h4>
                {returnFeatureCheckBoxes(selectedRole)}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_third">
                <Button type="submit" className="btn">
                  Apply Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default FlightingForm;
