import React from "react";
import { BubbleWrap } from "./Bubblewrap";
import styles from "./bubblewrap.module.css";

export function BubblewrapGenerator({ nodesData}) {
  const containerRef = React.useRef(null);
  
  React.useEffect(() => {
    let destroyFn;
    
    if (containerRef.current) {
      const { destroy } = BubbleWrap(containerRef.current, nodesData);
      destroyFn = destroy;
    }
    // destroy current graph and rerender with new data
    return destroyFn; 
  }, [nodesData]);

  return <div ref={containerRef} className={styles.container} />;
}