import React, { useEffect, useState } from "react";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as commonService from "utility/CommonService.js";

const RunTaskConfigOptions = ({
  selectedNode,
  setFormData,
  formData,
  handleSave,
  handleDelete,
  fileOptions,
  dockerImageOptions,
}) => {
  const [save, setSave] = useState(false)
  const [loadFormdata, setLoadFormData] = useState(false)

  useEffect(() => {
    if (selectedNode.data.taskData.length !== 0) {
      setFormData(selectedNode.data.taskData);
    } else {
      setFormData({
        fileName: "",
        image: "",
        installScript: "",
      });
    }
    setLoadFormData(true)
  }, [setFormData, selectedNode]);

  useEffect(() => {
    if (save) {
      handleSave();
    }
    setSave(false)
  }, [save, handleSave])

  return (
    <div>
      <div className="header">
        <div>Run Script</div>
        <div className="delete" onClick={handleDelete}>
          <DeleteOutline></DeleteOutline> Delete
        </div>
      </div>
      <div className="content">
        {formData && loadFormdata && (
          <div className="options">
            <div>
              File Name <span className="required-field"></span>
              <Autocomplete
                freeSolo
                disableClearable
                options={fileOptions.filter((obj) => {
                  const lower = obj.toLowerCase();
                  return (
                    lower.endsWith(".r") ||
                    lower.endsWith(".py") ||
                    lower.endsWith(".rmd")
                  );
                })}
                renderInput={(params) => <TextField {...params} />}
                value={formData.fileName}
                onClose={(event, reason) => {
                  const content =
                    reason === "selectOption"
                      ? event.target.textContent
                      : event.target.value;
                  if (
                    content.toLowerCase().endsWith(".r") ||
                    content.toLowerCase().endsWith(".py") ||
                    content.toLowerCase().endsWith(".rmd")
                  ) {
                    setFormData({ ...formData, fileName: content });
                    setSave(true);
                  } else {
                    commonService.forError(
                      "File must end in .r, .py, or .rmd",
                      "Error"
                    );
                  }
                }}
              />
            </div>
            <div>
              Environment (.r .py)
              <Autocomplete
                disableClearable
                options={dockerImageOptions}
                renderInput={(params) => <TextField {...params} />}
                value={formData.image}
                onInputChange={(event, newValue) => {
                  setFormData({ ...formData, image: newValue });
                  setSave(true);
                }}
              />
            </div>
            <div>
              Install Script
              <Autocomplete
                disableClearable
                options={fileOptions.filter((obj) => obj.endsWith(".sh"))}
                renderInput={(params) => <TextField {...params} />}
                value={formData.installScript}
                onInputChange={(event, newValue) => {
                  setFormData({ ...formData, installScript: newValue });
                  setSave(true);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RunTaskConfigOptions;
