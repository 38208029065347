import React, { useEffect, useState } from "react";
import {
  ACCESS_LEVEL,
  ENTITY_TYPES,
  SHARE_TYPE,
  TO_ENTITY_TYPE
} from "../../../../utility/constants/constants";
import { KeyboardArrowRight } from "@mui/icons-material";
import { MenuItem } from "@material-ui/core";
import { Select, ToggleButton } from "@mui/material";
import * as userActions from "../../../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";

const SelectOrganizationUsers = ({entity, entityType, user, sharedUsers, orgUsersToAdd, setOrgUsersToAdd}) => {
  // usersData - contains initial dispatch of organization users
  // sharedUsers - source of truth for the shares going to be resolved in the backend
  // orgUsersToAdd - contains the intermediate state of changes specific to users part of the organization
  const areAllUsersSelected = (orgUsers, arrayToAdd) => {
    return orgUsers.length > 0 && arrayToAdd.length > 0 &&
      orgUsers.length === arrayToAdd.length &&
      arrayToAdd.every((element) => element.share_type === "share")
  }

  const [usersData, setUsersData] = useState([]);
  const [selectAll, setSelectAll] = useState(areAllUsersSelected(usersData, orgUsersToAdd));
  const {users} = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  // get users from logged-in user's organization on select organization users button click
  useEffect(() => {
    dispatch(userActions.getUsers({
      limit: "all",
      organization: user.organization
    }));
  }, [dispatch, user.organization])

  useEffect(() => {
    setSelectAll(areAllUsersSelected(usersData, orgUsersToAdd))
  }, [orgUsersToAdd, usersData])

  useEffect(() => {
    const findAccessLevel = (user) => {
      const userIndex = sharedUsers.findIndex(x => x.email === user.email)
      if (userIndex >= 0) {
        return sharedUsers[userIndex]["access_level"]
      } else {
        return ACCESS_LEVEL.VIEW
      }
    }

    const data = users.filter(user => user.uid !== entity.user_uid).map((e) => ({
      email: e.email,
      access_level: findAccessLevel(e),
      to_entity_type: TO_ENTITY_TYPE.USER,
    }));

    setUsersData(data);
  }, [entity.user_uid, sharedUsers, users])

  useEffect(() => {
    // Sets orgUsersToAdd to have all users that are a part of the sharedUsers array already
    const userDataEmails = usersData.filter(el => el.email !== user.email).map(el => el.email)
    setOrgUsersToAdd(sharedUsers.filter(x => userDataEmails.some(a => x.email === a)))
  }, [setOrgUsersToAdd, sharedUsers, user.email, usersData])

  // Change the access levels for the org users upon changing the access level dropdown
  const handleOrgUserAccess = (e, index) => {
    usersData[index].access_level = e.target.value;
  };

  const handleSelectAll = (selectAll) => {
    let orgUsersToAddCopy = [...orgUsersToAdd];
    if (selectAll) {
      for (const userData of usersData.filter(a => a.email !== user.email)) {
        const orgUserIndex = orgUsersToAddCopy.findIndex(orgUser => orgUser.email === userData.email)
        if (orgUserIndex >= 0) {
          orgUsersToAddCopy[orgUserIndex]["share_type"] = SHARE_TYPE.SHARE;
        } else {
          orgUsersToAddCopy = [...orgUsersToAddCopy, {
            share_type: SHARE_TYPE.SHARE,
            to_entity_type: TO_ENTITY_TYPE.USER,
            email: userData.email,
            access_level: userData.access_level
          }]
        }
      }
      setOrgUsersToAdd(orgUsersToAddCopy)
    } else {
      // Remove all org users from userEmails
      for (const user of usersData) {
        const orgUserIndex = orgUsersToAddCopy.findIndex(orgUser => orgUser.email === user.email)
        if (orgUserIndex >= 0) {
          orgUsersToAddCopy[orgUserIndex]["share_type"] = SHARE_TYPE.UNSHARE;
        }
      }
    }
  }

  // Add selected org users to shared user list upon checking or unchecking the input
  const handleSelectOrgUser = (event, value) => {
    const tempEmails = [...orgUsersToAdd];
    const sharedUserIndex = tempEmails.findIndex((u) => u.email === value.email);
    if (sharedUserIndex >= 0) {
      // Email found - check access level
      if (value.source === 'click' && tempEmails[sharedUserIndex]["share_type"] === SHARE_TYPE.SHARE) {
        tempEmails[sharedUserIndex]["share_type"] = SHARE_TYPE.UNSHARE;
        setOrgUsersToAdd(tempEmails);
      } else if (value.source === 'click' && tempEmails[sharedUserIndex]["share_type"] === SHARE_TYPE.SHARE) {
        tempEmails[sharedUserIndex]["share_type"] = SHARE_TYPE.SHARE;
        setOrgUsersToAdd(tempEmails);
      } else {
        // Change the access level of an existing share
        tempEmails[sharedUserIndex]["share_type"] = SHARE_TYPE.SHARE;
        tempEmails[sharedUserIndex]["access_level"] = value.access_level;
        setOrgUsersToAdd(tempEmails);
      }
    } else {
      // Not in orgUsersToAdd currently - add share
      setOrgUsersToAdd([...orgUsersToAdd, {
        share_type: SHARE_TYPE.SHARE,
        to_entity_type: TO_ENTITY_TYPE.USER,
        email: value.email,
        access_level: value.access_level
      }]);
    }
  };

  return (
    <div className="select_organization_users_container">
      <div className="select_org_users_instruction">Select people from the organization</div>
      <div className="select_all_checkbox">
        <input type="checkbox"
               id="select-all"
               name="select-all"
               checked={selectAll}
               onChange={(e) => {
                 setSelectAll(e.target.checked)
                 handleSelectAll(e.target.checked)
               }}
        />
        <label htmlFor="select-all">Select all</label>
      </div>
      <div className="org_users">
        {usersData.length ? (
          usersData.map((data, i) =>
            user.email !== data.email ? (
              <div className={"org_user_button_container"} key={`${i}${data.email}`}>
                <ToggleButton className="org_user_button"
                              selected={orgUsersToAdd.some(x => (x.email === data.email && x.share_type === SHARE_TYPE.SHARE))}
                              onClick={(e, value) => {
                                handleSelectOrgUser(e, value)
                              }}
                              value={{'email': data.email, 'access_level': data.access_level, 'source': 'click'}}>
                  <div className={"org_user_email"}>{data.email}</div>
                </ToggleButton>
                <Select
                  className="org_user_select"
                  label="Access"
                  value={data.access_level}
                  onChange={(e) => {
                    handleSelectOrgUser(e, {'email': data.email, 'access_level': e.target.value, 'source': 'select'})
                    handleOrgUserAccess(e, i)
                  }}
                  IconComponent={KeyboardArrowRight}>
                  <MenuItem value="view">Viewer</MenuItem>
                  {entityType !== ENTITY_TYPES.DATASET && <MenuItem value="execute">Executor</MenuItem>}
                  <MenuItem value="edit">Editor</MenuItem>
                </Select>
              </div>
            ) : (
              ""
            )
          )
        ) : (
          <p>Users not found</p>
        )}
      </div>
    </div>
  )
}

export default SelectOrganizationUsers