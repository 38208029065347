import React, {useEffect, useState} from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Tooltip from "@mui/material/Tooltip";
import { dateToFromNowDaily } from "utility/CommonService";

const EntityInfo = ({ entity, isSharedWithMe }) => {
  const [author, setAuthor] = useState(null)

  useEffect(() => {
    if (entity.user && !isSharedWithMe) {
      setAuthor(entity.user)
    }
  }, [entity, author, isSharedWithMe])

  return (
    <>
      {
        isSharedWithMe ? (
          <div className="project_info">
          <div className="project_info_item">
            <div className="info_icns">
              <div className="info_icn_wrapper">
                {entity?.user?.photo_path ? (
                  <img src={`${entity.user.photo_path.original}`} alt="" />
                ) : (
                  <AccountCircleIcon />
                )}
              </div>
            </div>
            <div>
              {entity?.repo_author?.length > 0
                ? entity.repo_author
                : `${entity.user.first_name} ${entity.user.last_name}`}
            </div>
          </div>
        </div>
        ) : (
          <div className="project_info">
            {entity.shares?.length > 0 && <div className="project_info_item">
              <GroupIcon />
              <div>Shared with</div>
              <div className="info_icns" style={{width: `${entity.shares.length * 15 + 5}px`}}>
                {entity.shares.map((share, index) => {
                  return (
                    <Tooltip key={share.id} title={share.name}>
                      <div
                        style={{ marginLeft: `${index * 10 + 3}px` }}
                        className="info_icn_wrapper"
                      >
                        {share.photo_path ? (
                          <img
                            src={`${share.photo_path.original}`}
                            alt=""
                          />
                        ) : (
                          <AccountCircleIcon />
                        )}
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            </div>}
            <div className="project_info_item" id='edited_from'>
              <ModeEditIcon />
              <div>Edited {dateToFromNowDaily(entity.updated_at)}</div>
            </div>
            <div className="project_info_item">
              <div className="info_icns">
                <Tooltip key={entity.id} title={author ? author.first_name : ""}>
                  <div className="info_icn_wrapper">
                    {author && author.photo_path ? (
                      <img
                        src={`${author.photo_path.original}`}
                        alt=""
                      />
                    ) : (
                      <AccountCircleIcon />
                    )}
                  </div>
                </Tooltip>
              </div>
              <div>{author ? `${author.first_name} ${author.last_name}` : ""}</div>
            </div>
          </div>
        )
      }

    </>
  );
};

export default EntityInfo;
