import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as datasetActions from "../../../../redux/actions/DataSetActions";
import { StepTitle } from "./StepTitle";
import "./TemplateCreation.scss";

export const TemplateReview = (props) => {
  const { currStep, form, setNextPageReady, validation } = props;
  const dispatch = useDispatch();
  const { datasets } = useSelector((state) => state.dataSetReducer);
  const dsIDs = form.dataset_ids;

  // Get user datasets and ludis datasets
  useEffect(() => {
    if (validation(form)) {
      setNextPageReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, form]);

  // Get user datasets and ludis datasets
  useEffect(() => {
    dispatch(datasetActions.getDataSets());
  }, [dispatch]);

  // TODO: Need to make this work with more than one dataset
  const getDatasetNames = (datasetIds) => {
    const datasetNames = [];
    for (const dataset of datasets) {
      if (dataset.id === datasetIds[0]) datasetNames.push(dataset.name);
    }
    return datasetNames;
  };

  return (
    <div className="appInputPanel">
      <StepTitle currStep={currStep} />
      <div className="appContentPanel publish_modal publish_select_btn">
        <h4>App Name</h4>
        <div>{form.name}</div>

        <h4>App Description</h4>
        <div>{form.description}</div>

        <h4>Selected Template</h4>
        <div style={{ textTransform: "capitalize" }}>{form.app_type}</div>

        <h4>Selected Dataset</h4>
        <div>{getDatasetNames(dsIDs)}</div>
      </div>
    </div>
  );
};
