import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextTruncate, dateToFromNowDaily } from "utility/CommonService";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ShareEntityModal } from "../shared/Modals/ShareEntityModal/ShareEntityModal"
import { EditEntityModal } from "../../components/shared/Modals/EditEntityModal"
import { ENTITY_TYPES, DEFAULT_DATETIME_OPTION, PAGE_SIZE_CONSTANTS } from "utility/constants/constants";
import { createProjectShare, getProjects, updateProject } from "redux/actions/ProjectActions";
import { ReplyOutlined } from "@mui/icons-material";

export const ProjectRow = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const {
    project,
    deleteProject,
    index,
    accessLevel
  } = props;
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const menuOpen = Boolean(menuAnchor);
  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchor(false);
  };

  const getAuthor = () => {
    return project.users ? project.users.first_name + " " + project.users.last_name : "Unknown";
  };

  const getProjectImage = () => {
    const images = [
      require("assets/custom-styles/images/thumbnails/78_dzz.png"),
      require("assets/custom-styles/images/thumbnails/78_dzz2.jpg"),
      require("assets/custom-styles/images/thumbnails/78_dzz3.jpg"),
      require("assets/custom-styles/images/thumbnails/78_dzz4.jpg"),
      require("assets/custom-styles/images/thumbnails/78_dzz5.jpg"),
    ];
    return images[Math.abs(project.id.toString().hashCode() % 5)];
  };

  return (
    <>
      <div className="col-sm-12"
           key={index}
      >
        <div className="sper__Row">
          <div className="sper__left">
            <div className="sper__img">
              <img src={getProjectImage()} alt="projectimg" />
            </div>
          </div>
          <div className="sper__right">
            <div className="row">
              <div className="col-sm-7">
                <div className="inn__L1">
                  <h4>
                    <Link to={`projects/${project.id}`}>{project.name}</Link>
                  </h4>
                  <p className="word-break">
                    {TextTruncate({
                      text: project.description,
                      limit: 75,
                    })}
                  </p>
                  <div className="inn__bottom">
                    <Link to="/projects">
                    <span className="inn__avtar">
                      <img
                        src={require("assets/custom-styles/images/thumbnails/empty_photo.png")}
                        alt="projectimg"
                        className="mr-1"
                      />
                    </span>
                      {getAuthor()}
                    </Link>
                    <Link to="/projects">
                      <img
                        src={require("assets/custom-styles/images/thumbnails/cal_icn.jpg")}
                        alt="projectimg"
                        className="mr-1"
                      />
                      {project.created_at
                        ? moment(project.created_at).format(user.date_format ? user.date_format: DEFAULT_DATETIME_OPTION)
                        : null}
                    </Link>
                    <Link to="/projects">
                      <img
                        src={require("assets/custom-styles/images/thumbnails/files_icn.jpg")}
                        alt="projectimg"
                        className="mr-1"
                      />
                      {
                        project.notebooks ?
                          project.notebooks.filter((e) => e.status === "active")
                            .length : null
                      }
                    </Link>
                    <Link
                      className="cursor-pointer"
                      to={`/projects/${project.id}/edit`}
                    >
                      <img
                        src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                        alt="projectimg"
                        className="cursor-pointer mr-1"
                      />
                      {dateToFromNowDaily(project.updated_at)}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="inn__R1">
                  <div className="show_tool">
                    {
                      accessLevel && (
                        <Tooltip title="More" placement="top">
                          <IconButton
                            className="app_menu mr-2"
                            aria-label="more"
                            id="long-menu-button"
                            aria-controls={menuOpen ? 'long-menu' : undefined}
                            aria-expanded={menuOpen ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                    <Menu id="long-menu-button"
                          disableScrollLock={true}
                          anchorEl={menuAnchor}
                          open={menuOpen}
                          onClose={handleMenuClose}
                    >
                      {accessLevel && (
                        <MenuItem
                          dense={true}
                          onKeyDown={(e) => e.stopPropagation()}
                          onClick={(e) => {
                            setShowEditModal(true)
                            handleMenuClose()
                            e.stopPropagation()
                          }}
                        >
                          <ListItemIcon className="edit">
                            <div className="entity_menu_item">
                              <img
                                src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                                alt="Edit Entity"
                                className="cursor-pointer optionsIcon"
                              />
                              <p>Edit</p>
                            </div>
                          </ListItemIcon>
                        </MenuItem>
                      )}
                      <MenuItem
                        dense={true}
                        onKeyDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          setShowShareModal(true)
                          handleMenuClose()
                          e.stopPropagation()
                        }}
                      >
                        <ListItemIcon className="share">
                          <div className="entity_menu_item">
                            <ReplyOutlined sx={{transform: 'scaleX(-1)', width: '25px', height: '25px'}} />
                            <p>Share</p>
                          </div>
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => { deleteProject(project.id); handleMenuClose() }}
                      >
                        <ListItemIcon className="delete">
                          <DeleteIcon fontSize="small" />
                          <p>Delete</p>
                        </ListItemIcon>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditEntityModal entity={project} entityType={ENTITY_TYPES.PROJECT} updateEntity={updateProject} handleMenuClose={handleMenuClose} showModal={showEditModal} setShowModal={setShowEditModal}/>
      <ShareEntityModal entity={project} entityType={ENTITY_TYPES.PROJECT} createEntityShare={createProjectShare} getEntities={getProjects} handleMenuClose={handleMenuClose} entityLimit={PAGE_SIZE_CONSTANTS.PROJECTS} showModal={showShareModal} setShowModal={setShowShareModal}/>
    </>
  );
};
