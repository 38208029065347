import React from "react";
import { TemplatePicker } from "./TemplatePicker";
import { AppInfoPanel } from "./AppInfoPanel";
import { DatasetSelection } from "./DatasetSelection";
import "./TemplateCreation.scss";
import { TemplateReview } from "./TemplateReview";

export const AppCreation = (props) => {
  const { currStep, form, setForm, setNextPageReady, validation } = props;

  return (
    <div className="appCreationContainer">
      {/* TODO: Add the getForms to enable picks when using the back button */}
      {currStep === 0 ? (
        <TemplatePicker
          currStep={currStep}
          form={form}
          setForm={setForm}
          setNextPageReady={setNextPageReady}
        />
      ) : (
        <></>
      )}{" "}
      {}
      {currStep === 1 ? (
        <DatasetSelection
          currStep={currStep}
          form={form}
          setForm={setForm}
          setNextPageReady={setNextPageReady}
        />
      ) : (
        <></>
      )}
      {/* TODO: [ENG-1343] Need to re-enable the Datset Validation step here, and change the 'currStep' to reset at 4 */}
      {/* {currStep === 2 ? <DatasetValidation form={form} /> : <></>} */}
      {currStep === 2 ? (
        <AppInfoPanel
          currStep={currStep}
          form={form}
          setForm={setForm}
          setNextPageReady={setNextPageReady}
        />
      ) : (
        <></>
      )}
      {currStep === 3 ? (
        <TemplateReview
          currStep={currStep}
          form={form}
          setForm={setForm}
          setNextPageReady={setNextPageReady}
          validation={validation}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
