import React from 'react';
import { ACCESS_LEVEL } from "utility/constants/constants";
import Tooltip from '@mui/material/Tooltip';
import { AssignmentOutlined, AccountTreeOutlined } from '@mui/icons-material';
import {NavLink}  from 'react-router-dom';

const ProjectLink =({ projectName, workflow, accessLevel}) => {
  return (
      <div className="project-card">
        <div>Linked Pages</div>
        <div className="project-link-name">
          <AssignmentOutlined className='project-link-icon' />
          <div className="label">Project: </div>{" "}
          <Tooltip
            title={accessLevel === ACCESS_LEVEL.NONE ? "You need project access" : ""}
            placement="top"
          >
            <NavLink to={`/projectsv2/${workflow?.project_id}`} exact={true} className={accessLevel !== ACCESS_LEVEL.NONE ? "navlink-active" : "navlink-inactive"}>
              {projectName}
            </NavLink>
          </Tooltip>
          </div>
          <div className="project-link-name">
            <AccountTreeOutlined className='project-link-icon' />
            <div className="label">Designer: </div>{" "}
            <NavLink to={`/visual-workflows/${workflow?.id}`} exact={true} className={(workflow?.visual || workflow?.nodes !== null) ? "navlink-active" : "navlink-inactive"}>
            {(workflow?.visual || workflow?.nodes !== null)? workflow?.name : 'N/A'}
          </NavLink>
        </div>
      </div>
  );
}

export default ProjectLink;