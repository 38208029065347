import { LLMActionTypes } from "../actions/LLMActions/actionType";

export const initialState = {
  summary: ""
}

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps
  }
}

export const llmReducer = (state = initialState, action) => {
  switch (action.type) {
    case LLMActionTypes.CREATE_LOG_SUMMARY_PENDING:
      return updateObject(state, {});
    case LLMActionTypes.CREATE_LOG_SUMMARY_FULFILLED:
      return updateObject(state, { summary: action.payload.summary });
    case LLMActionTypes.CLEAR_LOG_SUMMARY:
      return updateObject(state, { summary: "" });
    default:
      return state;
  }
}