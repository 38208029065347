import axios, { API_VERSION_2 } from '../config';

export const getProjects = (params) =>
    axios.get(`${API_VERSION_2}/projects`, { params });

export const getProject = (id) =>
    axios.get(`${API_VERSION_2}/projects/${id}`)

export const checkProject = (id) =>
    axios.get(`${API_VERSION_2}/projects/check/${id}`)

export const getRepoContent = (body, id) =>
    axios.post(`${API_VERSION_2}/projects/repocontents/${id}`, body)

export const getFileContent = async (body, id) => {
    return axios.post(`${API_VERSION_2}/projects/repocontents/${id}`, body)
}

export const commitFileContent = async (body) => {
    return axios.post(`${API_VERSION_2}/projects/commitfile`, body)
}

export const deleteGithubFile = async (body) => {
    return axios.post(`${API_VERSION_2}/projects/deletefile`, body)
}

export const createProject = async (body) => {
    return axios.post(`${API_VERSION_2}/projects`, body)
}

export const getEnvVariables = async (params) => {
    return axios.get(`${API_VERSION_2}/projects/envvar`, { params });
}

export const createEnvVariable = async (body) => {
    return axios.post(`${API_VERSION_2}/projects/envvar`, body)
}

export const updateEnvVariable = async (body, id) => {
    return axios.put(`${API_VERSION_2}/projects/envvar/${id}`, body)
}

export const deleteEnvVariable = async (id) => {
    return axios.delete(`${API_VERSION_2}/projects/envvar/${id}`)
}

export const updateProject = (body, id) =>
  axios.put(`${API_VERSION_2}/projects/${id}`, body);

export const deleteProject = async (id) => {
    return axios.delete(`${API_VERSION_2}/projects/${id}`)
}

export const getContainerLogs = async (id) => {
    return axios.get(`${API_VERSION_2}/projects/containerlogs/${id}`)
}
export const createProjectShare = (body) =>
  axios.post(`${API_VERSION_2}/projects/share`, body)

export const getProjectShares = (params) =>
  axios.get(`${API_VERSION_2}/projects/share/list`,{params})

export const getProjectApps = async (id) =>
    axios.get(`${API_VERSION_2}/projects/getprojectapps/${id}`)

export const listDockerImages = async (id) =>
    axios.get(`${API_VERSION_2}/cluster/dockerimages/list`)