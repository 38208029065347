import { INSIGHTS_TABS } from "utility/constants/constants";
import { AppActionTypes } from "../actions/AppActions/actionType";
import { nextStateObject } from "utility/utility";
import { ProjectsActionTypes } from "redux/actions/ProjectsActions/actionType";
import { DEFAULT_INSIGHT_OPTIONS } from "utility/constants/constants";

export const initialState = {
  reports: [],
  app: null,
  pinnedApplications: [],
  appShares: [],
  applications: [],
  currentScreen: "Dashboard",
  appsPageCount: null,
  reportsPageCount: null,
  application: {},
  insightSharesPageCount: 1,
  previewContainerInfo: {},
  appDataset: null,
  appColumnNames: null,
  templateData: null,
  paginateData: null,
  datapoint: null,
  forecastData: null,
  insights: [],
  insightsPageCount: 1
};

const setScreen = (appType) => {
  if (appType === "application") return INSIGHTS_TABS.APPLICATIONS;
  else if (appType === "report" || appType === "user_report")
    return INSIGHTS_TABS.REPORTS;
  else return INSIGHTS_TABS.DASHBOARD;
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

//update state pinnedApplications with newly pinned/unpinned app
const updatePinnedApps = (state, body) => {
  if (body.pinned) {
    return [...state.pinnedApplications, body.app];
  } else {
    return state.pinnedApplications.filter((app) => app.id !== body.app.id);
  }
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.SET_CURRENT_SCREEN_FULFILLED:
      return updateObject(state, { currentScreen: action.payload });
    case AppActionTypes.GET_REPORTS_FULFILLED:
      return updateObject(state, {
        reports: action.payload.applications,
        reportsPageCount: action.payload.pageCount,
      });
    case AppActionTypes.GET_APP_SHARES_FULFILLED:
      return updateObject(state, {
        appShares: action.payload.entityShares,
        insightSharesPageCount: action.payload.pageCount,
      });
    case AppActionTypes.DELETE_APP_SHARE_FULFILLED:
      return updateObject(state, { appShares: [] });
    case AppActionTypes.GET_APP_FULFILLED:
      return updateObject(state, { app: action.payload.application });
    case AppActionTypes.GET_APP_PINS_FULFILLED:
      return updateObject(state, {
        pinnedApplications: action.payload.pinnedApplications,
      });
    case AppActionTypes.GET_COLUMN_NAMES_FULFILLED:
      return updateObject(state, { appColumnNames: action.payload[0] });
    case AppActionTypes.GET_GRAPH_DATA_FULFILLED:
      return updateObject(state, { templateData: action.payload[0] });
    case AppActionTypes.GET_FORECAST_DATA_FULFILLED:
      return updateObject(state, { forecastData: action.payload });
    case AppActionTypes.GET_DATA_PAGINATION_FULFILLED:
      return updateObject(state, { paginateData: action.payload[0] });
    case AppActionTypes.GET_DATA_POINT_FULFILLED:
      return updateObject(state, { datapoint: action.payload[0] });
    case AppActionTypes.UPDATE_APP_FULFILLED:
      let stateObject = {
        applications: [...state.applications],
        reports: [...state.reports],
        appShares: [...state.appShares],
        pinnedApplications: [...state.pinnedApplications],
      };
      const nextState = nextStateObject(stateObject, action.payload);
      return updateObject(state, {
        applications: nextState.applications,
        reports: nextState.reports,
        appShares: nextState.appShares,
        pinnedApplications: nextState.pinnedApplications,
      });
    case AppActionTypes.UPDATE_PINNED_APPS:
      return updateObject(state, {
        pinnedApplications: updatePinnedApps(state, action.payload),
      });
    case AppActionTypes.CREATE_APP_FULFILLED:
      return updateObject(state, {
        reports: [],
        app: action.payload,
      });
    case AppActionTypes.CREATE_APP_V2_FULFILLED:
      return updateObject(state, {
        app: action.payload,
      });
    case AppActionTypes.CREATE_APP_PREVIEW_FULFILLED:
      return updateObject(state, {
        previewContainerInfo: action.payload.containerInfo,
      });

    case AppActionTypes.IMPORT_REPORT_FULFILLED:
      return updateObject(state, {
        app: action.payload.appTableResp,
        currentScreen: setScreen(action.payload.appTableResp.app_type),
      });
    case AppActionTypes.IMPORT_APP_FULFILLED:
      return updateObject(state, {
        applications: [],
      });
    case AppActionTypes.DELETE_APP_FULFILLED:

      let preInsights = [...state.insights];
      const appIndex = preInsights.findIndex(
        (app) => app.id === action.payload.id
      );
      if (appIndex >= 0) {
        preInsights.splice(appIndex, 1);
      }
      return updateObject(state, {
        insights: preInsights,
      });
    case AppActionTypes.GET_APPLICATIONS_FULFILLED:
      return updateObject(state, {
        applications: action.payload.applications,
        appsPageCount: action.payload.pageCount,
      });
    case AppActionTypes.GET_INSIGHTS_FULFILLED:

      let insights = action.payload.page === '1'?[]:[...state.insights]

      if (insights.length <= (action.payload.pageCount - 1) * DEFAULT_INSIGHT_OPTIONS.pageSize) {
        insights.push(...action.payload.insights)
      }
      return updateObject(state, {
        insights: insights,
        insightsPageCount: action.payload.pageCount,
      });
    case AppActionTypes.RESTART_APP_SHARE_FULFILLED:
      return updateObject(state, {
        application: {
          ...state.application,
          [action.payload.application_id]: action.payload.application,
        },
      });
    case AppActionTypes.STOP_APP_SHARE_FULFILLED:
      return updateObject(state, {
        application: {
          ...state.application,
          [action.payload.application_id]: false,
        },
      });
    case AppActionTypes.STATUS_APP_SHARE_FULFILLED:
      return updateObject(state, {
        application: {
          ...state.application,
          [action.payload.application_id]: action.payload.application,
        },
      });
    case ProjectsActionTypes.GET_PROJECT_V2_FULFILLED:
      return updateObject(state, { previewContainerInfo: {} });
    case AppActionTypes.REDEPLOY_APP_FULFILLED:
      return updateObject(state, { currentScreen: INSIGHTS_TABS.APPLICATIONS });
    default:
      return state;
  }
};
