import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useRouteMatch } from "react-router-dom";

import * as actions from "redux/actions/ProjectActions";
import moment from "moment-timezone";
import * as commonService from "utility/CommonService.js";

import { dateToFromNowDaily } from "utility/CommonService";
import NotebooksModal from "./NotebooksModal";
import PublishModal from "./PublishModal";

const NoteBooks = ({ history }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [loaded, setLoaded] = useState(false);
  const { notebooks, project, projects } = useSelector(
    (state) => state.projectReducer
  );
  let match = useRouteMatch("/projects/:id");
  const projectId = match.params.id;

  const getNotebookImage = () => {
    const images = [
      require("assets/custom-styles/images/thumbnails/78_dzz.png"),
      require("assets/custom-styles/images/thumbnails/78_dzz2.jpg"),
      require("assets/custom-styles/images/thumbnails/78_dzz3.jpg"),
      require("assets/custom-styles/images/thumbnails/78_dzz4.jpg"),
      require("assets/custom-styles/images/thumbnails/78_dzz5.jpg")
    ];
    return images[Math.abs(projectId.hashCode() % 5)];
  };

  useEffect(() => {
    if (!loaded && projectId) {
      setLoaded(true);
      dispatch(actions.getProjectNotebooks(projectId));
    }
  }, [loaded, projectId, dispatch]);

  const deleteNotebook = (id) => {
    swal({
      text: "Are you sure you want to delete this Notebook?",
      className: "logout-alert-modal",
      buttons: [true, "Delete"]
    }).then((result) => {
      if (result) {
        dispatch(actions.deleteNotebook({ id })).then((res) => {
          commonService.forSuccess("Notebook Deleted!", "Success");
        });
      }
    });
  };
  return (
    <div className="tabu__Sper">
      <div className="row">
        <div className="col-sm-12">
          <div className="lds_pd_heading_btn">
            <h4>Notebooks</h4>
            <Link to={`/add-notebooks?project=${projectId}`} className="btn">
              create file
            </Link>
            {
              <NotebooksModal
                projects={projects}
                projectId={projectId}
                history={history}
              />
            }
            {
              <PublishModal
                projects={projects}
                projectId={projectId}
                history={history}
              />
            }
          </div>
        </div>
        {notebooks &&
          notebooks.map((notebook, index) => (
            <div className="col-sm-12" key={index}>
              <div className="sper__Row">
                <div className="sper__left">
                  <div className="sper__img">
                    <img src={getNotebookImage()} alt="icn" />
                  </div>
                </div>
                <div className="sper__right">
                  <div className="row">
                    <div className="col-sm-9">
                      <div className="inn__L1">
                        <h4>
                          <Link
                            to={{
                              pathname: `/notebooks/${notebook.id}`,
                              state: {
                                project: project
                              }
                            }}
                          >
                            {notebook.name}
                          </Link>
                        </h4>
                        <p className="word-break">{notebook.description}</p>
                        <div className="inn__bottom">
                          <Link to={`/projects/${notebook.project_id}`}>
                            <span className="inn__avtar">
                              <img
                                src={require("assets/custom-styles/images/thumbnails/empty_photo.png")}
                                alt="icn"
                                className="mr-1"
                              />
                            </span>
                            {project.author}
                          </Link>
                          <Link to={`/projects/${notebook.project_id}`}>
                            <img
                              src={require("assets/custom-styles/images/thumbnails/cal_icn.jpg")}
                              alt="icn"
                              className="mr-1"
                            />
                            {notebook.created_at
                              ? moment(notebook.created_at).format(user.date_format)
                              : null}
                          </Link>
                          <Link to={`/notebooks/${notebook.id}/edit`}>
                            <img
                              src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                              alt="icn"
                              className="mr-1"
                            />
                            {dateToFromNowDaily(notebook.updated_at)}
                          </Link>

                          <Link to={`/projects/${notebook.project_id}`}>
                            <img
                              src={require("assets/custom-styles/images/thumbnails/tag.png")}
                              alt="icn"
                              className="mr-1"
                            />
                            Tags
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="inn__R1">
                        <div className="ml_icn">
                          <img
                            src={require("assets/custom-styles/images/thumbnails/trash.png")}
                            alt="More Trash"
                            className="cursor-pointer optionsIcon"
                            onClick={(e) => deleteNotebook(notebook.id)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default NoteBooks;
