import React from "react";
//redux hooks for dispatch action and access reducer state
import { useDispatch, useSelector } from "react-redux";
import * as commonService from "utility/CommonService.js";

import * as actions from "redux/actions/ProjectActions";
import AddProjectForm from "components/Projects/Form";

const EditForm = ({ history }) => {
  const defaultForm = {
    name: "",
    description: "",
    author: "Bob",
    type: "report",
    language: "Python",
    user_id: "",
    dataset_ids: [],
    size: "5",
  };

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const { datasets } = useSelector(state => state.dataSetReducer)

  const handleSubmit = (form) => {
    if (form.dataset_ids) {
      dispatch(
        actions.createProject({
          body: {
            project: {
              ...form,
              author: user.git_username,
              user_id: user.id,
            },
          },
        })
      ).then((res) => {
        if (res.value.success)
          history.push(`/projects/${res.value.project.id}`);
      });
    } else {
      commonService.forError("Dataset is required", "Error");
    }
  };

  return (
    <AddProjectForm
      handleSubmit={handleSubmit}
      defaultForm={defaultForm}
      datasets={datasets}
      history={history}
    />
  );
};

export default EditForm;
