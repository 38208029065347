import ModalHeader from "react-bootstrap/ModalHeader";
import {Button, Modal, ModalBody, ModalFooter} from "react-bootstrap";
import React, { useRef } from "react";
import {TextArea} from "../../shared/ui-components/form-components";

const CreateProjectModal = ({showCreateModal, setShowCreateModal, newProject, setNewProject, createProject}) => {
  const inputRef = useRef();


  return (
    <Modal
      className="import_modal"
      show={showCreateModal}
      onHide={() => setShowCreateModal(false)}
      onEntered={() => inputRef.current.focus()}
    >
      <ModalHeader closeButton>Create Project</ModalHeader>
      <ModalBody>
        <form>
          <div className="col-md-12">
            <div className="publish_modal">
              <h4>Name</h4>
              <input
                ref={inputRef}
                onChange={(e) => {
                  setNewProject({ ...newProject, name: e.target.value });
                }}
              ></input>
            </div>
          </div>
          <div className="col-md-12">
            <div className="publish_modal lds_cp_box_heading">
              <h4>Description</h4>
              <TextArea
                onChange={(e) => {
                  setNewProject({
                    ...newProject,
                    description: e.target.value,
                  });
                }}
              ></TextArea>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={createProject} className="btn">Create</Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateProjectModal