import axios from "../config";
import { API_VERSION } from "config";

export const getDataSets = (params) =>
  axios.get(`${API_VERSION}/datasets/listdatasets`, { params })

export const getDataSetFiles = (body, id) =>
  axios.post(`${API_VERSION}/datasets/listfiles/${id}`, body);

export const getDataSet = (id) =>
  axios.post(`${API_VERSION}/datasets/getdatasetdetails/${id}`);

export const createDataSet = (body) =>
  axios.post(`${API_VERSION}/datasets/createdataset`, body);

export const updateDataSet = (body, id) =>
  axios.put(`${API_VERSION}/datasets/updatedataset/${id}`, body)

export const getPresignedUrl = (body, id) =>
  axios.post(`${API_VERSION}/datasets/getpresignedurl/${id}`, body);

export const getFilePreview = (body, id) =>
  axios.post(`${API_VERSION}/datasets/getfilepreview/${id}`, body);

export const getLudisDatasets = () =>
  axios.post(`${API_VERSION}/datasets/listludisdatasets`);

export const getNewDataSets = () =>
  axios.post(`${API_VERSION}/datasets/gethomedatasets`);

export const createDataSetSubscription = (body) =>
  axios.post(`${API_VERSION}/datasets/createsubscription`, body);

export const getDataSetSubscriptions = () =>
  axios.post(`${API_VERSION}/datasets/listsubscriptions`);

export const createDataSetDirectory = (body) =>
  axios.post(`${API_VERSION}/datasets/createdatasetdirectory`, body);

export const deleteDataSetObject = (body, id) =>
  axios.post(`${API_VERSION}/datasets/deletedatasetobject/${id}`, body);

export const updateDatasetShare = (body) =>
  axios.post(`${API_VERSION}/datasets/share`, body)

export const getDatasetShares = (params) =>
  axios.get(`${API_VERSION}/datasets/share/list`, { params })

export const getLudisUrl = (body, id) =>
  axios.post(`${API_VERSION}/datasets/get/ludisurl/${id}`, body)

