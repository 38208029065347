import React, { useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Modal } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { setPermissionTooltip } from "../../../utility/utility";

const DeleteConfirmationModal = ({ type='file', onConfirm, accessLevel, disableIcon }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleConfirm = () => {
        onConfirm();
        setIsOpen(false)
      };

      const handleClose = () => {
        setIsOpen(false);
      };

  return (
    <div>
      <Tooltip title={setPermissionTooltip("edit", accessLevel, "")}>
        <span>
          <DeleteOutlineIcon className={disableIcon(["view", "execute"], accessLevel)} onClick={() => setIsOpen(true)} />
        </span>
      </Tooltip>
      <Modal centered className='delete_modal' show={isOpen} onHide={handleClose}>
          <div className='delete_modal_wrapper'>
          <div className='delete_modal_header'>
              <h4>Delete the {type}</h4>
              <HighlightOffIcon onClick={handleClose} />
          </div>
          <div className='delete_text'>
              Are you sure you want to delete this {type}?
          </div>
          <div className='delete_btns'>
              <Button className='btn secondary_btn' onClick={handleConfirm}>Yes, delete the {type}</Button>
              <Button className='btn' onClick={handleClose}>No, keep the {type}</Button>
          </div>
          </div>
      </Modal>
    </div>
  )
}

export default DeleteConfirmationModal