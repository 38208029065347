import React from "react";
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "./TemplateCreation.scss";

export const StepBar = (props) => {
	const { currStep } = props;

	return (
		<div className="stepBarContainer">
			<Steps
				direction="vertical"
				current={currStep}
				status="process"
				className="stepBar"
			>
				<Step title="Step 1" description="App Template" />
				<Step title="Step 2" description="Datasource" />
				{/* TODO: [ENG-1343] Need to re-enable the Datset Validation step here, and change the 'currStep' to reset at 4 */}
				{/* <Step title="Step 3" description="Validate" /> */}
				<Step title="Step 3" description="Information" />
				<Step title="Step 4" description="Review" />
			</Steps>
		</div>
	);
};
