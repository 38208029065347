import { FlightingActionTypes } from "redux/actions/FlightingActions/actionType";

export const initialState = {
  createFlightConfig: null,
  config: [],
  flightingConfig: [],
  configOptions: {},
  currentOrgFlighting: [],
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const flightingReducer = (state = initialState, action) => {
  let flightingConfig;

  switch (action.type) {
    case FlightingActionTypes.GET_CONFIG_FULFILLED:
      return updateObject(state, { configOptions: action.payload });
    case FlightingActionTypes.GET_ORGANIZATION_FLIGHTING_PENDING:
      return updateObject(state, { flightingConfig: [] });
    case FlightingActionTypes.GET_ORGANIZATION_FLIGHTING_FULFILLED:
      flightingConfig = setDefaultConfig(
        state.configOptions,
        action.payload.flightings
      );
      return updateObject(state, { flightingConfig });
    case FlightingActionTypes.CREATE_FLIGHT_FULFILLED:
      return updateObject(state, { createFlightConfig: action.payload });
    case FlightingActionTypes.GET_FLIGHTING_FULFILLED:
      return updateObject(state, { config: action.payload.flightings });
    case FlightingActionTypes.UPDATE_FLIGHT_FULFILLED:
      return updateObject(state, {
        flightingConfig: action.payload.flightings,
      });
    case FlightingActionTypes.CLEAR_FLIGHTING:
      flightingConfig = []
      return updateObject(state, { flightingConfig: flightingConfig });
    case FlightingActionTypes.GET_CURRENT_ORG_FLIGHTING_PENDING:
      return updateObject(state, { currentOrgFlighting: [] })
    case FlightingActionTypes.GET_CURRENT_ORG_FLIGHTING_FULFILLED:
      return updateObject(state, { currentOrgFlighting: action.payload.flightings })
    case FlightingActionTypes.CLEAR_CURRENT_ORG_FLIGHTING:
      flightingConfig = []
      return updateObject(state, { currentOrgFlighting: flightingConfig });
    default:
      return state;
  }
};

const setDefaultConfig = (config, orgFlightings) => {
  let tempData = [...orgFlightings];
  const options = [...config.flightings];
  options.map((e) => {
    let index = tempData.findIndex((t) => t.key === e.key);
    if (index < 0) {
      e.active = false;
      e["user_roles"] = ["developer", "management", "administrator", "superadmin"];
      tempData = [...tempData, e];
    }
    return e;
  });
  return tempData;
};
