import React, { useState, useRef } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

const CommitModal = ({ showCommitModal, handleShowCommitModal, onCommit }) => {
  const [newFileName, setNewFileName] = useState("");
  const inputRef = useRef()
  return (
    <Modal
      show={showCommitModal}
      onHide={handleShowCommitModal}
      id="edit_modal"
      onEntered={() => inputRef.current.focus()}
    >
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <form>
          <div className="col-md-12">
            <div className="publish_modal">
              <h4>Add File</h4>
              <input
                onChange={(e) => {
                  setNewFileName(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    e.preventDefault()
                    onCommit(newFileName)
                  }
                }}
                ref={inputRef}
              ></input>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <button className="btn" onClick={() => onCommit(newFileName)}>
          Add
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default CommitModal;
