import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { INSIGHT_FILTERS } from "utility/constants/constants";

const filterOptions = [
    { value: "All Apps", label: <span className="font-size-normal">All Apps</span>},
    { value: "Starred", label: <span  className="font-size-normal">Starred</span>},
    { value: "Created by me", label: <span  className="font-size-normal">Created by me</span> },
    { value: "Template", label: <span  className="font-size-normal">Template</span> },
    { value: "Shared with me", label: <span  className="font-size-normal">Shared with me</span> }
  ];

const sortOptions = [
  {
    value:1,
    sortByValue: "DESC",
    orderByValue: "name",
    label: <span className="font-size-normal">Reverse Alphabetical Name</span>
  },
  {
    value:2,
    sortByValue: "ASC",
    orderByValue: "name",
    label: <span className="font-size-normal">Alphabetical Name</span>
  },
  {
    value:3,
    sortByValue: "DESC",
    orderByValue: "created_at",
    label: <span className="font-size-normal">Newest Created</span>,
  },
  {
    value:4,
    sortByValue: "ASC",
    orderByValue: "created_at",
    label: <span className="font-size-normal">Oldest Created</span>,
  },
  {
    value:5,
    sortByValue: "DESC",
    orderByValue: "updated_at",
    label: <span className="font-size-normal">Newest Updated</span>,
  },
  {
    value:6,
    sortByValue: "ASC",
    orderByValue: "updated_at",
    label: <span className="font-size-normal">Oldest Updated</span>,
  },
];

const customStyles = {
    menu: provided => ({ ...provided, zIndex: 9999, width: "max-content", minWidth: "100%" }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#DBDDFF;' : provided.backgroundColor,
      fontFamily: 'Lato', // Specify the desired font family
      fontSize: '16px', // Specify the desired font size
      borderColor: '#FFF',
      width: "max-content",
      minWidth: "100%"
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#6B71FB',
      }),
    // Add more styles for other components if needed
    indicatorSeparator: (base, state) => ({
        ...base,
        // Custom styles for the indicator separator
        display: 'none', // Hide the indicator separator
      }),
    dropdownIndicator: (base, state) => ({
        ...base,
        // Custom styles for the dropdown indicator
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        transform: state.selectProps.menuIsOpen ? 'rotate(0deg)' : null, // Prevent rotation
        transition: 'transform 0.3s ease', // Add a transition effect if needed
      })
  };

// Custom dropdown indicator component with a custom image
const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={require("assets/custom-styles/images/icons/filterIcon.svg")}
          alt="Custom Dropdown Indicator"
          style={{ width: '20px', height: '20px' }}
        />
      </components.DropdownIndicator>
    );
  };



const FilterInsights = ({setAppTypeSort, appTypeSort }) => {
  // delay text input search
  const [search, setSearch] = useState("");

  useEffect(() => {
    const debounceFn = setTimeout(() => setAppTypeSort({...appTypeSort, page: 1, search: search}), 500);
    return () => clearTimeout(debounceFn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="filter_container">
        <Select
            menuPlacement="top"
            placeholder="Filter"
            className="filterInsightDropdown"
            name="language"
            autosize={false}
            options={filterOptions}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            onChange={(e) => setAppTypeSort({...appTypeSort, page: 1, filter: INSIGHT_FILTERS[e.value.toLowerCase()]})}
            styles={customStyles}
        />
        <Select
            menuPlacement="top"
            placeholder="Sort"
            className="filterInsightDropdown"
            name="language"
            options={sortOptions}
            autosize={false}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            onChange={(e) => setAppTypeSort({...appTypeSort, page: 1, orderBy: e.orderByValue, sortOperator: e.sortByValue})}
            styles={customStyles}
        />
        <div className="search_container">
            <img src={require('assets/custom-styles/images/icons/magnifyingGlass.svg')} alt="" />
            <input
                style={{border:'none'}}
                type="text"
                placeholder="Search by name"
                value={search}
                onChange={(e) => setSearch(e.target.value)}/>
        </div>
    </div>
  );
};
export default FilterInsights;
