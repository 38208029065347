import React, { useEffect, useRef } from "react";
import Chart from "chart.js";
import {
  BAR_GRAPH_COLORS
} from "../../../utility/constants/constants";


// barLabels - the label that will go under each bar in the graph
// barSectionLabels - the label that will describe a section of the bar
const StackedBarGraph = ({data, options, barLabels, barSectionLabels, className, barPercentage = 1}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      let ctx = chartRef.current.getContext('2d')
      const config = {
        type: 'bar',
        data: {
          labels: barLabels, // Array of bar labels
          datasets: barSectionLabels.map((sectionLabel, i) =>
            {
              return (
                {
                  label: sectionLabel,
                  data: Object.keys(data).map(barName => data[barName]).map(countMap => countMap[sectionLabel]),
                  backgroundColor: BAR_GRAPH_COLORS[i % BAR_GRAPH_COLORS.length],
                  barPercentage: barPercentage
                }
              )
            }
          )
        },
        options: options
      }

      let chart = new Chart(ctx, config)
      return () => {
        chart.destroy()
      }
    }
  }, [data, options, barLabels, barSectionLabels, barPercentage])

  return <canvas className={className} ref={chartRef} />
}

export default StackedBarGraph