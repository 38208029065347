import { Web3ActionTypes } from "../actions/Web3Actions/ActionType";

export const initialState = {
    nodes: [],
    links: [],
    collections: [],
    dropdownOptions: [],
    bubblewrap_nodes: [],
    dagRun: {},
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps
  };
};


export const web3Reducer = (state = initialState, action) => {
    switch (action.type) {
        case Web3ActionTypes.GET_STARMAP_FULFILLED:
            return updateObject(state, {
                nodes: action.payload.nodes,
                links: action.payload.links
            });
        case Web3ActionTypes.GET_COLLECTIONS_FULFILLED:
            let dropdownOptions = action.payload.collections
                .reduce((oldState, cur) => ({ ...oldState, [cur.gcp_slug]: cur.name }), {})
            return updateObject(state, {
                collections: action.payload.collections,
                dropdownOptions: dropdownOptions
            });
        case Web3ActionTypes.GET_BUBBLEWRAP_FULFILLED:
            return updateObject(state, {
                bubblewrap_nodes: action.payload.nodes
            });
        case Web3ActionTypes.GET_DAG_RUN_FULFILLED:
          return updateObject(state, { dagRun: action.payload.dagRun })
        default:
            return state;
    }
};