import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../redux/actions/AuthActions";
import swal from "sweetalert";
import * as commonService from "utility/CommonService.js";
import { Link } from "react-router-dom";

export const ChangePasswordForm = ({ history, defaultForm }) => {
  const [passwordForm, setPasswordForm] = useState(defaultForm);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPasswordForm({ ...passwordForm, [name]: value });
  };

  const Validation = () => {
    console.log(passwordForm.newPassword.length)
    if (passwordForm.newPassword.length < 6) {
      commonService.forError(
        "Password must be at least 6 characters long",
        "Error"
      );
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Validation()) {
      dispatch(actions.changePassword({ ...passwordForm })).then((res) => {
        if (res.value.success) {
          swal({
            text: "Password Updated",
            className: "logout-alert-modal",
            buttons: [true, "Ok"],
          });
          history.push("/profile-setting");
        }
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="lds_right_box_wrapper">
        <Link to={`/profile-setting`} className="cursor-pointer pr-3">
          <i className="flaticon-left-arrow"></i> Back
          </Link>
          <div className="lds_cp_box">

            <div className="row">
              <div className="col-md-12">
                <div className="lds_cp_box_heading">
                  <h4>current password</h4>
                  <input
                    type="password"
                    name="currentPassword"
                    required
                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                    value={passwordForm.currentPassword}
                  />
                </div>
                <div className="lds_cp_box_heading mt-3">
                  <h4>new password</h4>
                  <input
                    type="password"
                    name="newPassword"
                    required
                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                    value={passwordForm.newPassword}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="lds_cp_box_heading mt-3">
                  <h4>confirm password</h4>
                  <input
                    type="password"
                    name="confirmPassword"
                    required
                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                    value={passwordForm.confirmPassword}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="lds_cp_box_heading mt-3">
                  <button type="submit" className="btn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ChangePasswordForm;
