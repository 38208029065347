import React from "react";
import Select from "react-select";

const SelectInput = ({ options, className, handler, placeholder, ...props }) => {
  const selectOptions = () => {
    if (Array.isArray(options)) {
      // for arrays of objects
      if (typeof options[0] === "object") {
        return options.map((option) => ({ label: option.name, value: option.id }));
      // for arrays of strings
      } else if (typeof options[0] === "string") {
        return options.map((option) => ({ label: option, value: option }));
      }
    // for an objects that are not arrays
    } else if (typeof options === "object") {
      return Object.entries(options).map(([key, value]) => ({
        label: value.name,
        value: key,
      }));
    }
    return [];
  };

  return (
    <Select
      {...props}
      options={selectOptions()}
      placeholder={placeholder}
      className={`${className} select__input`}
    />
  );
};

export default SelectInput;
