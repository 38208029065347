import { ProjectActionTypes } from './actionType';
import * as API from '../../../api/ProjectAPI';
//
//function getHistory() {
//    const storeState = store.getState();
//    const history = storeState.historyReducer.history;
//    return history;
//}


export const getProjects = (params) => dispatch => dispatch({
    type: ProjectActionTypes.GET_PROJECTS,
    payload: API.getProjects(params)
});
export const getProject = ({ id }) => dispatch => dispatch({
    type: ProjectActionTypes.GET_PROJECT,
    payload: API.getProject(id)
});

export const getLudisProjects = () => dispatch => dispatch({
    type: ProjectActionTypes.GET_LUDIS_PROJECTS,
    payload: API.getLudisProjects()
});

export const createProject = ({ body }) => dispatch => dispatch({
    type: ProjectActionTypes.CREATE_PROJECT,
    payload: API.createProject(body)
});
export const deleteProject = ({ id }) => dispatch => dispatch({
    type: ProjectActionTypes.DELETE_PROJECT,
    payload: API.deleteProject(id)
});
export const updateProject = ({ body, id }) => (dispatch) => 
  dispatch({
    type: ProjectActionTypes.UPDATE_PROJECT,
    payload: API.updateProject(body, id)
});

export const datasets = () => dispatch => dispatch({
    type: ProjectActionTypes.GET_DATASETS,
    payload: API.datasets()
});
export const getProjectNotebooks = (id) => dispatch => dispatch({
    type: ProjectActionTypes.GET_NOTEBOOKS,
    payload: API.getProjectNotebooks(id)
});

export const deleteNotebook = ({ id }) => dispatch => dispatch({
    type: ProjectActionTypes.DELETE_NOTEBOOK,
    payload: API.deleteNotebook(id).then(res => id)
});

export const createProjectShare = (body) => dispatch => 
  dispatch({
    type: ProjectActionTypes.CREATE_PROJECT_SHARE,
    payload: API.createProjectShare(body).then(res => ({...res, ...body}))
  });

export const getProjectShares = (params) => (dispatch) => { 
  dispatch({
    type: ProjectActionTypes.GET_PROJECT_SHARES,
    payload: API.getProjectShares(params)
  });
}

export const setProjectScreen = (params) => (dispatch) => {
  dispatch({
    type: ProjectActionTypes.SET_PROJECT_SCREEN_FULFILLED,
    payload: params
  });
};
