import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getConfig } from "../../redux/actions/FlightingActions/action";

import Form from "../../components/Organizations/Form";

const CreateOrganization = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  return (
    <div>
      <Form />
    </div>
  );
};
export default CreateOrganization;
