import {
  UserActivitiesActionTypes
} from "../actions/UserActivitiesActions/ActionType";

export const initialState = {
  numLogins: {},
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const userActivitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActivitiesActionTypes.GET_NUM_LOGINS_PENDING:
      return updateObject(state, {});
    case UserActivitiesActionTypes.GET_NUM_LOGINS_FULFILLED:
      return updateObject(state, { numLogins: action.payload.numLogins });
    case UserActivitiesActionTypes.CLEAR_NUM_LOGINS:
      return updateObject(state, { numLogins: {} });
    default:
      return state;
  }
}