import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as commonService from "utility/CommonService.js";
import {
  Select,
  Input,
  Button,
  TextArea
} from "components/shared/ui-components/form-components";
import { LANGUAGES } from "config";
const ProjectForm = ({ datasets, handleSubmit, defaultForm, history }) => {
  const [form, setForm] = useState(defaultForm);
  //const [optionsDataset, setOptions] = useState(defaultForm);
  const [loaded, setLoaded] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  function Validation() {
    if (/[^a-zA-Z0-9\-\s/]/.test(form.name)) {
      commonService.forError(
        "Please enter valid project name. No special character allowed.",
        "Error"
      );
      return false;
    } else if (!form.name.trim()) {
      commonService.forError("Empty project name not allowed.", "Error");
      return false;
    }
    return true;
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (Validation()) {
      handleSubmit(form);
    }
  };

  useEffect(() => {
    if (!loaded && datasets) {
      setLoaded(true);
      let options = {};
      datasets.map(
        (dataset) => (options = { ...options, [dataset.id]: dataset.name })
      );
      //setOptions(options);
    }
  }, [loaded, datasets]);
  return (
    <div className="lds_right_box_wrapper">
      <form onSubmit={handleFormSubmit}>
        <div className="lds_cp_heading">
          <Link to={`/projects`} className="cursor-pointer float-left">
            <i className="flaticon-left-arrow"></i> Back
          </Link>
        </div>

        <div className="lds_cp_box">
          <div className="row">
            <div className="col-md-12">
              <div className="lds_cp_box_heading">
                <h4>name of project</h4>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  disabled={form.id ? true : false}
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                  value={form.name}
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_second">
                <h4>Description</h4>
                <TextArea
                  rows="4"
                  name="description"
                  placeholder="Type Here"
                  onChange={handleChange}
                  value={form.description}
                  required
                ></TextArea>
              </div>
            </div>
            <div className="col-md-12"></div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_second">
                <h4>select language</h4>
                <Select
                  placeholder="Languages"
                  name="language"
                  className={form.id ? "disabled" : ""}
                  options={LANGUAGES}
                  onChange={(e) =>
                    handleChange({
                      target: { value: e.value, name: "language" }
                    })
                  }
                  value={{ label: form.language, value: form.language }}
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_third">
                <Button type="submit" className="btn">
                  {defaultForm.id ? "Update" : "Create"} project
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProjectForm;
