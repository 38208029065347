
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from 'react-bootstrap';
import * as actions from "../../../redux/actions/WorkflowActions"
import * as projectActions from "../../../redux/actions/ProjectsActions"
import * as commonService from "utility/CommonService.js";
import ScheduleInput from './ScheduleInput';

const CreateWorkflowModal = ({ show, setShowCreateWorkflowModal, setShowWorkflowPublishModal}) => {

    const dispatch = useDispatch();
    const { project } = useSelector((state) => state.projectsReducer);
    const [name, setName] = useState(project?.name || '');
    const [cronExpression, setCronExpression] = useState('');

    useEffect(() => {
        if (project?.name) {
          setName(project.name);
        }
      }, [project]);

    const handleNameChange = (event) => {
      setName(event.target.value);
    };

    const handleSubmit = (event) => {
      if (name.trim() === "") {
        commonService.forError("Please add a workflow name", "Error");
        return;
      }
      event.preventDefault();
      const cronExpr = cronExpression;
      dispatch(
          actions.createWorkflow({
              body: {
                  project_id: project?.id,
                  name: name,
                  schedule: cronExpr
              }
          })
      ).then((res) => {
        setShowCreateWorkflowModal(false)
        if (res.value && res.value.success) {
            commonService.forSuccess(`Workflow Created Successfully`, "Success")
            let fileName = res.value.workflowResp.id + '_dag.py'
            dispatch(
                projectActions.getRepoContent({ body: { project: project, path: "dags" }, id: project.id })
            ).then((res) => {
                let fileItem = res.value.contents.find((item => item.name === fileName))
                if (fileItem) {
                  dispatch(
                    projectActions.getFileContent({ body: { project: project, path: fileItem.path }, id: project.id })
                )
              }
            });
        }
      })
      setName("");
    };

    return (
        <Modal
            className="import_modal"
            size="l"
            show={show}
            onHide={() => setShowCreateWorkflowModal(false)}
            animation={false}
            scrollable={true}
        >
          <Modal.Header closeButton>
            <div className="d-flex align-items-center">
              <h2>Create a Workflow</h2>
            </div>
          </Modal.Header>
          <Modal.Body>
          <div className="col-md-12">
            <div className="publish_modal">
              <h4>Workflow Name</h4>
              <input
                type="text"
                placeholder={""}
                value={name}
                name="name"
                onChange={(e) => {
                  if (!e.nativeEvent.data || e.target.value.trim())
                    handleNameChange(e);
                  return e;
                }}
              />
            </div>
            <ScheduleInput setCronExpression = {setCronExpression}></ScheduleInput>
          </div>
          </Modal.Body>
          <Modal.Footer>
              <Button
                  onClick={handleSubmit}
                  type="submit"
                  value="Submit"
                  className="btn float-right"
              >
                  Create
              </Button>
          </Modal.Footer>
      </Modal>
    );
}

export default CreateWorkflowModal;
