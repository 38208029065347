import React, { useEffect, useState } from "react";
import * as actions from "../../../redux/actions/ProjectActions";
import { useDispatch, useSelector } from "react-redux";
import { ProjectRow } from "../ProjectRow";
import { PAGE_SIZE_CONSTANTS } from "utility/constants/constants";
import PaginationComponent from "components/shared/Pagination/Pagination";
import { getEditAccess } from "utility/utility";

const SharedTab = ({deleteProject}) => {
  const dispatch = useDispatch();
  const { projectShares, projectSharesPageCount } = useSelector((state) => state.projectReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [filterParams, setFilterParams] = useState({
    page: 1,
    pageSize: PAGE_SIZE_CONSTANTS.PROJECTS,
    sortOperator: "DESC",
    orderBy: "created_at",
  });
  //get all projects shared to the current user
  useEffect(() => {
    dispatch(actions.getProjectShares(filterParams));
  }, [dispatch, filterParams]);

  const renderSharedProjects = () => {
    return projectShares.map((project, index) => {
      if (project.type.includes("report")) {
        return (
          <ProjectRow
            project={project}
            key={project.id}
            index={index}
            deleteProject={deleteProject}
            accessLevel={getEditAccess(project, user)}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {/* Project Rows */}
      <div className="tabu__Sper">
        <div className="row">{renderSharedProjects()}</div>
      </div>
      <div className="pagination_container">
        <div className="entity_pagination">
          <PaginationComponent
            setNextPage={() => setFilterParams({...filterParams, page: filterParams.page + 1})}
            setLastPage={() => setFilterParams({...filterParams, page: filterParams.page - 1})}
            page={filterParams.page}
            count={projectSharesPageCount}
          />
        </div>
      </div>
    </>
  );
};

export default SharedTab;
