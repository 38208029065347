import * as d3 from 'd3'

export function Starmap (
  container, 
  linksData, 
  nodesData,
  ) {
    const containerRect = container.getBoundingClientRect();
    const height = containerRect.height;
    const width = containerRect.width;
    // map nodes and links as objects
    const links = linksData.map((d) => Object.assign({}, d));
    const nodes = nodesData.map((d) => Object.assign({}, d));
    // color scheme for nodes 
    const color = () => {
        const scale = d3.scaleOrdinal(d3.schemeCategory10);
        return d => scale(d.class);
    }
    // Node and Tooltip display functions
    const showNodeTooltip = function (event, d) {
        tooltip
            .style("opacity", 1)
            .html(`Wallet Address: ${d.wallet}
                    <br>Group: ${d.class}`)
            .style("left", (event.x/2) + "px")
            .style("top", (event.y/2) + "px")
    }
    const showLinkTooltip = function (event, d) {
        tooltip
            .style("opacity", 1)
            .html(`From: ${d.source.wallet}
                    <br>To: ${d.target.wallet}
                    <br># of Transactions: ${d.value}`)
            .style("left", (event.x/2) + "px")
            .style("top", (event.y/2) + "px")
    }
    const showNodeInfo = function (event, d) {
        clickInfo
            .html(`Wallet Address: 
                    <br><a href="https://etherscan.io/address/${d.wallet}" target="_blank" rel="noopener noreferrer">${d.wallet}</a><br>
                    <br>Group: ${d.class}`);
    }
    const showLinkInfo = function (event, d) {
      clickInfo
          .style("opacity", 1)
          .html(`From: 
                  <br><a href="https://etherscan.io/address/${d.source.wallet}" target="_blank" rel="noopener noreferrer">${d.source.wallet}</a><br>
                  <br>To: 
                  <br><a href="https://etherscan.io/address/${d.target.wallet}" target="_blank" rel="noopener noreferrer">${d.target.wallet}</a><br>
                  <br># of Transactions: ${d.value}`);
    }


    const hideTooltip = function (event, d) {
      tooltip
          .style("opacity", 0)
    }


    const tooltip = d3.select(container)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("color", "white")

    const clickInfo = d3.select(container)
      .append("div")
      .attr("class", "clickInfo")
      .style("position", "absolute")
      .style("top", "0")
      .style("left", "0")
      .style("background-color", "white")
      .style("border-radius", "5px")
      .style("border-style", "solid")
      .style("border-width", "1px")

    const simulation = d3
      .forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.wallet))
      .force("charge", d3.forceManyBody())
      .force("x", d3.forceX())
      .force("y", d3.forceY());

    const svg = d3 // use d3 to create the svg or scaleable vector graphics box to draw the chart on
        .select(container)
        .append("svg") // make the svg
        .attr("viewBox", [-width/2, -height/2, width, height])
        .call(d3.zoom().scaleExtent([0.3,3]).on("zoom", zoomed))


    const link = svg // draw the links on the svg
        .append("g") // make all the links html elements called g
        .attr("stroke", "#999") // define the color of the link lines
        .attr("stroke-opacity", 0.6) // define the opacity of the link lines
        .selectAll("line") // select all line objects
        .data(links) // use the links entries from the data to draw the lines
        .join("line") // adjust the lines when something moves, like if we drag a node or there's a collission
        .attr("stroke-width", d => Math.sqrt(d.value)) // make the links as wide as the square root of the value column (this is a good way to scale things if you have a wide range in values).
        .attr("class", "bubbles")
        .on("mouseover", showLinkTooltip)
        .on("mouseleave", hideTooltip)
        .on("click", showLinkInfo);

    const node = svg // draw the nodes on the svg
        .append("g") // make all nodes html elements called g
        .attr("stroke", "#fff") // define the default color of the nodes
        .attr("stroke-width", 1.5) // draw a stroke 1.5px wide around each node
        .selectAll("circle") // select all circle objects
        .data(nodes)
        .join("circle")
        .attr("class", "bubbles")
        .attr("r", 5)
        .attr("fill", color())
        .on("mouseover", showNodeTooltip)
        .on("mouseleave", hideTooltip)
        .on("click", showNodeInfo);;
  
      

    simulation.on("tick", () => {
        link // adjust the position of every link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);
    
        node.attr("cx", d => d.x).attr("cy", d => d.y); // adjust the position of the nodes
    });
    // zoom function
    function zoomed(event) {
        node.attr("transform", event.transform);
        link.attr("transform", event.transform);
    }

    return {
        // stop simulation and remove d3 components when component is removed 
        destroy: () => {
          simulation.stop();
          svg.remove();
          tooltip.remove();
          clickInfo.remove();
        },
        nodes: () => {
          return svg.node();
        }
      };
}; 

export default Starmap