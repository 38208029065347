import React from "react";

function DataInsight(props) {
    const data = props.data;
    const columnToAverage = props.columnToAverage;

    // Calculate the average of the selected column
    const columnValues = data.map(row => row[columnToAverage]);
    if (!isNaN(parseFloat(columnValues))) {
        const intArray = columnValues.map(str => {
            const num = parseFloat(str, 10);
            return isNaN(num) ? 0 : num;
        });

        const sum = intArray.reduce((a, b) => a + b, 0);
        const average = sum / intArray.length;

        return (
            <div>
                <p>{average.toFixed(2)}</p>
            </div>
        );
    } else {
        const columnValuesSet = new Set(columnValues)

        return (
            <div>
                <p>{columnValuesSet.size}</p>
            </div>
        );
    }

  }

export function ValueBox(
    {plotData, axis, label, rows}
) {
    return(
    <div>
        {plotData?.length > 0 && axis && <div className="axisDisplayXItems">
            <div className="axisDisplayXLabelBox">
                {label}
            </div>
            <div className="axisDisplayXNumFont">
                {
                    <DataInsight data={plotData[0]} columnToAverage={axis}></DataInsight>
                }
            </div>
            <div className="axisDisplayXSubLabelFont">
                {rows && <p>
                    based on {rows[1]} rows
                </p>}
            </div>
        </div>}
    </div>
    )
}

export default ValueBox