import React from "react";

const EmptyGraph = () => {
  return (
    <div className="empty_graph">
      <img
        src={require(`assets/custom-styles/images/icons/emptyGraph.svg`)}
        alt="img-ins"
        height="136"
        width="190"
      ></img>
      <div className="empty_graph_text">no data yet</div>
    </div>
  )
}

export default EmptyGraph