import React from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';

import { Form } from "components/Account/Form";

class ProfileSetting extends React.Component {
  constructor(props) {
    super(props);
    let body = document.querySelector("body");
    body.classList.add("lds_account_back_main_wrapper")
  }
  componentWillUnmount() {
    let body = document.querySelector("body");
    body.classList.remove("lds_account_back_main_wrapper")
  }

  render() {
    return (
        <div className="lds_right_box_wrapper">
          {/* <div className="lds_cp_heading lds_ap_heading">
            <h1>Account Profile</h1>
          </div> */}
          <div className="lds_cp_box">
            <div className="row">
              <div className="col-md-12">
                <div className="lds_ap_box">
                  <Form user={this.props.user}></Form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}



const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

const mapStateToDispatch = (dispatch) => ({
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ProfileSetting));


