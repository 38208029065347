import React, { useState } from "react";
import * as commonService from "utility/CommonService.js";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';


const Form = ({ defaultForm, handleSubmit, history }) => {
  const [notebookForm, setNotebookForm] = useState(defaultForm);
  const {project} = useSelector(state => state.projectReducer);
  let projectId = project.id;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNotebookForm({ ...notebookForm, [name]: value });
  };
  function Validation() {
    if (!notebookForm.name.trim()) {
      commonService.forError("Empty notebook name not allowed.", "Error");
      return false;
    }
    return true;
  }
  const handleNotebookSubmit = (e) => {
    e.preventDefault();
    if (Validation()) handleSubmit(notebookForm);
  };

  return (
    <form onSubmit={handleNotebookSubmit}>
      <div className="lds_right_box_wrapper">
        <div className="lds_cp_heading">
          {/* <h1>{defaultForm.id ? "Edit" : "Create"} Notebook</h1> */}
          <Link to={`/projects/${projectId}`} className="cursor-pointer float-left">
          <i className="flaticon-left-arrow"></i> Back
          </Link>
        </div>
        <div className="lds_cp_box">
          <div className="row">
            <div className="col-md-12">
              <div className="lds_cp_box_heading">
                <h4>name of notebook</h4>
                <input
                  type="text"
                  placeholder="My new notebook"
                  name="name"
                  required
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                  value={notebookForm.name}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_second">
                <h4>Description</h4>
                <textarea
                  rows="4"
                  placeholder="Type Here"
                  name="description"
                  required
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                  value={notebookForm.description}
                ></textarea>
              </div>
            </div>

            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_third">
                <button type="submit" className="btn">
                  {defaultForm.id ? "Update" : "Create"} notebook
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
