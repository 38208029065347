import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Storage } from "aws-amplify";
import { downloadBlob, getFileNameFromPath } from "utility/utility";
import { AWS_CONSTANTS } from "utility/constants/constants";
import { useDispatch } from "react-redux";
import * as actions from "../../../../redux/actions/DataSetActions"

//Upload progress bar component
const ProgressBarComponent = ({
  filePath,
  fileCount,
  file,
  isUpload,
  handleIncrementCompletedFiles
}) => {
  const [currProgress, setCurrProgress] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    //If this is an upload, put the file in s3
    if (isUpload) {
      Storage.put(filePath, file, {
        progressCallback(progress) {
          setCurrProgress((progress.loaded / progress.total) * 100);
        },
      }).then((res) => {
        dispatch(actions.prependFileToFileList(res))
        handleIncrementCompletedFiles()
      });
    } else {
      //If this is a download, download the file
      let key = file.Key;
      Storage.get(key.replace(`${AWS_CONSTANTS.AMPLIFY_PREFIX}/`, ""), {
        download: true,
        cacheControl: 'no-cache',
        progressCallback(progress) {
          setCurrProgress((progress.loaded / progress.total) * 100);
        },
      }).then((res) => {
        downloadBlob(res.Body, key.slice(key.lastIndexOf("/") + 1)).then(() => {
          handleIncrementCompletedFiles()
        });
      });
    }
  }, [file, filePath, fileCount, isUpload, handleIncrementCompletedFiles, dispatch]);

  return (
    <div key={filePath}>
      <p>{file.name || getFileNameFromPath(file.Key)}</p>
      <ProgressBar striped now={currProgress} />
    </div>
  );
};

export default ProgressBarComponent;
