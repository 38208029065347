export const Web3ActionTypes = {
    GET_STARMAP: "GET_STARMAP",
    GET_STARMAP_PENDING: "GET_STARMAP_PENDING",
    GET_STARMAP_FULFILLED: "GET_STARMAP_FULFILLED",

    GET_COLLECTIONS: "GET_COLLECTIONS",
    GET_COLLECTIONS_PENDING: "GET_COLLECTIONS_PENDING",
    GET_COLLECTIONS_FULFILLED: "GET_COLLECTIONS_FULFILLED",

    ADD_COLLECTION: "ADD_COLLECTION",
    ADD_COLLECTION_PENDING: "ADD_COLLECTION_PENDING",
    ADD_COLLECTION_FULFILLED: "ADD_COLLECTION_FULFILLED",

    VALIDATE_ALLIUM_CONTRACT: "VALIDATE_ALLIUM_CONTRACT",
    VALIDATE_ALLIUM_CONTRACT_PENDING: "VALIDATE_ALLIUM_CONTRACT_PENDING",
    VALIDATE_ALLIUM_CONTRACT_FULFILLED: "VALIDATE_ALLIUM_CONTRACT_FULFILLED",

    GET_DAG_RUN: "GET_DAG_RUN",
    GET_DAG_RUN_PENDING: "GET_DAG_RUN_PENDING",
    GET_DAG_RUN_FULFILLED: "GET_DAG_RUN_FULFILLED",

    GET_BUBBLEWRAP: "GET_BUBBLEWRAP",
    GET_BUBBLEWRAP_PENDING: "GET_BUBBLEWRAP_PENDING",
    GET_BUBBLEWRAP_FULFILLED: "GET_BUBBLEWRAP_FULFILLED",
}