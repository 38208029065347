import { ProjectsActionTypes } from './actionType';
import * as API from '../../../api/ProjectsAPI';


export const getProjects = (params) => dispatch => dispatch({
    type: ProjectsActionTypes.GET_PROJECTS_V2,
    payload: API.getProjects(params)
})

export const getProject = ({ id }) => dispatch => dispatch({
    type: ProjectsActionTypes.GET_PROJECT_V2,
    payload: API.getProject(id)
})

export const checkProject = ({ id }) => dispatch => dispatch({
  type: ProjectsActionTypes.CHECK_PROJECT_V2,
  payload: API.checkProject(id)
})

export const getRepoContent = ({ body, id }) => dispatch => dispatch({
    type: ProjectsActionTypes.GET_REPO_CONTENT,
    payload: API.getRepoContent(body, id)
})

export const getFileContent = ({ body, id }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { projectFilesContent } = state.projectsReducer;
    if (projectFilesContent[body.path]?.code !== undefined) {
        return dispatch({
            type: ProjectsActionTypes.SET_PROJECT_FILE_CONTENT,
            payload: {code: projectFilesContent[body.path]?.code, currentFilePath: body.path, hasUnsavedChanges: projectFilesContent[body.path]?.hasUnsavedChanges}
        })
    }
    return dispatch({
      type: ProjectsActionTypes.GET_FILE_CONTENT,
      payload: API.getFileContent(body, id),
    });
  };

export const commitFileContent = ({body}) => dispatch => dispatch({
    type: ProjectsActionTypes.COMMIT_FILE_CONTENT,
    payload: API.commitFileContent(body)
})

export const deleteGithubFile = (body) => dispatch => dispatch({
    type: ProjectsActionTypes.DELETE_GIT_FILE,
    payload: API.deleteGithubFile(body)
})

export const createProject = ({body}) => dispatch => dispatch({
    type: ProjectsActionTypes.CREATE_PROJECT_V2,
    payload: API.createProject(body)
})

export const getEnvVariables = (params) => dispatch => dispatch({
    type: ProjectsActionTypes.GET_ENV_VARIABLES,
    payload: API.getEnvVariables(params)
})

export const deleteEnvVariable = (id) => dispatch => dispatch({
    type: ProjectsActionTypes.DELETE_ENV_VARIABLE,
    payload: API.deleteEnvVariable(id)
})

export const createEnvVariable = (body) => dispatch => dispatch({
    type: ProjectsActionTypes.CREATE_ENV_VARIABLE,
    payload: API.createEnvVariable(body)
})

export const updateEnvVariable = (body, id) => dispatch => dispatch({
    type: ProjectsActionTypes.UPDATE_ENV_VARIABLE,
    payload: API.updateEnvVariable(body, id)
})

export const updateProject = ({ body, id }) => (dispatch) => dispatch({
    type: ProjectsActionTypes.UPDATE_PROJECT_V2,
    payload: API.updateProject(body, id)
})

export const deleteProject = ({id}) => dispatch => dispatch({
    type: ProjectsActionTypes.DELETE_PROJECT_V2,
    payload: API.deleteProject(id)
})

export const getContainerLogs = (id) => dispatch => dispatch({
    type: ProjectsActionTypes.GET_CONTAINER_LOGS,
    payload: API.getContainerLogs(id)
})

export const createProjectShare = (body) => dispatch =>
  dispatch({
    type: ProjectsActionTypes.CREATE_PROJECT_V2_SHARE,
    payload: API.createProjectShare(body).then(res => ({...res, ...body}))
  });

export const getProjectShares = (params) => (dispatch) => {
  dispatch({
    type: ProjectsActionTypes.GET_PROJECT_V2_SHARES,
    payload: API.getProjectShares(params)
  });
}

export const currentProjectTab = (params) => (dispatch) => {
    dispatch({
      type: ProjectsActionTypes.UPDATE_PROJECT_V2_SHARES_FULFILLED,
      payload: params
    });
}

export const setHasUnsavedChanges = (hasChanges) => (dispatch) => {
    dispatch({
      type: ProjectsActionTypes.SET_HAS_UNSAVED_CHANGES,
      payload: hasChanges
    })
  }


export const getProjectApps = (id) => (dispatch) => dispatch({
        type: ProjectsActionTypes.GET_PROJECT_APPS,
        payload: API.getProjectApps(id)
    })

export const setProjectFileContent = (currentFilePath, code) => (dispatch) => dispatch({
    type: ProjectsActionTypes.SET_PROJECT_FILE_CONTENT,
    payload: {code: code, currentFilePath: currentFilePath, hasUnsavedChanges: true}
})

export const appendContainerLogs = (newLogs) => (dispatch) =>
  dispatch({
    type: ProjectsActionTypes.APPEND_CONTAINER_LOGS,
    payload: newLogs,
  });

export const reappendContainerLogs = () => (dispatch) =>
  dispatch({
    type: ProjectsActionTypes.RESET_CONTAINER_LOGS
  })

export const listDockerImages = () => (dispatch) =>
  dispatch({
    type: ProjectsActionTypes.LIST_DOCKER_IMAGES,
    payload: API.listDockerImages()
  })