import React from 'react';

const DefaultLayout = (props) => {
  return (
      <div className="lds_login_main_wrapper float_left">
        {props.children}
      </div>
  );
};

export default DefaultLayout;