import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getOrganization } from "redux/actions/OrganizationActions/Action";
import { getOrganizationFlighting, getConfig } from "../../redux/actions/FlightingActions/action";

import Form from "../../components/Organizations/Form";

const EditOrganization = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfig()).then(res => {
      dispatch(getOrganizationFlighting(match.params.organizationId));
    });
    dispatch(getOrganization({ id: match.params.organizationId }));
  });

  return (
    <div>
      <Form type="edit" />
    </div>
  );
};
export default EditOrganization;
