import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  clearNumLogins,
  getNumLogins
} from "../../redux/actions/UserActivitiesActions/Action";
import LineGraph from "../shared/Graph/LineGraph";
import TimeRangeIndicator
  from "../shared/Graph/TimeRangeIndicator/TimeRangeIndicator";
import { ROLES } from "utility/constants/constants";
import Loader from "../shared/Loader/Loader";

const UserActivities = ({ user, isSelected }) => {
  const dispatch = useDispatch();
  const { numLogins } = useSelector((state) => state.userActivitiesReducer);
  const [timeRange, setTimeRange] = useState('week')
  const [isLoaded, setIsLoaded] = useState(false)

  const userActivityOptions = {
    legend: {
      position: 'right'
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    },
  }

  const handleTimeChange = (event, newTime) => {
    if (newTime !== null) {
      setTimeRange(newTime)
    }
  }

  useEffect(() => {
    if (isSelected) {
      setIsLoaded(false)
      if (user.role === ROLES.ADMINISTRATOR) {
        dispatch(
          getNumLogins({ organization: user.organization, period: timeRange })
        )
      } else if (user.role === ROLES.SUPERADMIN) {
        dispatch(
          getNumLogins({ period: timeRange })
        )
      }
    }
  }, [dispatch, isSelected, timeRange, user.organization, user.role])

  useEffect(() => {
    if (isSelected) {
      dispatch(clearNumLogins())
    }
  }, [dispatch, isSelected])

  useEffect(() => {
    setIsLoaded(true)
  }, [numLogins])

  return (
    <div className="admin_tab_panel_container">
      <div className="panel_header">Active Users</div>
      <div className="panel user_activities_graph_container align_items_end">
        <TimeRangeIndicator timeRange={timeRange} handleTimeChange={handleTimeChange}
                            className={"time_range_button_group align_self_end"}>
        </TimeRangeIndicator>
        <>
          {
            isLoaded ?
              <LineGraph data={numLogins} x={'date'} y={'logins'} type={'line'}
                         lineLabel={'orgName'} options={userActivityOptions}
                         className={"admin_portal_graph"} /> :
              <Loader loading={!isLoaded}></Loader>
          }
        </>
      </div>
    </div>
  )
}

export default UserActivities