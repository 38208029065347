import * as d3 from 'd3'

export function BubbleWrap(
    container,
    nodesData,
) {
    const containerRect = container.getBoundingClientRect();
    const height = containerRect.height;
    const width = containerRect.width;
    // map nodes as objects
    const nodes = nodesData.map((d) => Object.assign({}, d));
    // color scheme for nodes 
    const color = function () {
        const scale = d3.scaleOrdinal()
            .domain([1, 2, 3, 4, 5, 6, 7, 8])
            .range(["#A48DE2", "#4065C9", "#B8D9F5", "#432692", "#34D582", "#B221D3", "#FF99DB", "#525252"]); // adjust schema category as desired
        return d => (d.spam === 1) ? "#fff" : scale(d.group); // variable to base color on
    }
    // A scale that gives a X target position for each group
    const x = d3.scaleOrdinal()
        .domain([1, 2, 3, 4, 5, 6, 7, 8])
        .range([50, 100, 150, 200, 250, 300, 350, 400])

    // Node and Tooltip display functions
    const showNodeTooltip = function (event, d) {
        tooltip
            .style("opacity", 1)
            .html(`Contract Address: ${d.contract_address}
                    <br>Name: ${d.collection_name}
                    <br>Wallet Overlap: ${(d.overlap_wallet_perc * 100).toFixed(2)}% (${d.current_wallets} wallets)
                    <br>Tokens Held: ${d.tokens_held}`)
            .style("left", (event.x - 100) + "px")
            .style("top", (event.y - 100) + "px")
    }
    const showNodeInfo = function (event, d) {
        clickInfo
            .html(`Contract Address: 
            <br><a href="https://etherscan.io/address/${d.contract_address}" target="_blank" rel="noopener noreferrer">${d.contract_address}</a><br>
            <br>Name: ${d.collection_name}
            <br>Wallet Overlap: ${(d.overlap_wallet_perc * 100).toFixed(2)}% (${d.current_wallets} wallets)
            <br>Tokens Held: ${d.tokens_held}`);
    }
    const hideTooltip = function (event, d) {
        tooltip
            .style("opacity", 0)
    }


    const tooltip = d3.select(container)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "black")
        .style("border-radius", "5px")
        .style("padding", "10px")
        .style("color", "white")

    const clickInfo = d3.select(container)
        .append("div")
        .attr("class", "clickInfo")
        .style("position", "absolute")
        .style("top", "0")
        .style("left", "0")
        .style("background-color", "white")
        .style("border-radius", "5px")
        .style("border-style", "solid")
        .style("border-width", "1px")

    const simulation = d3.forceSimulation()
        .force("x", d3.forceX().strength(0.5).x(d => x(d.group)))
        .force("y", d3.forceY().strength(0.1).y(height / 2))
        .force("center", d3.forceCenter().x(width / 2).y(height / 2)) // Attraction to the center of the svg area
        .force("charge", d3.forceManyBody().strength(1)) // Nodes are attracted one each other of value is > 0
        .force("collide", d3.forceCollide().strength(.1).radius(32).iterations(1)); // Force that avoids circle overlapping

    const svg = d3 // use d3 to create the svg or scaleable vector graphics box to draw the chart on
        .select(container)
        .append("svg") // make the svg
        .attr("viewBox", [-width*0.05, -height*0.05, width, height])
        .call(d3.zoom().scaleExtent([0.3, 3]).on("zoom", zoomed))

    const node = svg // draw the nodes on the svg
        .append("g") // make all nodes html elements called g
        .selectAll("circle") // select all circle objects
        .data(nodes)
        .join("circle")
        .attr("r", d => d.overlap_wallet_perc * 30)
        .attr("cx", width / 2)
        .attr("cy", height / 2)
        .style("fill-opacity", d => (d.spam === 1) ? 0.8 : 1) // base opacity of each node on if spam contract
        .attr("fill", color())
        .attr("stroke", d => (d.spam === 1) ? "#AE0202" : "#fff") // base stroke color around each node on if spam contract
        .attr("stroke-width", d => (d.spam === 1) ? 5 : 1.5) // base stroke width around each node on if spam contract
        .on("mouseover", showNodeTooltip)
        .on("mouseleave", hideTooltip)
        .on("click", showNodeInfo);

    // Apply these forces to the nodes and update their positions.
    // Once the force algorithm is happy with positions ('alpha' value is low enough), simulations will stop.
    simulation
        .nodes(nodes)
        .on("tick", function (d) {
            node
                .attr("cx", d => d.x)
                .attr("cy", d => d.y)
        });

    // zoom function
    function zoomed(event) {
        node.attr("transform", event.transform);
    }
    // Spam Tokens Summary
    const getSpamTokens = function (d) {
        let totalTokens = 0;
        let spamTokens = 0;
        d.forEach((collection, i) => {
            totalTokens += collection.tokens_held;
            if (collection.spam === 1) {
                spamTokens += collection.tokens_held;
            };
        })
        return (`Total Overlapping Tokens Held*: ${totalTokens}       
                <br><b>${((spamTokens / totalTokens) * 100).toFixed(2)}%</b> of Overlapping Tokens Held are in <b style="color:#AE0202;">spam collections</b>
                <br><em>* Overlapping Tokens Held: the total number of tokens held across all overlapping collections where at least 10% of wallets holding assets of the selected collection hold at least 1 token in the overlapping collection</em>`);
    }
    d3.select(".spamText").html(`${getSpamTokens(nodesData)}`)

    return {
        // stop simulation and remove d3 components when component is removed 
        destroy: () => {
            simulation.stop();
            svg.remove();
            tooltip.remove();
            clickInfo.remove();
        },
        nodes: () => {
            return svg.node();
        }
    };
};

export default BubbleWrap