import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "redux-logger";
import promiseMiddleware from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "../reducers";
let composeEnhancers;

const configureStore = () => {
  let middlewares = [thunkMiddleware, promiseMiddleware];
  if (process.env.NODE_ENV === "production") {
    composeEnhancers = compose(applyMiddleware(...middlewares));
  } else {
    middlewares.push(loggerMiddleware);
    composeEnhancers = composeWithDevTools(applyMiddleware(...middlewares));
  }

  const persistConfig = {
    key: "root",
    whitelist: ["authReducer", "awsReducer", "flightingReducer"],
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, reducers);

  let store = createStore(persistedReducer, composeEnhancers);
  let persistor = persistStore(store);
  const storeAndPersistor = { store, persistor };
  return storeAndPersistor;
};

export default configureStore();
