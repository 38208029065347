import * as d3 from 'd3'
import MiniBatchKMeans from "./ClusteringTemplate/minbatch";
import moment from 'moment-timezone';
import { dateFormats } from 'utility/constants/constants';

export const groupAndSummarize = (dataset, xval, yval) => {
    // Group the dataset by xval and groupedval
    const groupedData = d3.group(dataset, d => d[xval]);

    // Initialize an empty array to store the summarized data
    let summarizedData = [];

    // Iterate over the groups
    groupedData.forEach((valueX, keyX) => {
        // Create a new object with the grouped values and the mean
        const summary = {
            [xval]: keyX,
            k:"ALL",
            mean: d3.mean(valueX, d => d[yval]),
            sum: d3.sum(valueX, d => d[yval]),
            median: d3.median(valueX, d => d[yval]),
            variance: d3.variance(valueX, d => d[yval]),
            count: d3.count(valueX, d => d[yval]),
            min: d3.min(valueX, d => parseFloat(d[yval])),
            max: d3.max(valueX, d => parseFloat(d[yval])),
        };

        // Add the summary object to the summarizedData array
        summarizedData.push(summary);
    });
    summarizedData = summarizedData.sort((a, b) =>
    moment(a[xval], dateFormats, true).isValid()
    ?moment(a[xval], dateFormats).diff(moment(b[xval], dateFormats))
    :(a - b)
  )
    return summarizedData;
}

export const groupAndSummarize2Vars = (dataset, xval, groupedval, yval) => {
    // Group the dataset by xval and groupedval
    const groupedData = d3.group(dataset, d => d[xval], d => d[groupedval]);

    // Initialize an empty array to store the summarized data
    const summarizedData = [];

    // Iterate over the groups
    groupedData.forEach((valueX, keyX) => {
        valueX.forEach((valueGrouped, keyGrouped) => {
            // Create a new object with the grouped values and the mean
            const summary = {
                [xval]: keyX,
                [groupedval]: keyGrouped,
                mean: d3.mean(valueGrouped, d => d[yval]),
                sum: d3.sum(valueGrouped, d => d[yval]),
                median: d3.median(valueGrouped, d => d[yval]),
                variance: d3.variance(valueGrouped, d => d[yval]),
                count: d3.count(valueGrouped, d => d[yval]),
                min: d3.min(valueGrouped, d => parseFloat(d[yval])),
                max: d3.max(valueGrouped, d => parseFloat(d[yval])),
            };

            // Add the summary object to the summarizedData array
            summarizedData.push(summary);
        });
    });

    return summarizedData;
}

export const getClusters = (kmeansData, xval, yval, zval, k) => {
    // Run clustering algorithm
    let clusterIndexes = [];
    let clusteredKMeansData
    if(xval && yval) {
        // execute clustering using dataset
        let xy = []
        if (zval) {
            xy = kmeansData.map((d) => [d[xval], d[yval], d[zval]])
        } else {
            xy = kmeansData.map((d) => [d[xval], d[yval]])
        }
        // build clustering model
        const MiniBatch = new MiniBatchKMeans(k, xy.length, 10);
        MiniBatch.fit(xy);

        // cluster assignment step
        clusterIndexes = MiniBatch.assignments

        // Use the map method to add the new column to each object in the array
        clusteredKMeansData = kmeansData.map((element, index) => {
            return { ...element,
                k: clusterIndexes[index] + 1,
                id:index + 1 // account for indexing at 0 vs 1
            };
        });
    } else {
        clusteredKMeansData = kmeansData
        clusteredKMeansData["k"] = "ALL"
    }

    return clusteredKMeansData;
}

export const htmlLegend = (chart, parentClassName, legendClassName) => {
    if(chart.config.data.datasets.length > 0) {
      // get the selected location
      const forecastLegend = document.querySelector(parentClassName)

      // create div
      const div = document.createElement("ID")
      div.setAttribute("id",legendClassName)

      // create ul
      const ul = document.createElement("UL")

      // title
      const header = document.createElement("h5")
      const title = document.createTextNode("Legend")

      ul.appendChild(header)
      header.appendChild(title)

      chart.config.data.datasets.forEach((datasets, index) => {
        const text = parseFloat(datasets.label) ?`Cluster ${datasets.label}`: `${datasets.label}`
        const bgcolor = datasets.pointBackgroundColor
        const bcolor = datasets.pointBorderColor

        //create li in for each loop
        const li = document.createElement("LI")

        //create span color box
        const input = document.createElement("input")
        input.type = "checkbox"
        input.checked = true

        const spanBox = document.createElement("SPAN")

        spanBox.className = "circle"
        spanBox.style.borderColor = bcolor
        spanBox.style.backgroundColor = bgcolor

        //p + text
        const p = document.createElement("P")
        const textNode = document.createTextNode(text)


        li.onclick = () => {
          let meta = chart.getDatasetMeta(index);
          if (meta.hidden === true) {
            meta.hidden = false;
            input.checked = true;
          } else {
            meta.hidden = true;
            input.checked = false;
          }
          li.classList.toggle("fade2")
          chart.update()
        }

        ul.appendChild(li)
        li.appendChild(input)
        li.appendChild(spanBox)
        li.appendChild(p)
        p.appendChild(textNode)
      })

      //insert div into forecastLegend
      forecastLegend.appendChild(div)
      div.appendChild(ul)

      return forecastLegend
    }
  }

export const createAxes = (xValues, xval, yValues, yval, isStacked) => {

    let xAxes = []
    let xScale = ''

    if (moment(xValues[1], dateFormats, true).isValid()) {
      xValues = xValues.sort((a, b) => moment(a, dateFormats).diff(moment(b, dateFormats)));
      xAxes = [{
        type: 'time',
        scaleLabel: {
            display: true,
            labelString: xval,
            fontSize: 15,
        }
      }]
      xScale = "time"
    } else if (!isNaN(xValues[1]) && !isNaN(parseFloat(xValues[1]))) {
        xAxes = [{
            type: 'linear',
            scaleLabel: {
                display: true,
                labelString: xval,
                fontSize: 15,
            }
        }]
    } else {
        xAxes = [{
            type: 'category',
            labels: [...new Set(xValues)],
            scaleLabel: {
                display: true,
                labelString: xval,
                fontSize: 15,
            }
        }]
    }


    let yAxes = []
    let yScale = ''
    if (moment(yValues[1], dateFormats, true).isValid()) {
        yValues = yValues.sort((a, b) => moment(a, dateFormats).diff(moment(b)));
        yAxes = [{
            type: 'time',
            scaleLabel: {
                display: true,
                labelString: yval,
                fontSize: 15,
            }
        }]
      yScale = "time"
    } else if (!isNaN(yValues[1]) && !isNaN(parseFloat(yValues[1]))) {
        yAxes = [{
            type: 'linear',
            scaleLabel: {
                display: true,
                labelString: yval,
                fontSize: 15,
            }
        }]
    } else {
        yAxes = [{
            type: 'category',
            labels: [...new Set(yValues)],
            scaleLabel: {
                display: true,
                labelString: yval,
                fontSize: 15,
            }
        }]
    }

    return [xAxes, xScale, yAxes, yScale]
}