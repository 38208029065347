import React, { useEffect, useRef } from 'react';
import { CAMPAIGN_APP_COLORS } from "utility/constants/constants";
import Chart from 'chart.js';
import { tidy, filter } from '@tidyjs/tidy'
import { htmlLegend } from '../template.util';
import "../CampaignApp/campaignApp.css"
import moment from 'moment-timezone';
import { createAxes } from '../template.util';
import { dateFormats } from 'utility/constants/constants';

Chart.pluginService.register({
    beforeDraw: function (chart, easing) {
        if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
            let ctx = chart.chart.ctx;
            let chartArea = chart.chartArea;

            ctx.save();
            ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
            ctx.fillRect(0, 0, chartArea.right + 10, chartArea.bottom + 60);
            ctx.restore();
        }
    }
});

const ClusterChart = ({data, xval, yval, k, handleClick, vars}) => {
    const chartRef = useRef(null);
    let datasets = []
    let xValues = data.map((d) => d[xval]).sort((a, b) => a - b);
    let yValues = data.map((d) => d[yval]).sort((a, b) => b - a);

    let [xAxes, xScale, yAxes, yScale] = createAxes(xValues, xval, yValues, yval, true)
    for (let i = 1; i < k + 1; i++) {

        const kCluster = tidy(data,
            filter((d) => d.k === i)
        )
        // add in gradients here
        let xy = kCluster.map(d => ({
                x:d[xval],
                y:d[yval],
                id:d["id"]
        }))

        const ids = kCluster.map((d) => d["id"])

        if (xScale === "time") {
            xy = xy.sort((a, b) => moment(a.x, dateFormats).diff(moment(b.x, dateFormats)))
        } else {
            xy = xy.sort((a, b) => a.x - b.x)
        }
        if (yScale === "time") {
            xy = xy.sort((a, b) => moment(a.y, dateFormats).diff(moment(b.y, dateFormats)))
        } else {
            xy = xy.sort((a, b) => a.y - b.y)
        }

        datasets.push({
            label:i,
            text:ids,
            data:xy,
            backgroundColor: CAMPAIGN_APP_COLORS.kColor[i - 1 + vars] + ".2)",
            borderColor: CAMPAIGN_APP_COLORS.kColor[i - 1 + vars] + "1)",
            pointBackgroundColor : CAMPAIGN_APP_COLORS.kColor[i - 1 + vars] + ".2)",
            pointBorderColor : CAMPAIGN_APP_COLORS.kColor[i - 1 + vars] + "1)",
            pointRadius:5,
            showLine: false,
            fill: false
        })
    }

    useEffect(() => {

        if(data.length > 0) {
            let ctx = chartRef.current.getContext('2d');
            const config = {
                type: "scatter",
                data: {
                    datasets:datasets
                },
                options: {
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                                let x = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x
                                let y = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y
                                if (label) {
                                    label += ': ';
                                }
                                label += `(${x}, ${y})`;
                                return label;
                            }
                        }
                    },
                    chartArea: '#fff',
                    onClick: handleClick,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: xAxes,
                        yAxes: yAxes,
                    },
                    legend: {
                        display: false
                    },
                    legendCallback: function(chart) {
                        htmlLegend(chart, ".myInfo", "clusterLegend")
                    },
                }
            };

            // Create the line graph
            let chartCluster = new Chart(ctx, config);
            let currentLegend = document.getElementById("clusterLegend");
            if (currentLegend) {
                currentLegend.remove()
            }
            document.getElementsByClassName('myInfo').innerHTML = chartCluster.generateLegend()

            return () => {
                // Cleanup chart on component unmount
                chartCluster.destroy();
            };

        }
    // Chart should not update when datasets or handleclick are updated. This causes a bug when point is clicked
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, data, xval, yval]);

    return <canvas ref={chartRef}/>;
};

export default ClusterChart;
