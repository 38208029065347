import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import * as commonService from "utility/CommonService.js";
import { Form as FormBootstrap } from "react-bootstrap";
import { forEach } from "lodash";

import {
  createOrganization,
  updateOrganization,
} from "../../redux/actions/OrganizationActions/Action";

import { TIER } from "config";

import {
  Select,
  Input,
  Button,
} from "components/shared/ui-components/form-components";

import { updateFlighting } from "../../redux/actions/FlightingActions/action";

const OrganizationsForm = ({ type }) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const { flightingConfig, configOptions } = useSelector(
    (state) => state.flightingReducer
  );
  const { organization } = useSelector((state) => state.organizationReducer);
  const history = useHistory();
  const simpleValidator = useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    admin_email: "",
    description: "",
    tier: "",
    max_seats: "",
  });
  const [, forceUpdate] = useState();

  const [loaded, setLoaded] = useState(false);
  const [flightLoaded, setFlightLoaded] = useState(false);
  const [flightingChanges, setFlightingChanges] = useState([]);

  const onFormChange = (e) => {
    const tempFlights = [...flightingChanges];
    const index = tempFlights.findIndex((f) => f.key === e.target.id);
    tempFlights[index]["active"] = e.target.checked;
    setFlightingChanges(tempFlights);
  };

  const returnFeatureCheckBoxes = () => {
    const features = [];
    const flightings =
      configOptions && configOptions.flightings?.length
        ? configOptions.flightings
        : [];
    const tempOptions = type === "edit" ? flightingConfig : [...flightings];
    if (tempOptions.length) {
      forEach(tempOptions, (feature) => {
        if (feature.key) {
          const { active, key, name } = feature;
          features.push(
            <div className="mb-3">
              <FormBootstrap.Check
                defaultChecked={active}
                inline
                type="checkbox"
                id={key}
                label={name}
              />
            </div>
          );
        }
        return null;
      });
      return (
        <FormBootstrap flexDirection="row" onChange={onFormChange}>
          {features}
        </FormBootstrap>
      );
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let res;
      if (!form.description) {
        delete form.description
      }

      if (organization) {
        await dispatch(
          updateFlighting(organization.id, { flightings: flightingChanges })
        );

        if (form.description === '') {
          delete form.description
        }

        res = await dispatch(
          updateOrganization({
            body: {
              organization: form,
            },
            id: organization.id,
          })
        );
      } else {
        res = await dispatch(
          createOrganization({
            body: {
              organization: { ...form, flightings: flightingChanges },
            },
          })
        );
      }
      if (res) {
        if (res.value.success) {
          commonService.forSuccess(
            organization
              ? " Organization updated successfully!"
              : "  Organization created successfully!",
            "Success"
          );
        }
        history.push("/organizations");
      }
    } else {
      setSubmitted(true);
      simpleValidator.current.showMessages(); //show validation messages
      forceUpdate(1);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (organization && !loaded) {
      setLoaded(true);
      const {
        name,
        description,
        admin_email,
        max_seats,
        tier,
      } = organization;
      setForm({ name, description, admin_email, max_seats, tier });
    }
  }, [loaded, organization]);

  useEffect(() => {
    if (type !== "edit" && configOptions.flightings?.length && !flightLoaded) {
      setFlightLoaded(true);
      const tempData = [...configOptions.flightings];
      tempData.map((e) => {
        delete e.users;
        e["user_roles"] = ["developer", "management", "administrator", "superadmin"];
        return e;
      });
      setFlightingChanges(tempData);
    } else if (type === "edit" && !flightLoaded && flightingConfig.length) {
      setFlightLoaded(true);
      const tempData = [...flightingConfig];
      setFlightingChanges(tempData);
    }
  }, [configOptions, flightingConfig, flightLoaded, type]);

  return (
    <div className="lds_right_box_wrapper">
      <form onSubmit={handleSubmit}>
        <div className="lds_cp_heading">
          <Link to={"/organizations"} className="cursor-pointer float-left">
            <i className="flaticon-left-arrow"></i> Back
          </Link>
        </div>

        <div className="lds_cp_box">
          <div className="row">
            <div className="col-md-12">
              <div className="lds_cp_box_heading">
                <h4>Name</h4>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={form.name}
                  onChange={handleChange}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("name")
                      : true
                  }
                />
                {simpleValidator.current.message("name", form.name, "required")}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Email</h4>
                <Input
                  type="email"
                  name="admin_email"
                  placeholder="Email"
                  value={form.admin_email}
                  onChange={handleChange}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("admin_email")
                      : true
                  }
                />
                {simpleValidator.current.message(
                  "admin_email",
                  form.admin_email,
                  "required|email"
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Description</h4>
                <textarea
                  rows="3"
                  type="text"
                  name="description"
                  placeholder="description"
                  value={form.description}
                  onChange={handleChange}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("description")
                      : true
                  }
                />{" "}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Tier</h4>
                <Select
                  placeholder="Tier"
                  name="tier"
                  options={TIER}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("tier")
                      : true
                  }
                  onChange={(e) =>
                    handleChange({
                      target: { value: e.value, name: "tier" },
                    })
                  }
                  value={{
                    label: form.tier,
                    value: form.tier,
                  }}
                  required
                />
                {simpleValidator.current.message("tier", form.tier, "required")}
              </div>
            </div>

            <div className="col-md-12">
              <div className="lds_cp_box_heading mt-3">
                <h4>Max Seats</h4>
                <Input
                  type="number"
                  min="0"
                  name="max_seats"
                  placeholder="Max Seats"
                  value={form.max_seats}
                  onChange={handleChange}
                  onBlur={() =>
                    isSubmitted
                      ? simpleValidator.current.showMessageFor("max_seats")
                      : true
                  }
                />{" "}
                {simpleValidator.current.message(
                  "max_seats",
                  form.max_seats,
                  "required"
                )}
              </div>
            </div>
            <a
              href="#/"
              className="col-md-12 mt-4"
              type="collapse"
              role="button"
              data-toggle="collapse"
              data-target="#advancedSettings"
              aria-expanded="false"
              aria-controls="advancedSettings"
            >
              <div className="advanced_settings_btn">
                Advanced Settings
                <img
                  src={require("assets/custom-styles/images/thumbnails/settings_icn.png")}
                  alt="Settings"
                  className="cursor-pointer optionsIcon float-right"
                />
              </div>
            </a>

            <div class="collapse col-md-12" id="advancedSettings">
              <div
                style={{ flexDirection: "row", marginTop: 20, marginLeft: 20 }}
              >
                <h4 className="mb-2">Flighting Configuration</h4>
                {returnFeatureCheckBoxes()}
              </div>
            </div>
            <div className="col-md-12">
              <div className="lds_cp_box_heading lds_cp_box_heading_third">
                <Button type="submit" className="btn">
                  {organization ? "Update" : "Create"} Organization
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default OrganizationsForm;
