import React from "react";
import { Starmap } from "./Starmap";
import styles from "./starmap.module.css";

export function StarmapGenerator({ linksData, nodesData}) {
  const containerRef = React.useRef(null);
  
  React.useEffect(() => {
    let destroyFn;
    
    if (containerRef.current) {
      const { destroy } = Starmap(containerRef.current, linksData, nodesData);
      destroyFn = destroy;
    }
    // destroy current graph and rerender with new data
    return destroyFn; 
  }, [linksData, nodesData]);

  return <div ref={containerRef} className={styles.container} />;
}