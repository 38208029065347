import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

const PublishConfirmationModal = ({ show, setShowPublishConfirmation, workflow, setShowShareModal }) => {
  const history = useHistory();
  const onViewDetails = (e) => {
    if (e) e.stopPropagation();
    history.push(`/workflows/${workflow.id}`);
  }

  const onShare = (e) => {
    if (e) e.stopPropagation();
    setShowPublishConfirmation(false);
    setShowShareModal(true);
  }

  return (
    <Modal
      className="import_modal"
      show={show}
      onHide={() => setShowPublishConfirmation(false)}
      animation={false}
      scrollable={true}
    >
      <Modal.Body>
      <div className="col-md-12 text-center">
        <div className="publish-success-message">
          <CheckCircleIcon className="check-publish"/>
          <h2>Your workflow has been successfully published!</h2>
        </div>
        <div className="button-container">
          <Tooltip
              title={workflow?.published ? "" : "Please wait for initialization" }
              placement="top"
          >
            <span className="confirmation-button-wrapper">
              <Button
                onClick={onViewDetails}
                type="button"
                className="btn btn-primary publish-modal-btn"
                disabled={!workflow?.published}
              >
                View Details
              </Button>
            </span>
          </Tooltip>
          <Button
            onClick={onShare}
            type="button"
            className="btn btn-outline-primary publish-modal-btn-outline"
          >
            Share With Team
          </Button>
        </div>
      </div>
      </Modal.Body>
    </Modal>
  );
};

export default PublishConfirmationModal;
