import { Modal, Table } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/DataSetActions";
import * as commonService from "../../../utility/CommonService";
import { Tooltip, Typography } from "@material-ui/core";

const PreviewFileModal = ({ filePath }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { filePreview, dataSet } = useSelector((state) => state.dataSetReducer);
  const path = filePath;


  const handleClose = () => {
    setShow(false);
    setLoaded(false);
  };

  const previewFile = () => {
    commonService.isLoading.onNext(true);
    dispatch(
      actions.getFilePreview({
        body: { path: path },
        id: dataSet.id
      })
    ).then((res) => {
      commonService.isLoading.onNext(false);
      setLoaded(true)
      setShow(true);
    });
  };

  return (
    <>
      <Tooltip title={<Typography>Preview</Typography>} placement="top" arrow>
        <img
          alt=""
          src={require("assets/custom-styles/images/thumbnails/preview_icn_dark.png")}
          onMouseOver={(e) =>
            (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/preview_icn.png"))
          }
          onMouseLeave={(e) =>
            (e.currentTarget.src = require("assets/custom-styles/images/thumbnails/preview_icn_dark.png"))
          }
          height="50px"
          onClick={previewFile}
          className="cursor-pointer"
        />
      </Tooltip>

      <Modal
        className="s3_object_modal"
        size="xl"
        show={show}
        onHide={handleClose}
        animation={false}
        scrollable={true}
      >
        <Modal.Header closeButton>File Preview</Modal.Header>
        {loaded && (
          <Modal.Body>
            <Table responsive>
              <tbody>
                {/* iterate through file preview contents and display in a bootstrap table */}
                {filePreview &&
                  filePreview.map((row) => {
                    row = row.split(",");
                    return (
                      <tr>
                        {row.map((cell) => {
                          return <td>{cell}</td>;
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default PreviewFileModal;
