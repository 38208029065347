import _ from "lodash";
import {
  ENTITY_TYPES,
  SHARE_TYPE,
  TO_ENTITY_TYPE,
} from "../../../../utility/constants/constants";
import React, { useState } from "react";
import { forError } from "../../../../utility/CommonService";
import { MenuItem } from "@material-ui/core";
import { Select, TextField } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

const StageSharedUsers = ({
  entityType,
  hasOrgShare,
  setHasOrgShare,
  setIsSelectingOrgUsers,
  sharedUsers,
  setSharedUsers,
  user,
  entity,
}) => {
  const [emailInput, setEmailInput] = useState("");
  const [accessLevel, setAccessLevel] = useState("view");

  // add user to "unshare" list upon clicking x on their email to unshare
  const removeEmail = (index) => {
    const tempEmails = [...sharedUsers];
    tempEmails[index]["share_type"] = SHARE_TYPE.UNSHARE;
    if (tempEmails[index]["to_entity_type"] === "Organization" && hasOrgShare) {
      setHasOrgShare(false);
    }
    setSharedUsers(tempEmails);
  };

  // Check if email typed into the input is valid. If so, add the share to the userEmails list.
  const handleEmails = () => {
    const emails = emailInput.split(",");
    let tempEmails = [...sharedUsers];
    emails.forEach((email) => {
      // validate email
      email = email.trim();
      if (email === user.email) {
        forError("Cannot share to yourself!", "Error");
        return;
      }
      const re =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (re.test(email)) {
        const emailIndex = tempEmails.findIndex((el) => el.email === email);
        if (emailIndex >= 0) {
          tempEmails[emailIndex]["share_type"] = SHARE_TYPE.SHARE;
          tempEmails[emailIndex]["access_level"] = accessLevel;
        } else {
          tempEmails = [
            ...tempEmails,
            {
              share_type: SHARE_TYPE.SHARE,
              email,
              access_level: accessLevel,
              to_entity_type: TO_ENTITY_TYPE.USER,
            },
          ];
        }
      } else {
        forError("Email is invalid!", "Error");
      }
    });
    setSharedUsers(tempEmails);
    setAccessLevel("view");
    setEmailInput("");
  };

  // Change access level for user selected
  const handleAccess = (e, index) => {
    if (e.target.value === "revokeAccess") {
      removeEmail(index);
      return;
    }

    const tempData = [...sharedUsers];
    tempData[index].access_level = e.target.value;
    setSharedUsers(tempData);
  };

  return (
    <>
      <div className="select_users">
        <div className="select_org_users_button_container">
          <button
            className="text-small select_org_users_button"
            type="button"
            onClick={() => {
              setIsSelectingOrgUsers(true);
            }}
          >
            Select from Organization
          </button>
        </div>
        <div className="input_individual_users_container">
          <div className="user_email_dropdown_container">
            <TextField
              id="user-email-input"
              name="user-email-input"
              className="user_email_input"
              variant="filled"
              label="Email, comma separated"
              InputProps={{ disableUnderline: true }}
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              multiline
              maxRows={3}
            />
            <Select
              className="user_email_select"
              label="Access"
              value={accessLevel}
              onChange={(e) => setAccessLevel(e.target.value)}
              IconComponent={KeyboardArrowDown}
            >
              <MenuItem value="view">can view</MenuItem>
              {entityType !== ENTITY_TYPES.DATASET && (
                <MenuItem value="execute">can execute</MenuItem>
              )}
              <MenuItem value="edit">can edit</MenuItem>
            </Select>
          </div>
          <button
            type="button"
            disabled={!emailInput}
            className="add_user_button btn"
            onClick={handleEmails}
          >
            add
          </button>
        </div>
      </div>
      <div className="added_users_container">
        <div className="people_with_access_header">People with access</div>
        <div className="added_users">
          <div className="added_user">
            <label style={{ padding: "8px 10px", borderRadius: "5px" }}>
              {entity.user.email}
            </label>
            <Select
              className="added_user_select"
              label="Access"
              value="Owner"
              disabled
              IconComponent={null}
            >
              <MenuItem value="Owner">Owner</MenuItem>
            </Select>
          </div>
          {sharedUsers.length > 0 ? (
            _.uniqBy(sharedUsers, "email").map((data, i) =>
              data.share_type === SHARE_TYPE.SHARE ? (
                <div key={`${i}${data}`} className="added_user">
                  <label
                    key={`${i}v${data}`}
                    style={{ padding: "8px 10px", borderRadius: "5px" }}
                  >
                    {data.email}
                  </label>
                  <Select
                    className="added_user_select"
                    label="Access"
                    value={data.access_level}
                    onChange={(e) => handleAccess(e, i)}
                    IconComponent={KeyboardArrowRight}
                  >
                    <MenuItem value="view">Viewer</MenuItem>
                    {entityType !== ENTITY_TYPES.DATASET && (
                      <MenuItem value="execute">Executor</MenuItem>
                    )}
                    <MenuItem value="edit">Editor</MenuItem>
                    <MenuItem value="revokeAccess">Revoke Access</MenuItem>
                  </Select>
                </div>
              ) : (
                ""
              )
            )
          ) : (
            <div className="added_users_empty_state">
              This has not been shared with any users
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StageSharedUsers;
