import { ProjectsActionTypes } from '../actions/ProjectsActions/actionType';
import { nextStateObject } from "utility/utility";
import { PROJECT_TABS } from 'utility/constants/constants';
import { WorkflowActionTypes } from 'redux/actions/WorkflowActions/actionType';
import { AppActionTypes } from 'redux/actions/AppActions/actionType';


export const initialState = {
    projects: [],
    project: null,
    projectsPageCount: 1,
    currentProjectTab: PROJECT_TABS.MY_PROJECTS,
    projectShares: [],
    projectSharesPageCount: 1,
    repoContents: [],
    envVariables: [],
    previewLogs: "",
    currentFilePath: "",
    hasUnsavedChanges: false,
    projectFilesContent: {},
    dockerImages: [],
    projectPermission: null
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

const updateProjectFileContent = (projectFilesContentCopy, newContent, currentFilePath, hasUnsavedChanges) => {
    let newContentObj = {
        code: newContent,
        originalBase64: projectFilesContentCopy[currentFilePath].originalBase64,
        hasUnsavedChanges: hasUnsavedChanges
    }
    projectFilesContentCopy[currentFilePath] = newContentObj

    return projectFilesContentCopy
}

const resetSavedChangesFlag = (projectFilesContentCopy, gitCommitBody) => {
    gitCommitBody.tree.forEach((fileCommit) => {
        let filePath = fileCommit.path;
        projectFilesContentCopy[filePath] = {...projectFilesContentCopy[filePath], hasUnsavedChanges: false}
    })
    return projectFilesContentCopy
}

export const projectsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ProjectsActionTypes.GET_PROJECTS_V2_FULFILLED:
            return updateObject(state, { projects: action.payload.projects, projectsPageCount: action.payload.pageCount, repoContents: []})
        case ProjectsActionTypes.GET_PROJECT_V2_FULFILLED:
            return updateObject(state, { project: action.payload.project, repoContents: action.payload.project.repoContents || [], currentFilePath: "", projectFilesContent: {}})
        case ProjectsActionTypes.CHECK_PROJECT_V2_FULFILLED:
            return updateObject(state, { projectPermission: action.payload })
        case WorkflowActionTypes.CREATE_WORKFLOW_FULFILLED:
            return updateObject(state, { currentFilePath: "dags" })
        case ProjectsActionTypes.GET_FILE_CONTENT_FULFILLED:
            return updateObject(state, {
              currentFilePath: action.payload.contents.path,
              hasUnsavedChanges: false,
              projectFilesContent: {
                ...state.projectFilesContent,
                [action.payload.contents.path]: {
                  code: atob(action.payload.contents.content),
                  originalBase64: action.payload.contents.content,
                  hasUnsavedChanges: false,
                },
              },
            });
        case ProjectsActionTypes.GET_REPO_CONTENT_FULFILLED:
            return updateObject(state, { repoContents: action.payload.contents, currentFilePath: action.payload.path })
        case ProjectsActionTypes.GET_ENV_VARIABLES_FULFILLED:
            return updateObject(state, { envVariables: action.payload.envVariables })
        case ProjectsActionTypes.UPDATE_PROJECT_V2_FULFILLED:
            let stateObject = { projects: [...state.projects],
            }
            const nextState = nextStateObject(stateObject, action.payload.projectResp);
            return updateObject(state, {
                projects: nextState.projects,
                project: action.payload.projectResp
            });
        case ProjectsActionTypes.DELETE_PROJECT_V2_FULFILLED:
            let curr_projects = [...state.projects];
            curr_projects.splice(
                curr_projects.findIndex((proj) => proj.id === action.payload.id),
                1
            );
            return updateObject(state, { projects: curr_projects });
        case ProjectsActionTypes.APPEND_CONTAINER_LOGS:
            return updateObject(state, { previewLogs: state.previewLogs + action.payload })
        case ProjectsActionTypes.RESET_CONTAINER_LOGS:
            return updateObject(state, { previewLogs: "" })
        case ProjectsActionTypes.GET_PROJECT_V2_SHARES_FULFILLED:
            return updateObject(state, { projectShares: action.payload.entityShares, projectSharesPageCount: action.payload.pageCount, hasUnsavedChanges: false });
        case ProjectsActionTypes.UPDATE_PROJECT_V2_SHARES_FULFILLED:
            return updateObject(state, { currentProjectTab: action.payload });
        case ProjectsActionTypes.COMMIT_FILE_CONTENT_FULFILLED:
            return updateObject(state, { projectFilesContent: resetSavedChangesFlag({...state.projectFilesContent}, action.payload.gitCommitBody)})
        case ProjectsActionTypes.SET_PROJECT_FILE_CONTENT:
            return updateObject(state, {
                projectFilesContent:
                updateProjectFileContent({...state.projectFilesContent}, action.payload.code, action.payload.currentFilePath, action.payload.hasUnsavedChanges),
                currentFilePath: action.payload.currentFilePath,
            })
        case AppActionTypes.CREATE_APP_PREVIEW_FULFILLED:
            return updateObject(state, { previewLogs: ""});
        case ProjectsActionTypes.LIST_DOCKER_IMAGES_FULFILLED:
            return updateObject(state, { dockerImages: action.payload.dockerImages })
        default: return state
    }
}