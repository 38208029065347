import axios from "../config";
import { API_VERSION_2 } from "config";

export const getWorkflows = (params) =>
  axios.get(`${API_VERSION_2}/workflows`, {params});

export const getWorkflowData = (body) =>
  axios.post(`${API_VERSION_2}/workflows/workflowdata/`, body);

export const getWorkflow = (id) =>
  axios.get(`${API_VERSION_2}/workflows/${id}`)

export const getDagInfo = (id) =>
  axios.get(`${API_VERSION_2}/workflows/daginfo/${id}`)

export const getVisualWorkflow = (id) =>
  axios.get(`${API_VERSION_2}/workflows/visual/${id}`)

export const updateWorkflow = (body, id) =>
  axios.put(`${API_VERSION_2}/workflows/${id}`, body);

export const pauseWorkflow = (body, id) =>
  axios.patch(`${API_VERSION_2}/workflows/${id}`, body);

export const markWorkflow = (body, id) =>
  axios.patch(`${API_VERSION_2}/workflows/mark/${id}`, body);

export const runWorkflow = (body, id) =>
  axios.post(`${API_VERSION_2}/workflows/run/${id}`, body);

export const createWorkflow = (body) =>
  axios.post(`${API_VERSION_2}/workflows`, body);

export const publishWorkflow = (body, id) =>
  axios.post(`${API_VERSION_2}/workflows/publish/${id}`, body);

export const publishVisualWorkflow = (body, id) =>
  axios.post(`${API_VERSION_2}/workflows/visual/publish/${id}`, body);

export const getWorkflowRuns = (id) =>
  axios.get(`${API_VERSION_2}/workflows/runs/${id}`);

export const getWorkflowTaskInstances = (id, runId) =>
  axios.get(`${API_VERSION_2}/workflows/tasks/${id}/${runId}`);

export const getWorkflowTaskInstanceLogs = (id, runId, taskId, tryNumber) =>
  axios.get(`${API_VERSION_2}/workflows/tasks/${id}/${runId}/${taskId}/${tryNumber}`);

export const getProjectWorkflows = (params) =>
  axios.get(`${API_VERSION_2}/workflows`, {params});

export const getSharedProjectWorkflows = (params) =>
  axios.get(`${API_VERSION_2}/workflows/share/list`, {params})

export const deleteWorkflow = (id) =>
  axios.post(`${API_VERSION_2}/workflows/delete/${id}`);

export const clearTask = (body, id,) =>
  axios.post(`${API_VERSION_2}/workflows/clear/${id}`, body);

export const getLatestTasks = (body, id) =>
  axios.post(`${API_VERSION_2}/workflows/latest/${id}`, body);

export const createWorkflowShare = (body) =>
  axios.post(`${API_VERSION_2}/workflows/share`, body);

export const getWorkflowShares = (params) =>
  axios.get(`${API_VERSION_2}/workflows/share/list`, {params})

export const updateSchedule = (body, id) =>
  axios.post(`${API_VERSION_2}/workflows/schedule/${id}`, body);

export const saveWorkflow = (body, id) =>
  axios.post(`${API_VERSION_2}/workflows/save/${id}`, body);