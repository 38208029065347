export const ProjectsActionTypes = {

    GET_PROJECTS_V2: "GET_PROJECTS_V2",
    GET_PROJECTS_V2_PENDING: "GET_PROJECTS_V2_PENDING",
    GET_PROJECTS_V2_FULFILLED: "GET_PROJECTS_V2_FULFILLED",

    GET_PROJECT_V2: "GET_PROJECT_V2",
    GET_PROJECT_V2_PENDING: "GET_PROJECT_V2_PENDING",
    GET_PROJECT_V2_FULFILLED: "GET_PROJECT_V2_FULFILLED",

    CHECK_PROJECT_V2: "CHECK_PROJECT_V2",
    CHECK_PROJECT_V2_PENDING: "CHECK_PROJECT_V2_PENDING",
    CHECK_PROJECT_V2_FULFILLED: "CHECK_PROJECT_V2_FULFILLED",

    CREATE_PROJECT_V2: "CREATE_PROJECT_V2",
    CREATE_PROJECT_V2_PENDING: "CREATE_PROJECT_V2_PENDING",
    CREATE_PROJECT_V2_FULFILLED: "CREATE_PROJECT_V2_FULFILLED",

    GET_FILE_CONTENT: "GET_FILE_CONTENT",
    GET_FILE_CONTENT_PENDING: "GET_FILE_CONTENT_PENDING",
    GET_FILE_CONTENT_FULFILLED: "GET_FILE_CONTENT_FULFILLED",

    GET_REPO_CONTENT: "GET_REPO_CONTENT",
    GET_REPO_CONTENT_PENDING: "GET_REPO_CONTENT_PENDING",
    GET_REPO_CONTENT_FULFILLED: "GET_REPO_CONTENT_FULFILLED",

    GET_ENV_VARIABLES: "GET_ENV_VARIABLES",
    GET_ENV_VARIABLES_PENDING: "GET_ENV_VARIABLES_PENDING",
    GET_ENV_VARIABLES_FULFILLED: "GET_ENV_VARIABLES_FULFILLED",

    CREATE_ENV_VARIABLE: "CREATE_ENV_VARIABLE",
    CREATE_ENV_VARIABLE_PENDING: "CREATE_ENV_VARIABLE_PENDING",
    CREATE_ENV_VARIABLE_FULFILLED: "CREATE_ENV_VARIABLE_FULFILLED",

    UPDATE_ENV_VARIABLE: "UPDATE_ENV_VARIABLE",
    UPDATE_ENV_VARIABLE_PENDING: "UPDATE_ENV_VARIABLE_PENDING",
    UPDATE_ENV_VARIABLE_FULFILLED: "UPDATE_ENV_VARIABLE_FULFILLED",

    DELETE_ENV_VARIABLE: "DELETE_ENV_VARIABLE",
    DELETE_ENV_VARIABLE_PENDING: "DELETE_ENV_VARIABLE_PENDING",
    DELETE_ENV_VARIABLE_FULFILLED: "DELETE_ENV_VARIABLE",

    DELETE_PROJECT_V2: "DELETE_PROJECT_V2",
    DELETE_PROJECT_V2_PENDING: "DELETE_PROJECT_V2_PENDING",
    DELETE_PROJECT_V2_FULFILLED: "DELETE_PROJECT_V2_FULFILLED",

    UPDATE_PROJECT_V2: "UPDATE_PROJECT_V2",
    UPDATE_PROJECT_V2_PENDING: "UPDATE_PROJECT_V2_PENDING",
    UPDATE_PROJECT_V2_FULFILLED: "UPDATE_PROJECT_V2_FULFILLED",

    APPEND_CONTAINER_LOGS: "APPEND_CONTAINER_LOGS",
    APPEND_CONTAINER_LOGS_PENDING: "APPEND_CONTAINER_LOGS_PENDING",
    APPEND_CONTAINER_LOGS_FULFILLED: "APPEND_CONTAINER_LOGS_FULFILLED",

    RESET_CONTAINER_LOGS: "RESET_CONTAINER_LOGS",

    CREATE_PROJECT_V2_SHARE: "CREATE_PROJECT_V2_SHARE",
    CREATE_PROJECT_V2_SHARE_PENDING: "CREATE_PROJECT_V2_SHARE_PENDING",
    CREATE_PROJECT_V2_SHARE_FULFILLED: "CREATE_PROJECT_V2_SHARE_FULFILLED",

    GET_PROJECT_V2_SHARES: "GET_PROJECT_V2_SHARES",
    GET_PROJECT_V2_SHARES_PENDING: "GET_PROJECT_V2_SHARES_PENDING",
    GET_PROJECT_V2_SHARES_FULFILLED: "GET_PROJECT_V2_SHARES_FULFILLED",

    COMMIT_FILE_CONTENT: "COMMIT_FILE_CONTENT",
    COMMIT_FILE_CONTENT_PENDING: "COMMIT_FILE_CONTENT_PENDING",
    COMMIT_FILE_CONTENT_FULFILLED: "COMMIT_FILE_CONTENT_FULFILLED",

    DELETE_GIT_FILE: "DELETE_GIT_FILE",
    DELETE_GIT_FILE_PENDING: "DELETE_GIT_FILE_PENDING",
    DELETE_GIT_FILE_FULFILLED: "DELETE_GIT_FILE_FULFILLED",

    UPDATE_PROJECT_V2_SHARES_FULFILLED: "UPDATE_PROJECT_V2_SHARES_FULFILLED",

    SET_HAS_UNSAVED_CHANGES: "SET_HAS_UNSAVED_CHANGES",

    SET_PROJECT_FILE_CONTENT: "SET_PROJECT_FILE_CONTENT",

    GET_PROJECT_APPS: "GET_PROJECT_APPS",
    GET_PROJECT_APPS_PENDING: "GET_PROJECT_APPS_PENDING",
    GET_PROJECT_APPS_FULFILLED: "GET_PROJECT_APPS_FULFILLED",

    LIST_DOCKER_IMAGES: "LIST_DOCKER_IMAGES",
    LIST_DOCKER_IMAGES_FULFILLED: "LIST_DOCKER_IMAGES_FULFILLED",
}