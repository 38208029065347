import React, { useEffect, useState } from "react";

const UserPanelInfo = ({ activeUserCounts }) => {
  const [orgCount, setOrgCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    const sumOrgsAndUsers = (activeUserCounts) => {
      let orgs = 0;
      let users = 0;
      if (activeUserCounts.length > 0) {
        orgs = activeUserCounts.length
        users = activeUserCounts.reduce((accumulator, entry) => accumulator + entry.count, users)
      }

      return {
        orgs: orgs,
        users: users
      }
    }

    if (activeUserCounts) {
      const sums = sumOrgsAndUsers(activeUserCounts)
      setOrgCount(sums.orgs)
      setUserCount(sums.users)
    }
  }, [activeUserCounts])


  return (
    <div className="organization_panel_info">
      <div className="active_user_count align_bottom">
        <span className="count">{orgCount}</span>
        <span className="count_text">organizations</span>
      </div>
      <div className="active_user_count align_bottom">
        <span className="count">{userCount}</span>
        <span className="count_text">users</span>

      </div>
    </div>
  )
}

export default UserPanelInfo