
import React from 'react'
import Plot from 'react-plotly.js'
import { tidy, filter } from '@tidyjs/tidy'

export function KMeans3D(
    { nodesData, xvar, yvar, zvar, k, handlePlotClick}
) {

    // Set plot colors
    const kColor = ["#A48DE2", "#4065C9", "#1f985a", "#432692", "#34D582", "#B221D3", "#FF99DB", "#525252", "#F76737"]

    const plotData = []
    for (let i = 1; i < k + 1; i++) {
        const kCluster = tidy(nodesData,
            filter((d) => d.k === i)
        )
        const kTrace = {
            x: kCluster.map((d) => d[xvar]),
            y: kCluster.map((d) => d[yvar]),
            z: kCluster.map((d) => d[zvar]),
            text: kCluster.map(d => d.id),
            name: 'Cluster ' + i,
            hovertemplate:
                `${xvar}: %{x},
                <br>${yvar}: %{y},
                <br>${zvar}: %{z},
                <br>id: %{text},`
            ,
            mode: 'markers',
            marker: {
                size: 10,
                color: kColor[i - 1],
                opacity: 0.9,
                line: {
                    color: 'rgba(217, 217, 217, 0.14)',
                    width: 3
                },
            },
            type: 'scatter3d'
        }
        plotData.push(kTrace);
    }
    const layout = {
        margin: {
            l: 50,
            r: 50,
            b: 50,
            t: 50
        },
        xaxis: {
            title: {
                text: xvar,
                font: {
                family: 'Courier New, monospace',
                size: 18,
                color: '#7f7f7f'
                }
            },
        },
        yaxis: {
            title: {
                text: yvar,
                font: {
                family: 'Courier New, monospace',
                size: 18,
                color: '#7f7f7f'
                }
            },
        },
        zaxis: {
            title: {
                text: zvar,
                font: {
                family: 'Courier New, monospace',
                size: 18,
                color: '#7f7f7f'
                }
            },
        },
        scene: {
            xaxis: {
                title: xvar,
                zerolinecolor: "black",
                zerolinewidth: 3
            },
            yaxis: {
                title: yvar,
                zerolinecolor: "black",
                zerolinewidth: 3
            },
            zaxis: {
                title: zvar,
                zerolinecolor: "black",
                zerolinewidth: 3
            }
        },
        showlegend: true
    };

    return (
        <Plot data={plotData} layout={layout} className="graph" onClick={handlePlotClick} />
    )
};

export default KMeans3D