import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import * as actions from "../../../redux/actions/AppActions";
import * as commonService from "utility/CommonService.js";
import { AppTile } from "./AppTile";
import { getHighestAccessLevel } from "utility/utility";
import ScrollToTop from "./ScrollToTop";
import InfiniteScroll from "react-infinite-scroll-component";
import { DEFAULT_INSIGHT_OPTIONS } from "utility/constants/constants";
import EmptyScreen from "../../shared/ListPage/emptyScreen";
import {ENTITY_TYPES} from "../../../utility/constants/constants";

const InsightTiles = ({ setAppTypeSort, appTypeSort }) => {
  const dispatch = useDispatch();
  const [rotate, setRotate] = useState(45);

  useEffect(() => {
    // Set the degrees of rotation for the loading label while there is a new page to render
    if(insights.length <= (insightsPageCount - 1) * DEFAULT_INSIGHT_OPTIONS.pageSize) {
      const interval = setInterval(() => setRotate(rotate + 45), 1000);
      return () => {
        clearInterval(interval);
      };
    }
    // No need to include all of the variables inside the useeffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotate]);

  const { user } = useSelector((state) => state.authReducer);
  const { appShares, pinnedApplications, insightsPageCount, insights  } = useSelector(
    (state) => state.appReducer
  );

  const loaderShapeStyle = {
    background: 'rgb(107, 113, 251)',
    height: '4px',
    width: '4px',
    transition: 'transform 1s',
  };

  const loaderLabelStyle = {
    color:'rgb(107, 113, 251)',
    fontFamily: "'Lato', sans-serif",
    fontSize:18,
  }

  const loaderStyle = {
    display:'inline-flex',
    gap: '10px',
    width: '100%',
    height: '25px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  }

  const isPinned = (app) => {
    if (
      pinnedApplications &&
      pinnedApplications.find((pin) => pin.id === app.id)
    )
      return true;
    else return false;
  };

  const handlePin = (app) => {
    dispatch(
      actions.createAppPin({
        body: {
          app_pin: {
            app_id: app.id,
            user_id: user.id,
            pinned: !isPinned(app),
          },
        },
      })
      //update local pinnedApps in reducer to reflect new / updated app pin
    ).then((res) => {
      dispatch(
        actions.updatePinnedApps({
          body: {
            app: app,
            pinned: res.value.applicationPinInfo.pinned,
          },
        })
      );
    });
  };

  const deleteApp = (app) => {
    let id = app.id;
    if (isPinned(app)) {
      handlePin(app);
    }
    swal({
      text: "Are you sure you want remove this from the insights page?",
      className: "logout-alert-modal",
      buttons: [true, "Delete"],
    }).then((result) => {
      if (result) {
        dispatch(actions.deleteApp({ id })).then((res) => {
          if (res.value && res.value.success) {
            commonService.forSuccess("App Deleted!", "Success");
          }
        });
      }
    });
  };

  const deleteAppShare = (app) => {
    let id = app.id;
    //unpin before deleting if necessary
    if (isPinned(app)) {
      handlePin(app);
    }
    swal({
      text: "Are you sure you want remove this from the insights page?",
      className: "logout-alert-modal",
      buttons: [true, "Delete"],
    }).then((result) => {
      if (result) {
        dispatch(actions.deleteAppShare(id)).then(
          (res) => {
            if (res.value && res.value.success) {
              commonService.forSuccess("App Deleted!", "Success");
            }
          },
          [dispatch]
        );
      }
    });
  };

  useEffect(() => {
    dispatch(actions.getAppPins({ params: null }));
    dispatch(actions.getAppPins({ params: null }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.getInsights({appTypeSort}));
  }, [dispatch, appTypeSort]);

  const renderApps = () => {
      return insights.map((app, index) =>
        <AppTile
          key={app.id}
          app={app}
          index={index}
          handlePin={handlePin}
          isPinned={isPinned(app)}
          deleteApp={appShares.includes(app) ? deleteAppShare : deleteApp}
          appsPage={appTypeSort.page}
          accessLevel={getHighestAccessLevel(app, user)}
        />
      )
  };

  return (
    <div className="col-md-12" style={{paddingBottom:'30px'}}>
      <InfiniteScroll
        dataLength={insights.length}
        next={() => {
          setAppTypeSort({...appTypeSort, page: appTypeSort.page + 1})
        }}
        hasMore={insights.length <= (insightsPageCount - 1) * DEFAULT_INSIGHT_OPTIONS.pageSize && insights.length !== 0}
        loader={
        <div style={loaderStyle}>
          <div style={loaderLabelStyle}> Loading </div>
          <div style={{...loaderShapeStyle, transform: `rotate(${rotate}deg)`}}></div>
          <div style={{...loaderShapeStyle, transform: `rotate(${rotate + 45}deg)`}}></div>
          <div style={{...loaderShapeStyle, transform: `rotate(${rotate}deg)`}}></div>
        </div>}
      >
        <div className="lds_po_tabs_main_box">
          <div className="row">
            <div className="tab-content float_left">
              <div className="col-md-12">
                <div className="row">{insights.length !== 0 ?renderApps():<EmptyScreen entity={ENTITY_TYPES.INSIGHT}></EmptyScreen>}</div>
              </div>
            </div>
          </div>
        </div>
      </InfiniteScroll>
      <ScrollToTop>
      </ScrollToTop>
    </div>
  );
};

export default InsightTiles;
