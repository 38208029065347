import React, { useEffect } from "react";
import { StepTitle } from "./StepTitle";
import "./TemplateCreation.scss";

export const AppInfoPanel = (props) => {
  const { currStep, form, setForm, setNextPageReady } = props;

  useEffect(() => {
    if (form.name !== "" && form.description !== "") {
      setNextPageReady(true);
    } else {
      setNextPageReady(false);
    }
  }, [form, setNextPageReady]);

  return (
    <div className="appInputPanel">
      <StepTitle currStep={currStep} />
      <div className="appContentPanel">
        <h4>App Name</h4>
        <div>
          <input
            className="inputPanelStyle"
            type="text"
            value={form.name}
            name="name"
            required
            onChange={(e) => {
              if (!e.nativeEvent.data || e.target.value.trim())
                setForm({ ...form, name: e.target.value });
              return e;
            }}
          />
        </div>
        <h4>App Description</h4>
        <div>
          <input
            className="inputPanelStyle"
            type="text"
            value={form.description}
            name="name"
            onChange={(e) => {
              if (!e.nativeEvent.data || e.target.value.trim())
                setForm({ ...form, description: e.target.value });
              return e;
            }}
          />
        </div>
      </div>
    </div>
  );
};
