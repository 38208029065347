export const AuthActionTypes = {

    LOGIN: "LOGIN",
    LOGIN_PENDING: "LOGIN_PENDING",
    LOGIN_FULFILLED: "LOGIN_FULFILLED",

    REFRESH_TOKEN: "REFRESH_TOKEN",
    REFRESH_TOKEN_PENDING: "REFRESH_TOKEN_PENDING",
    REFRESH_TOKEN_FULFILLED: "REFRESH_TOKEN_FULFILLED",

    SIGNUP: "SIGNUP",
    SIGNUP_PENDING: "SIGNUP_PENDING",
    SIGNUP_FULFILLED: "SIGNUP_FULFILLED",

    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    FORGOT_PASSWORD_PENDING: "FORGOT_PASSWORD_PENDING",
    FORGOT_PASSWORD_FULFILLED: "FORGOT_PASSWORD_FULFILLED",

    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_PENDING: "RESET_PASSWORD_PENDING",
    RESET_PASSWORD_FULFILLED: "RESET_PASSWORD_FULFILLED",

    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_PASSWORD_PENDING: "CHANGE_PASSWORD_PENDING",
    CHANGE_PASSWORD_FULFILLED: "CHANGE_PASSWORD_FULFILLED",

    AUTHORIZE: "AUTHORIZE",
    LOGOUT: "LOGOUT",

    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_PENDING: "UPDATE_USER_PENDING",
    UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",

    GET_IMAGE_ACCESS: "GET_IMAGE_ACCESS",
    GET_IMAGE_ACCESS_PENDING: "GET_IMAGE_ACCESS_PENDING",
    GET_IMAGE_ACCESS_FULFILLED: "GET_IMAGE_ACCESS_FULFILLED",

    GIT_DETAILS: "GIT_DETAILS",
    GIT_DETAILS_PENDING: "GIT_DETAILS_PENDING",
    GIT_DETAILS_FULFILLED: "GIT_DETAILS_FULFILLED",

    ENABLE_2FA: "ENABLE_2FA",
    ENABLE_2FA_PENDING: "ENABLE_2FA_PENDING",
    ENABLE_2FA_FULFILLED: "ENABLE_2FA_FULFILLED",

    VERIFY_2FA: "VERIFY_2FA",
    VERIFY_2FA_PENDING: "VERIFY_2FA_PENDING",
    VERIFY_2FA_FULFILLED: "VERIFY_2FA_FULFILLED",
};


export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY"
}
