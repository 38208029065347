import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import EntityList from "./EntityList";
import {ENTITY_TYPES, PAGE_SIZE_CONSTANTS} from "../../../utility/constants/constants";
import CreateAndImportButtons from "../CreateAndImportButtons";

const ListPage = ({location}) => {
  const {user} = useSelector(state => state.authReducer);

  const [entityName, setEntityName] = useState("");
  const [entityType, setEntityType] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showCreateWorkflowModal, setShowCreateWorkflowModal] = useState(false);
  const [showProjectSelectModal, setShowProjectSelectModal] = useState(false);
  const [showCreateVisualWorkflowModal, setShowCreateVisualWorkflowModal] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    description: "",
    repoAuthor: user.git_username,
  });

  const [filterParams, setFilterParams] = useState({
    page: 1,
    pageSize: PAGE_SIZE_CONSTANTS.ROWS,
    sortOperator: "DESC",
    orderBy: "created_at",
  });
  const [sharedFilterParams, setSharedFilterParams] = useState({
    page: 1,
    pageSize: PAGE_SIZE_CONSTANTS.ROWS,
    sortOperator: "DESC",
    orderBy: "created_at",
  });

  useEffect(() => {
    if (location.pathname === "/projectsv2") {
      setEntityType(ENTITY_TYPES.PROJECT_V2)
      setEntityName("Projects")
    } else if (location.pathname === "/datasets") {
      setEntityType(ENTITY_TYPES.DATASET)
      setEntityName("Datasets")
    } else if (location.pathname === "/workflows") {
      setEntityType(ENTITY_TYPES.WORKFLOW)
      setEntityName("Workflows")
    }
  }, [location]);

  return (
    <div className="lds_right_box_wrapper lds_right_po_box_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="lds_po_heading">
              <h1>{entityName}</h1>
            </div>
          </div>
          <div className="col-md-9 col-sm-12">
            <div className="lds_po_cp_btn">
              <CreateAndImportButtons entityType={entityType} showImportModal={showImportModal}
                                      setShowImportModal={setShowImportModal}
                                      showCreateModal={showCreateModal} setShowCreateModal={setShowCreateModal}
                                      showCreateWorkflowModal={showCreateWorkflowModal}
                                      setShowCreateWorkflowModal={setShowCreateWorkflowModal}
                                      showProjectSelectModal={showProjectSelectModal}
                                      setShowProjectSelectModal={setShowProjectSelectModal}
                                      showCreateVisualWorkflowModal={showCreateVisualWorkflowModal}
                                      setShowCreateVisualWorkflowModal={setShowCreateVisualWorkflowModal}
                                      newProject={newProject} setNewProject={setNewProject}
                                      user={user} filterParams={filterParams}/>
            </div>
          </div>
        </div>
        <EntityList entityName={entityName} entityType={entityType} user={user} filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    sharedFilterParams={sharedFilterParams} setSharedFilterParams={setSharedFilterParams}/>
      </div>
    </div>
  )
};

export default ListPage;