import axios from "../config";
import { API_VERSION, API_VERSION_2 } from "config";

export const getReports = (params) =>
  axios.get(`${API_VERSION}/applications/reports`, {params});

export const getApp = (id) => axios.get(`${API_VERSION}/applications/${id}`);

export const getGraphData = (body, id) => axios.post(`${API_VERSION}/templates/graph.data/${id}`, body);

export const getForecastData = (body, id) => axios.post(`${API_VERSION}/templates/forecast/${id}`, body);

export const getColumnNames = (id) => axios.get(`${API_VERSION}/templates/col.names/${id}`);

export const paginateData = (body, id) => axios.post(`${API_VERSION}/templates/data.paginate/${id}`, body);

export const datapoint = (body, id) => axios.post(`${API_VERSION}/templates/data.paginate/${id}`, body);

export const createApp = (body) =>
  axios.post(`${API_VERSION}/applications`, body);

export const createAppV2 = (body) =>
  axios.post(`${API_VERSION_2}/applications/create`, body);

export const importApp = (body) =>
  axios.post(`${API_VERSION}/applications/importapp`, body);

export const importReport = (body) =>
  axios.post(`${API_VERSION}/applications/importreport`, body);

export const updateApp = (body, id) =>
  axios.put(`${API_VERSION}/applications/${id}`, body);

export const deleteApp = (id) =>
  axios.delete(`${API_VERSION}/applications/${id}`);

export const getApplications = (params) =>
  axios.get(`${API_VERSION}/applications/applications`, { params });

export const getInsights = (params) =>
  axios.get(`${API_VERSION}/applications/insights`, { params });

export const getAppPins = (params) =>
  axios.get(`${API_VERSION}/applications/pin/list`, params);

export const createAppPin = (body) =>
  axios.post(`${API_VERSION}/applications/pin`, body);

export const createAppShare = (body) =>
  body.directory_id
    ? axios.put(`${API_VERSION}/directory/share`, body)
    : axios.post(`${API_VERSION}/applications/share`, body);

export const unshareApp = (id, body) =>
  axios.put(`${API_VERSION}/applications/share/${id}/unshare`, body);

export const getAppShares = (params) =>
  axios.get(`${API_VERSION}/applications/share/list`, { params });

export const deleteAppShare = (id) =>
  axios.delete(`${API_VERSION}/applications/share/${id}`);

export const reStartAppShare = (body) =>
  axios.post(`${API_VERSION}/applications/restart`,body);

export const stopAppShare = (body) =>
  axios.post(`${API_VERSION}/applications/stop`,body);

export const statusAppShare = (body) =>
  axios.post(`${API_VERSION}/applications/status`,body);

export const redeployApp = (body, id) =>
  axios.post(`${API_VERSION_2}/applications/redeploy/${id}`, body);
