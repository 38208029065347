import React from "react";

import ChangePasswordForm from "../../../components/Auth/ChangePassword/ChangePasswordForm";

const ChangePassword = ({ history }) => {
    const defaultForm = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      };
return <ChangePasswordForm history={history} defaultForm = {defaultForm} ></ChangePasswordForm>;
}

export default ChangePassword;