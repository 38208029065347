import React from 'react';

import Sidebar from "components/shared/NavMenu/NavMenu";
//import Chat from "components/shared/ChatBox";
const UserLayout = ({children}) => {
    return (
        <>
        {/* <Chat></Chat> */}
          <Sidebar></Sidebar>

            {children}

        </>
    );
  };

export default UserLayout;