import React, {useEffect, useState}  from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as actions from "redux/actions/NotebookActions";
import EditNotebookForm from "components/NoteBooks/Form";

const EditForm = ({history}) => {
  const dispatch = useDispatch();
  const {notebook} = useSelector(state => state.notebookReducer);
  let id = history.location.pathname.split("/")[2];

  const [dataLoaded, setLoaded] = useState(false)
  const handleSubmit = (notebookForm) => {
   const {name, description} = notebookForm;
    dispatch(actions.updateNotebook({body: {notebook: {name, description}}, id: notebookForm.id})).then(res => {
      if (res.value.success){
        history.push(`/projects/${notebook.project_id}`);
      }
    });
  };
  useEffect(() => {
    if (!notebook || notebook.id !== parseInt(id)) {
      dispatch(actions.getNotebook({ id })).then(res => {
        setLoaded(true);
      });
    } else if (notebook.id === parseInt(id)) setLoaded(true);
  }, [notebook, dispatch, history, id]);
  return(
      <>{
        notebook && dataLoaded && <EditNotebookForm handleSubmit={handleSubmit} defaultForm={notebook} history={history}/>
        
      }</>
  )
};

export default EditForm
