import React from "react";
import '../CampaignApp/campaignApp.css'

export function DataPointInfo(
    {columns, data}
) {
    return (
        <div className="dataInfoContainer">
            <div className=".DataPointInfoBodyValue">
                {data && columns &&
                <div className="DataPointInfoBodyValueInside">
                    {data[0].map((element, index) => (
                        <div key={columns[index]} className="datapoints">
                            <div className={"datapointLabel"}>{`${columns[index]}:`}</div>
                            <div className="datapointValue">{element}</div>
                        </div>
                    ))}
                </div>}
            </div>
        </div>
    );
}

export default DataPointInfo